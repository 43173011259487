import { CheckIcon } from '@heroicons/react/solid';
import { Course } from '@shared/common';
import styles from '@styles/components/course-chapters.module.scss';
import { Typography } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Check from '../../../assets/icons/check-icon-green.svg';

interface CourseChaptersProps {
  modules: any[];
  progress: {
    course: Course;
    completedLessonIds: string[];
    completedQuizzIds: string[];
  };
}

// Util function
function isModuleCompleted(chapterLessons: any[], completedIds: string[]) {
  const count = chapterLessons.reduce((count, lesson) => {
    return (
      count + Number(Boolean(completedIds.find((id) => id === lesson._id)))
    );
  }, 0);

  return count / chapterLessons.length === 1;
}

const CourseChapters: React.FC<CourseChaptersProps> = ({
  modules,
  progress,
}) => {
  const { pathname } = useLocation();

  return (
    <div>
      <div className={styles.lessonContainer}>
        {modules.map((module, index) => {
          const isModuleComplete = isModuleCompleted(module.lessons, [
            ...progress.completedLessonIds,
            ...progress.completedQuizzIds,
          ]);

          return (
            <Link key={module._id} to={`${pathname}/chapters/${index}`}>
              <div
                className={styles.lessonCard}
                data-completed={isModuleComplete}
              >
                <div
                  className={styles.lessonCardBanner}
                  style={{
                    background: isModuleComplete
                      ? `#F4F4F6`
                      : `linear-gradient(153deg, ${progress?.course?.color}, #34377A)`,
                  }}
                >
                  <p className={styles.lessonCardIndex}>{index + 1}</p>
                </div>
                <div className={styles.lessonCardInfo}>
                  <div>
                    {isModuleComplete ? null : (
                      <span
                        style={{
                          color: progress?.course?.color,
                          fontWeight: 900,
                          font: 'normal normal bold 12px Open Sans',
                          letterSpacing: '0.45px',
                          fontSize: '12px',
                        }}
                      >
                        Next Up!
                      </span>
                    )}

                    <p className={styles.lessonCardTitle}>{module.title}</p>
                  </div>
                  {isModuleComplete ? (
                    // TODO remove this if it will no longer be used
                    // <CheckIcon className={styles.checkIcon} />
                    <img src={Check} />
                  ) : null}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default CourseChapters;
