import classNames from 'classnames';
import React from 'react';
import './LoadingSpinner.style.scss';

interface LoadingSpinnerProps {
  size?: 'default';
  color?: 'light';
  className?: string;
  inheritColor?: boolean;
}

export const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const { size = 'default', color = 'light', className, inheritColor } = props;

  return (
    <div
      className={classNames(
        'loading-spinner',
        size,
        color,
        { 'inherit-color': inheritColor },
        className
      )}
    />
  );
};
