import { ErrorCodes } from '@shared/constants';
import { message } from 'antd';
import { useMutation, UseMutationOptions } from 'react-query';
import { useEnvironmentState } from '../contexts';

const useAuthMutation = <RequestData, ResponseData>(
  action: (data: RequestData) => Promise<ResponseData>,
  options: UseMutationOptions<ResponseData, Error, RequestData> = {}
) => {
  const { device } = useEnvironmentState();
  const isWeb = device === 'web';

  return useMutation(action, {
    ...options,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any, variables, context) => {
      if (options.onError) {
        options.onError(error, variables, context);
        return;
      }

      if (isWeb) {
        if (
          error?.response?.data?.error?.code === ErrorCodes.TOKEN_EXPIRED ||
          error?.response?.error?.code === ErrorCodes.TOKEN_EXPIRED ||
          error?.error?.code === ErrorCodes.TOKEN_EXPIRED ||
          error?.code === ErrorCodes.TOKEN_EXPIRED
        ) {
          message.error('Your session has expired. Please login again.');
        } else {
          message.error(
            error.response?.data?.error?.message ||
              error.response?.error?.message ||
              error.error?.message ||
              error?.message ||
              'An error occurred'
          );
        }
      }
    },
    onSettled: (data, error, variables, context) => {
      if (options.onSettled) {
        options.onSettled(data, error, variables, context);
        return;
      }

      if (!data) return;

      if (isWeb) {
        if (typeof data === 'object' && 'message' in data) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          message.success((data as any).message);
        } else {
          message.success('Successful operation');
        }
      }
    },
  });
};

export default useAuthMutation;
