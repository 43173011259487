import { RenderRoutes, PageDisplay, MentorAfterMeeting } from '@components';
import Admin from '@modules/Admin';
import Licenses from '@modules/Licenses';
import NotificationsPage from '@modules/Notifications';
import Sales from '@modules/Sales';
import AccountPage from '../Account';
import LessonDisplay from './LessonDisplay';
import Meeting from './Meeting';
import MeetingClient from './Meeting/Client';
import MeetingAlreadyEnded from './Meeting/MeetingAlreadyEnded';

const Base = () => {
  return (
    <RenderRoutes
      routes={[
        { path: '/account', component: AccountPage },
        { path: '/lesson/:id', component: PageDisplay },
        { path: '/lesson', component: LessonDisplay },
        { path: '/meeting/speed-test', component: Meeting },
        { path: '/meeting/mentor-after', component: MentorAfterMeeting },
        { path: '/meeting/already-ended', component: MeetingAlreadyEnded },
        { path: '/meeting/:meetingId', component: MeetingClient },
        { path: '/admin', component: Admin },
        { path: '/sales', component: Sales },
        { path: '/licenses', component: Licenses },
        { path: '/notifications', component: NotificationsPage },
      ]}
      redirectPath={'/admin'}
    />
  );
};

export default Base;
