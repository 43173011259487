import { MentorSelectorValue } from '@components/Selectors/MentorSelector';
import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import { CreateEnrollment } from '@shared/apis/enrollments.apis';
import { subjectsOptions } from '@shared/constants';
import {
  useCreateEnrollment,
  useGetAllCourses,
  useGetEnrollments,
  useStudentDetails,
} from '@shared/react';
import { message } from 'antd';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import '../EnrollmentsModalMentors/enrollments-modal-mentors.styles.scss';
import AgoraBigSelector from '../Utils/InputsV3/AgoraBigSelector';
import AgoraCourseSelector from '../Utils/InputsV3/AgoraCourseSelector';
import AgoraDatePicker from '../Utils/InputsV3/AgoraDatePicker';
import AgoraMentorSelector from '../Utils/InputsV3/AgoraMentorSelector';
import AgoraNewInput from '../Utils/InputsV3/AgoraNewInput';
import AgoraStudentSelector from '../Utils/InputsV3/AgoraStudentSelector';

type Props = {
  currentEnrolment: string;
  handleCurrentPage: (page: string) => void;
  userId: string;
  refetchEnrollments: () => any;
  newEnrolmentTeaching: boolean;
};

const NewEnrolmentPage = ({
  currentEnrolment,
  handleCurrentPage,
  userId,
  refetchEnrollments,
  newEnrolmentTeaching,
}: Props) => {
  const [selectedCourse, setSelectedCourse] = useState<null | string>(null);
  const [selectedSubject, setSelectedSubject] = useState<null | string>(null);

  const [selectedMentor, setSelectedMentor] = useState<MentorSelectorValue>({
    choice: {
      label: 'TBD',
      value: 'TBD',
    },
  });

  const [selectedStudent, setSelectedStudent] = useState<any>({
    choice: {
      label: 'TBD',
      value: 'TBD',
    },
  });

  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [estimatedEndDate, setEstimatedEndDate] = useState<Moment | null>(
    moment().add(1, 'years')
  );
  const [nrSessions, setNrSessions] = useState(10);

  const { data: courses } = useGetAllCourses();
  const { data: userData, refetch: refetchUserData } =
    useStudentDetails(userId);
  const { refetch: refetchUserEnrollments } = useGetEnrollments(userId);

  const { mutate: createEnrollment, isLoading: creatingEnrollment } =
    useCreateEnrollment({
      onSuccess: () => {
        refetchUserData();
        refetchUserEnrollments();
        message.success('Enrollment created successfully');
        setSelectedCourse(null);
        handleCurrentPage('enrollments');
        refetchEnrollments();
      },
    });

  const handleEnrollBeingTaught = () => {
    if (!selectedCourse || !selectedSubject) {
      message.error('Please select a course and a subject');
      return;
    }

    const course = courses?.find((course) => course._id === selectedCourse);
    if (!course) {
      message.error('Course not found!');
      return;
    }

    if (!startDate) {
      message.error('Please select a start date');
      return;
    }

    const data: CreateEnrollment['payload'] = {
      userId: userId,
      courseId: selectedCourse,
      subject: selectedSubject,
      mentor: 'none',
      startDate: startDate?.toISOString(),
      estimatedEndDate: estimatedEndDate?.toISOString(),
      numberOfSessions: nrSessions,
    };

    // Add mentor
    if (selectedMentor?.choice?.value === 'TBD') {
      data.mentor = 'TBD';
    } else {
      const mentor = selectedMentor?.user;
      if (!mentor) {
        message.error('Unexpected error: Mentor not defined!');
        return;
      }

      data.mentor = {
        _id: mentor.value,
        fullName: mentor.label,
      };
    }

    createEnrollment(data);
  };

  const handleErrorTeaching = () => {
    if (!selectedCourse || !selectedSubject) {
      message.error('Please select a course and a subject');
      return;
    }

    const course = courses?.find((course) => course._id === selectedCourse);
    if (!course) {
      message.error('Course not found!');
      return;
    }

    if (!startDate) {
      message.error('Please select a start date');
      return;
    }

    if (
      !selectedStudent ||
      selectedStudent?.choice?.value === 'TBD' ||
      !selectedStudent?.value
    ) {
      message.error('Please select a student');
      return;
    }

    if (!userData) {
      return;
    }

    const data: CreateEnrollment['payload'] = {
      userId: selectedStudent?.value,
      courseId: selectedCourse,
      subject: selectedSubject,
      mentor: {
        _id: userId,
        fullName: userData.fullName,
      },
      startDate: startDate?.toISOString(),
      estimatedEndDate: estimatedEndDate?.toISOString(),
      numberOfSessions: nrSessions,
    };

    const student = selectedStudent;
    if (!student) {
      message.error('Unexpected error: Student not defined!');
      return;
    }

    createEnrollment(data);
  };

  return (
    <div className="flex flex-col gap-6">
      <AgoraCourseSelector
        value={selectedCourse ?? ''}
        onChange={(val) => setSelectedCourse(val)}
        label="Course"
        isRequired
      />

      <AgoraBigSelector
        label="Subject"
        onSelect={(value) => {
          setSelectedSubject((value as string) ?? null);
        }}
        showOptionsBy="value"
        options={subjectsOptions}
        onClear={() => {
          setSelectedSubject(null);
        }}
        isRequired
      />

      {newEnrolmentTeaching ? (
        <AgoraStudentSelector
          label="Student"
          isRequired
          value={selectedStudent}
          onChange={(data) => setSelectedStudent(data)}
        />
      ) : (
        <AgoraMentorSelector
          disabled={creatingEnrollment}
          label="Mentor"
          isRequired
          onChange={(data) => {
            setSelectedMentor(data);
          }}
          value={selectedMentor}
        />
      )}

      <div className="flex w-full gap-6 max-[830px]:flex-col">
        <AgoraDatePicker
          allowClear={false}
          value={startDate ?? undefined}
          onChange={(value) => setStartDate(value)}
          disabledDate={(date) => date < moment(moment().format('YYYY-MM-DD'))}
          isRequired
          label="Start Date"
        />

        <AgoraDatePicker
          allowClear={false}
          value={estimatedEndDate ?? undefined}
          onChange={(value) => setEstimatedEndDate(value)}
          disabledDate={(date) => date < moment(moment().format('YYYY-MM-DD'))}
          isRequired
          label="End Date"
        />
      </div>

      <div className="mb-16 w-[calc(50%-12px)] max-[830px]:w-full">
        <AgoraNewInput
          htmlFor="noOfMeetings"
          onChange={(e) => setNrSessions(parseInt(e.target.value))}
          name="noOfMeetings"
          id="noOfMeetings"
          placeholder="Select no. of meetings."
          label="No. of meetings"
          isRequired
          type="number"
          value={nrSessions}
        />
      </div>

      <SecondaryButton
        onClick={
          newEnrolmentTeaching ? handleErrorTeaching : handleEnrollBeingTaught
        }
        backgroundColor={COLORS.WHITE}
        height="32px"
        padding="4px 10px 4px 10px"
        margin="12px 0 0 0"
        width="100%"
      >
        <span className="button-text" style={{ color: COLORS.BLACK }}>
          Create Enrolment
        </span>
      </SecondaryButton>
    </div>
  );
};

export default NewEnrolmentPage;
