import styles from '@styles/components/table.module.scss';
import moment from 'moment';
import React from 'react';
import { COLORS } from '../../../../v3/global';
import GiveFeedbackButtonMentor from '../../../../views/UserView/Home/Common/Buttons/GiveFeedbackButtonMentor';

interface Props {
  meeting: any;
  completedNumber?: number;
  isEven: boolean;
}

const SessionHistoryRow: React.FC<Props> = ({ meeting, isEven }) => {
  const classes = `${styles.gridTableRow} ${
    isEven ? styles.gridTableRowEven : styles.gridTableRowOdd
  }`;

  let status = '';
  if (meeting.status === 0) {
    status = 'Scheduled';
  } else if (meeting.status === 1) {
    status = 'Rescheduled';
  } else if (meeting.status === 2) {
    status = 'Canceled';
  } else if (meeting.status === 3) {
    status = 'Attended';
  } else if (meeting.status === 4) {
    status = 'Student Attended';
  } else if (meeting.status === 5) {
    status = 'Mentor Attended';
  } else if (meeting.status === 6) {
    status = 'No show';
  } else if (meeting.status === 7) {
    status = 'Completed';
  } else if (meeting.status === 8) {
    status = 'Completed by admin';
  } else if (meeting.status === 9) {
    status = 'Pending review';
  } else if (meeting.status === 10) {
    status = 'Uncompleted';
  } else if (meeting.status === 11) {
    status = 'Paid';
  } else {
    status = 'Other';
  }

  return (
    <>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={`${classes} ${styles.gridTableRowFirst}`}
      >
        {meeting.students[0]?.fullName}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {meeting.enrollment?.title}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {moment(meeting.startDate).format('DD MMM YYYY')}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {status}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={`${classes} ${styles.gridTableRowLast}`}
      >
        {meeting.status === 7 ||
        meeting.status === 6 ||
        meeting.status === 11 ||
        meeting.status === 9 ? null : (
          <GiveFeedbackButtonMentor meeting={meeting} />
        )}
      </div>
    </>
  );
};

export default SessionHistoryRow;
