import { Modal } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

type Props = {
  when: any;
  onOK: any;
  onCancel: any;
  title: string;
  okText: string;
  cancelText: string;
};

export function RouterPrompt({
  when,
  onOK,
  onCancel,
  title,
  okText,
  cancelText,
}: Props) {
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return 'true';
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <Modal
      centered
      title={title}
      visible={showPrompt}
      onOk={handleCancel}
      okText={okText}
      onCancel={handleOK}
      cancelText={cancelText}
      closable={true}
    >
      There are unsaved changes. Are you sure want to leave this page ?
    </Modal>
  ) : null;
}
