import {
  ApiDescription,
  ApiData,
  RequestsMethod,
  Homework,
  RawObject,
} from '../common';

export const createHomework: ApiDescription = {
  endpoint: '/homeworks',
  method: RequestsMethod.Post,
};
export type CreateHomework = ApiData<
  Omit<RawObject<Homework>, 'fileId' | 'mentor' | 'completed'>,
  Homework
>;

export const listMentorHomework: ApiDescription = {
  endpoint: '/homeworks',
  method: RequestsMethod.Get,
};
export type ListMentorHomework = ApiData<
  { completed?: boolean; student?: string },
  Homework[]
>;

export const listMyHomework: ApiDescription = {
  endpoint: '/homeworks/my',
  method: RequestsMethod.Get,
};
export type ListMyHomework = ApiData<
  { completed?: boolean; mentor?: string; enrollmentId?: string },
  Homework[]
>;

export const updateHomework: ApiDescription = {
  endpoint: '/homeworks/:id',
  method: RequestsMethod.Patch,
};
export type UpdateHomework = ApiData<Partial<Homework>, Homework>;
