import defaultAvatar from '@assets/default-user.png';
import CustomUploader from '@components/Form/CustomUploader';
import FormButton from '@components/Form/FormButton';
import FormCustomElement from '@components/Form/FormCustomElement';
import FormInput from '@components/Form/FormInput';
import { LockClosedIcon } from '@heroicons/react/outline';
import { genderOptions, pronounsOptions } from '@shared/constants';
import styles from '@styles/pages/account.module.scss';
import { Popconfirm, Select, Cascader, Tooltip, Avatar } from 'antd';
import { COLORS } from '../../../v3/global';

const PersonalSetting = ({
  avatar,
  name,
  uploadFn,
  username,
  changePasswordFn,
  gender,
  setGender,
  country,
  setCountry,
  pronouns,
  setPronouns,
  email,
  universities,
  setUniversities,
  saveAll,
  type,
}: any) => {
  return (
    <div className={styles.settingsCard}>
      <h3
        style={{ color: COLORS.TEXT_PRIMARY }}
        className={styles.settingsCardTitle}
      >
        Personal Info
      </h3>

      <div className={styles.settingsProfile}>
        <Avatar
          src={avatar || defaultAvatar}
          className={styles.settingsProfileAvatar}
        />
        <div
          style={{ color: COLORS.TEXT_PRIMARY }}
          className={styles.settingsProfileInfo}
        >
          <p>{name}</p>
          <p>Profile</p>
        </div>
      </div>
      <p style={{ color: COLORS.TEXT_PRIMARY }} className={styles.email}>
        {email}
      </p>
      <CustomUploader action={uploadFn} title="Upload New Image" />

      <div className={styles.settingsCardColumn}>
        <Tooltip
          placement="right"
          title="Contact support to change your username."
        >
          <FormInput value={username} title="Username" disabled />
        </Tooltip>

        {type === 'mentor' && (
          <>
            <FormInput
              value={country}
              title="Country"
              placeholder="Enter country"
              onChange={(e) => setCountry(e.target.value)}
            />

            <FormCustomElement title="Universities">
              <Select
                mode="tags"
                allowClear
                placeholder={'Please type your universities'}
                value={universities}
                onChange={(value: string[]) => {
                  setUniversities(value);
                }}
              >
                {universities?.map((university: string) => (
                  <Select.Option key={university} value={university}>
                    {university}
                  </Select.Option>
                ))}
              </Select>
            </FormCustomElement>
          </>
        )}

        <Popconfirm
          placement="bottomLeft"
          title="You will be logged out and taken to the Log In page. There you can choose 'Forgot Password'. Continue?"
          onConfirm={changePasswordFn}
          okText="Yes"
          cancelText="No"
        >
          <div>
            <FormButton title="Password">
              <LockClosedIcon style={{ width: 20, height: 20 }} />
              <div>Change Password</div>
            </FormButton>
          </div>
        </Popconfirm>

        <FormCustomElement title="Gender">
          <Cascader
            fieldNames={{
              label: 'name',
              value: 'name',
              children: 'items',
            }}
            options={genderOptions}
            onChange={(genders) => setGender(genders[genders.length - 1])}
            placeholder={`Current: ${gender}`}
            displayRender={(label) => label[label.length - 1]}
            className="w-full"
          />
        </FormCustomElement>

        <FormCustomElement title="Pronouns">
          <Select
            defaultValue={pronouns}
            value={pronouns}
            onChange={(pronoun) => setPronouns(pronoun)}
            className="w-full"
          >
            {pronounsOptions.map((pronouns) => {
              return (
                <Select.Option key={pronouns.value} value={pronouns.value}>
                  {pronouns.text}
                </Select.Option>
              );
            })}
          </Select>
        </FormCustomElement>
      </div>
    </div>
  );
};

export default PersonalSetting;
