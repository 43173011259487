import EnrollmentsPage from '@components/V3/EnrollmentsModalMentors/EnrollmentsPage';
import EditEnrolmentDetailsModal from '@components/V3/EnrollmentsModalPages/EditEnrolmentDetailsModal';
import EditEnrolmentPage from '@components/V3/EnrollmentsModalPages/EditEnrolmentPage';
import NewEnrolmentPage from '@components/V3/EnrollmentsModalPages/NewEnrolmentPage';
import AgoraModal from '@components/V3/Utils/AgoraModal';
import { useGetMentorEnrollmentsWithExtraInfo } from '@shared/react';
import { MentorWithRate } from '@shared/types';
import { COLORS } from 'apps/agora/src/v3/global';
import React, { useEffect, useState } from 'react';
import './enrollments-modal-mentors.styles.scss';
import SecondaryButton from '../Utils/SecondaryButton';

type Props = {
  showModal: boolean;
  handleShowModal: () => void;
  mentorData?: MentorWithRate;
  enrolmentModalPage?: string;
  currentEnrolmentId?: string;
};

const EnrollmentsModalMentors = ({
  showModal,
  handleShowModal,
  mentorData,
  enrolmentModalPage,
  currentEnrolmentId,
}: Props) => {
  const [teachingPage, setTeachingPage] = useState(1);
  const [beingTaughtPage, setBeingTaughtPage] = useState(1);
  const [isEnded, setIsEnded] = useState(false);

  const handleTeachingPage = (page: number) => {
    setTeachingPage(page);
  };

  const handleBeingTaughtPage = (page: number) => {
    setBeingTaughtPage(page);
  };

  const {
    data: enrollments,
    refetch: refetchEnrollments,
    isLoading: isLoadingEnrollments,
  } = useGetMentorEnrollmentsWithExtraInfo({
    id: mentorData?.mentor?._id ?? '',
    teachingPage: teachingPage,
    beingTaughtPage: beingTaughtPage,
    isEnded: isEnded,
    limit: 5,
  });

  const handleIsEnded = (isEnded: boolean) => {
    setIsEnded(isEnded);
  };

  const [currentEnrolment, setCurrentEnrolment] = useState<string>(
    currentEnrolmentId ?? ''
  );
  const [currentPage, setCurrentPage] = useState(
    enrolmentModalPage ?? 'enrollments'
  );
  const [newEnrolmentTeaching, setNewEnrolmentTeaching] = useState(true);

  const [editEnrolmentCourseName, setEditEnrolmentCourseName] = useState('-');
  const [editEnrolmentStudent, setEditEnrolmentStudent] = useState('-');
  const [editEnrolmentMentor, setEditEnrolmentMentor] = useState('-');
  const [selectedTab, setSelectedTab] = useState('Active');
  const [lastMeeting, setLastMeeting] = useState('');
  const [enrolmentData, setEnrolmentData] = useState('');

  const handleEditEnrolmentTitle = (
    courseName: string,
    mentor: string,
    student: string
  ) => {
    setEditEnrolmentCourseName(courseName);
    setEditEnrolmentMentor(mentor);
    setEditEnrolmentStudent(student);
  };

  const handleNewEnrolmentTeaching = (value: boolean) => {
    setNewEnrolmentTeaching(value);
  };

  const isCreateUserFlow =
    enrolmentModalPage === 'create-new-user-enrolments-mentor';

  const handleCurrentPage = (page: string) => {
    if (isCreateUserFlow && page === 'enrollments') {
      setCurrentPage(enrolmentModalPage);
    } else {
      setCurrentPage(page);
    }
  };

  const handleCurrentEnrolment = (enrolmentId: string) => {
    setCurrentEnrolment(enrolmentId);
  };

  const handleSelectedTab = (tab: string) => {
    setSelectedTab(tab);
  };

  const handleLastMeeting = (lastMeeting: string) => {
    setLastMeeting(lastMeeting);
  };

  const handleEnrolmentData = (enrolmentData: any) => {
    setEnrolmentData(enrolmentData);
  };

  useEffect(() => {
    refetchEnrollments();
  }, [selectedTab, isEnded]);

  return (
    <div>
      <AgoraModal
        backButton={
          currentPage === 'edit-enrollment' ||
          currentPage === 'new-enrollment' ||
          currentPage === 'edit-enrollment-details'
        }
        onBack={
          currentPage === 'edit-enrollment' || currentPage === 'new-enrollment'
            ? handleCurrentPage
            : currentPage === 'edit-enrollment-details'
            ? () => handleCurrentPage('edit-enrollment')
            : () => null
        }
        backRoute={
          enrolmentModalPage === 'create-new-user-enrolments-mentor'
            ? 'create-new-user-enrolments-mentor'
            : 'enrollments'
        }
        title={
          currentPage === 'enrollments' ||
          currentPage === 'create-new-user-enrolments-mentor'
            ? `Enrollments for ${mentorData?.mentor.fullName ?? '-'}`
            : currentPage === 'new-enrollment'
            ? 'Create a new enrolment'
            : `Edit - ${editEnrolmentCourseName ?? '-'}`
        }
        title2={
          currentPage === 'enrollments' ||
          currentPage === 'new-enrollment' ||
          currentPage === 'create-new-user-enrolments-mentor'
            ? ''
            : `${editEnrolmentMentor ?? '-'} / ${editEnrolmentStudent ?? '-'}`
        }
        setModalOpen={() => handleShowModal()}
        isModalOpen={showModal}
        closable={true}
        width="58vw"
      >
        {currentPage === 'enrollments' ||
        currentPage === 'create-new-user-enrolments-mentor' ? (
          <EnrollmentsPage
            enrollments={enrollments}
            refetchEnrollments={refetchEnrollments}
            handleCurrentPage={handleCurrentPage}
            handleCurrentEnrolment={handleCurrentEnrolment}
            handleTeachingPage={handleTeachingPage}
            handleBeingTaughtPage={handleBeingTaughtPage}
            handleIsEnded={handleIsEnded}
            isLoadingEnrollments={isLoadingEnrollments}
            selectedTab={selectedTab}
            handleSelectedTab={handleSelectedTab}
            handleNewEnrolmentTeaching={handleNewEnrolmentTeaching}
            handleLastMeeting={handleLastMeeting}
            handleEnrolmentData={handleEnrolmentData}
          />
        ) : currentPage === 'edit-enrollment' ? (
          <EditEnrolmentPage
            currentEnrolment={currentEnrolment}
            handleCurrentPage={handleCurrentPage}
            refetchEnrollments={refetchEnrollments}
            handleEditEnrolmentTitle={handleEditEnrolmentTitle}
            lastMeeting={lastMeeting}
            enrolmentData={enrolmentData}
          />
        ) : currentPage === 'edit-enrollment-details' ? (
          <EditEnrolmentDetailsModal
            currentEnrolment={currentEnrolment}
            handleCurrentPage={handleCurrentPage}
            mentorId={''}
            refetchEnrollments={refetchEnrollments}
            // newEnrolmentTeaching={newEnrolmentTeaching}
          />
        ) : currentPage === 'new-enrollment' ? (
          <NewEnrolmentPage
            currentEnrolment={currentEnrolment}
            handleCurrentPage={handleCurrentPage}
            userId={mentorData?.mentor._id ?? ''}
            refetchEnrollments={refetchEnrollments}
            newEnrolmentTeaching={newEnrolmentTeaching}
          />
        ) : null}

        {isCreateUserFlow &&
          currentPage === 'create-new-user-enrolments-mentor' && (
            <SecondaryButton
              onClick={() => {
                if (isCreateUserFlow) handleShowModal();
              }}
              backgroundColor={COLORS.WHITE}
              height="40px"
              padding="12px"
              margin="20px 0 0 0"
              width="100%"
            >
              <span
                className="create-new-user-modal-submit-button"
                style={{ color: COLORS.BLACK }}
              >
                Continue
              </span>
            </SecondaryButton>
          )}
      </AgoraModal>
    </div>
  );
};

export default EnrollmentsModalMentors;
