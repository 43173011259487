import { useDebounce } from '@hooks';
import { SelectOption } from '@shared/common';
import { STUDENT_ROLE } from '@shared/common';
import { useSearchUsers } from '@shared/react';
import { Select, Spin } from 'antd';
import { CSSProperties, useEffect, useState } from 'react';

type Props = {
  value?: SelectOption;
  onChange?: (value: SelectOption) => void;
  style?: CSSProperties;
  showDeactivatedStudents?: boolean;
};

const StudentSelector = ({
  value,
  onChange,
  style,
  showDeactivatedStudents,
}: Props) => {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    if (value) setInternalValue(value);
  }, [value]);

  const setValues = (val: SelectOption) => {
    if (onChange) onChange(val);
    if (!value) setInternalValue(val);
  };

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const { data: studentsResponse, isLoading: studentsLoading } = useSearchUsers(
    {
      roles: [STUDENT_ROLE],
      search: debouncedSearch,
      isDezactivated: showDeactivatedStudents ? undefined : 'unchecked',
      limit: 20,
    }
  );

  const options = (studentsResponse?.users || []).map((s) => ({
    label: s?.fullName,
    value: s._id,
  }));

  return (
    <Select
      style={style}
      placeholder="Select a Student"
      labelInValue
      showSearch
      optionFilterProp="label"
      options={options}
      onSearch={setSearch}
      notFoundContent={studentsLoading ? <Spin size="small" /> : null}
      value={internalValue}
      onChange={setValues}
    />
  );
};

export default StudentSelector;
