import { Typography } from 'antd';
import moment from 'moment';
import { COLORS } from '../v3/global';

const TimezoneNotice = () => {
  return (
    <Typography.Text style={{ color: COLORS.TEXT_SECONDARY }} type="secondary">
      Times are in your local timezone, it is currently{' '}
      {moment().format('HH:mm')}
    </Typography.Text>
  );
};

export default TimezoneNotice;
