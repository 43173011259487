import React, { useState } from 'react';
import {
  useGetStudentEnrollmentsWithExtraInfo,
  useGetUserProfile,
} from '@shared/react';
import AgoraModal from '@components/V3/Utils/AgoraModal';
import ProfilePageStudents from '@components/V3/ProfileModalStudents/ProfilePageStudents';
import AddLanguageExam from '@components/V3/ProfileModalStudents/AddLanguageExam';
import './profile-modal-students.styles.scss';
import EditProfileDetailsPage from '@components/V3/ProfileModalStudents/EditProfileDetailsPage';

type Props = {
  showModal: boolean;
  handleShowModal: () => void;
  studentData?: any;
  enrolmentModalPage?: string;
  currentEnrolmentId?: string;
};

const ProfileModalStudents = ({
  showModal,
  handleShowModal,
  studentData,
  enrolmentModalPage,
  currentEnrolmentId,
}: Props) => {
  const [page, setPage] = useState(1);
  const [isEnded, setIsEnded] = useState(false);

  const {
    data: userProfile,
    refetch: refetchUserProfile,
    isLoading: isLoadingUserProfile,
  } = useGetUserProfile(studentData?._id);

  const {
    data: enrollments,
    refetch: refetchEnrollments,
    isLoading: isLoadingEnrollments,
  } = useGetStudentEnrollmentsWithExtraInfo({
    id: studentData?._id ?? '',
    page: page,
    isEnded: isEnded,
    limit: 10,
  });

  const [currentEnrolment, setCurrentEnrolment] = useState<string>(
    currentEnrolmentId ?? ''
  );
  const [currentPage, setCurrentPage] = useState(
    enrolmentModalPage ?? 'student-profile'
  );

  const [selectedTab, setSelectedTab] = useState('Universities');

  const handleCurrentPage = (page: string) => {
    setCurrentPage(page);
  };

  const handleSelectedTab = (tab: string) => {
    setSelectedTab(tab);
  };

  return (
    <div>
      <AgoraModal
        backButton={
          currentPage === 'edit-profile-details' ||
          currentPage === 'add-university' ||
          currentPage === 'add-highschool' ||
          currentPage === 'add-ecl' ||
          currentPage === 'add-language-exam'
        }
        onBack={
          currentPage === 'edit-profile-details'
            ? handleCurrentPage
            : currentPage === 'add-university' ||
              currentPage === 'add-highschool' ||
              currentPage === 'add-ecl' ||
              currentPage === 'add-language-exam'
            ? () => handleCurrentPage('student-profile')
            : () => null
        }
        backRoute="student-profile"
        title={
          currentPage === 'student-profile'
            ? `Student Profile`
            : currentPage === 'edit-profile-details'
            ? 'Edit Student Profile'
            : currentPage === 'add-university'
            ? 'Add University'
            : currentPage === 'add-highschool'
            ? 'Add Highschool'
            : currentPage === 'add-ecl'
            ? 'Add ECL'
            : currentPage === 'add-language-exam'
            ? 'Add Exam'
            : ''
        }
        title2={
          currentPage === 'student-profile' ? `${studentData?.fullName}` : ''
        }
        setModalOpen={() => handleShowModal()}
        isModalOpen={showModal}
        closable={true}
        width="58vw"
      >
        {currentPage === 'student-profile' ? (
          <ProfilePageStudents
            studentId={studentData?._id}
            studentData={userProfile}
            refetchStudentData={refetchUserProfile}
            handleCurrentPage={handleCurrentPage}
            selectedTab={selectedTab}
            handleSelectedTab={handleSelectedTab}
          />
        ) : currentPage === 'add-language-exam' ? (
          <AddLanguageExam
            handleCurrentPage={handleCurrentPage}
            studentId={studentData?._id ?? ''}
            refetchEnrollments={refetchEnrollments}
            studentData={studentData}
            refetchStudentData={refetchUserProfile}
          />
        ) : currentPage === 'edit-profile-details' ? (
          <EditProfileDetailsPage
            handleCurrentPage={handleCurrentPage}
            studentId={studentData?._id}
            refetchEnrollments={refetchEnrollments}
            studentData={userProfile}
            refetchStudentData={refetchUserProfile}
          />
        ) : null}
      </AgoraModal>
    </div>
  );
};

export default ProfileModalStudents;
