import DriveIcon from '@assets/icons/Icon-awesome-google-drive.png';
import HomeworkIcon from '@assets/icons/Icon-awesome-home.png';
import { useModal } from '@hooks';

import { CreateHomeworkModal } from '@modules/Mentor/reusables';
import { NewMeeting } from '@shared/common';
import { ENVIRONMENT } from '@shared/frontendEnv';
import { useGetHomeworkByMeeting, useUserDetails } from '@shared/react';
import { documentUrl } from '@shared/utils/string';
import btn from '@styles/components/reusables/buttons.module.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { COLORS } from '../../../../../v3/global';
import MeetingListItemButton from './MeetingListItemButton';

interface HomeworkButtonProps {
  meeting: NewMeeting;
}

const HomeworkButton: React.FC<HomeworkButtonProps> = ({ meeting }) => {
  const [HomeworkModal, showHomeworkModal] = useModal(CreateHomeworkModal);

  const { data: homework, refetch } = useGetHomeworkByMeeting(meeting._id);
  const { data: user } = useUserDetails(meeting?.students[0]?._id);

  const googleDrive = user?.googleDrive[`${ENVIRONMENT}`].student.id;

  const handleClick = () => {
    if (!homework) {
      showHomeworkModal({
        enrollmentId: meeting?.enrollment?._id,
        studentId: meeting.students[0]._id,
        meetingId: meeting._id,
        refetch,
      });
    }

    if (homework?.fileId) {
      window.open(documentUrl(homework.fileId), '_blank');
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {googleDrive && (
        <MeetingListItemButton>
          <div
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={(e: any) => e.stopPropagation()}
          >
            <img
              src={DriveIcon}
              style={{ marginRight: '12px', width: '14px' }}
            />
            <Link
              style={{
                color: COLORS.TEXT_PRIMARY,
              }}
              to={{
                pathname: `https://drive.google.com/drive/folders/${googleDrive}`,
              }}
              target="_blank"
            >
              Open Drive
            </Link>
          </div>
        </MeetingListItemButton>
      )}

      <MeetingListItemButton>
        <div
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={handleClick}
        >
          <img
            src={HomeworkIcon}
            style={{ marginRight: '12px', width: '14px' }}
          />
          <span
            style={{
              color: COLORS.TEXT_PRIMARY,
              whiteSpace: 'nowrap',
            }}
            className={`${btn.actionButton} gradient`}
          >
            {homework?.fileId ? 'View Homework' : 'Add Homework'}
          </span>
        </div>
      </MeetingListItemButton>

      {HomeworkModal}
    </div>
  );
};

export default HomeworkButton;
