import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import { CreateUserV2 } from '@shared/apis/auth.apis';
import { countryList } from '@shared/constants';
import { CreateNewUserResponse } from '@shared/types';
import AgoraBigSelector from '@utils/AgoraBigSelector';
import AgoraInput from '@utils/AgoraInput';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { UseMutateFunction } from 'react-query';
import { COLORS } from '../../../v3/global';
import './create-new-user.styles.scss';

type StudentDetails = {
  fullName: string;
  phoneNumber: string;
  parentName: string;
  parentEmail: string;
  parentPhoneNumber: string;
  city: string;
  country: string;
  highschool: string;
  graduationYear: string;
  gpa: string;
  universities: string[];
  countriesToStudyIn: string[];
  major: string;
};

type Props = {
  email: string;
  role: 'STUDENT' | 'MENTOR' | 'ADMIN' | undefined;
  handleCurrentPage: (page: string) => void;
  studentDetails: StudentDetails;
  handleInputChangeStudent: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  setNewUserId: (string: string | undefined) => void;
  handleArrayChangeStudent: (name: keyof StudentDetails, value: string) => void;
  handleArrayRemoveStudent: (name: keyof StudentDetails, index: number) => void;
  handleCountrySelectStudent: (value: any) => void;
  handleCountriesToStudyInSelect: (value: any) => void;
  handleGraduationYearChange: (date: Moment | null) => void;
  handleUniversities: (event: React.ChangeEvent<HTMLInputElement>) => void;
  createUserV2: UseMutateFunction<
    CreateNewUserResponse,
    Error,
    CreateUserV2['payload']
  >;
};

const CreateNewUserStudentStepTwo = ({
  email,
  role,
  handleCurrentPage,
  studentDetails,
  handleInputChangeStudent,
  setNewUserId,
  handleArrayChangeStudent,
  handleArrayRemoveStudent,
  handleCountrySelectStudent,
  handleCountriesToStudyInSelect,
  handleGraduationYearChange,
  handleUniversities,
  createUserV2,
}: Props) => {
  const [touched, setTouched] = useState<Record<keyof StudentDetails, boolean>>(
    {
      fullName: false,
      phoneNumber: false,
      parentName: false,
      parentEmail: false,
      parentPhoneNumber: false,
      city: false,
      country: false,
      highschool: false,
      graduationYear: false,
      gpa: false,
      universities: false,
      countriesToStudyIn: false,
      major: false,
    }
  );

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name } = event.target;
    setTouched((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const getColor = (name: keyof typeof studentDetails) => {
    if (!touched[name]) {
      return COLORS.TEXT_SECONDARY;
    } else {
      if (name === 'universities' || name === 'countriesToStudyIn') {
        return studentDetails[name].length ? COLORS.PRIMARY : COLORS.ERROR;
      } else {
        return studentDetails[name] ? COLORS.PRIMARY : COLORS.ERROR;
      }
    }
  };

  const areDetailsFilled = !!(
    studentDetails.highschool &&
    studentDetails.graduationYear &&
    studentDetails.countriesToStudyIn &&
    studentDetails.universities &&
    studentDetails.major
  );

  return (
    <div style={{ marginTop: '8px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}></div>

      <div
        style={{
          height: '60vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <h3
            style={{
              color: COLORS.TEXT_SECONDARY,
              marginBottom: '24px',
            }}
            className="modal-page-section-title"
          >
            Educational Background
          </h3>

          <AgoraInput
            htmlFor="highschool"
            type="text"
            name="highschool"
            id="highschool"
            placeholder=""
            label="Highschool"
            color={getColor('highschool')}
            labelColor={getColor('highschool')}
            borderColor={getColor('highschool')}
            labelMargin="0 0 0 8px"
            value={studentDetails?.highschool}
            margin="0px 0px 16px 0px"
            onChange={handleInputChangeStudent}
            onBlur={handleBlur}
            isRequired
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                width: '100%',
                margin: '0px 24px 24px 0px ',
              }}
            >
              <label
                style={{
                  color: getColor('graduationYear'),
                  backgroundColor: 'transparent',
                  marginLeft: '8px',
                }}
                htmlFor="location"
                className="block text-sm font-medium leading-6 text-customPrimary"
              >
                End Date <span className="text-red-500">*</span>
              </label>

              <DatePicker
                picker="year"
                value={
                  studentDetails.graduationYear
                    ? moment(studentDetails.graduationYear, 'YYYY')
                    : null
                }
                onChange={handleGraduationYearChange}
                onClick={() =>
                  setTouched((prev) => ({
                    ...prev,
                    graduationYear: true,
                  }))
                }
                style={{
                  backgroundColor: 'transparent',
                  width: '100%',
                  height: '38px',
                  color: 'red',
                  borderColor: getColor('graduationYear'),
                }}
                clearIcon={null}
                className="custom-date-picker-secondary mt-2"
              />
            </div>

            <AgoraInput
              htmlFor="gpa"
              type="text"
              name="gpa"
              id="gpa"
              placeholder=""
              label="GPA"
              labelColor={COLORS.TEXT_SECONDARY}
              borderColor={COLORS.TEXT_SECONDARY}
              labelMargin="0 0 0 8px"
              value={studentDetails?.gpa}
              margin="0px 0px 24px 0px"
              onChange={handleInputChangeStudent}
              // onBlur={handleBlur}
            />
          </div>

          <h3
            style={{
              color: COLORS.TEXT_SECONDARY,
              marginBottom: '24px',
            }}
            className="modal-page-section-title"
          >
            Academic Path
          </h3>

          <AgoraInput
            htmlFor="universities"
            type="text"
            name="universities"
            id="universities"
            placeholder=""
            label="Universities"
            color={getColor('universities')}
            labelColor={getColor('universities')}
            borderColor={getColor('universities')}
            labelMargin="0 0 0 8px"
            margin="0px 0px 16px 0px"
            onBlur={handleBlur}
            value={studentDetails?.universities[0]}
            onChange={handleUniversities}
            isRequired
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{ width: 'calc(50% - 12px)' }}
              onClick={() => {
                setTouched((prev) => ({
                  ...prev,
                  countriesToStudyIn: true,
                }));
              }}
            >
              <AgoraBigSelector
                label="Countries to study in"
                labelColor={getColor('countriesToStudyIn')}
                labelMargin="0 0 8px 0"
                isRequired
                onSelect={(value) => {
                  handleCountriesToStudyInSelect([
                    ...studentDetails.countriesToStudyIn,
                    value,
                  ]);
                }}
                onDeselect={(value) => {
                  const updatedCountries =
                    studentDetails.countriesToStudyIn.filter(
                      (country) => country !== value
                    );
                  handleCountriesToStudyInSelect(updatedCountries);
                }}
                options={countryList.map((country) => ({
                  label: country,
                  value: country,
                }))}
                onClear={() => {
                  handleCountriesToStudyInSelect([]);
                }}
                labelKey="label"
                valueKey="value"
                margin="0 0 8px 0"
                // height="38px"
                minHeight="38px"
                width="100%"
                borderRadius="8px"
                borderColor={getColor('countriesToStudyIn')}
                mode="multiple"
                value={studentDetails.countriesToStudyIn}
              />
            </div>

            <AgoraInput
              htmlFor="major"
              type="text"
              name="major"
              id="major"
              placeholder=""
              label="Major (Target University Programs)"
              color={getColor('major')}
              labelColor={getColor('major')}
              borderColor={getColor('major')}
              labelMargin="0 0 0 8px"
              value={studentDetails?.major}
              margin="0 0 8px 0"
              onChange={handleInputChangeStudent}
              width="calc(50% - 12px)"
              onBlur={handleBlur}
              isRequired
            />
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '24px',
            }}
          >
            <span
              style={{ marginRight: '16px' }}
              className="create-new-user-modal-steps-text"
            >
              Step 2 of 2
            </span>
            <div
              style={{
                width: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div className="create-new-user-modal-step-container">
                <div className="create-new-user-modal-step-circle"></div>
              </div>

              <div className="create-new-user-modal-step-container">
                <div className="create-new-user-modal-step-circle"></div>
              </div>
            </div>
          </div>
          <SecondaryButton
            onClick={() => {
              if (areDetailsFilled) {
                createUserV2({
                  email: email,
                  userType: role ?? '',
                  permissions:
                    role === 'STUDENT'
                      ? { student: true }
                      : role === 'MENTOR'
                      ? { mentor: true }
                      : { admin: true },
                  studentData: {
                    fullName: studentDetails.fullName,
                    phoneNumber: studentDetails.phoneNumber,

                    parentEmail: studentDetails.parentEmail,
                    parentFullName: studentDetails.parentName,
                    parentPhoneNumber: studentDetails.parentPhoneNumber,

                    city: studentDetails.city,
                    country: studentDetails.country,
                    highSchool: studentDetails.highschool,
                    graduationYear: Number(studentDetails.graduationYear),
                    gpa: 0,
                    ecl: '',
                    standardisedTests: [],
                    universities: studentDetails.universities,
                    admissionExamPrep: [],
                    studySubjects: [],
                    subjectsStudentLikes: [],
                    subjectsStudentDoesntLike: [],
                    learningStyle: [],
                    countries: studentDetails.countriesToStudyIn,
                    interests: [],
                  },
                });
              }
            }}
            backgroundColor={COLORS.WHITE}
            height="40px"
            padding="12px"
            margin="0 0 0 0"
            width="100%"
            disabled={!areDetailsFilled}
          >
            <span
              className="create-new-user-modal-submit-button"
              style={{ color: COLORS.BLACK }}
            >
              Continue
            </span>
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default CreateNewUserStudentStepTwo;
