import FormInput from '@components/Form/FormInput';
import { CalendarIcon, PlusIcon, TrashIcon } from '@heroicons/react/outline';
import { useModal } from '@hooks';
import {
  useChecklistById,
  useCreateMilestone,
  useDeleteMilestone,
  useToggleChecklistMilestoneStatus,
  useUpdateChecklist,
} from '@shared/react';
import { NewEnrollment } from '@shared/types';
import styles from '@styles/components/checklist-modal.module.scss';
import modalStyles from '@styles/components/reusables/modal.module.scss';
import { message } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { COLORS } from '../../../../v3/global';
import ImportChecklistModal from './ImportChecklistModal';

type Props = {
  isActive: boolean;
  enrollment: NewEnrollment;
};

const ChecklistItem: React.FC<Props> = ({ isActive, enrollment }) => {
  const { data: checklist, refetch } = useChecklistById(
    enrollment?.checklist?._id || ''
  );
  const { mutate: toggleStatus } = useToggleChecklistMilestoneStatus(
    enrollment?.checklist?._id || '',
    { onSuccess: () => refetch() }
  );

  const items = checklist?.items || [];

  const [milestone, setMilestone] = useState('');
  const [deadline, setDeadline] = useState('');

  const { mutate: createMilestone } = useCreateMilestone(
    enrollment?.checklist?._id || '',
    { onSuccess: () => refetch() }
  );

  const { mutate: deleteMilestone } = useDeleteMilestone(
    enrollment?.checklist?._id || '',
    {
      onSuccess: () => {
        refetch();
        message.success('Deleted.');
      },
    }
  );

  const handleCreateMilestone = () => {
    if (moment(deadline).isBefore(moment())) {
      message.error('Deadline must be in the future');
    }

    if (deadline && milestone && moment(deadline).isAfter(moment())) {
      createMilestone(
        { title: milestone, deadline },
        {
          onSuccess: () => {
            setMilestone('');
            setDeadline('');

            message.success('Milestone created succesfully');
          },
        }
      );
    }
  };

  const [ImportChecklistM, showImportChecklistM] =
    useModal(ImportChecklistModal);

  return (
    <div>
      {ImportChecklistM}
      {isActive && (
        <div className={styles.checklist}>
          <div
            style={{ color: COLORS.TEXT_PRIMARY }}
            className={styles.checklistTitle}
          >
            {enrollment?.course.title} - Checklist
          </div>

          <div className={styles.checklistItems}>
            {items.map((item, index) => (
              <div
                style={{
                  color: COLORS.TEXT_PRIMARY,
                }}
                key={item._id}
                className={`${styles.checklistItem} ${
                  item.isDone ? styles.checklistItemDone : ''
                }`}
              >
                <div className={styles.checklistItemDone}></div>

                <div className={styles.checklistItemLabel}>Milestone</div>

                <div>{item.title}</div>

                <div className={styles.checklistItemLabel}>Deadline</div>
                <div className={styles.checklistItemWithIcon}>
                  {moment(item.deadline).format('YYYY.MM.DD')}{' '}
                  <CalendarIcon className={styles.icon} />
                </div>

                <div className={styles.checklistItemAction}>
                  Mark Complete
                  <input
                    type="checkbox"
                    checked={item.isDone}
                    onChange={() => toggleStatus({ itemIndex: index })}
                  />
                  <TrashIcon
                    width={20}
                    className="ml1"
                    color="red"
                    cursor="pointer"
                    onClick={() =>
                      deleteMilestone({ milestoneId: item._id || '' })
                    }
                  />
                </div>
              </div>
            ))}
          </div>

          <div className={styles.checklistsForm}>
            <FormInput
              value={milestone}
              placeholder="Milestone title"
              onChange={(e) => setMilestone(e.target.value)}
            />
            <FormInput
              value={deadline}
              placeholder="Deadline"
              onChange={(e) => {
                setDeadline(e.target.value);
              }}
              type="date"
            />
          </div>

          <div
            className={`${modalStyles.modalFooterActions} ${modalStyles.modalFooterActionsPrimary}`}
          >
            <button
              className={`${modalStyles.modalBtn} ${modalStyles.modalBtnPrimary}`}
              onClick={handleCreateMilestone}
            >
              <PlusIcon className={modalStyles.icon} />
              Add Milestone
            </button>

            {/*TODO Remove the functionality completely later on*/}
            {/*<button*/}
            {/*  className={`${modalStyles.modalBtn} ${modalStyles.modalBtnSecondary}`}*/}
            {/*  onClick={() =>*/}
            {/*    showImportChecklistM({*/}
            {/*      checklistId: enrollment.checklist?._id || '',*/}
            {/*      enrollmentId: enrollment._id || '',*/}
            {/*      refetch,*/}
            {/*    })*/}
            {/*  }*/}
            {/*>*/}
            {/*  <PlusIcon className={modalStyles.icon} />*/}
            {/*  Import Template*/}
            {/*</button>*/}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChecklistItem;
