import { ReactComponent as BackSignIcon } from '@assets/icons/back-sign.svg';
import { ReactComponent as StopGeneratingIcon } from '@assets/icons/kb-stop-generating.svg';
import { ReactComponent as HistoryIcon } from '@assets/icons/knowledge-base-history-outlined.svg';
import KnowledgeBaseInputButtonPrimary from '@assets/icons/knowledge-base-input-button-primary.svg';
import KnowledgeBaseInputButtonSecondary from '@assets/icons/knowledge-base-input-button-secondary.svg';
import { ReactComponent as NewChatIcon } from '@assets/icons/knowledge-base-new-chat-outlined.svg';
import Button from '@components/V4/Button';
import IconButton from '@components/V4/IconButton';
import Tooltip from '@components/V4/Tooltip';
import { useHideHubspot } from '@hooks';
import { DOMAIN, isDevelopment } from '@shared/frontendEnv';
import { useGetChat, useGetUserChatsTitles } from '@shared/react';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { useState, useRef, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import { Link } from 'react-router-dom';
import ChatMessage from './ChatMessage/ChatMessage';
import ChatPlaceholder from './ChatPlaceholder';
import SideMenu from './SideMenu';
import { transformMessages } from '../utils/helpers';
import useWebSocket from '../hooks/useWebSocket';

const KnowledgeBaseAssistant = () => {
  const [fetchChat, setFetchChat] = useState(false);
  const [chatId, setChatId] = useState<string>();
  const [conversation, setConversation] = useState<any>([]);
  const [newChatCreated, setNewChatCreated] = useState<boolean>(false);
  const [inputNewChatCreat, setInputNewChatCreated] = useState<boolean>(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useHideHubspot();

  const { data: chatTitles, refetch: refetchChatTitles } =
    useGetUserChatsTitles();

  const {
    data: currentChat,
    refetch: refetchCurrentChat,
    isLoading: isLoadingCurrentChat,
  } = useGetChat(chatId, {
    enabled: fetchChat,
  });

  const [
    lastMessage,
    sendMessage,
    createNewChat,
    newChat,
    isOpened,
    loading,
    isGeneratingText,
    handleSetLoading,
  ] = useWebSocket({
    url: `${isDevelopment ? 'ws' : 'wss'}://${DOMAIN}/api/handler-chat-ws`,
    onTitleGenerated: refetchChatTitles,
  });

  useEffect(() => {
    if (!textareaRef.current) {
      return;
    }

    textareaRef.current.style.height = 'auto';
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  }, [inputValue]);

  useEffect(() => {
    setTimeout(() => {
      if (isOpened) {
        sendMessage('initial_connection', '');
      }
    }, 1000);
  }, [isOpened]);

  const addMessageToConversation = (newMessage: string, isUser: boolean) => {
    const newConversationItem = {
      message: newMessage,
      isUser: isUser,
    };

    setConversation((prevConversation: any) => [
      ...prevConversation,
      newConversationItem,
    ]);
  };

  useEffect(() => {
    setConversation(transformMessages(currentChat?.messages || []));
  }, [currentChat]);

  useEffect(() => {
    if (newChat !== null && newChatCreated === false) {
      sendMessage('chat', inputValue, newChat?.threadId, newChat?.chatId);
      addMessageToConversation(inputValue, true);
      addMessageToConversation('', false);

      setInputValue('');
    }
    refetchChatTitles();
  }, [newChat, newChatCreated]);

  useEffect(() => {
    if (!(!isLoadingCurrentChat && conversation.length === 0)) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [lastMessage]);

  useEffect(() => {
    setConversation((prevConversation: any) => {
      if (prevConversation.length > 0) {
        return [
          ...prevConversation.slice(0, prevConversation.length - 1),
          {
            message: lastMessage,
            isUser: false,
          },
        ];
      }

      return prevConversation;
    });
  }, [lastMessage]);

  const handleFetchChat = (chatId: string) => {
    setChatId(chatId);

    if (!fetchChat) {
      // Enable fetchChat to trigger the first fetch
      setFetchChat(true);
    } else {
      // Refetch if already enabled
      refetchCurrentChat();
    }
  };

  useEffect(() => {
    const newChat = chatTitles?.find((chat) => chat.title === 'New Chat');

    if (newChat && !chatId && !inputNewChatCreat) {
      handleFetchChat(newChat._id);
    }
  }, [chatTitles]);

  const handleCreateChat = () => {
    try {
      createNewChat();
    } catch (error) {
      console.error('Error creating chat:', error);
    }
  };

  const handleSend = () => {
    if (loading || !inputValue.trim()) {
      return;
    }

    handleSetLoading(true);

    if (newChat) {
      sendMessage('chat', inputValue, newChat?.threadId, newChat?.chatId);
      addMessageToConversation(inputValue, true);
      addMessageToConversation('', false);

      setInputValue('');
    } else if (currentChat?.threadId) {
      sendMessage(
        'chat',
        inputValue,
        currentChat?.threadId,
        currentChat?._id,
        currentChat?.title
      );

      addMessageToConversation(inputValue, true);
      addMessageToConversation('', false);

      setInputValue('');
    } else if (chatTitles && !currentChat?.threadId && !newChat) {
      handleCreateChat();
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();

      handleSend();
    }

    setInputNewChatCreated(true);
  };

  const handleCancel = () => {
    setInputValue('');

    if (currentChat?.threadId) {
      sendMessage(
        'stop_generating',
        inputValue,
        currentChat?.threadId,
        currentChat?._id
      );
      return;
    }

    if (newChat) {
      sendMessage(
        'stop_generating',
        inputValue,
        newChat?.threadId,
        newChat?.chatId
      );
    }
  };

  const doesNewChatExist = chatTitles?.some(
    (chat) => chat.title === 'New Chat'
  );

  const newChatClickHandler = () => {
    if (doesNewChatExist) {
      return;
    }

    setNewChatCreated(true);
    createNewChat();
    setConversation([]);
  };

  return (
    <div className="flex bg-surfaceObject">
      {/*SIDE MENU*/}
      {drawerVisible && (
        <SideMenu
          onItemClick={handleFetchChat}
          onClose={() => setDrawerVisible(false)}
          selectedItemId={chatId}
          items={chatTitles}
        />
      )}
      {/*RIGHT CONTENT*/}
      <div className="bg-surfaceObject h-[calc(100vh-3.5rem)] w-full laptop:h-screen">
        {/* MAIN CONTAINER */}
        <div
          className={mergeClassNames(
            'flex flex-col h-full pt-4 mx-4 laptop:mx-0'
          )}
        >
          {/* PAGE CONTROLS */}
          <div
            className={mergeClassNames(
              'hidden pl-2 justify-center laptop:justify-start ml-0 tablet:flex',
              {
                'laptop:ml-20': !drawerVisible,
              }
            )}
          >
            <div className="flex w-168 laptop:w-auto">
              <Link className="mr-12" to={`/home`}>
                <Button
                  buttonText="Back"
                  variant="secondary"
                  icon={<BackSignIcon />}
                />
              </Link>
              <div className="text-xxs">
                <Link to="/home" className="text-customGrey">
                  Home
                </Link>{' '}
                <span className="text-white">/ Knowledge Base Assistant</span>
              </div>
            </div>
          </div>

          {/* CHAT HISTORY CONTROLS */}
          <div className="w-full flex justify-center laptop:justify-start laptop:ml-20">
            <div className="h-6 flex mt-0 pl-2 w-168 tablet:mt-6 laptop:w-auto">
              {!drawerVisible && (
                <div className="flex">
                  <Tooltip text="Chat History">
                    <IconButton
                      className="mr-4"
                      icon={<HistoryIcon />}
                      onClick={() => setDrawerVisible(true)}
                    />
                  </Tooltip>
                  <Tooltip text="New Chat">
                    <IconButton
                      className={mergeClassNames({
                        'cursor-not-allowed': doesNewChatExist,
                      })}
                      icon={<NewChatIcon />}
                      onClick={newChatClickHandler}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>

          {/* CHAT CONTAINER */}
          <div className="flex flex-col items-center justify-start h-full max-h-[calc(100%-4.625rem)]">
            <div className="flex flex-col items-center justify-end w-full h-full">
              {/* CHAT MESSAGES CONTAINER */}
              <div
                className={mergeClassNames(
                  'w-full h-full flex flex-col items-center overflow-y-auto p-4 my-4',
                  {
                    'justify-center': conversation.length === 0,
                    'justify-start': conversation.length !== 0,
                  }
                )}
              >
                {!isLoadingCurrentChat &&
                  !loading &&
                  conversation.length === 0 && <ChatPlaceholder />}

                {!isLoadingCurrentChat &&
                  conversation.map((message: any, index: number) => (
                    <div
                      className={mergeClassNames('w-full max-w-168 flex', {
                        'justify-end': message.isUser,
                        'justify-start': !message.isUser,
                      })}
                    >
                      <ChatMessage
                        key={index}
                        message={message.message}
                        isUser={message.isUser}
                        showPulsatingDot={
                          index === conversation.length - 1 && loading
                        }
                      />
                    </div>
                  ))}
                <div ref={messagesEndRef} />
              </div>

              {/* CHAT INPUT */}
              <div className="relative max-w-168 w-full mb-2 laptop:mb-12">
                <textarea
                  ref={textareaRef}
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  rows={1}
                  placeholder={'Ask Knowledge Base Assistant...'}
                  className="text-xsm text-white bg-surfaceHover w-full h-10 py-2.5 pr-12 pl-8 rounded-2.5xl outline-0 resize-none overflow-y-scroll scrollbar-hide border-0 max-h-24 placeholder:text-customGrey"
                />

                <button
                  onClick={() => {
                    if (inputValue) {
                      setInputNewChatCreated(true);
                      handleSend();
                    }
                  }}
                  disabled={loading && !isGeneratingText}
                  className="absolute right-2 top-1.5 w-6 h-6 flex justify-center items-center rounded-full border-0 outline-0 text-white"
                >
                  {isGeneratingText ? (
                    <StopGeneratingIcon
                      className="cursor-pointer"
                      onClick={handleCancel}
                    />
                  ) : (
                    <img
                      src={
                        !inputValue.trim() || loading
                          ? KnowledgeBaseInputButtonSecondary
                          : KnowledgeBaseInputButtonPrimary
                      }
                      alt="ask-button"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KnowledgeBaseAssistant;
