import MeetingFeedbackFirstScreenMentor from '@assets/images/meeting-feedback-first-screen.png';
import { NewMeeting } from '@shared/common';
import { useSetMeetingForReschedule } from '@shared/react';
import styles from '@styles/components/mentor-feedback.module.scss';
import SecondaryButton from '@utils/SecondaryButton';
import { Button, Space } from 'antd';
import { useState } from 'react';
import { COLORS } from '../../../../../v3/global';

type Props = {
  studentName: string;
  increaseStep: () => void;
  meeting: NewMeeting;
  hideFeedbackModal: () => void;
};

const Step1 = ({
  studentName,
  increaseStep,
  meeting,
  hideFeedbackModal,
}: Props) => {
  const [studentMissedPrompt, setStudentMissedPrompt] = useState(false);

  const {
    mutate: setMeetingForReschedule,
    isLoading: settingMeetingForRescheduling,
  } = useSetMeetingForReschedule(meeting._id, {
    onSuccess: () => {
      hideFeedbackModal();
    },
  });

  const StudentMissedPromptPage = () => (
    <>
      <h1 style={{ color: COLORS.TEXT_PRIMARY }}>
        <b>
          Are you sure {studentName} did not attend {meeting.name}?
        </b>
      </h1>
      <Space style={{ marginTop: 20 }}>
        <Button onClick={() => setStudentMissedPrompt(false)}>
          Take me back
        </Button>
        <Button
          type="primary"
          danger
          loading={settingMeetingForRescheduling}
          onClick={() => setMeetingForReschedule({})}
        >
          Student did not attend
        </Button>
      </Space>
    </>
  );

  const FeedbackPage = () => (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        style={{ minHeight: '280px', maxHeight: '280px', marginBottom: '48px' }}
        src={MeetingFeedbackFirstScreenMentor}
        alt="meeting-needing-feedback"
      />
      <div
        style={{
          textAlign: 'center',
          color: COLORS.TEXT_PRIMARY,
        }}
      >
        <h2
          style={{
            fontSize: '2rem',
            color: COLORS.TEXT_PRIMARY,
            marginBottom: '24px',
          }}
          className={styles.meetingNeedingFeedbackMentorStepOneTitle}
        >
          Almost done.. Please provide feedback for the session
        </h2>
        <h4
          style={{ color: COLORS.PRIMARY, marginBottom: '48px' }}
          className={styles.meetingNeedingFeedbackMentorStepOneDescription}
        >
          {meeting.name} - {studentName}
        </h4>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <SecondaryButton
          backgroundColor={COLORS.PRIMARY}
          borderColor={COLORS.PRIMARY}
          height="48px"
          width="75%"
          margin="0 0 24px 0"
          onClick={increaseStep}
        >
          <span
            style={{ color: COLORS.BLACK }}
            className={styles.meetingNeedingFeedbackMentorButtonText}
          >
            Leave Feedback and Complete Session
          </span>
        </SecondaryButton>
        <button
          style={{
            color: COLORS.TEXT_PRIMARY,
          }}
          className={`${styles.feedbackLink} main-green-link`}
          onClick={() => setStudentMissedPrompt(true)}
        >
          <span
            style={{ color: COLORS.TEXT_PRIMARY, textDecoration: 'none' }}
            className={
              styles.meetingNeedingFeedbackMentorStudentDidNotAttendText
            }
          >
            Student did not attend session
          </span>
        </button>
      </div>
    </div>
  );

  return (
    <>{studentMissedPrompt ? <StudentMissedPromptPage /> : <FeedbackPage />}</>
  );
};

export default Step1;
