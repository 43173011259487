import { usePaidMeetings } from '@shared/react';
import { Table } from 'antd';
import moment from 'moment';

type Props = {
  mentorId: string;
};

const PaidMeetingsTable = ({ mentorId }: Props) => {
  const { data, isLoading } = usePaidMeetings(mentorId);

  return (
    <Table
      pagination={false}
      loading={isLoading}
      dataSource={data}
      rowKey="_id"
      columns={[
        { title: 'Student', dataIndex: ['students', '0', 'fullName'] },
        { title: 'Course', dataIndex: ['course', 'title'] },
        {
          title: 'Date',
          dataIndex: 'startDate',
          render: (date) => moment(date).format('LLLL'),
        },
        {
          title: 'Value',
          dataIndex: 'sessionRate',
          render: (value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        },
      ]}
    />
  );
};

export default PaidMeetingsTable;
