import { Tooltip as AntTooltip, TooltipProps as AntTooltipProps } from 'antd';
import { BasicProps } from 'antd/lib/layout/layout';

interface TooltipProps {
  text: string;
}

const Tooltip = (props: TooltipProps & AntTooltipProps & BasicProps) => {
  const {
    children,
    text,
    placement = 'bottom',
    trigger = 'hover',
    ...rest
  } = props;

  return (
    <AntTooltip
      {...rest}
      zIndex={9999}
      placement={placement}
      trigger={trigger}
      overlayClassName="custom-tooltip"
      title={text}
    >
      {children}
    </AntTooltip>
  );
};

export default Tooltip;
