import EditEnrolmentMeetingsModalRow from '@components/V3/EnrollmentsModalPages/EditEnrolmentMeetingsModalRow';
import EditEnrolmentMentorModalRow from '@components/V3/EnrollmentsModalPages/EditEnrolmentMentorModalRow';
import useDetails, {
  DATE_FORMAT,
} from '@modules/Admin/Users/EnrollmentDetails/UpdateEnrollmentModal/useDetails';
import { SESSION_STATUSES } from '@shared/constants';
import {
  useAddSession,
  useCompletedMeetings,
  useDeleteSession,
  useEnrollment,
  useEnrollmentMentorHistory,
} from '@shared/react';
import SecondaryButton from '@utils/SecondaryButton';
import { message, Popconfirm } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { COLORS } from '../../../v3/global';
import './enrollments-modal.styles.scss';

type Props = {
  handleCurrentPage: (page: string) => void;
  currentEnrolment: string;
  enrolmentData?: any;
  handleEditEnrolmentTitle: (course: any, mentor: any, student: any) => any;
  refetchEnrollments: () => any;
  lastMeeting: string;
};

const EditEnrolmentPage = ({
  handleCurrentPage,
  currentEnrolment,
  enrolmentData,
  handleEditEnrolmentTitle,
  lastMeeting,
}: Props) => {
  const { data: currentEnrolmentData, isLoading } =
    useEnrollment(currentEnrolment);

  const { data: mentorHistory } = useEnrollmentMentorHistory(currentEnrolment);

  const { mutate: addSession } = useAddSession({
    onSuccess() {
      message.success('Meeting slot added successfully');
    },
    onError(err: any) {
      message.error(err?.message || 'An error has occurred!');
    },
  });

  const { mutate: deleteSession } = useDeleteSession({
    onSuccess() {
      message.success('Meeting slot deleted successfully');
    },
    onError(err: any) {
      message.error(err?.message || 'An error has occurred!');
    },
  });

  const { startDate, estimatedEndDate } = useDetails(
    currentEnrolmentData?._id ?? ''
  );

  const [selectedTab, setSelectedTab] = useState(
    lastMeeting !== '' ? 'Meetings' : 'Mentor'
  );

  useEffect(() => {
    if (!isLoading) {
      handleEditEnrolmentTitle(
        currentEnrolmentData?.course?.title,
        currentEnrolmentData?.mentor?.fullName,
        currentEnrolmentData?.user?.fullName
      );
    }
  }, [currentEnrolmentData, isLoading]);

  return (
    <div>
      {/*ENROLMENT INFO*/}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '8px',
          marginBottom: '8px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <span
            style={{ color: COLORS.TEXT_PRIMARY, marginBottom: '16px' }}
            className="edit-enrolment-section-subtitle"
          >
            Details
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                lineHeight: '14px',
              }}
            >
              <div style={{ marginBottom: '16px' }}>
                <span
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    height: 'fit-content',
                  }}
                  className="edit-enrolment-details-label"
                >
                  Status:{' '}
                </span>
                <span
                  style={{ color: COLORS.TEXT_PRIMARY }}
                  className="edit-enrolment-details-value"
                >
                  {currentEnrolmentData?.ended === true
                    ? 'Ended'
                    : currentEnrolmentData?.ended === false
                    ? 'Active'
                    : '-'}
                </span>
              </div>
              <div style={{ marginBottom: '16px' }}>
                <span
                  style={{ color: COLORS.TEXT_SECONDARY }}
                  className="edit-enrolment-details-label"
                >
                  Start Date:{' '}
                </span>
                <span
                  style={{ color: COLORS.TEXT_PRIMARY }}
                  className="edit-enrolment-details-value"
                >
                  {startDate ? moment(startDate).format(DATE_FORMAT) : '-'}
                </span>
              </div>
              <div style={{ marginBottom: '16px' }}>
                <span
                  style={{ color: COLORS.TEXT_SECONDARY }}
                  className="edit-enrolment-details-label"
                >
                  End Date:{' '}
                </span>
                <span
                  style={{ color: COLORS.TEXT_PRIMARY }}
                  className="edit-enrolment-details-value"
                >
                  {estimatedEndDate
                    ? moment(estimatedEndDate).format(DATE_FORMAT)
                    : '-'}
                </span>
              </div>
            </div>
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                lineHeight: '14px',
              }}
            >
              <div style={{ marginBottom: '16px' }}>
                <span
                  style={{ color: COLORS.TEXT_SECONDARY }}
                  className="edit-enrolment-details-label"
                >
                  Total Meetings:{' '}
                </span>
                <span
                  style={{ color: COLORS.TEXT_PRIMARY }}
                  className="edit-enrolment-details-value"
                >
                  {enrolmentData?.noOfCompletedMeetings ?? '-'}/
                  {enrolmentData?.noOfTotalMeetings ?? '-'}
                </span>
              </div>
              <div style={{ marginBottom: '16px' }}>
                <span
                  style={{ color: COLORS.TEXT_SECONDARY }}
                  className="edit-enrolment-details-label"
                >
                  Mentor:{' '}
                </span>
                <span
                  style={{ color: COLORS.TEXT_PRIMARY }}
                  className="edit-enrolment-details-value"
                >
                  {currentEnrolmentData?.mentor?.fullName ?? '-'}
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <SecondaryButton
              onClick={() => handleCurrentPage('edit-enrollment-details')}
              backgroundColor={COLORS.WHITE}
              height="27px"
              padding="6px 24px 6px 24px"
              margin="0px"
            >
              <span className="button-text" style={{ color: COLORS.BLACK }}>
                Edit
              </span>
            </SecondaryButton>
          </div>
        </div>
      </div>

      {/*TABS*/}
      <div style={{ marginBottom: '16px' }}>
        <nav
          style={{ overflowX: 'auto' }}
          className="flex space-x-4"
          aria-label="Tabs"
        >
          <span
            onClick={() => {
              setSelectedTab('Mentor');
            }}
            style={{
              cursor: 'pointer',
              color:
                selectedTab === 'Mentor' ? COLORS.BLACK : COLORS.TEXT_PRIMARY,
              background:
                selectedTab === 'Mentor'
                  ? COLORS.PRIMARY
                  : COLORS.DARK_GREY_ALT,
              borderRadius: '32px',
              paddingLeft: '24px',
              paddingRight: '24px',
              paddingTop: '4px',
              paddingBottom: '4px',
              height: '24px',
              fontSize: '11px',
              lineHeight: '15px',
            }}
            aria-current={selectedTab === 'Mentor' ? 'page' : undefined}
          >
            Mentor
          </span>

          <span
            onClick={() => {
              setSelectedTab('Meetings');
            }}
            style={{
              cursor: 'pointer',
              color:
                selectedTab === 'Meetings' ? COLORS.BLACK : COLORS.TEXT_PRIMARY,
              background:
                selectedTab === 'Meetings'
                  ? COLORS.PRIMARY
                  : COLORS.DARK_GREY_ALT,
              borderRadius: '32px',
              paddingLeft: '24px',
              paddingRight: '24px',
              paddingTop: '4px',
              paddingBottom: '4px',
              height: '24px',
              fontSize: '11px',
              lineHeight: '15px',
            }}
            aria-current={selectedTab === 'Meetings' ? 'page' : undefined}
          >
            Meetings
          </span>
        </nav>
      </div>

      {/*MENTOR TABLE*/}
      {selectedTab === 'Mentor' && (
        <div>
          {/*COLUMNS*/}
          <div
            style={{
              display: 'flex',
              marginBottom: '12px',
              width: '100%',
            }}
          >
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
                width: '50%',
                marginRight: '8px',
              }}
              className="modal-column-text"
            >
              Mentor Name
            </span>
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
                width: '30%',
                marginRight: '8px',
              }}
              className="modal-column-text"
            >
              Date Assigned
            </span>
            <span
              style={{ color: COLORS.TEXT_SECONDARY, width: '20%' }}
              className="modal-column-text"
            >
              Status
            </span>
          </div>
          {/*TABLE HEADER DIVIDER*/}
          <div style={{ borderTopWidth: '0.5px' }} className="relative">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
          </div>

          {/*MENTOR ROWS*/}
          <EditEnrolmentMentorModalRow mentorHistory={mentorHistory} />
        </div>
      )}

      {/*MEETINGS TABLE*/}
      {selectedTab === 'Meetings' && (
        <div>
          {/*COLUMNS*/}
          <div
            style={{
              display: 'flex',
              marginBottom: '12px',
              width: '100%',
            }}
          >
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
                width: '50%',
                marginRight: '8px',
              }}
              className="modal-column-text"
            >
              Date
            </span>
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
                width: '30%',
                marginRight: '8px',
              }}
              className="modal-column-text"
            >
              Status
            </span>
            <span
              style={{ color: COLORS.TEXT_SECONDARY, width: '20%' }}
              className="modal-column-text"
            >
              Actions
            </span>
          </div>
          {/*TABLE HEADER DIVIDER*/}
          <div style={{ borderTopWidth: '0.5px' }} className="relative">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
          </div>

          {/*MEETINGS ROWS*/}
          {currentEnrolmentData?.sessions?.map(
            (session: any, index: number) => (
              <div>
                <EditEnrolmentMeetingsModalRow
                  session={session}
                  deleteSession={deleteSession}
                  enrolmentId={currentEnrolmentData?._id}
                  meetingIndex={index}
                  lastMeeting={lastMeeting}
                />
              </div>
            )
          )}

          {/*ADD MEETING BUTTON*/}
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <Popconfirm
              icon={null}
              title="Are you sure you want to add a new meeting slot?"
              onConfirm={() => {
                currentEnrolmentData?._id &&
                  addSession({ enrollmentId: currentEnrolmentData._id });
              }}
            >
              <SecondaryButton
                backgroundColor={COLORS.WHITE}
                height="27px"
                padding="4px 10px 4px 10px"
                margin="12px 0 0 0"
              >
                <span className="button-text" style={{ color: COLORS.BLACK }}>
                  Add Meeting Slot
                </span>
              </SecondaryButton>
            </Popconfirm>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditEnrolmentPage;
