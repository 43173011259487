import { EditOutlined } from '@ant-design/icons';
import { NotesModal } from '@components';
import MentorReliabilityScoreModal from '@components/Admin/MentorReliabilityScore';
import UserEditableField from '@components/Admin/UserEditableField';
import { useModal } from '@hooks';
import {
  MENTORING_STYLES,
  MENTOR_STATUS_V3,
  RECRUITED_FROM,
  User,
} from '@shared/common';
import { useGetAllCoursesLite, useMentors } from '@shared/react';
import { getRoundedNumber } from '@shared/utils/number';
import { Avatar, Button, notification, Space, Table } from 'antd';

import moment from 'moment';
import ActiveEnrollmentsCalculator from './ActiveEnrollmentsCalculator';
import EnrollmentsInfo from './EnrollmentsInfo';
import MentorPaymentDetailsDrawer from './MentorPaymentDetailsDrawer';
import MentorSettingsDrawer from './MentorSettingsDrawer';
import { MentorSearchParam } from '.';

const MentorsTable = ({
  searchParams,
  searchQuery,
  tableHeight,
}: {
  searchParams: MentorSearchParam;
  searchQuery: string;
  tableHeight: number;
}) => {
  const { data: searchMentorResponse, isLoading } = useMentors({
    ...searchParams,
    search: searchQuery,
  });
  const [PaymentDetails, showPaymentDetails] = useModal(
    MentorPaymentDetailsDrawer
  );
  const [MentorSettings, showMentorSettings] = useModal(MentorSettingsDrawer);
  const [Notes, showNotes] = useModal(NotesModal);
  const [MentorReliabilityScore, showMentorReliabilityScore] = useModal(
    MentorReliabilityScoreModal
  );

  const { data: courses } = useGetAllCoursesLite();

  type coursesDictType =
    | {
        [key: string]: string;
      }
    | undefined;

  const coursesDict: coursesDictType = courses?.reduce(
    (acc, course) => ({ ...acc, [course?._id]: course.title }),
    {}
  );

  const calcTop3MentorTraits = (mentor: User) => {
    return 'todo';
  };

  const viewFullText = (text: string) => {
    const args = {
      message: 'Full Text',
      description: text,
      duration: 0,
      placement: 'top' as any,
    };
    notification.open(args);
  };

  const translateLink = (bio: string) =>
    `https://www.deepl.com/translator#en/ro/${bio || ''}`;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      fixed: 'left' as any,
      width: 250,
      render: (_: unknown, mentor: User) => (
        <UserEditableField
          updateLocation="fullName"
          type="string"
          initialValue={mentor.fullName}
          userId={mentor?._id}
        />
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (_: unknown, mentor: User) => (
        <UserEditableField
          updateLocation="mentorData.status"
          type="dropdown"
          dropdownOptions={MENTOR_STATUS_V3}
          initialValue={mentor.mentorData?.status}
          userId={mentor?._id}
        />
      ),
    },
    {
      title: 'Maximum Capacity',
      data: 'mentorData.maximumActiveEnrollments',
      key: 'mentorData.maximumActiveEnrollments',
      render: (_: unknown, mentor: User) => (
        <UserEditableField
          updateLocation="mentorData.maximumActiveEnrollments"
          type="number"
          initialValue={mentor.mentorData.maximumActiveEnrollments || 0}
          userId={mentor?._id}
        />
      ),
    },
    {
      title: 'Enrollment Max Capacity',
      data: 'mentorData.mentorCapacity.capacity',
      key: 'mentorData.mentorCapacity.capacity',
      render: (_: unknown, mentor: User) => (
        <UserEditableField
          updateLocation="mentorData.mentorCapacity.capacity"
          type="number"
          initialValue={mentor.mentorData?.mentorCapacity?.capacity || 0}
          userId={mentor?._id}
        />
      ),
    },
    {
      title: 'Current Capacity',
      data: '_id',
      key: 'currentCapacity',
      render: (_: unknown, mentor: User) => (
        <ActiveEnrollmentsCalculator mentorId={mentor?._id} />
      ),
    },
    {
      title: 'Courses',
      data: 'mentorData.coursesCanTeach',
      key: 'mentorData.coursesCanTeach',
      render: (_: unknown, mentor: User) => {
        const { coursesCanTeach } = mentor.mentorData;
        if (!coursesCanTeach) return 'None';

        const courses = coursesCanTeach.map((courseId) =>
          coursesDict ? coursesDict[courseId] : null
        );

        return (
          <span
            onClick={() => showMentorSettings({ mentorId: mentor?._id })}
            style={{ cursor: 'pointer' }}
          >
            {courses.join(', ')}
            <EditOutlined />
          </span>
        );
      },
    },
    {
      title: 'Subjects they can teach',
      data: 'studySubjects',
      key: 'studySubjects',
      render: (mentor: User) => <span>{mentor.studySubjects}</span>,
    },
    {
      title: 'Start date',
      data: 'createdAt',
      key: 'createdAt',
      render: (mentor: User) => moment(mentor.createdAt).format('YYYY-MM-DD'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (_: unknown, mentor: User) => (
        <UserEditableField
          updateLocation="email"
          type="string"
          initialValue={mentor.email}
          userId={mentor?._id}
        />
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (_: unknown, mentor: User) => (
        <UserEditableField
          updateLocation="phoneNumber"
          type="string"
          initialValue={mentor.phoneNumber}
          userId={mentor?._id}
        />
      ),
    },
    {
      title: 'Universities',
      data: 'mentorData.universities',
      key: 'mentorData.universities',
      render: (mentor: User) => (
        <span
          onClick={() => showMentorSettings({ mentorId: mentor?._id })}
          style={{ cursor: 'pointer' }}
        >
          {mentor.mentorData.universities.join(', ')}
          <EditOutlined />
        </span>
      ),
    },
    {
      title: 'Characteristics',
      data: 'mentoringStyle',
      key: 'mentoringStyle',
      render: (mentor: User) =>
        MENTORING_STYLES[mentor.mentoringStyle] || 'None',
    },
    {
      title: 'Notes',
      data: 'notes',
      key: 'notes',
      render: (mentor: User) => (
        <Button onClick={() => showNotes({ userId: mentor._id })}>
          Edit notes
        </Button>
      ),
    },
    {
      title: 'Recruited from',
      data: 'mentorData.recruitedFrom',
      key: 'mentorData.recruitedFrom',
      render: (_: unknown, mentor: User) => (
        <UserEditableField
          updateLocation="mentorData.recruitedFrom"
          type="dropdown"
          dropdownOptions={RECRUITED_FROM}
          initialValue={mentor.mentorData.recruitedFrom || 'Unknown'}
          userId={mentor._id}
        />
      ),
    },
    {
      title: 'Reliability',
      data: 'mentorData.reliability',
      key: 'mentorData.reliability',
      render: (_: unknown, mentor: User) => (
        <span
          onClick={() => showMentorReliabilityScore({ userId: mentor._id })}
          style={{ cursor: 'pointer' }}
        >
          {mentor.mentorData.reliability?.toFixed(1)} {''}
          <EditOutlined />
        </span>
      ),
    },
    {
      title: 'Student Feedback',
      dataIndex: 'averageFeedback',
      key: 'averageFeedback',
      render: (key: any, user: User) => {
        const { mentorData } = user;
        const score = mentorData.averageFeedback
          ? getRoundedNumber(mentorData.averageFeedback)
          : 'n/a';
        return <span>{score}</span>;
      },
    },
    {
      title: 'Positive Feedback',
      dataIndex: 'positiveFeedback',
      key: 'positiveFeedback',
      render: (key: any, user: User) => calcTop3MentorTraits(user),
    },
    {
      title: 'Short Bio',
      dataIndex: 'mentorData.shortBiography',
      key: 'mentorData.shortBiography',
      width: '200px',
      render: (key: any, mentor: User) =>
        mentor.mentorData?.shortBiography?.length ? (
          <>
            <Button
              onClick={() =>
                viewFullText(mentor.mentorData?.shortBiography || '')
              }
            >
              Original
            </Button>
            <a
              href={translateLink(mentor.mentorData.shortBiography)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button>RO</Button>
            </a>
          </>
        ) : (
          <Button disabled>None</Button>
        ),
    },
    {
      title: 'Full Bio',
      dataIndex: 'mentorData.biography',
      key: 'mentorData.biography',
      width: '200px',
      render: (key: any, mentor: User) =>
        mentor.mentorData?.biography?.length ? (
          <>
            <Button
              onClick={() => viewFullText(mentor.mentorData?.biography || '')}
            >
              Original
            </Button>
            <a
              href={translateLink(mentor.mentorData.biography)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button>RO</Button>
            </a>
          </>
        ) : (
          <Button disabled>None</Button>
        ),
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (avatar: string) => <Avatar src={avatar} size={50} />,
    },
    {
      title: 'Is inactive?',
      key: 'isMarkedInactive',
      render: (mentor: User) => (
        <UserEditableField
          updateLocation="isMarkedInactive"
          type="checkbox"
          initialValue={mentor.isMarkedInactive || false}
          userId={mentor._id}
        />
      ),
    },
    {
      title: 'Actions',
      dataIndex: '_id',
      render: (id: string) => (
        <Space>
          <Button onClick={() => showPaymentDetails({ mentorId: id })}>
            Payment Details
          </Button>
          <Button onClick={() => showMentorSettings({ mentorId: id })}>
            Mentor Settings
          </Button>
        </Space>
      ),
      width: 400,
    },
  ];

  return (
    <div>
      <small>{searchMentorResponse?.total} results</small>
      <Table
        pagination={{
          pageSizeOptions: ['10', '20', '50', '100'],
          defaultPageSize: 50,
        }}
        dataSource={searchMentorResponse?.users}
        loading={isLoading}
        rowKey="_id"
        columns={columns}
        scroll={{ x: 4200, y: tableHeight }}
        tableLayout={'fixed'}
        expandable={{
          expandedRowRender: (record) => <EnrollmentsInfo record={record} />,
          rowExpandable: (record) => !!record.mentorData,
        }}
      />
      {PaymentDetails}
      {MentorSettings}
      {Notes}
      {MentorReliabilityScore}
    </div>
  );
};

export default MentorsTable;
