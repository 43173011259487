import { TimezoneNotice } from '@components';
import CustomTimePicker, {
  CustomTimePickerIcon,
} from '@components/Form/CustomTimePicker';
import { useEditMeeting, useMeeting } from '@shared/react';
import { CloseModalProps, Meeting } from '@shared/types';
import styles from '@styles/components/reusables/modal.module.scss';
import { Button, message, Modal, Row, Col, DatePicker, Form } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import './calendar-meeting-modal.styles.scss';
import { COLORS } from '../../../../../v3/global';

type Props = CloseModalProps & {
  meetingId: string;
  dateTime: string;
  afterSave?: () => void;
};

const RescheduleModal = ({
  visible,
  hide,
  meetingId,
  dateTime,
  afterSave,
}: Props) => {
  const { data: meeting } = useMeeting(meetingId);
  const [errorMeeting, setErrorMeeting] = useState<Partial<Meeting>>();
  const { mutate: editMeeting, isLoading } = useEditMeeting(meetingId, {
    onSuccess() {
      message.success('Meeting successfully rescheduled.');
      hide();
    },
    onError(error: any) {
      message.error(
        error?.response?.data?.message ??
          error?.data?.message ??
          error.message ??
          'An error occurred'
      );
      setErrorMeeting(error?.response?.data?.meeting);
    },
  });

  const startDate = moment(dateTime).format('YYYY-MM-DD');

  const [date, setDate] = useState(moment(startDate));
  const [time, setTime] = useState(moment(dateTime).format('HH:mm'));

  const [timepickerFocus, setTimepickerFocus] = useState(false);

  const saveChanges = () => {
    const selectedTime = moment(time, 'H:mm');
    const newDateTime =
      date &&
      moment(
        date.set({
          hour: selectedTime.get('hour'),
          minute: selectedTime.get('minute'),
          second: 0,
          millisecond: 0,
        })
      );
    editMeeting({ startDate: moment(newDateTime).toISOString() });

    if (afterSave) {
      afterSave();
    }
  };

  return (
    <Modal
      title={<span className={'agoraModalTitle'}>Reschedule Meeting</span>}
      bodyStyle={{
        backgroundColor: COLORS.SECTION_BACKGROUND,
      }}
      visible={visible}
      onCancel={hide}
      centered
      footer={
        <Button
          style={{
            backgroundColor: COLORS.BLACK,
            color: COLORS.WHITE,
          }}
          loading={isLoading}
          onClick={saveChanges}
        >
          Reschedule
        </Button>
      }
      className={'agora-modal-content'}
    >
      <p
        style={{
          fontWeight: 300,
          color: COLORS.TEXT_PRIMARY,
        }}
      >
        Reschedule <span style={{ fontWeight: 600 }}>{meeting?.name}</span> with{' '}
        <span style={{ fontWeight: 600 }}>
          {meeting?.students[0]?.fullName}
        </span>
      </p>
      <Form layout="vertical" {...layout} initialValues={{ date: date }}>
        <Row style={{ marginTop: '16px' }} gutter={8}>
          <Col span={9}>
            <Form.Item rules={[{ required: true }]} name="date">
              <p
                style={{
                  color: COLORS.TEXT_PRIMARY,
                }}
              >
                <span style={{ color: COLORS.ERROR }}>*</span> Date
              </p>
              <DatePicker
                value={date}
                onChange={(value) => setDate(moment(value))}
                disabledDate={(date) =>
                  date < moment(moment().format('YYYY-MM-DD'))
                }
                style={{
                  backgroundColor: 'transparent',
                  fontFamily: 'Open Sans, sans-serif',
                  width: '100%',
                }}
                className="agora-date-picker"
              />
            </Form.Item>
          </Col>
          <Col span={6}></Col>
          <Col span={9} className={styles.newMeetingModalSelectTime}>
            <Form.Item rules={[{ required: true }]} name="time">
              <p
                style={{
                  color: COLORS.TEXT_PRIMARY,
                }}
              >
                <span style={{ color: COLORS.ERROR }}>*</span> Date
              </p>
              <CustomTimePicker
                time={time}
                handleChange={(value: string) => setTime(value)}
                handleFocus={() => setTimepickerFocus(true)}
                date={date}
                disablePastHours={true}
              />
            </Form.Item>
            <CustomTimePickerIcon timepickerFocus={timepickerFocus} />
          </Col>
        </Row>
      </Form>

      <div className={styles.modalTimezone}>
        <TimezoneNotice />
      </div>

      {errorMeeting && (
        <div className={styles.modalError}>
          <p className="mt2">
            <b>Warning:</b> The meeting you are trying to reschedule is in
            conflict with another meeting.
          </p>
          <p>
            <b>Meeting Name:</b> {errorMeeting.name}
          </p>
          <p>
            <b>Date:</b>{' '}
            {moment(errorMeeting.startDate).format('MMMM DD YYYY, HH:mm')}
          </p>
          <p>
            <b>Course:</b> {errorMeeting.course?.title}
          </p>
          <p>
            <b>Students:</b>{' '}
            {errorMeeting.students.map((s: any) => s.fullName).join(', ')}
          </p>
          {errorMeeting.chapterId && (
            <p>
              <b>Chapter:</b> {errorMeeting.chapterId + 1}
            </p>
          )}
        </div>
      )}
    </Modal>
  );
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
};

export default RescheduleModal;
