import {
  PlusOutlined,
  EditOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import { TrashIcon } from '@heroicons/react/outline';
import { LMSCourse } from '@shared/common';
import { useLMSCourses, useSoftDeleteCourse } from '@shared/react';
import {
  message,
  PageHeader,
  Card,
  Tree,
  Button,
  Modal,
  Input,
  Select,
  Checkbox,
  Popconfirm,
} from 'antd';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { TwitterPicker } from 'react-color';
import authHeader from '../../services/auth-header';
import IndividualCourseButton from '../Course/IndividualCourseButton';

import ModuleResourcesModal from './ModuleResourcesModal';

const { DirectoryTree } = Tree;

const CourseManagementPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [treeData, setTreeData] = useState<any>([]);

  // form fields
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [lmsCourseId, setLmsCourseId] = useState(''); // for LMS
  const [lmsCoursePrice, setLmsCoursePrice] = useState(0); // for LMS
  const [abbreviation, setAbbreviation] = useState('');
  const [isFree, setIsFree] = useState(true);
  const [country, setCountry] = useState('');
  const [color, setColor] = useState('#cccccc');
  const [expandedKeys, setExpandedKeys] = useState<any>([]);
  const [selectedKeys, setSelectedKeys] = useState<any>([]);

  // new module modal
  const [isNewModuleModalVisible, setIsNewModuleModalVisible] = useState(false);
  const [newModuleModalCourseTitle, setNewModuleModalCourseTitle] =
    useState('');
  const [newModuleModalCourseId, setNewModuleModalCourseId] = useState('');

  // module resources modal
  const [resourcesCourseId, setResourcesCourseId] = useState('');
  const [resourcesModuleIndex, setResourcesModuleIndex] = useState('');

  // LMS courses
  const { data: lmsCourses } = useLMSCourses();

  const { mutate: deleteCourse } = useSoftDeleteCourse(resourcesCourseId, {
    onSuccess: () => {
      message.success('Course deleted successfully');
      getAllCourses();
    },
  });

  const onSelect = (keys: any, info: any) => {
    setSelectedKeys([...keys]);
    if (keys[0].startsWith('new-module/')) {
      const info = keys[0].split('/');
      showNewModuleModal(info[1], info[2]);
      return;
    }
    if (keys[0].startsWith('new') || keys[0].startsWith('edit')) {
      window.location.href = `/admin/lesson/${keys[0]}?refType=Lesson`;
    }
    if (keys[0].startsWith('manage-courses/')) {
      window.location.href = `/admin/${keys[0]}`;
    }
    if (keys[0].startsWith('resources/')) {
      const info = keys[0].split('/');
      setResourcesCourseId(info[1]);
      setResourcesModuleIndex(info[2]);
    }
    if (keys[0].startsWith('delete-course/')) {
      const info = keys[0].split('/');
      setResourcesCourseId(info[1]);
    }
  };

  const onExpand = (keys: any) => {
    setExpandedKeys([...keys]);
  };

  useEffect(() => {
    getAllCourses();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showNewModuleModal = (id: string, title: string) => {
    setIsNewModuleModalVisible(true);
    setNewModuleModalCourseId(id);
    setNewModuleModalCourseTitle(title);
  };

  const getAllCourses = () => {
    axios
      .get('/api/v2/course/list-all', { headers: authHeader() })
      .then((response) => {
        const courses = response.data.docs;
        const newTreeData = [];

        for (let i = 0; i < courses.length; i++) {
          const currentChildren = [];
          currentChildren.push({
            title: 'EDIT COURSE DETAILS',
            key: `manage-courses/${courses[i]._id}`,
            isLeaf: true,
            icon: <EditOutlined />,
          });

          currentChildren.push({
            title: (
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => deleteCourse({})}
                okText="Yes"
                cancelText="No"
                style={{ zIndex: '99999 !important', width: '100px' }}
              >
                DELETE COURSE
              </Popconfirm>
            ),
            key: `delete-course/${courses[i]._id}`,
            isLeaf: true,
            icon: (
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => deleteCourse({})}
                okText="Yes"
                cancelText="No"
                style={{ zIndex: '99999 !important', width: '100px' }}
              >
                <TrashIcon />
              </Popconfirm>
            ),
          });

          for (let j = 0; j < courses[i].modules.length; j++) {
            const currentModule: any = {
              title: `Module ${j}: ${courses[i].modules[j].title}`,
              key: `module/${courses[i]._id}/${j}`,
              children: [],
              isLeaf: false,
            };

            currentModule.children.push({
              title: `RESOURCES LIST`,
              key: `resources/${courses[i]._id}/${j}`, // resources/COURSE_ID/MODULE_INDEX
              isLeaf: true,
              icon: <PaperClipOutlined />,
            });

            const lessons = courses[i].modules[j].lessons;
            for (let k = 0; k < lessons.length; k++) {
              const word = lessons[k].refType.toLowerCase();

              currentModule.children.push({
                title: `Lesson ${k}: ${lessons[k].title}`,
                key: `edit-${word}/${courses[i]._id}/${lessons[k]._id}/${courses[i].modules[j]._id}`, // edit/COURSE_ID/LESSON_ID/MODULE_ID
                isLeaf: true,
              });
            }

            currentModule.children.push({
              title: `NEW LESSON`,
              key: `new-lesson/${courses[i]._id}/n/${courses[i].modules[j]._id}`, // edit/COURSE_ID/n/MODULE_ID
              isLeaf: true,
              icon: <PlusOutlined />,
            });

            currentModule.children.push({
              title: `NEW QUIZ`,
              key: `new-quiz/${courses[i]._id}/n/${courses[i].modules[j]._id}`, // edit/COURSE_ID/n/MODULE_ID
              isLeaf: true,
              icon: <PlusOutlined />,
            });

            currentChildren.push(currentModule);
          }

          currentChildren.push({
            title: 'NEW MODULE',
            key: `new-module/${courses[i]._id}/${courses[i].title}`,
            isLeaf: true,
            icon: <PlusOutlined />,
          });
          newTreeData.push({
            title: courses[i].title,
            key: courses[i]._id,
            children: currentChildren,
          });
        }

        setTreeData(newTreeData);
      })
      .catch((error) => {
        message.error('An error occured. Details in console.');
        console.error(error);
      });
  };

  const handleOk = () => {
    axios
      .post(
        '/api/v2/course/new',
        {
          title,
          subtitle,
          abbreviation,
          lmsCourseId,
          lmsCoursePrice,
          isFree,
          country,
          color,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        message.success(response.data.message);
        handleCancel();
        getAllCourses();
      })
      .catch((error) => {
        message.error('An error occured.');
        message.error(JSON.stringify(error.data));
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setTitle('');
    setAbbreviation('');
    setLmsCourseId('');
    setLmsCoursePrice(0);
    setSubtitle('');
    setIsFree(true);
    setCountry('');
    setColor('#cccccc');
  };

  return (
    <div>
      <div>
        <PageHeader ghost={false} title="Manage Courses"></PageHeader>
      </div>

      <Card style={{ margin: 15, minHeight: 300 }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          style={{ marginBottom: 15 }}
          onClick={showModal}
        >
          Add New Course
        </Button>
        <DirectoryTree
          multiple
          onSelect={onSelect}
          onExpand={onExpand}
          treeData={treeData}
          expandedKeys={expandedKeys}
          selectedKeys={selectedKeys}
        />
      </Card>

      <Modal
        title="New Course"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          placeholder="Title"
          value={title}
          name="title"
          onChange={(e) => setTitle(e.target.value)}
          style={{ marginBottom: 10 }}
        />

        <Input
          placeholder="Subtitle"
          value={subtitle}
          name="subtitle"
          onChange={(e) => setSubtitle(e.target.value)}
          style={{ marginBottom: 10 }}
        />

        <Input
          placeholder="Abbreviation"
          value={abbreviation}
          name="abbreviation"
          onChange={(e) => setAbbreviation(e.target.value)}
          style={{ marginBottom: 10 }}
        />

        <p style={{ color: 'red' }}>LMS Course</p>
        <Select
          onChange={(val) => {
            setLmsCourseId(val);
            setLmsCoursePrice(lmsCourses?.find((c) => c.id == val)?.price ?? 0);
          }}
          value={lmsCourseId}
          style={{ marginBottom: 10, width: 400 }}
        >
          <Select.Option value="">No LMS course selected</Select.Option>
          {lmsCourses?.map((course: LMSCourse) => (
            <Select.Option value={course.id} key={course.id}>
              {course.title} ({`${course?.price}$` ?? 'Free'})
            </Select.Option>
          ))}
        </Select>

        <Checkbox
          checked={isFree}
          onChange={(e) => setIsFree(e.target.checked)}
          style={{ marginBottom: 10 }}
        >
          Is free (check even if only a few lessons are free)
        </Checkbox>

        <br />

        <Select
          onChange={(val) => setCountry(val)}
          value={country}
          style={{ marginBottom: 10 }}
        >
          <Select.Option value="">No targeted country</Select.Option>
          <Select.Option value="US">US</Select.Option>
          <Select.Option value="UK">UK</Select.Option>
          <Select.Option value="EU">EU</Select.Option>
        </Select>

        <p>Color</p>

        <TwitterPicker color={color} onChange={(c) => setColor(c.hex)} />

        <br />
        <br />
        <br />
        <b>Preview</b>
        <IndividualCourseButton
          clickDisabled={true}
          data={{
            complete: 35,
            color,
            title,
            subtitle,
            nextLesson: 'Lesson Title Here',
          }}
        />
      </Modal>

      <NewModuleModal
        isModalVisible={isNewModuleModalVisible}
        setIsModalVisible={setIsNewModuleModalVisible}
        getAllCourses={getAllCourses}
        courseTitle={newModuleModalCourseTitle}
        courseId={newModuleModalCourseId}
      />

      <ModuleResourcesModal
        courseId={resourcesCourseId}
        moduleIndex={resourcesModuleIndex}
        setCourseId={setResourcesCourseId}
        setModuleIndex={setResourcesModuleIndex}
      />
    </div>
  );
};

const NewModuleModal = ({
  isModalVisible,
  setIsModalVisible,
  getAllCourses,
  courseTitle,
  courseId,
}: any) => {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [isSkippable, setIsSkippable] = useState(false);

  const resetState = () => {
    setTitle('');
    setSubtitle('');
    setIsSkippable(false);
  };

  const handleOk = () => {
    axios
      .post(
        `/api/v2/course/new-module/${courseId}`,
        {
          title,
          subtitle,
          isSkippable,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        message.success(response.data.message);
        getAllCourses();
        setIsModalVisible(false);
        resetState();
      })
      .catch((err) => {
        console.error(err);
        message.error("Something didn't work. Check the console.");
        resetState();
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      title={`New Module For ${courseTitle}`}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Input
        placeholder="Title"
        value={title}
        name="title"
        onChange={(e) => setTitle(e.target.value)}
        style={{ marginBottom: 10 }}
      />

      <Input
        placeholder="Subtitle"
        value={subtitle}
        name="subtitle"
        onChange={(e) => setSubtitle(e.target.value)}
        style={{ marginBottom: 10 }}
      />

      <Checkbox
        checked={isSkippable}
        onChange={(e) => setIsSkippable(e.target.checked)}
        style={{ marginBottom: 10 }}
      >
        Is skippable (WIP)
      </Checkbox>
    </Modal>
  );
};

export default CourseManagementPage;
