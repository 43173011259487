import { ReactComponent as DeleteIcon } from '@assets/icons/icon-material-delete.svg';
import { ReactComponent as EditIcon } from '@assets/icons/icon-material-edit.svg';
import { useModal } from '@hooks';
import EditExtraServicesModal from '@modules/Admin/ExtraServices/ExtraServicesModal/EditExtraServiceModal';
import authHeader from '@services/auth-header';
import { ExtraServiceStatus } from '@shared/constants';
import { BASE_URL } from '@shared/frontendEnv';
import rowStyles from '@styles/components/extra-services-row.module.scss';
import styles from '@styles/components/table.module.scss';
import { message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React from 'react';

// TODO Refactor any types
interface Props {
  selectedDate: any;
  extraService: any;
  completedNumber?: number;
  isEven: boolean;
  handleSelected: (extraService: any) => void;
  selectAll: boolean;
  selectedItems: any[];
  handleRowTouched: () => void;
  index: number;
}

const ExtraServicesRow: React.FC<Props> = ({
  selectedDate,
  extraService,
  isEven,
  handleSelected,
  selectAll,
  selectedItems,
  handleRowTouched,
  index,
}) => {
  const [EditServiceModal, showEditModal] = useModal(EditExtraServicesModal);

  const classes = `${styles.gridTableRow} ${
    isEven ? styles.gridTableRowEven : styles.gridTableRowOdd
  }`;

  const deleteExtraService = async () => {
    try {
      await axios.delete(
        `${BASE_URL}/api/v1/extra-services/admin/${extraService._id}`,
        { headers: authHeader() }
      );
      message.success('Extra service was deleted successfully!');
    } catch (error) {
      console.log(error);
    }
    handleRowTouched();
  };

  return (
    <>
      <div className={` ${classes} ${styles.gridTableRowFirst}`}>
        {extraService?.mentor?.fullName}
      </div>
      <div className={` ${classes}`}>
        {extraService?.serviceType.charAt(0).toUpperCase() +
          extraService?.serviceType.slice(1)}
      </div>
      <div className={` ${classes}`}>{extraService?.description}</div>
      <div className={` ${classes}`}>
        {extraService?.amount} {extraService?.currency}
      </div>
      <div className={` ${classes}`}>
        {moment(extraService?.createdAt).format('YYYY-MM-DD')}
      </div>
      <div className={`${classes}`}>
        {ExtraServiceStatus[extraService?.status]}
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: '120px',
          maxWidth: '140px',
        }}
        className={`${rowStyles.optionsContainer} ${classes} ${styles.gridTableRowLast}`}
      >
        <span
          style={{ margin: '0px 4px 0px 4px' }}
          onClick={() => {
            showEditModal({
              name: `Edit Extra Service`,
              users: [],
              date: moment(),
              handleRowTouched: handleRowTouched,
              serviceId: extraService?._id,
            });
          }}
        >
          <EditIcon
            style={{ cursor: 'pointer' }}
            className={rowStyles.editItem}
          />
        </span>
        <span
          style={{ margin: '0px 4px 0px 4px' }}
          onClick={(event) => {
            deleteExtraService();
          }}
        >
          <DeleteIcon style={{ cursor: 'pointer' }} />
        </span>
      </div>

      {EditServiceModal}
    </>
  );
};

export default ExtraServicesRow;
