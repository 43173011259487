import { Result, Button, Card, Space } from 'antd';
import { Link } from 'react-router-dom';

const MeetingAlreadyEnded = () => {
  return (
    <div>
      <Card style={{ margin: 15 }}>
        <Result
          title="This meeting has already ended."
          subTitle="If you believe this happened in error, please talk to your mentor. They can help you reschedule."
          extra={
            <Space>
              <Link to="/home">
                <Button type="primary" key="go_home">
                  Go Home
                </Button>
              </Link>
            </Space>
          }
        />
      </Card>
    </div>
  );
};

export default MeetingAlreadyEnded;
