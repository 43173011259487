import EditIcon from '@assets/icons/edit-icon.svg';
import ExternalLinkIconPrimary from '@assets/icons/external-link-primary.svg';
import ExternalLinkIcon from '@assets/icons/external-link.svg';
import ProfileIconPrimary from '@assets/icons/profile-icon-primary.png';
import ProfileIcon from '@assets/icons/profile-icon-white.png';
import EnrollmentsModalMentors from '@components/V3/EnrollmentsModalMentors';
import ProfileModalMentors from '@components/V3/ProfileModalMentors';
import { MENTOR_STATUS_V3 } from '@shared/constants';
import {
  useSetVatPayerStatus,
  useUpdateMentorCapacity,
  useUpdateMentorData,
  useUpdateUser,
} from '@shared/react';
import { MentorWithRate } from '@shared/types';
import { message, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './mentors-table-row.styles.scss';

type Props = {
  key?: string | number;
  mentorData: MentorWithRate;
  refetchSearchMentors: () => void;
  refetchMentorStatistics: () => void;
};

const MentorsTableRow = ({
  key,
  mentorData,
  refetchSearchMentors,
  refetchMentorStatistics,
}: Props) => {
  const { mutate: updateMentorCapacity } = useUpdateMentorCapacity(
    mentorData.mentor._id,
    {
      onSuccess: () => {
        refetchMentorStatistics();
      },
    }
  );

  const [showSecondRow, setShowSecondRow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [maxCap, setMaxCap] = useState(
    Number(mentorData.mentor.mentorData.mentorCapacity?.capacity)
  );

  const [editPhone, setEditPhone] = useState(false);
  const [phone, setPhone] = useState(mentorData.mentor.phoneNumber ?? '-');
  const [editEmail, setEditEmail] = useState(false);
  const [email, setEmail] = useState(mentorData.mentor.email ?? '-');
  const [editUniversities, setEditUniversities] = useState(false);
  const [universities, setUniversities] = useState<string[]>(
    mentorData?.mentor?.mentorData?.universities ?? []
  );
  const [targetUniversity, setTargetUniversity] = useState('');

  const [vatPayer, setVatPayer] = useState(
    mentorData?.mentor?.mentorData?.rate?.vatPayer ?? false
  );
  const [editVatPayer, setEditVatPayer] = useState(false);

  const [editBio, setEditBio] = useState(false);
  const [bio, setBio] = useState(
    mentorData.mentor.mentorData.shortBiography ?? '-'
  );
  const [showProfileModal, setShowProfileModal] = useState(false);

  const { mutate: updateUser } = useUpdateUser(mentorData.mentor._id, {
    onSuccess: () => {
      setPhone(phone);
    },
    onError: (error) => {
      setPhone(mentorData.mentor.phoneNumber);
      message.error('Could not save');
    },
  });

  const { mutate: updateMentorData } = useUpdateMentorData(
    mentorData.mentor._id,
    {
      onSuccess: () => {
        refetchSearchMentors();
      },
    }
  );

  const courses = mentorData.mentor.mentorData.coursesCanTeach ?? [];

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleShowProfileModal = () => {
    setShowProfileModal(!showProfileModal);
  };

  const addUniversity = () => {
    if (targetUniversity) {
      setUniversities([...universities, targetUniversity]);
      updateMentorData({
        universities: [...universities, targetUniversity],
      });
    }
    setTargetUniversity('');
  };

  const removeUniversity = (removedItem: string) => {
    setUniversities(
      universities.filter((university) => university !== removedItem)
    );
    updateMentorData({
      universities: universities.filter(
        (university) => university !== removedItem
      ),
    });
    setTargetUniversity('');
  };

  const { mutate: setVatPayerStatus } = useSetVatPayerStatus(
    mentorData.mentor._id,
    {
      onSuccess: () => {
        refetchSearchMentors();
      },
    }
  );

  return (
    <tr>
      {/*MENTOR NAME*/}
      <td className="mentors-table-row-text">
        {/*<div*/}
        {/*  onClick={() => setShowSecondRow(!showSecondRow)}*/}
        {/*  style={{*/}
        {/*    cursor: 'pointer',*/}
        {/*    marginRight: '8px',*/}
        {/*    backgroundColor: showSecondRow*/}
        {/*      ? COLORS.DARK_GREY3*/}
        {/*      : 'transparent',*/}
        {/*    display: 'flex',*/}
        {/*    justifyContent: 'center',*/}
        {/*    alignItems: 'center',*/}
        {/*    padding: '8px',*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {showSecondRow ? <MinusOutlined /> : <PlusOutlined />}*/}
        {/*</div>*/}
        <span>{mentorData.mentor.fullName ?? '-'}</span>
      </td>

      {/*STATUS*/}
      <td style={{}} className="mentors-table-row-text">
        <select
          onChange={(e) => {
            const selectedValue = e.target.value;
            updateMentorData({
              status: selectedValue,
            });
          }}
          className="mentors-row-status-component"
          style={{
            borderColor: COLORS.TEXT_PRIMARY,
            color: COLORS.TEXT_PRIMARY,
            backgroundColor: COLORS.SECTION_BACKGROUND,
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            appearance: 'none',
            paddingRight: '15px',
            paddingLeft: '0px',
            backgroundImage: `url(${EditIcon})`,
            backgroundSize: '8px 8px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right center',
            cursor: 'pointer',
          }}
          defaultValue={mentorData.mentor.mentorData.status ?? 'N/A'}
        >
          {MENTOR_STATUS_V3.map((status) => (
            <option value={status}>{status}</option>
          ))}
        </select>
      </td>

      {/*CAPACITY*/}
      <td className="mentors-table-row-text">
        <span>{mentorData?.currentCapacity ?? '-'}</span>
      </td>

      {/*MAX CAPACITY*/}
      <td style={{}} className="mentors-table-row-text">
        <span>
          <input
            className="block w-full rounded-md border-0 py-1.5 px-0 text-white shadow-sm ring-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:px-1.5 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
            style={{
              backgroundColor: 'transparent',
              width: '64px',
              height: '26px',
              marginBottom: '0px',
              fontSize: '12px',
              lineHeight: '16px',
            }}
            type="number"
            value={maxCap}
            min={0}
            onChange={(e) => setMaxCap(Number(e.target.value))}
            onBlur={() => {
              updateMentorCapacity({
                capacity: maxCap,
              });
            }}
          />
        </span>
      </td>

      {/*MAX CAPACITY UPDATED AT*/}
      <td style={{}} className="mentors-table-row-text">
        <span>
          <input
            className="block w-full rounded-md border-0 py-1.5 px-0 text-white shadow-sm ring-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:px-1.5 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
            style={{
              backgroundColor: 'transparent',
              width: '88px',
              height: '26px',
              marginBottom: '0px',
              fontSize: '12px',
              lineHeight: '16px',
            }}
            type="text"
            value={
              mentorData.mentor.mentorData.mentorCapacity?.updatedAt
                ? moment(
                    mentorData.mentor.mentorData.mentorCapacity?.updatedAt
                  ).format('DD.MM.YYYY')
                : '-'
            }
          />
        </span>
      </td>

      {/*PAY RATE*/}
      <td className="mentors-table-row-text">
        <span>
          {mentorData?.rate?.amount !== undefined &&
          mentorData?.rate?.currency !== undefined
            ? `${mentorData?.rate?.amount} ${mentorData?.rate?.currency}/h`
            : '-'}
        </span>
      </td>

      {/*UNIVERSITIES*/}
      <td style={{}} className="mentors-table-row-text">
        {/*TOOLTIP*/}
        {universities.length > 1 ? (
          <Tooltip
            zIndex={1}
            color="#333333"
            title={
              <div>
                {universities.length > 1 &&
                  universities?.map((university: string) => (
                    <>
                      <span>{university};</span>
                      <br />
                    </>
                  ))}
              </div>
            }
          >
            <span>
              {Array.isArray(universities) && universities.length > 1
                ? universities[0] + '...'
                : Array.isArray(universities) && universities.length > 0
                ? universities[0] ?? '-'
                : '-'}
            </span>
          </Tooltip>
        ) : (
          <span>
            {Array.isArray(universities) && universities.length > 1
              ? universities[0] + '...'
              : Array.isArray(universities) && universities.length > 0
              ? universities[0] ?? '-'
              : '-'}
          </span>
        )}
      </td>

      {/*COURSES*/}
      <td className="mentors-table-row-text">
        {/*TOOLTIP*/}
        {courses.length > 1 ? (
          <Tooltip
            zIndex={1}
            color="#333333"
            title={
              <div>
                {courses.length > 1 &&
                  courses?.map((course: any) => (
                    <>
                      <span>{course?.title};</span>
                      <br />
                    </>
                  ))}
              </div>
            }
          >
            <span>
              {Array.isArray(courses) && courses.length > 1
                ? (courses[0] as { title?: string })?.title + '...'
                : Array.isArray(courses) && courses.length > 0
                ? (courses[0] as { title?: string })?.title ?? '-'
                : '-'}
            </span>
          </Tooltip>
        ) : (
          <span>
            {Array.isArray(courses) && courses.length > 1
              ? (courses[0] as { title?: string })?.title + '...'
              : Array.isArray(courses) && courses.length > 0
              ? (courses[0] as { title?: string })?.title ?? '-'
              : '-'}
          </span>
        )}
      </td>

      {/*STUDY SUBJECTS*/}
      <td className="mentors-table-row-text">
        <span>
          {mentorData?.mentor?.studySubjects?.map((studySubject) => (
            <span>{studySubject}</span>
          ))}
        </span>
      </td>

      {/*CREATED AT*/}
      <td className="mentors-table-row-text">
        <span>
          {moment(mentorData?.mentor?.createdAt).format('DD.MM.YYYY')}
        </span>
      </td>

      {/*ACCESS*/}
      <td>
        <div style={{ display: 'flex' }}>
          <div
            onClick={() => setShowModal(!showModal)}
            style={{ display: 'flex', marginRight: '12px' }}
            className="enrollments-action"
          >
            <img
              style={{ marginRight: '8px' }}
              className="external-link-icon"
              src={ExternalLinkIcon}
              alt="enrollments"
            />
            <img
              style={{ marginRight: '8px' }}
              className="external-link-icon-primary"
              src={ExternalLinkIconPrimary}
              alt="enrollments"
            />
            <span className="mentors-table-access-button">Enrollments</span>
          </div>
          <div style={{ width: '25%', display: 'flex' }}>
            <div
              onClick={() => setShowProfileModal(!showProfileModal)}
              style={{
                display: 'flex',
                marginRight: '12px',
                cursor: 'pointer',
              }}
              className="enrollments-action"
            >
              <img
                style={{
                  marginRight: '8px',
                  minWidth: '14px',
                  maxWidth: '14px',
                }}
                className="external-link-icon"
                src={ProfileIcon}
                alt="enrollments"
              />
              <img
                style={{
                  marginRight: '8px',
                  minWidth: '14px',
                  maxWidth: '14px',
                }}
                className="external-link-icon-primary"
                src={ProfileIconPrimary}
                alt="enrollments"
              />
              <span className="mentors-table-access-button">Profile</span>
            </div>
          </div>

          {/*ENROLLMENTS MODAL*/}
          {mentorData && showModal && (
            <EnrollmentsModalMentors
              showModal={showModal}
              handleShowModal={handleShowModal}
              mentorData={mentorData}
            />
          )}

          {mentorData && showProfileModal && (
            <ProfileModalMentors
              showModal={showProfileModal}
              handleShowModal={handleShowProfileModal}
              mentorData={mentorData}
            />
          )}
        </div>
      </td>

      {/*TODO Remove second row code if no longer needed*/}
      {/*/!*SECOND ROW*!/*/}
      {/*{showSecondRow && (*/}
      {/*  <div style={{ backgroundColor: COLORS.DARK_GREY3 }}>*/}
      {/*    /!*SECOND ROW CONTENT*!/*/}
      {/*    <div*/}
      {/*      style={{*/}
      {/*        paddingTop: '24px',*/}
      {/*        paddingBottom: '24px',*/}
      {/*        display: 'flex',*/}
      {/*        flexDirection: 'row',*/}
      {/*        justifyContent: 'center',*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      /!*CONTACT INFO*!/*/}
      {/*      <div style={{ width: '80%' }}>*/}
      {/*        <div style={{ marginBottom: '16px' }}>*/}
      {/*          <span*/}
      {/*            className="contact-info-title"*/}
      {/*            style={{ color: COLORS.PURPLE }}*/}
      {/*          >*/}
      {/*            Contact Info*/}
      {/*          </span>*/}
      {/*        </div>*/}
      {/*        <div*/}
      {/*          style={{*/}
      {/*            display: 'flex',*/}
      {/*            flexDirection: 'row',*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <div*/}
      {/*            style={{*/}
      {/*              width: '65%',*/}
      {/*              display: 'flex',*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            <div style={{ display: 'flex', flexDirection: 'column' }}>*/}
      {/*              <span*/}
      {/*                className="second-row-text-label"*/}
      {/*                style={{*/}
      {/*                  color: COLORS.TEXT_SECONDARY,*/}
      {/*                  whiteSpace: 'nowrap',*/}
      {/*                  marginBottom: '12px',*/}
      {/*                }}*/}
      {/*              >*/}
      {/*                Created Date:*/}
      {/*              </span>*/}
      {/*              <span*/}
      {/*                style={{*/}
      {/*                  marginBottom: '12px',*/}
      {/*                  color: COLORS.TEXT_SECONDARY,*/}
      {/*                }}*/}
      {/*                className="second-row-text-label"*/}
      {/*              >*/}
      {/*                Phone:*/}
      {/*              </span>*/}
      {/*              <span*/}
      {/*                style={{*/}
      {/*                  marginBottom: '12px',*/}
      {/*                  color: COLORS.TEXT_SECONDARY,*/}
      {/*                }}*/}
      {/*                className="second-row-text-label"*/}
      {/*              >*/}
      {/*                Email:*/}
      {/*              </span>*/}
      {/*              <span*/}
      {/*                style={{*/}
      {/*                  marginBottom: '12px',*/}
      {/*                  color: COLORS.TEXT_SECONDARY,*/}
      {/*                  minHeight: '80px',*/}
      {/*                }}*/}
      {/*                className="second-row-text-label"*/}
      {/*              >*/}
      {/*                Universities:*/}
      {/*              </span>*/}
      {/*              <span*/}
      {/*                style={{*/}
      {/*                  marginBottom: '12px',*/}
      {/*                  color: COLORS.TEXT_SECONDARY,*/}
      {/*                  minHeight: '80px',*/}
      {/*                }}*/}
      {/*                className="second-row-text-label"*/}
      {/*              >*/}
      {/*                Vat Payer?:*/}
      {/*              </span>*/}
      {/*            </div>*/}
      {/*            <div*/}
      {/*              style={{*/}
      {/*                display: 'flex',*/}
      {/*                flexDirection: 'column',*/}
      {/*                marginLeft: '48px',*/}
      {/*              }}*/}
      {/*            >*/}
      {/*              <span*/}
      {/*                style={{*/}
      {/*                  color: COLORS.TEXT_PRIMARY,*/}
      {/*                  marginBottom: '12px',*/}
      {/*                }}*/}
      {/*                className="second-row-text-value"*/}
      {/*              >*/}
      {/*                {mentorData?.mentor?.createdAt*/}
      {/*                  ? moment(mentorData?.mentor?.createdAt).format(*/}
      {/*                      'DD.MM.YYYY'*/}
      {/*                    )*/}
      {/*                  : '-'}*/}
      {/*              </span>*/}
      {/*              <div*/}
      {/*                style={{*/}
      {/*                  display: 'flex',*/}
      {/*                  alignItems: 'center',*/}
      {/*                  marginBottom: '12px',*/}
      {/*                }}*/}
      {/*                className="second-row-text-value"*/}
      {/*              >*/}
      {/*                {editPhone ? (*/}
      {/*                  <span>*/}
      {/*                    <input*/}
      {/*                      className="block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"*/}
      {/*                      style={{*/}
      {/*                        backgroundColor: 'transparent',*/}
      {/*                        width: '112px',*/}
      {/*                        height: '16px',*/}
      {/*                        marginBottom: '0px',*/}
      {/*                        fontSize: '12px',*/}
      {/*                        lineHeight: '16px',*/}
      {/*                      }}*/}
      {/*                      value={phone}*/}
      {/*                      onChange={(e) => {*/}
      {/*                        setPhone(e.target.value);*/}
      {/*                      }}*/}
      {/*                    />*/}
      {/*                  </span>*/}
      {/*                ) : (*/}
      {/*                  <span*/}
      {/*                    style={{*/}
      {/*                      color: COLORS.TEXT_PRIMARY,*/}
      {/*                      marginRight: '12px',*/}
      {/*                    }}*/}
      {/*                  >*/}
      {/*                    {phone ?? '-'}*/}
      {/*                  </span>*/}
      {/*                )}*/}
      {/*                <span*/}
      {/*                  style={{*/}
      {/*                    display: 'flex',*/}
      {/*                    alignItems: 'center',*/}
      {/*                  }}*/}
      {/*                >*/}
      {/*                  {editPhone ? (*/}
      {/*                    <CheckOutlined*/}
      {/*                      style={{*/}
      {/*                        minWidth: '22px',*/}
      {/*                        maxWidth: '22px',*/}
      {/*                        cursor: 'pointer',*/}
      {/*                      }}*/}
      {/*                      onClick={() => {*/}
      {/*                        updateUser({ phoneNumber: phone });*/}
      {/*                        setEditPhone(false);*/}
      {/*                      }}*/}
      {/*                    />*/}
      {/*                  ) : (*/}
      {/*                    <img*/}
      {/*                      onClick={() => {*/}
      {/*                        setEditPhone(true);*/}
      {/*                      }}*/}
      {/*                      style={{*/}
      {/*                        minWidth: '12px',*/}
      {/*                        maxWidth: '12px',*/}
      {/*                        cursor: 'pointer',*/}
      {/*                      }}*/}
      {/*                      src={EditIcon}*/}
      {/*                      alt="edit"*/}
      {/*                    />*/}
      {/*                  )}*/}
      {/*                </span>*/}
      {/*              </div>*/}

      {/*              <div*/}
      {/*                style={{*/}
      {/*                  display: 'flex',*/}
      {/*                  alignItems: 'center',*/}
      {/*                  marginBottom: '14px',*/}
      {/*                }}*/}
      {/*                className="second-row-text-value"*/}
      {/*              >*/}
      {/*                {editEmail ? (*/}
      {/*                  <span>*/}
      {/*                    <input*/}
      {/*                      className="block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"*/}
      {/*                      style={{*/}
      {/*                        backgroundColor: 'transparent',*/}
      {/*                        width: '160px',*/}
      {/*                        height: '16px',*/}
      {/*                        marginBottom: '0px',*/}
      {/*                        fontSize: '12px',*/}
      {/*                        lineHeight: '16px',*/}
      {/*                      }}*/}
      {/*                      value={email}*/}
      {/*                      onChange={(e) => {*/}
      {/*                        setEmail(e.target.value);*/}
      {/*                      }}*/}
      {/*                    />*/}
      {/*                  </span>*/}
      {/*                ) : (*/}
      {/*                  <span*/}
      {/*                    style={{*/}
      {/*                      color: COLORS.TEXT_PRIMARY,*/}
      {/*                      marginRight: '12px',*/}
      {/*                    }}*/}
      {/*                  >*/}
      {/*                    {email ?? '-'}*/}
      {/*                  </span>*/}
      {/*                )}*/}
      {/*                <span*/}
      {/*                  style={{*/}
      {/*                    display: 'flex',*/}
      {/*                    alignItems: 'center',*/}
      {/*                  }}*/}
      {/*                >*/}
      {/*                  {editEmail ? (*/}
      {/*                    <CheckOutlined*/}
      {/*                      style={{*/}
      {/*                        minWidth: '22px',*/}
      {/*                        maxWidth: '22px',*/}
      {/*                        cursor: 'pointer',*/}
      {/*                      }}*/}
      {/*                      onClick={() => {*/}
      {/*                        updateUser({ email: email });*/}
      {/*                        setEditEmail(false);*/}
      {/*                      }}*/}
      {/*                    />*/}
      {/*                  ) : (*/}
      {/*                    <img*/}
      {/*                      onClick={() => {*/}
      {/*                        setEditEmail(true);*/}
      {/*                      }}*/}
      {/*                      style={{*/}
      {/*                        minWidth: '12px',*/}
      {/*                        maxWidth: '12px',*/}
      {/*                        cursor: 'pointer',*/}
      {/*                      }}*/}
      {/*                      src={EditIcon}*/}
      {/*                      alt="edit"*/}
      {/*                    />*/}
      {/*                  )}*/}
      {/*                </span>*/}
      {/*              </div>*/}
      {/*              <div*/}
      {/*                style={{*/}
      {/*                  display: 'flex',*/}
      {/*                  alignItems: 'flex-start',*/}
      {/*                  marginBottom: '12px',*/}
      {/*                  minHeight: '80px',*/}
      {/*                }}*/}
      {/*                className="second-row-text-value"*/}
      {/*              >*/}
      {/*                {editUniversities ? (*/}
      {/*                  <span>*/}
      {/*                    <div*/}
      {/*                      style={{*/}
      {/*                        display: 'flex',*/}
      {/*                        flexDirection: 'row',*/}
      {/*                        flexWrap: 'wrap',*/}
      {/*                      }}*/}
      {/*                    >*/}
      {/*                      {universities.map((university) => {*/}
      {/*                        return (*/}
      {/*                          <Tag*/}
      {/*                            style={{*/}
      {/*                              marginRight: '4px',*/}
      {/*                              marginBottom: '2px',*/}
      {/*                            }}*/}
      {/*                            className="tag-close-icon"*/}
      {/*                            key={university}*/}
      {/*                            color={COLORS.DARK_GREY_ALT}*/}
      {/*                            closable*/}
      {/*                            onClose={() => removeUniversity(university)}*/}
      {/*                            closeIcon={*/}
      {/*                              <CloseOutlined className="tag-close-icon" />*/}
      {/*                            }*/}
      {/*                          >*/}
      {/*                            {stringFormatters.capitalizeFirstLetter(*/}
      {/*                              university*/}
      {/*                            )}*/}
      {/*                          </Tag>*/}
      {/*                        );*/}
      {/*                      })}*/}
      {/*                    </div>*/}
      {/*                    <span*/}
      {/*                      style={{*/}
      {/*                        display: 'flex',*/}
      {/*                        flexDirection: 'row',*/}
      {/*                        alignItems: 'center',*/}
      {/*                      }}*/}
      {/*                    >*/}
      {/*                      <input*/}
      {/*                        className="block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"*/}
      {/*                        style={{*/}
      {/*                          backgroundColor: 'transparent',*/}
      {/*                          width: '134px',*/}
      {/*                          height: '16px',*/}
      {/*                          marginBottom: '4px',*/}
      {/*                          marginTop: '8px',*/}
      {/*                          fontSize: '12px',*/}
      {/*                          lineHeight: '16px',*/}
      {/*                        }}*/}
      {/*                        value={targetUniversity}*/}
      {/*                        onChange={(e) => {*/}
      {/*                          setTargetUniversity(e.target.value);*/}
      {/*                        }}*/}
      {/*                      />*/}
      {/*                      {editUniversities && (*/}
      {/*                        <CheckOutlined*/}
      {/*                          style={{*/}
      {/*                            minWidth: '22px',*/}
      {/*                            maxWidth: '22px',*/}
      {/*                          }}*/}
      {/*                          onClick={() => {*/}
      {/*                            addUniversity();*/}
      {/*                            setEditUniversities(false);*/}
      {/*                          }}*/}
      {/*                        />*/}
      {/*                      )}*/}
      {/*                    </span>*/}
      {/*                  </span>*/}
      {/*                ) : (*/}
      {/*                  <div*/}
      {/*                    style={{*/}
      {/*                      color: COLORS.TEXT_PRIMARY,*/}
      {/*                      marginRight: '12px',*/}
      {/*                      display: 'flex',*/}
      {/*                      flexDirection: 'row',*/}
      {/*                      alignItems: 'flex-start',*/}
      {/*                      flexWrap: 'wrap',*/}
      {/*                    }}*/}
      {/*                  >*/}
      {/*                    {universities?.length > 0*/}
      {/*                      ? mentorData?.mentor?.mentorData?.universities?.map(*/}
      {/*                          (university) => (*/}
      {/*                            <span*/}
      {/*                              style={{*/}
      {/*                                display: 'flex',*/}
      {/*                                marginRight: '4px',*/}
      {/*                                marginBottom: '0px',*/}
      {/*                              }}*/}
      {/*                            >*/}
      {/*                              {university};{' '}*/}
      {/*                            </span>*/}
      {/*                          )*/}
      {/*                        )*/}
      {/*                      : '-'}*/}
      {/*                  </div>*/}
      {/*                )}*/}

      {/*                <span*/}
      {/*                  style={{*/}
      {/*                    display: 'flex',*/}
      {/*                    alignItems: 'center',*/}
      {/*                  }}*/}
      {/*                >*/}
      {/*                  {!editUniversities && (*/}
      {/*                    <img*/}
      {/*                      onClick={() => {*/}
      {/*                        setEditUniversities(true);*/}
      {/*                      }}*/}
      {/*                      style={{*/}
      {/*                        minWidth: '12px',*/}
      {/*                        maxWidth: '12px',*/}
      {/*                        cursor: 'pointer',*/}
      {/*                      }}*/}
      {/*                      src={EditIcon}*/}
      {/*                      alt="edit"*/}
      {/*                    />*/}
      {/*                  )}*/}
      {/*                </span>*/}
      {/*              </div>*/}
      {/*              <div*/}
      {/*                style={{*/}
      {/*                  display: 'flex',*/}
      {/*                  alignItems: 'flex-start',*/}
      {/*                  marginBottom: '12px',*/}
      {/*                  minHeight: '80px',*/}
      {/*                }}*/}
      {/*              >*/}
      {/*                {editVatPayer ? (*/}
      {/*                  <span>*/}
      {/*                    <input*/}
      {/*                      style={{*/}
      {/*                        borderRadius: '4px',*/}
      {/*                        color: COLORS.WHITE,*/}
      {/*                        borderColor: 'white',*/}
      {/*                        backgroundColor: 'transparent',*/}
      {/*                      }}*/}
      {/*                      type="checkbox"*/}
      {/*                      checked={vatPayer}*/}
      {/*                      onChange={(e) => {*/}
      {/*                        setVatPayer(e.target.checked);*/}
      {/*                      }}*/}
      {/*                    />*/}
      {/*                  </span>*/}
      {/*                ) : (*/}
      {/*                  <span*/}
      {/*                    style={{*/}
      {/*                      color: COLORS.WHITE,*/}
      {/*                      marginRight: '12px',*/}
      {/*                    }}*/}
      {/*                  >*/}
      {/*                    {vatPayer ? 'Yes' : 'No'}*/}
      {/*                  </span>*/}
      {/*                )}*/}
      {/*                <span>*/}
      {/*                  {editVatPayer ? (*/}
      {/*                    <SecondaryButton*/}
      {/*                      height="18px"*/}
      {/*                      margin="4px 12px 12px 12px"*/}
      {/*                      padding="4px 10px 4px 10px"*/}
      {/*                      color={COLORS.WHITE}*/}
      {/*                      onClick={() => {*/}
      {/*                        setVatPayerStatus({ isVatPayer: vatPayer });*/}
      {/*                        setEditVatPayer(false);*/}
      {/*                      }}*/}
      {/*                    >*/}
      {/*                      <span*/}
      {/*                        className="button-text"*/}
      {/*                        style={{ color: COLORS.WHITE }}*/}
      {/*                      >*/}
      {/*                        Save*/}
      {/*                      </span>*/}
      {/*                    </SecondaryButton>*/}
      {/*                  ) : (*/}
      {/*                    <img*/}
      {/*                      onClick={() => {*/}
      {/*                        setEditVatPayer(true);*/}
      {/*                      }}*/}
      {/*                      style={{*/}
      {/*                        minWidth: '12px',*/}
      {/*                        maxWidth: '12px',*/}
      {/*                        cursor: 'pointer',*/}
      {/*                      }}*/}
      {/*                      src={EditIcon}*/}
      {/*                      alt="edit"*/}
      {/*                    />*/}
      {/*                  )}*/}
      {/*                </span>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div*/}
      {/*            style={{*/}
      {/*              width: '35%',*/}
      {/*              display: 'flex',*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            <div style={{ display: 'flex', flexDirection: 'column' }}>*/}
      {/*              <span*/}
      {/*                style={{ color: COLORS.TEXT_SECONDARY }}*/}
      {/*                className="second-row-text-label"*/}
      {/*              >*/}
      {/*                Biography:*/}
      {/*              </span>*/}
      {/*            </div>*/}
      {/*            <div*/}
      {/*              style={{*/}
      {/*                display: 'flex',*/}
      {/*                flexDirection: 'row',*/}
      {/*                marginLeft: '24px',*/}
      {/*              }}*/}
      {/*            >*/}
      {/*              {editBio ? (*/}
      {/*                <textarea*/}
      {/*                  rows={6}*/}
      {/*                  name="comment"*/}
      {/*                  id="comment"*/}
      {/*                  className="block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"*/}
      {/*                  // className="block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-white focus:ring-1 focus:ring-inset focus:customPrimary hover:ring-1 hover:ring-inset hover:customPrimary sm:text-sm sm:leading-6"*/}
      {/*                  value={bio}*/}
      {/*                  onChange={(e) => setBio(e.target.value)}*/}
      {/*                  style={{*/}
      {/*                    backgroundColor: COLORS.DARK_GREY_ALT,*/}
      {/*                    marginRight: '12px',*/}
      {/*                  }}*/}
      {/*                />*/}
      {/*              ) : (*/}
      {/*                <span*/}
      {/*                  style={{*/}
      {/*                    color: COLORS.TEXT_PRIMARY,*/}
      {/*                    marginRight: '12px',*/}
      {/*                  }}*/}
      {/*                  className="second-row-text-value"*/}
      {/*                >*/}
      {/*                  {bio}*/}
      {/*                </span>*/}
      {/*              )}*/}

      {/*              <span>*/}
      {/*                {editBio ? (*/}
      {/*                  <CheckOutlined*/}
      {/*                    style={{*/}
      {/*                      minWidth: '22px',*/}
      {/*                      maxWidth: '22px',*/}
      {/*                      cursor: 'pointer',*/}
      {/*                    }}*/}
      {/*                    onClick={() => {*/}
      {/*                      // updateUser({ email: email });*/}
      {/*                      updateMentorData({ shortBiography: bio });*/}
      {/*                      setEditBio(false);*/}
      {/*                    }}*/}
      {/*                  />*/}
      {/*                ) : (*/}
      {/*                  <img*/}
      {/*                    onClick={() => {*/}
      {/*                      setEditBio(true);*/}
      {/*                    }}*/}
      {/*                    style={{*/}
      {/*                      minWidth: '12px',*/}
      {/*                      maxWidth: '12px',*/}
      {/*                      cursor: 'pointer',*/}
      {/*                    }}*/}
      {/*                    src={EditIcon}*/}
      {/*                    alt="edit"*/}
      {/*                  />*/}
      {/*                )}*/}
      {/*              </span>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    /!*SECOND ROW DIVIDER*!/*/}
      {/*    <div style={{ borderTopWidth: '0.5px' }} className="relative">*/}
      {/*      <div*/}
      {/*        className="absolute inset-0 flex items-center"*/}
      {/*        aria-hidden="true"*/}
      {/*      >*/}
      {/*        <div className="w-full border-t border-gray-300" />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
    </tr>
  );
};

export default MentorsTableRow;
