import { ReactComponent as MenuIcon } from '@assets/icons/burger-menu.svg';
import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import { BASE_URL } from '@shared/frontendEnv';
import { useAuthState } from '@shared/react';
import styles from '@styles/components/header.module.scss';
import { Alert, message } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { COLORS } from '../../v3/global';
import AccountDropdown from './Components/AccountDropdown';
import LogoButton from './Components/LogoButton';
import MobileMenuDropdown from './Components/MobileMenuDropdown';
import ThemeButton from './Components/ThemeButton';
import { useMobileState } from './MobileContext';
import NotificationsBell from './NotificationsBell';

const Header = () => {
  const user = useAuthState();
  const harvesterLink = `${BASE_URL}/referral/${user?.harvesterToken}` ?? '';

  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const isMobile = useMobileState();
  const { pathname } = useLocation();

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(harvesterLink)
      .then(() => {
        message.success('Link copied to clipboard!');
      })
      .catch((err) => {
        message.error('Failed to copy the link.');
      });
  };

  useEffect(() => {
    setMobileMenuVisible(false);
  }, [pathname]);

  const isImpersonating = () => !!localStorage.getItem('impersonate-user-id');

  const stopImpersonating = () => {
    localStorage.removeItem('impersonate-user-id');
    localStorage.setItem('menuType', '2');

    message.loading('Stopping impersonating...', 0);

    setTimeout(() => {
      window.location.href = '/';
    }, 2000);
  };

  const mobileHeader = (
    <>
      <MobileMenuDropdown visible={mobileMenuVisible}>
        <MenuIcon
          style={{ cursor: 'pointer' }}
          onClick={() => setMobileMenuVisible((prev) => !prev)}
        />
      </MobileMenuDropdown>
      <LogoButton width="50px" height="25px" />
      <NotificationsBell />
      <div style={{ width: '20px', height: '20px' }}></div>
    </>
  );

  const desktopHeader = (
    <>
      <div className={styles.itemsGroup}>{/* <ThemeButton /> */}</div>

      <div className={styles.itemsGroup}>
        <SecondaryButton
          onClick={handleCopyClick}
          height="32px"
          borderRadius="4px"
        >
          <span style={{ fontSize: '12px' }}>Copy Referral</span>
        </SecondaryButton>
        <div>
          <AccountDropdown />
        </div>
      </div>
    </>
  );

  return (
    <>
      {isImpersonating() && (
        <Alert
          message="You are currently impersonating another user's account. Click to stop."
          type="info"
          onClick={stopImpersonating}
          showIcon
          style={{ cursor: 'pointer' }}
        />
      )}

      <div className={styles.header}>
        {isMobile ? mobileHeader : desktopHeader}
      </div>
    </>
  );
};

export default Header;
