import { useAuthState, useFeedbackMeetings } from '@shared/react';
import style from '@styles/views/home.module.scss';
import { Spin, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import MeetingNeedingFeedbackMentor from './MeetingNeedingFeedbackMentor';

type Props = {
  isMentor?: boolean;
};

const MeetingsNeedingFeedback = ({ isMentor }: Props) => {
  const { data, isLoading, isError } = useFeedbackMeetings();

  const { userId } = useAuthState();

  const impersonateId = localStorage.getItem('impersonate-user-id');

  const [viewAll, setViewAll] = useState(false);

  const meetings = data?.filter(
    (item) =>
      item.mentor?._id === (impersonateId ?? userId) &&
      !item.mentorFeedback?.message
  );

  const filteredMeetings = meetings?.filter(
    (meeting: any) =>
      moment().isAfter(meeting.startDate) && !meeting.mentorFeedback?.message
  );

  if (isLoading) {
    return <Spin />;
  }
  if (isError) {
    return (
      <Typography.Paragraph style={{ color: COLORS.TEXT_PRIMARY }}>
        An error has occurred...
      </Typography.Paragraph>
    );
  }

  return (
    <div className={style.meetings}>
      {!meetings?.some(
        (meeting) =>
          moment().isAfter(meeting.startDate) &&
          !meeting.mentorFeedback?.message
      ) ? (
        <Typography.Paragraph style={{ color: COLORS.TEXT_SECONDARY }}>
          No meetings that need feedback
        </Typography.Paragraph>
      ) : null}

      {!viewAll &&
        filteredMeetings?.map((meeting: any, index) =>
          index < 5 ? (
            <div key={meeting?._id}>
              <MeetingNeedingFeedbackMentor
                meeting={meeting}
                isMentor={isMentor}
              />
            </div>
          ) : null
        )}

      {viewAll &&
        filteredMeetings?.map((meeting: any, index) => (
          <div key={meeting._id}>
            <MeetingNeedingFeedbackMentor
              meeting={meeting}
              isMentor={isMentor}
            />
          </div>
        ))}

      {filteredMeetings && filteredMeetings?.length > 5 ? (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <span
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              font: 'normal normal medium 13px/16px Urbane Rounded',
              fontSize: '13px',
              color: COLORS.PRIMARY,
              alignSelf: 'flex-end',
            }}
            onClick={() => setViewAll(!viewAll)}
          >
            {viewAll ? 'View less' : 'View all'}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default MeetingsNeedingFeedback;
