import JoinIcon from '@assets/icons/Icon-material-video-call.svg';
import { NewMeeting } from '@shared/common';
import { useUpdateNewMeeting } from '@shared/react';
import button from '@styles/components/reusables/buttons.module.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { COLORS } from '../../../../../v3/global';
import MeetingListItemButton from './MeetingListItemButton';

interface JoinButtonProps {
  meeting: NewMeeting;
  afterSave?: () => void;
}

const JoinButtonStudent: React.FC<JoinButtonProps> = ({
  meeting,
  afterSave,
}) => {
  const { mutate: attendMeeting } = useUpdateNewMeeting(meeting._id);

  const endMeetingHandler = () => {
    attendMeeting({
      _id: meeting._id,
      attended: { mentor: meeting.attended.mentor, student: true },
    });
  };

  return (
    <div className={button.joinButtonStudentContainer}>
      <span
        style={{
          color: COLORS.TEXT_PRIMARY,
        }}
        className={`${button.actionButton} gradient ${button.joinButtonMentorJoinContainer}`}
      >
        {meeting.meetingLink !== 'http://' ? (
          <MeetingListItemButton>
            <div
              onClick={() => endMeetingHandler()}
              className={button.joinButtonStudentTextAndIcon}
            >
              <img
                src={JoinIcon}
                alt="Join meeting"
                className={button.joinButtonStudentIcon}
              />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={meeting.meetingLink}
                style={{ color: 'inherit' }}
              >
                Join meeting
              </a>
            </div>
          </MeetingListItemButton>
        ) : (
          <MeetingListItemButton>
            <div
              onClick={() => endMeetingHandler()}
              className={button.joinButtonStudentTextAndIcon}
            >
              <img
                src={JoinIcon}
                alt="Join meeting"
                className={button.joinButtonStudentIcon}
              />
              <Link to={meeting.meetingLink ?? ''} style={{ color: 'inherit' }}>
                Join meeting
              </Link>
            </div>
          </MeetingListItemButton>
        )}
      </span>
    </div>
  );
};

export default JoinButtonStudent;
