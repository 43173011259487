import { Modal, List, Comment, Rate } from 'antd';
import axios from 'axios';
import { useState, useEffect } from 'react';

import authHeader from '../services/auth-header';

const MeetingFeedbackResultsModal = ({ visible, hide, meeting }: any) => {
  const [feedback, setFeedback] = useState([]);
  const [mentorFeedback, setMentorFeedback] = useState(
    'Waiting for mentor to leave feedback.'
  );

  const getAllFeedback = async () => {
    try {
      const response = await axios.get(
        `/api/meeting/${meeting._id}/get-all-feedback`,
        {
          headers: authHeader(),
        }
      );
      setFeedback(response.data.feedback || []);
      if (response.data.mentorFeedback) {
        setMentorFeedback(response.data.mentorFeedback);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const meetingId = meeting._id?.toString();

  useEffect(() => {
    if (!meetingId) return;

    setFeedback([]);
    setMentorFeedback('Waiting for mentor to leave feedback.');
    getAllFeedback();
  }, [meetingId]);

  return (
    <Modal
      visible={visible}
      onCancel={hide}
      footer={null}
      title={`${meeting?.name} - Feedback`}
    >
      <List
        itemLayout="horizontal"
        dataSource={feedback}
        renderItem={(item: any) => (
          <li key={item._id}>
            <Comment
              author={item.student?.fullName}
              content={
                <div dangerouslySetInnerHTML={{ __html: item.message }} />
              }
              datetime={
                <Rate
                  disabled
                  defaultValue={item.rating}
                  style={{ fontSize: '10px' }}
                />
              }
            />
          </li>
        )}
      />
      <Comment
        author={'Mentor Feedback'}
        content={<div dangerouslySetInnerHTML={{ __html: mentorFeedback }} />}
      />
    </Modal>
  );
};

export default MeetingFeedbackResultsModal;
