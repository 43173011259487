import { useDebounce } from '@hooks';
import { MENTOR_ROLE } from '@shared/common';
import { SelectOption } from '@shared/common';
import { useSearchUsers } from '@shared/react';
import { Select, Spin } from 'antd';
import { CSSProperties, useEffect, useState } from 'react';

type Props = {
  value?: SelectOption;
  onChange?: (value: SelectOption) => void;
  style?: CSSProperties;
};

const MentorSelectorMeeting = ({ value, onChange, style }: Props) => {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    if (value) setInternalValue(value);
  }, [value]);

  const setValues = (val: SelectOption) => {
    if (onChange) onChange(val);
    if (!value) setInternalValue(val);
  };

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const { data: mentorsResponse, isLoading: mentorsLoading } = useSearchUsers({
    roles: [MENTOR_ROLE],
    search: debouncedSearch,
    limit: 20,
  });

  const options = (mentorsResponse?.users || []).map((s) => ({
    label: s.fullName,
    value: s._id,
  }));

  return (
    <Select
      style={style}
      placeholder="Select a Mentor"
      labelInValue
      showSearch
      optionFilterProp="label"
      options={options}
      onSearch={setSearch}
      notFoundContent={mentorsLoading ? <Spin size="small" /> : null}
      value={internalValue}
      onChange={setValues}
    />
  );
};

export default MentorSelectorMeeting;
