import { SpeedTestPage } from '@components';
import { useQueryParams } from '@hooks';
import { meetingsApis } from '@shared/apis';
import { useMeeting } from '@shared/react';
import { Alert, Card, CardProps } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

import MeetingTooEarly from './MeetingTooEarly';

const SmallCard = (props: CardProps) => (
  <Card
    {...props}
    style={{
      textAlign: 'center',
      margin: '40px auto 0',
      maxWidth: '600px',
      width: '100%',
    }}
  />
);

const Meeting = () => {
  const queryClient = useQueryClient();
  const params = useQueryParams();
  const id = params.get('id');

  const { data, isLoading } = useMeeting(id || '');

  const meetingDate = moment(data?.startDate);
  const now = moment();

  const diffMinutes = meetingDate.diff(now, 'minutes');
  const tooEarly = diffMinutes > 15;

  useEffect(() => {
    const interval = setInterval(() => {
      if (data && !tooEarly) return;

      queryClient.invalidateQueries(meetingsApis.getMeeting.endpoint);
    }, 1000 * 60 /* every minute */);

    return () => clearInterval(interval);
  }, []);

  if (isLoading) return <SmallCard loading={true} />;
  if (!data)
    return (
      <SmallCard>
        <Alert message="Meeting not found" type="error" />
      </SmallCard>
    );

  if (tooEarly)
    return (
      <SmallCard>
        <MeetingTooEarly startingIn={meetingDate.fromNow()} />
      </SmallCard>
    );

  return (
    <SmallCard>
      <SpeedTestPage />
    </SmallCard>
  );
};

export default Meeting;
