import arrow from '@assets/icons/arrow-right.svg';
import NoDataMeetings from '@assets/icons/no-data-meetings.png';
import MeetingNeedingFeedbackMentor from '@components/V3/MeetingsNeedingFeedbackMentor/MeetingNeedingFeedbackMentor';
import NextMeetingMentor from '@components/V3/NextMeetingsMentor/NextMeetingMentor';
import Modal from '@components/V4/Modal';
import { NewMeeting } from '@shared/types';
import moment, { Moment } from 'moment';
import NewMeetingButton from '../../Common/Buttons/NewMeetingButton';

type Props = {
  showModal: boolean;
  handleShowModal: (show: boolean) => void;
  date: Moment;
  isOnHomePage?: boolean;
  dayMeetingsModal: NewMeeting[] | undefined;
  day: moment.Moment;
  handleSelectedDay: (day: Moment) => void;
  goToPreviousDay: () => void;
  goToNextDay: () => void;
};

const CalendarMeetingsModal = ({
  showModal,
  handleShowModal,
  date,
  dayMeetingsModal,
  day,
  handleSelectedDay,
  goToPreviousDay,
  goToNextDay,
}: Props) => {
  const isBeforeToday = date < moment();

  const closeModalHandler = () => {
    handleShowModal(false);
    handleSelectedDay(day);
  };

  return (
    <Modal isOpen={showModal} onClose={closeModalHandler} hasFullMaxHeight>
      <Modal.Header title="Day View" className="mb-6 flex-col">
        <div className="flex justify-between items-center">
          {/*NAV*/}
          <div className="flex items-center gap-6">
            <nav className="flex">
              <img
                src={arrow}
                alt="Go to the previous day."
                onClick={() => goToPreviousDay()}
                className="cursor-pointer fill-customGrey rotate-180"
              />
              <img
                src={arrow}
                alt="Go to the next day."
                onClick={() => goToNextDay()}
                className="cursor-pointer fill-customGrey"
              />
            </nav>
            <h3 className="text-base font-raleway font-bold">
              {moment(date).format('MMM Do YYYY')}
            </h3>
          </div>
          <div>
            <NewMeetingButton
              date={moment(date)}
              disabled={isBeforeToday}
              isOnHomePage={true}
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="flex flex-col overflow-x-hidden overflow-y-auto h-110">
        <div className="flex flex-col justify-center">
          {/*CONTENT*/}
          {dayMeetingsModal?.length === 0 ? (
            // NO MEETINGS SCHEDULED
            <div className="flex flex-col items-center justify-center h-full">
              <img
                src={NoDataMeetings}
                alt="no-data"
                className="w-16 h-auto mb-6"
              />
              <p className="font-raleway font-bold text-xl text-customGrey text-center">
                Looks like you have no meetings scheduled.
              </p>
            </div>
          ) : (
            // MEETINGS
            <div className="flex flex-col gap-2">
              {dayMeetingsModal?.map((meeting) => {
                const startDate = moment(meeting?.startDate);
                const currentDate = moment();

                if (startDate.isBefore(currentDate)) {
                  return (
                    <MeetingNeedingFeedbackMentor
                      key={meeting._id}
                      meeting={meeting}
                      isMentor={true}
                    />
                  );
                } else {
                  return (
                    <NextMeetingMentor
                      key={meeting._id}
                      meeting={meeting}
                      isMentor={true}
                    />
                  );
                }
              })}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CalendarMeetingsModal;
