import {
  ApiDescription,
  ApiData,
  RequestsMethod,
  Student,
  MentorFeedbackItem,
  NewEnrollment,
} from '../types';

export const getStudentData: ApiDescription = {
  method: RequestsMethod.Get,
  endpoint: '/parent/student-data',
};

export type GetStudentData = ApiData<
  { userId: string },
  {
    student: Student;
    enrollments: NewEnrollment[];
    mentorFeedback: MentorFeedbackItem[];
  }
>;

export const reportParentIssue: ApiDescription = {
  endpoint: '/parent/parent-issue',
  method: RequestsMethod.Post,
};
export type ReportParentIssue = ApiData<any, unknown>;
