import { ApiDescription, ApiData, RequestsMethod } from '@shared/types';

export const getZoomAccessToken: ApiDescription = {
  endpoint: '/zoom/access-token',
  method: RequestsMethod.Get,
};

export type GetZoomAccessToken = ApiData<
  {
    session_key: string;
    session_name: string;
    name: string;
    type: string;
  },
  { token: string }
>;
