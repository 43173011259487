import { DeleteFilled } from '@ant-design/icons';
import SecondaryButton from '@utils/SecondaryButton';
import React, { useState, useEffect } from 'react';
import { COLORS } from '../../../v3/global';
import './profile-modal-students.styles.scss';

type Props = {
  handleCurrentPage: (page: string) => void;
  studentData: any;
  refetchStudentData: () => void;
};

const LanguageExamTable = ({
  handleCurrentPage,
  studentData,
  refetchStudentData,
}: Props) => {
  const [exams, setExams] = useState([]);

  useEffect(() => {
    setExams(studentData?.standardisedTests || []);
  }, [studentData]);

  return (
    <div>
      <div style={{ width: '100%', marginTop: '8px' }}>
        {/*COLUMNS*/}
        <div
          style={{
            display: 'flex',
            marginBottom: '12px',
            width: '100%',
          }}
        >
          <span
            style={{
              color: COLORS.TEXT_SECONDARY,
              width: '50%',
              marginRight: '8px',
            }}
            className="modal-column-text"
          >
            Exam Name
          </span>
          <span
            style={{
              color: COLORS.TEXT_SECONDARY,
              width: '30%',
              marginRight: '8px',
            }}
            className="modal-column-text"
          >
            Grade
          </span>
          <span
            style={{ color: COLORS.TEXT_SECONDARY, width: '20%' }}
            className="modal-column-text"
          >
            Actions
          </span>
        </div>
      </div>

      {/*TABLE HEADER DIVIDER*/}
      <div style={{ borderTopWidth: '0.5px' }} className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
      </div>

      {/*TABLE DATA*/}
      {studentData?.standardisedTests.length === 0 ? (
        <div style={{ marginTop: '14px' }}>
          <p
            style={{ color: COLORS.TEXT_SECONDARY, textAlign: 'center' }}
            className="no-results-text"
          >
            No results for Exams
          </p>
        </div>
      ) : (
        studentData?.standardisedTests?.map((test: any) => {
          return (
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '12px',
                  marginBottom: '12px',
                }}
              >
                {/*EXAM NAME*/}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: COLORS.TEXT_SECONDARY,
                    width: '50%',
                    marginRight: '8px',
                  }}
                >
                  <span
                    style={{ color: COLORS.TEXT_PRIMARY }}
                    className="modal-row-column-text"
                  >
                    {test ?? '-'}
                  </span>
                </div>
                {/*GRADE*/}
                <div
                  style={{
                    display: 'flex',
                    color: COLORS.TEXT_SECONDARY,
                    width: '30%',
                    marginRight: '8px',
                  }}
                >
                  <span
                    style={{ color: COLORS.TEXT_PRIMARY }}
                    className="modal-row-column-text"
                  >
                    {'-'}
                  </span>
                </div>

                {/*TODO Implement exams logic when API is ready*/}
                {/*ACTIONS*/}
                <div
                  style={{
                    display: 'flex',
                    color: COLORS.TEXT_SECONDARY,
                    width: '20%',
                  }}
                >
                  <span
                    style={{
                      color: COLORS.TEXT_PRIMARY,
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'not-allowed',
                    }}
                    className="modal-row-column-text"
                  >
                    <DeleteFilled
                      style={{ color: COLORS.ERROR, fontSize: '16px' }}
                    />
                  </span>
                </div>
              </div>
              <div style={{ borderTopWidth: '0.5px' }} className="relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
              </div>
            </div>
          );
        })
      )}

      {/*ADD BUTTON*/}
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '24px',
        }}
      >
        <SecondaryButton
          disabled={true}
          onClick={() => {
            // handleCurrentPage('add-language-exam');
          }}
          backgroundColor={COLORS.WHITE}
          height="27px"
          padding="6px 24px 6px 24px"
          margin="0px"
        >
          <span className="button-text" style={{ color: COLORS.BLACK }}>
            Add Exam
          </span>
        </SecondaryButton>
      </div>
    </div>
  );
};

export default LanguageExamTable;
