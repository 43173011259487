import CourseCard from '@components/V3/CourseCard';
import PreviewStudentProgressCard from '@components/V3/CoursePictureEditor/preview-student-progress-card';
import { Modal, Button } from 'antd';
import React, { useState, useCallback, useEffect } from 'react';
import { COLORS } from '../../../v3/global';
import './image-upload-crop.styles.scss';

interface ImageUploadCropProps {
  aspect: number;
  refetchData: () => void;
  onCropComplete: (croppedImageUrl: string, croppedBlob: Blob) => void;
  handleImageSrc: (image: any) => void;
  title: string;
}

const ImageUploadCrop = ({
  aspect,
  refetchData,
  onCropComplete,
  handleImageSrc,
  title,
}: ImageUploadCropProps) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 160, y: 160 });
  const [zoom, setZoom] = useState(1);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  // TODO Remove canvas logic
  const getImgBlob = async (imageSrc: string) => {
    const image = new Image();
    image.src = imageSrc;
    await new Promise((resolve) => {
      image.onload = resolve;
    });

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('2D context is not available');
    }

    canvas.width = image.width;
    canvas.height = image.height;
    ctx.drawImage(image, 0, 0);

    return new Promise<{ url: string; blob: Blob }>((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve({ url: URL.createObjectURL(blob), blob });
        } else {
          reject(new Error('Failed to create blob'));
        }
      }, 'image/jpeg');
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageSrc(null);
    setImageDimensions({
      width: 0,
      height: 0,
    });

    const file = event.target.files?.[0];
    if (file) {
      setImageSrc(null);
      const url = window.URL.createObjectURL(file);
      // handleImageSrc(url);
      setImageSrc(url);
      setPreviewVisible(true);
    }
  };

  const handleCropComplete = useCallback(async () => {
    try {
      if (imageSrc) {
        const { url, blob } = await getImgBlob(imageSrc);
        onCropComplete(url, blob);

        setPreviewVisible(false);
      }
    } catch (e) {
      console.error(e);
    }
    setImageSrc(null);
  }, [imageSrc, crop, zoom, aspect, onCropComplete]);

  useEffect(() => {
    setImageSrc(null);
    setImageDimensions({
      width: 0,
      height: 0,
    });
  }, []);

  useEffect(() => {
    if (imageSrc) {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        setImageDimensions({ width: img.width, height: img.height });
      };
    }
  }, [imageSrc]);

  return (
    <div>
      <input
        type="file"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="image-upload-input"
      />
      <label htmlFor="image-upload-input">
        <Button
          onClick={() => {
            setImageSrc(null);
            document.getElementById('image-upload-input')?.click();
          }}
        >
          Change Course Image
        </Button>
      </label>

      <Modal
        visible={previewVisible}
        onCancel={() => setPreviewVisible(false)}
        onOk={handleCropComplete}
        footer={
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Button key="back" onClick={() => setPreviewVisible(false)}>
              Cancel
            </Button>
            <div style={{ display: 'flex' }}>
              <Button
                key="change"
                onClick={() => {
                  setImageSrc(null);
                  document.getElementById('image-upload-input')?.click();
                }}
              >
                Change Image
              </Button>
              <Button
                disabled={
                  imageDimensions.width < 470 || imageDimensions.height < 300
                }
                key="submit"
                type="primary"
                onClick={handleCropComplete}
              >
                Upload
              </Button>
            </div>
          </div>
        }
      >
        <div style={{ marginTop: 32, marginBottom: 2 }}>
          {imageDimensions.width < 470 || imageDimensions.height < 300 ? (
            <p style={{ color: COLORS.ERROR }}>
              Image should be at least 470px width and 300px height
            </p>
          ) : (
            <div>
              <p style={{ marginBottom: 8 }}>Course Cards Preview:</p>
              <div style={{ marginBottom: 8 }}>
                <CourseCard title={title} image={imageSrc ?? null} />
              </div>
              <div style={{ marginBottom: 8 }}>
                <PreviewStudentProgressCard
                  courseTitle={title}
                  image={imageSrc}
                />
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ImageUploadCrop;
