import StudentProgressCard from '@components/V3/StudentProgressCard';
import SectionTitle from '@components/V3/Utils/SectionTitle';
import { COURSES } from '@routes';
import { useLoginAndRedirectToCourse } from '@shared/react';
import { NewEnrollmentCourse } from '@shared/types';
import { Empty } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { COLORS } from '../../../v3/global';
import './your-courses.styles.scss';

type Props = {
  enrollments: NewEnrollmentCourse[];
};

const YourCoursesStudent = ({ enrollments }: Props) => {
  const history = useHistory();

  const { data: URL, mutate: redirectToLMS } = useLoginAndRedirectToCourse({
    onSuccess: (data) => {
      if (data?.url) {
        setTimeout(() => {
          window.open(data.url, '_blank');
        });
      }
    },
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [showAll, setShowAll] = useState(false);
  const itemsPerPage = 7;
  const slicedCourses = showAll
    ? enrollments
    : enrollments?.slice(0, itemsPerPage);

  const [showAllHovered, setShowAllHovered] = useState(false);

  const displayedCardIndex = 0;

  const handleMouseEnterShowAll = () => {
    setShowAllHovered(true);
  };

  const handleMouseLeaveLinkShowAll = () => {
    setShowAllHovered(false);
  };

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  return (
    <div>
      <div className="your-courses-header-container">
        <div className="your-courses-section-title">
          <SectionTitle title="Ongoing Courses" />
        </div>

        {enrollments &&
          enrollments?.length > itemsPerPage &&
          windowWidth > 1017 && (
            <div
              style={{
                cursor: 'pointer',
                fontSize: '13px',
                fontWeight: 'bold',
              }}
              onClick={() => setShowAll(!showAll)}
              onMouseEnter={handleMouseEnterShowAll}
              onMouseLeave={handleMouseLeaveLinkShowAll}
            >
              {showAll ? (
                <span
                  style={{
                    textDecoration: showAllHovered ? 'underline' : 'none',
                    whiteSpace: 'nowrap',
                    color: showAllHovered
                      ? COLORS.PRIMARY
                      : COLORS.TEXT_PRIMARY,
                  }}
                >
                  See Less
                </span>
              ) : (
                <span
                  style={{
                    textDecoration: showAllHovered ? 'underline' : 'none',
                    whiteSpace: 'nowrap',
                    color: showAllHovered
                      ? COLORS.PRIMARY
                      : COLORS.TEXT_PRIMARY,
                  }}
                >
                  See All
                </span>
              )}
            </div>
          )}
      </div>

      <div className="your-courses-content">
        {enrollments?.length && windowWidth > 1017 ? (
          slicedCourses?.map((course, index) =>
            !(course.ended && course.ended === true) ? (
              <div
                onClick={() => {
                  if (course?.course?.lmsCourseId) {
                    redirectToLMS({
                      courseId: course.course.lmsCourseId,
                    });
                  } else {
                    history.push(`${COURSES}/${course.course._id}`);
                  }
                }}
                style={{
                  cursor: 'pointer',
                  marginBottom: '32px',
                  marginRight:
                    windowWidth > 1700 && displayedCardIndex % 3 === 2
                      ? '0px'
                      : '32px',
                }}
                className={'your-courses-cards-container'}
              >
                <StudentProgressCard enrollment={course} key={course._id} />
              </div>
            ) : null
          )
        ) : enrollments?.length && windowWidth <= 1017 ? (
          enrollments?.map((course, index) => (
            <div
              onClick={() => {
                if (course?.course?.lmsCourseId) {
                  redirectToLMS({
                    courseId: course.course.lmsCourseId,
                  });
                } else {
                  history.push(`${COURSES}/${course.course._id}`);
                }
              }}
              style={{
                cursor: 'pointer',
                marginBottom: '32px',
                width: 'fit-content',
              }}
            >
              <StudentProgressCard enrollment={course} key={course._id} />
            </div>
          ))
        ) : (
          <div style={{ gridColumn: '1 / 8' }}>
            <Empty />
          </div>
        )}
      </div>
    </div>
  );
};

export default YourCoursesStudent;
