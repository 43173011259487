import { meetingsApis } from '../../apis';
import {
  genericMutation,
  genericMutationWithId,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';

export const useMySessionHistoryMeetings =
  genericQueryWithParams<meetingsApis.SearchMySessionHistoryMeetings>(
    meetingsApis.searchMySessionHistoryMeetings
  );

export const useMyMeetings =
  genericQueryWithParams<meetingsApis.SearchMyMeetings>(
    meetingsApis.searchMyMeetings
  );

export const useAllMyMeetings = () => {
  return useMyMeetings(
    { limit: 1000, extraInfo: true },
    { refetchInterval: 5000 }
  );
};

export const useFeedbackMeetings = () => {
  return useMyMeetings(
    {
      limit: 1000,
      startDate: true,
      sort: 'asc',
      excludeNoShow: true,
      extraInfo: true,
    },
    { refetchInterval: 5000 }
  );
};

export const useSetMeetingForReschedule =
  genericMutationWithId<meetingsApis.SetMeetingForReschedule>(
    meetingsApis.setMeetingForReschedule
  );

export const useUpcomingMeetings = () => {
  return useMyMeetings(
    { active: true, limit: 1000, extraInfo: true },
    { refetchInterval: 5000 }
  );
};

export const useNextMeeting = () => {
  const query = useMyMeetings({ active: true, limit: 1 });
  const data = query.data ? query.data[0] : undefined;

  return { ...query, data };
};

export const useMyUnpaidMeetings = () => {
  return useMyMeetings({
    limit: 100,
    paid: false,
    ended: true,
  });
};

export const useMyPaidMeetings = () => {
  return useMyMeetings({
    limit: 100,
    paid: true,
  });
};

export const useStudentMeetings =
  genericQueryWithId<meetingsApis.GetStudentMeetings>(
    meetingsApis.getStudentMeetings
  );

export const useStudentMeetingList = ({ studentId }: { studentId: string }) => {
  return useStudentMeetings(studentId);
};

export const useMeeting = genericQueryWithId<meetingsApis.GetMeeting>(
  meetingsApis.getMeeting
);

export const useSearchMeeting =
  genericQueryWithParams<meetingsApis.SearchMeetings>(
    meetingsApis.searchMeetings
  );

export const useSearchAllMeetings =
  genericQueryWithParams<meetingsApis.SearchAllMeetings>(
    meetingsApis.searchAllMeetings
  );

export const usePaidMeetings = (mentor: string) => {
  return useSearchMeeting(
    {
      limit: 100,
      mentor,
      paid: true,
    },
    { enabled: !!mentor }
  );
};

export const useUnpaidMeetings = (mentor: string) => {
  return useSearchMeeting(
    {
      limit: 100,
      mentor,
      paid: false,
      ended: true,
    },
    { enabled: !!mentor }
  );
};

export const useEditMeeting = genericMutationWithId<meetingsApis.EditMeeting>(
  meetingsApis.editMeeting,
  meetingsApis.searchMyMeetings.endpoint
);

export const useDeleteMeeting =
  genericMutationWithId<meetingsApis.DeleteNewMeeting>(
    meetingsApis.deleteNewMeeting
  );

export const useRegisterMentorFeedback =
  genericMutationWithId<meetingsApis.RegisterMentorFeedback>(
    meetingsApis.registerMentorFeedback
  );

export const useMarkAttended = genericMutationWithId<meetingsApis.MarkAttended>(
  meetingsApis.markAttended
);

export const useMarkMeetingAsCompleted =
  genericMutation<meetingsApis.MarkMeetingAsCompleted>(
    meetingsApis.markMeetingAsCompleted
  );

export const useGetHomeworkByMeeting =
  genericQueryWithId<meetingsApis.GetHomeworkByMeeting>(
    meetingsApis.getHomeworkByMeeting
  );

export const useUpdateNewMeeting =
  genericMutationWithId<meetingsApis.UpdateNewMeeting>(
    meetingsApis.updateNewMeeting,
    meetingsApis.getMeeting.endpoint
  );

export const useCreateNewMeeting =
  genericMutation<meetingsApis.CreateNewMeeting>(meetingsApis.createNewMeeting);

export const useCompletedMeetings =
  genericQueryWithId<meetingsApis.GetNoOfCompletedMeetings>(
    meetingsApis.getNoOfCompletedMeetings
  );

export const useMentorStatistics =
  genericQueryWithId<meetingsApis.GetMentorStatisticsByFeedback>(
    meetingsApis.getMentorStatisticsByFeedback
  );

export const useMentorSessionNo =
  genericQueryWithId<meetingsApis.CountCompletedMeetingForMentor>(
    meetingsApis.countCompletedMeetingForMentor
  );

// use in feature?

export const useMarkMeetingAsEnded =
  genericMutationWithId<meetingsApis.MarkMeetingAsEnded>(
    meetingsApis.markMeetingAsEnded
  );
