export const ClearIcon = () => (
  <svg
    enableBackground="new 0 0 512 512"
    fill="currentColor"
    height="30"
    viewBox="0 0 512 512"
    width="30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m495.718 1.582c-7.456-3.691-16.421-.703-20.142 6.694l-136.92 274.08-26.818-13.433c-22.207-11.118-49.277-2.065-60.396 20.083l-6.713 13.405 160.957 80.616 6.713-13.411c11.087-22.143 2.227-49.18-20.083-60.381l-26.823-13.435 136.919-274.077c3.706-7.412.703-16.421-6.694-20.141z" />
      <circle cx="173" cy="497" r="15" />
      <circle cx="23" cy="407" r="15" />
      <circle cx="83" cy="437" r="15" />
      <path d="m113 482h-60c-8.276 0-15-6.724-15-15 0-8.291-6.709-15-15-15s-15 6.709-15 15c0 24.814 20.186 45 45 45h60c8.291 0 15-6.709 15-15s-6.709-15-15-15z" />
      <path d="m108.635 388.074c-6.563.82-11.807 5.845-12.92 12.349-1.113 6.519 2.153 12.993 8.057 15.952l71.675 35.889c12.935 6.475 27.231 9.053 41.177 7.573-1.641 6.65 1.479 13.784 7.852 16.992l67.061 33.589c5.636 2.78 12.169 1.8 16.685-2.197 2.347-2.091 53.436-48.056 83.3-98.718l-161.605-80.94c-36.208 48.109-120.363 59.39-121.282 59.511z" />
    </g>
  </svg>
);

export const PenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26.009"
    viewBox="0 0 26 26.009"
  >
    <g transform="translate(0 0)">
      <path
        d="M.486,359.7a.761.761,0,0,0,.731.97.71.71,0,0,0,.208-.031l7.1-2.027L2.513,352.6Zm0,0"
        transform="translate(-0.434 -334.69)"
      />
      <path
        d="M.784,359.842a.782.782,0,0,1-.752-1L2.074,351.7l6.076,6.076L1,359.811a.768.768,0,0,1-.213.031Zm1.311-8.052L.078,358.851a.733.733,0,0,0,.706.935.706.706,0,0,0,.2-.03l7.061-2.017Zm0,0"
        transform="translate(0 -333.834)"
      />
      <path
        d="M347.4,4.4l-3.226-3.226a2.293,2.293,0,0,0-3.236,0l-1.615,1.615,6.457,6.457L347.4,7.622a2.294,2.294,0,0,0,0-3.226Zm0,0"
        transform="translate(-322.09 -0.475)"
      />
      <path
        d="M345.222,8.8,338.73,2.306l1.63-1.631a2.306,2.306,0,0,1,3.261,0L346.848,3.9a2.306,2.306,0,0,1,0,3.261ZM338.8,2.306l6.421,6.421,1.595-1.6a2.256,2.256,0,0,0,0-3.19L343.591.711a2.263,2.263,0,0,0-3.2,0Zm0,0"
        transform="translate(-321.523)"
      />
      <path
        d="M57.8,79.819,71.034,66.59l6.458,6.458L64.263,86.278Zm0,0"
        transform="translate(-54.868 -63.208)"
      />
      <path
        d="M63.529,85.659l-6.5-6.5L70.3,65.9l6.492,6.492Zm-6.421-6.5,6.421,6.421L76.721,72.39,70.3,65.969Zm0,0"
        transform="translate(-54.135 -62.551)"
      />
    </g>
  </svg>
);

export const EraserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.416"
    height="24.469"
    viewBox="0 0 26.416 24.469"
  >
    <g transform="translate(0 -17.881)">
      <g transform="translate(0 17.881)">
        <path
          d="M25.576,40.579H13.294L25.021,28.23a2.605,2.605,0,0,0,0-3.546l-5.764-6.07a2.3,2.3,0,0,0-3.367,0L1.048,34.243a2.6,2.6,0,0,0,0,3.546l2.65,2.79H.841a.886.886,0,0,0,0,1.77H25.576a.886.886,0,0,0,0-1.77Zm-8.5-20.713a.676.676,0,0,1,.99,0l5.764,6.07a.765.765,0,0,1,0,1.042L14.17,37.154,7.416,30.041ZM10.917,40.579H6.075L2.237,36.537a.766.766,0,0,1,0-1.042l3.99-4.2,6.754,7.112Z"
          transform="translate(0 -17.881)"
        />
      </g>
    </g>
  </svg>
);

export const UndoIcon = () => (
  <svg
    width="24"
    height="21"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.22 18C17.9916 18.0066 17.7678 17.9348 17.5858 17.7965C17.4039 17.6583 17.2748 17.4618 17.22 17.24C16.7698 15.4559 15.7783 13.8551 14.3816 12.6572C12.985 11.4593 11.2518 10.7232 9.42005 10.55V11.67C9.41988 12.0202 9.31638 12.3627 9.12249 12.6544C8.92859 12.9461 8.65294 13.1742 8.33005 13.31C7.9799 13.4709 7.59071 13.5273 7.20929 13.4723C6.82787 13.4173 6.47046 13.2533 6.18005 13L1.12005 8.58995C0.926013 8.42473 0.770177 8.21933 0.663311 7.98797C0.556445 7.75661 0.501099 7.5048 0.501099 7.24995C0.501099 6.9951 0.556445 6.74329 0.663311 6.51193C0.770177 6.28057 0.926013 6.07518 1.12005 5.90995L6.18005 1.48995C6.47428 1.23226 6.83761 1.0665 7.22508 1.01318C7.61255 0.95986 8.00715 1.02132 8.36005 1.18995C8.68294 1.32574 8.95859 1.55377 9.15249 1.8455C9.34638 2.13722 9.44988 2.47967 9.45005 2.82995V3.99995C12.1663 4.20996 14.7051 5.43042 16.5656 7.42051C18.4261 9.4106 19.4731 12.0258 19.5 14.75C19.4987 15.5895 19.3946 16.4257 19.19 17.24C19.1353 17.4618 19.0062 17.6583 18.8243 17.7965C18.6423 17.9348 18.4185 18.0066 18.19 18H18.22Z"
      fill="currentColor"
    />
  </svg>
);

export const RedoIcon = () => (
  <svg
    width="24"
    height="21"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.78105 18C2.00949 18.0066 2.23331 17.9348 2.41526 17.7965C2.59721 17.6583 2.7263 17.4618 2.78105 17.24C3.23132 15.4559 4.22284 13.8551 5.61945 12.6572C7.01607 11.4593 8.74928 10.7232 10.5811 10.55V11.67C10.5812 12.0202 10.6847 12.3627 10.8786 12.6544C11.0725 12.9461 11.3482 13.1742 11.6711 13.31C12.0212 13.4709 12.4104 13.5273 12.7918 13.4723C13.1732 13.4173 13.5306 13.2533 13.8211 13L18.8811 8.58995C19.0751 8.42473 19.2309 8.21933 19.3378 7.98797C19.4447 7.75661 19.5 7.5048 19.5 7.24995C19.5 6.9951 19.4447 6.74329 19.3378 6.51193C19.2309 6.28057 19.0751 6.07518 18.8811 5.90995L13.8211 1.48995C13.5268 1.23226 13.1635 1.0665 12.776 1.01318C12.3886 0.95986 11.994 1.02132 11.6411 1.18995C11.3182 1.32574 11.0425 1.55377 10.8486 1.8455C10.6547 2.13722 10.5512 2.47967 10.5511 2.82995V3.99995C7.83484 4.20996 5.29599 5.43042 3.43548 7.42051C1.57498 9.4106 0.52797 12.0258 0.501053 14.75C0.502422 15.5895 0.606529 16.4257 0.811052 17.24C0.865803 17.4618 0.994899 17.6583 1.17685 17.7965C1.3588 17.9348 1.58262 18.0066 1.81105 18H1.78105Z"
      fill="currentColor"
    />
  </svg>
);

export const eraserCursor = (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="29" height="29" fill="white" stroke="black" />
  </svg>
);
