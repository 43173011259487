import {
  ApiDescription,
  ApiData,
  RequestsMethod,
  Report,
  Invoice,
  ExchangeRate,
} from '../common';

export const getAllReports: ApiDescription = {
  endpoint: '/reports',
  method: RequestsMethod.Get,
};
export type GetAllReports = ApiData<never, Report[]>;

export const generateCSVReport: ApiDescription = {
  endpoint: '/reports/csv',
  method: RequestsMethod.Post,
};

export type GenerateCSVReport = ApiData<
  {
    date: string;
    reportIds: string[];
  },
  {
    csvData: any;
  }
>;

export const getLatestMentorReportStatus: ApiDescription = {
  endpoint: '/report/latest-status',
  method: RequestsMethod.Get,
};

export type GetLatestMentorReportStatus = ApiData<
  never,
  { status: number; _id: any }
>;

export const createMonthlyReports: ApiDescription = {
  endpoint: '/reports/monthly',
  method: RequestsMethod.Post,
};

export type CreateMonthlyReports = ApiData<{ date: string }, unknown>;

export const createMonthlyReportsActivity: ApiDescription = {
  endpoint: '/reports/monthly-activity',
  method: RequestsMethod.Post,
};

export type CreateMonthlyReportsActivity = ApiData<unknown, unknown>;

export const deleteCurrentMonthReports: ApiDescription = {
  endpoint: '/reports/monthly',
  method: RequestsMethod.Delete,
};

export type DeleteCurrentMonthReports = ApiData<unknown, unknown>;

export const getNoOfSessionsByMonth: ApiDescription = {
  endpoint: '/report/monthly/no-of-sessions',
  method: RequestsMethod.Get,
};

export type GetNoOfSessionsByMonth = ApiData<
  {
    date: string;
  },
  {
    noOfSessions: number;
  }
>;

export const getNoOfActiveMentorsByMonth: ApiDescription = {
  endpoint: '/report/monthly/no-of-active-mentors',
  method: RequestsMethod.Get,
};

export type GetNoOfActiveMentorsByMonth = ApiData<
  {
    date: string;
  },
  {
    noOfActiveMentors: number;
  }
>;

export const getTotalPaymentByMonth: ApiDescription = {
  endpoint: '/report/monthly/total-payment',
  method: RequestsMethod.Get,
};

export type GetTotalPaymentByMonth = ApiData<
  {
    date: string;
  },
  {
    totalPayment: {
      amountInEuro: number;
      amountInPounds: number;
      amountInDollars: number;
      amountInRON: number;
      paidAmountInEuro: number;
      paidAmountInPounds: number;
      paidAmountInDollars: number;
      paidAmountInRON: number;
      totalAmountInEuro: number;
    };
  }
>;

export const getRatesByMonth: ApiDescription = {
  endpoint: '/reports/monthly/rates',
  method: RequestsMethod.Get,
};
export type GetRatesByMonth = ApiData<{ date: string }, ExchangeRate[]>;

export const getMultipleReports: ApiDescription = {
  endpoint: '/report/multiple',
  method: RequestsMethod.Post,
};
export type GetMultipleReports = ApiData<{ reportIds: string[] }, Report[]>;

export const searchReports: ApiDescription = {
  endpoint: '/reports/search',
  method: RequestsMethod.Get,
};
export type SearchReports = ApiData<
  {
    date: string;
    page?: number;
    limit?: number;
    search?: string;
    status?: number;
    sortType?: string;
    sortValue?: string;
  },
  {
    reports: any;
    totalActivityReports: number;
    totalInvoices: number;
    page: number;
    limit: number;
  }
>;

export const uploadInvoiceByMentor: ApiDescription = {
  endpoint: '/report/invoice/:id/upload-invoice',
  method: RequestsMethod.Post,
};

export type UploadInvoiceByMentor = ApiData<
  { invoice: any; invoiceNo: string },
  Report
>;

export const markBulkReportsReadyForPayment: ApiDescription = {
  endpoint: '/report/bulk/mark-ready-for-payment',
  method: RequestsMethod.Post,
};

export type MarkBulkReportsReadyForPayment = ApiData<
  {
    reportIds: string[];
  },
  Report[]
>;

export const markBulkReportsAsPaid: ApiDescription = {
  endpoint: '/report/bulk/mark-paid',
  method: RequestsMethod.Post,
};

export type MarkBulkReportsAsPaid = ApiData<
  {
    reportIds: string[];
  },
  Report[]
>;

export const getMentorReportActivityHistory: ApiDescription = {
  endpoint: '/reports/activity-history',
  method: RequestsMethod.Get,
};

export const getMentorInvoiceHistory: ApiDescription = {
  endpoint: '/reports/invoice-history',
  method: RequestsMethod.Get,
};

export type GetMentorInvoiceHistory = ApiData<
  { id: string; date: string },
  Invoice[]
>;

export type GetMentorReportActivityHistory = ApiData<
  { id: string; date: string },
  Report[]
>;

export const getReport: ApiDescription = {
  endpoint: '/report/:id',
  method: RequestsMethod.Get,
};

export type GetReport = ApiData<never, Report>;

export const createReport: ApiDescription = {
  endpoint: '/report/:id',
  method: RequestsMethod.Post,
};

export type CreateReport = ApiData<{ date: string }, Report>;

export const deleteReport: ApiDescription = {
  endpoint: '/report/:id',
  method: RequestsMethod.Delete,
};

export type DeleteReport = ApiData<never, Report>;

export const regenerateReport: ApiDescription = {
  endpoint: '/report/:id/regenerate',
  method: RequestsMethod.Post,
};

export type RegenerateReport = ApiData<never, Report>;

export const uploadInvoiceByOperations: ApiDescription = {
  endpoint: '/report/:id/upload-invoice-operations',
  method: RequestsMethod.Post,
};

export type UploadInvoiceByOperations = ApiData<
  { invoice: any; invoiceNo: string },
  Report
>;

export const markReportReadyForPayment: ApiDescription = {
  endpoint: '/report/:id/mark-ready-for-payment',
  method: RequestsMethod.Post,
};

export type MarkReportReadyForPayment = ApiData<never, Report>;

export const markReportAsPaid: ApiDescription = {
  endpoint: '/report/:id/mark-paid',
  method: RequestsMethod.Post,
};

export type MarkReportAsPaid = ApiData<unknown, Report>;

export const downloadInvoice: ApiDescription = {
  endpoint: '/report/:id/download-invoice',
  method: RequestsMethod.Get,
};

export type DownloadInvoice = ApiData<never, unknown>;

export const downloadActivityReport: ApiDescription = {
  endpoint: '/report/:id/download-activity-report',
  method: RequestsMethod.Get,
};

export type DownloadActivityReport = ApiData<never, unknown>;

export const downloadBulkInvoices: ApiDescription = {
  endpoint: '/reports/bulk/download-invoices',
  method: RequestsMethod.Post,
};

export type DownloadBulkInvoices = ApiData<
  { date: string; mentor?: string; status?: number; isTestUser?: boolean },
  unknown
>;

export const downloadBulkActivityReportsV2: ApiDescription = {
  endpoint: '/reports/bulk/download-activity-reports-v2',
  method: RequestsMethod.Post,
};

export type DownloadBulkActivityReportsV2 = ApiData<
  { date: string; mentor?: string; status?: number; isTestUser?: boolean },
  unknown
>;

export const generatePFDsForAllReports: ApiDescription = {
  endpoint: '/reports/bulk/generate-pdfs',
  method: RequestsMethod.Patch,
};

export type GeneratePFDsForAllReports = ApiData<never, unknown>;

export const generatePFDsForMentorReports: ApiDescription = {
  endpoint: '/reports/generate-pdfs/:id',
  method: RequestsMethod.Patch,
};

export type GeneratePFDsForMentorReports = ApiData<never, unknown>;

export const clearActivityReportIds: ApiDescription = {
  endpoint: '/reports/clear-activity-report-ids',
  method: RequestsMethod.Patch,
};

export type ClearActivityReportIds = ApiData<never, unknown>;
export const checkMentorLastMonthMeetingAndExtraServices: ApiDescription = {
  endpoint: '/reports/check-mentor-last-month-meeting-and-extra-services/:id',
  method: RequestsMethod.Get,
};

export type CheckMentorLastMonthMeetingAndExtraServices = ApiData<
  unknown,
  boolean
>;

export const reportStatusHandler: ApiDescription = {
  endpoint: '/reports/report-status-handler',
  method: RequestsMethod.Patch,
};

export type ReportStatusHandler = ApiData<
  { reportId: string; status: string; reason?: string },
  Report
>;

export const generateAcceptedInvoicesCSVData: ApiDescription = {
  endpoint: '/reports/csv/accepted-invoices',
  method: RequestsMethod.Get,
};

export type GenerateAcceptedInvoicesCSVData = ApiData<never, any>;

export const electronicInvoiceStatusHandler: ApiDescription = {
  endpoint: '/reports/e-invoice-status-handler',
  method: RequestsMethod.Patch,
};

export type ElectronicInvoiceStatusHandler = ApiData<
  { reportId: string; status: string; reason?: string },
  Report
>;
