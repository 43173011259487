import {
  DollarOutlined,
  EuroCircleOutlined,
  PoundCircleOutlined,
} from '@ant-design/icons';
import { MENTOR_ROLE } from '@shared/constants';
import { useAuthState, useMentorSessionNo } from '@shared/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { COLORS } from '../../../v3/global';
import './balance-info.styles.scss';

interface BalanceInfoProps {
  onClick?: () => void;
}

const BalanceInfo = (props: BalanceInfoProps) => {
  const { onClick } = props;

  const history = useHistory();
  const { userId, roles, rate } = useAuthState();

  const [currency, setCurrency] = useState('$');
  const [currencyIcon, setCurrencyIcon] = useState<any>(null);
  const [isHovered, setIsHovered] = useState(false);

  const impersonateId = localStorage.getItem('impersonate-user-id');
  const menuType = localStorage.getItem('menuType');

  let isMentor: boolean | undefined = undefined;
  if (impersonateId) {
    isMentor = menuType === MENTOR_ROLE.toString();
  } else {
    isMentor = roles.includes(MENTOR_ROLE);
  }

  useEffect(() => {
    if (isMentor) {
      switch (rate?.currency) {
        case 'USD':
          setCurrency('$');
          setCurrencyIcon(
            <DollarOutlined style={{ color: 'white', fontSize: '16px' }} />
          );
          break;
        case 'EUR':
          setCurrency('€');
          setCurrencyIcon(
            <EuroCircleOutlined style={{ color: 'white', fontSize: '16px' }} />
          );
          break;
        case 'GBP':
          setCurrency('£');
          setCurrencyIcon(
            <PoundCircleOutlined style={{ color: 'white', fontSize: '16px' }} />
          );
          break;
        case 'R0N':
          setCurrency('RON');
          setCurrencyIcon(
            <EuroCircleOutlined style={{ color: 'white', fontSize: '16px' }} />
          );
          break;
        default:
          setCurrency('$');
          setCurrencyIcon(
            <DollarOutlined style={{ color: 'white', fontSize: '16px' }} />
          );
          break;
      }
    }
  }, [rate]);

  const { data: sessions } = useMentorSessionNo(userId);

  const clickHandler = () => {
    onClick?.();
    history.push('/session-history');
  };

  return (
    <div>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={clickHandler}
        style={{ cursor: 'pointer' }}
        className={'balanceMentorContainer'}
      >
        {isMentor && (
          <span className={'tooltipNavbarContainer'}>
            <div className={'balanceContainer'}>
              <div
                style={{ backgroundColor: COLORS.BLACK }}
                className={'balanceIconContainer'}
              >
                {currencyIcon}
              </div>
              <div className={'balanceTextContainer'}>
                {isHovered && (
                  <>
                    <span
                      style={{ color: COLORS.TEXT_SECONDARY }}
                      className={'balanceLabel'}
                    >
                      Monthly Balance
                    </span>
                    <span
                      style={{ color: COLORS.PRIMARY }}
                      className={'balanceValue'}
                    >
                      {currency}
                      {rate?.amount && sessions?.noOfSessions
                        ? rate?.amount * sessions?.noOfSessions
                        : 0}
                    </span>
                  </>
                )}
              </div>
            </div>
          </span>
        )}
      </div>
    </div>
  );
};

export default BalanceInfo;
