import classNames from 'classnames';

interface Props {
  label: string;
  className?: string;
  value?: JSX.Element | string | number;
}

const EditMeetingModalRow = (props: Props) => {
  const { label, className, value } = props;

  return (
    <div
      className={classNames(
        'flex flex-col tablet:flex-row w-full gap-4 text-sm font-normal min-h-10',
        className
      )}
    >
      <span className={'flex w-48 text-customGrey items-start'}>{label}:</span>
      {!!value && (
        <span className="block w-full tablet:w-[calc(100%-12rem)] break-words">
          {value}
        </span>
      )}
    </div>
  );
};

export default EditMeetingModalRow;
