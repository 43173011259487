import { CloseModalProps } from '@shared/common';
import { Drawer } from 'antd';
import { DrawerProps } from 'antd/es/drawer';
import MentorSidebar from './MentorSidebar';

type Props = CloseModalProps & {
  meetingId: string;
};

const MentorSidebarDrawer = ({ visible, hide, meetingId }: Props) => {
  const DRAWER_SIZE: DrawerProps['width'] = '500px';

  return (
    <Drawer
      bodyStyle={{ padding: 0, paddingBottom: '200px' }}
      width={DRAWER_SIZE}
      title="Mentor Sidebar"
      placement="right"
      onClose={hide}
      visible={visible}
      mask={false}
    >
      <MentorSidebar meetingId={meetingId} />
    </Drawer>
  );
};

export default MentorSidebarDrawer;
