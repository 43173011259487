import AcademicExperienceDetailsSection from '@components/V3/ProfileModalMentors/AcademicExperienceDetailsSection';
import CapacityDetailsSection from '@components/V3/ProfileModalMentors/CapacityDetailsSection';
import PaymentDetailsSection from '@components/V3/ProfileModalMentors/PaymentDetailsSection';
import WorkExperienceDetailsSection from '@components/V3/ProfileModalMentors/WorkExperienceDetailsSection';
import { searchMentors } from '@shared/apis/mentor.apis';
import { useGetAllCourses, useSaveUserProfile } from '@shared/react';
import SecondaryButton from '@utils/SecondaryButton';
import React, { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { COLORS } from '../../../v3/global';
import './profile-modal-mentors.styles.scss';
import AgoraBigSelector from '../Utils/InputsV3/AgoraBigSelector';

type Props = {
  mentorId: string;
  mentorData: any;
  handleCurrentPage: (page: string) => void;
  selectedTab: string;
  handleSelectedTab: (tab: string) => void;
  refetchMentorData: () => void;
};

const ProfilePageMentors = ({
  mentorId,
  mentorData,
  handleCurrentPage,
  selectedTab,
  handleSelectedTab,
  refetchMentorData,
}: Props) => {
  const [isEditMentorDetails, setIsEditMentorDetails] = useState(false);

  const QueryClient = useQueryClient();

  const { mutate: saveUserProfile } = useSaveUserProfile(mentorId, {
    onSuccess: () => {
      refetchMentorData();
      // Invalidate mentor table
      QueryClient.invalidateQueries(searchMentors.endpoint);
      setIsEditMentorDetails(false);
    },
  });

  const { data: allCourses } = useGetAllCourses();

  const [email, setEmail] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [country, setCountry] = useState<string | null>(null);
  const [parentName, setParentName] = useState<string | null>(null);
  const [parentEmail, setParentEmail] = useState<string | null>(null);
  const [parentPhone, setParentPhone] = useState<string | null>(null);

  const [formCoursesCanTeach, setFormCoursesCanTeach] = useState<
    { _id: string | number; title: string }[]
  >([]);

  const [editCoursesCanTeach, setEditCoursesCanTeach] =
    useState<boolean>(false);

  useEffect(() => {
    if (mentorData && mentorData.mentorData && allCourses) {
      const coursesCanTeachTitles = mentorData.mentorData.coursesCanTeach.map(
        (courseId: string) => {
          const course = allCourses.find(
            (courseFromAllCourses) => courseFromAllCourses._id === courseId
          );
          return { title: course ? course.title : null, _id: course?._id };
        }
      );

      setFormCoursesCanTeach(coursesCanTeachTitles);
    }
  }, [mentorData, allCourses]);

  // TODO Delete old edit profile details

  const mentorCourses = useMemo(() => {
    return mentorData?.mentorData?.coursesCanTeach.map((courseId: string) => {
      const course = allCourses?.find(
        (courseFromAllCourses) => courseFromAllCourses._id === courseId
      );
      return { title: course ? course.title : null, _id: course?._id };
    });
  }, [mentorData, allCourses]);

  return (
    <div className="flex flex-col gap-8">
      {/*USER INFO*/}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '8px',
          marginBottom: '8px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                lineHeight: '14px',
              }}
            >
              <span
                style={{ color: COLORS.TEXT_PRIMARY, marginBottom: '16px' }}
                className="edit-enrolment-section-subtitle"
              >
                Contact Info
              </span>
              <div
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    height: 'fit-content',
                    marginRight: '4px',
                  }}
                  className="edit-enrolment-details-label"
                >
                  Email:{' '}
                </span>
                {isEditMentorDetails ? (
                  <span>
                    <input
                      className="edit-enrolment-details-value block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
                      style={{
                        backgroundColor: 'transparent',
                        maxWidth: '160px',
                        height: '16px',
                        marginBottom: '0px',
                        fontSize: '12px',
                        lineHeight: '16px',
                      }}
                      value={email === null ? mentorData?.email : email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </span>
                ) : (
                  <span
                    style={{ color: COLORS.TEXT_PRIMARY }}
                    className="edit-enrolment-details-value"
                  >
                    {mentorData?.email ?? '-'}
                  </span>
                )}
              </div>
              <div
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    height: 'fit-content',
                    marginRight: '4px',
                  }}
                  className="edit-enrolment-details-label"
                >
                  Phone:{' '}
                </span>
                {isEditMentorDetails ? (
                  <span>
                    <input
                      className="edit-enrolment-details-value block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
                      style={{
                        backgroundColor: 'transparent',
                        maxWidth: '160px',
                        height: '16px',
                        marginBottom: '0px',
                        fontSize: '12px',
                        lineHeight: '16px',
                      }}
                      value={phone === null ? mentorData?.phoneNumber : phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                  </span>
                ) : (
                  <span
                    style={{ color: COLORS.TEXT_PRIMARY }}
                    className="edit-enrolment-details-value"
                  >
                    {mentorData?.phoneNumber ?? '-'}
                  </span>
                )}
              </div>
              <div
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    height: 'fit-content',
                    marginRight: '4px',
                  }}
                  className="edit-enrolment-details-label"
                >
                  City:{' '}
                </span>
                {isEditMentorDetails ? (
                  <span>
                    <input
                      className="edit-enrolment-details-value block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
                      style={{
                        backgroundColor: 'transparent',
                        maxWidth: '160px',
                        height: '16px',
                        marginBottom: '0px',
                        fontSize: '12px',
                        lineHeight: '16px',
                      }}
                      value={city === null ? mentorData?.city : city}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                    />
                  </span>
                ) : (
                  <span
                    style={{ color: COLORS.TEXT_PRIMARY }}
                    className="edit-enrolment-details-value"
                  >
                    {mentorData?.city ?? '-'}
                  </span>
                )}
              </div>
              <div
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    height: 'fit-content',
                    marginRight: '4px',
                  }}
                  className="edit-enrolment-details-label"
                >
                  Country:{' '}
                </span>
                {isEditMentorDetails ? (
                  <span>
                    <input
                      className="edit-enrolment-details-value block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
                      style={{
                        backgroundColor: 'transparent',
                        maxWidth: '160px',
                        height: '16px',
                        marginBottom: '0px',
                        fontSize: '12px',
                        lineHeight: '16px',
                      }}
                      value={country === null ? mentorData?.country : country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                    />
                  </span>
                ) : (
                  <span
                    style={{ color: COLORS.TEXT_PRIMARY }}
                    className="edit-enrolment-details-value"
                  >
                    {mentorData?.country ?? '-'}
                  </span>
                )}
              </div>
            </div>

            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                lineHeight: '14px',
              }}
            >
              <span
                style={{ color: COLORS.TEXT_PRIMARY, marginBottom: '16px' }}
                className="edit-enrolment-section-subtitle"
              >
                Biography
              </span>
              <div
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{ color: COLORS.TEXT_PRIMARY }}
                  className="edit-enrolment-details-value"
                >
                  {mentorData?.mentorData?.biography ?? '-'}
                </span>
              </div>
              {/*////*/}
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {isEditMentorDetails ? (
              <div style={{ display: 'flex' }}>
                <SecondaryButton
                  onClick={() => {
                    setIsEditMentorDetails(false);
                    // handleCurrentPage('edit-profile-details')
                  }}
                  backgroundColor={COLORS.TRANSPARENT}
                  height="27px"
                  padding="6px 24px 6px 24px"
                  margin="0px 16px 0px 0px"
                >
                  <span
                    className="button-text"
                    style={{ color: COLORS.TEXT_PRIMARY }}
                  >
                    Cancel
                  </span>
                </SecondaryButton>
                <SecondaryButton
                  onClick={() => {
                    saveUserProfile({
                      email: email === null ? mentorData?.email : email,
                      phoneNumber:
                        phone === null ? mentorData?.phoneNumber : phone,
                      city: city === null ? mentorData?.city : city,
                      country: country === null ? mentorData?.country : country,
                      parentData: {
                        fullName:
                          parentName === null
                            ? mentorData?.parentData?.fullName
                            : parentName,
                        phoneNumber:
                          parentPhone === null
                            ? mentorData?.parentData?.phoneNumber
                            : parentPhone,
                        email:
                          parentEmail === null
                            ? mentorData?.parentData?.email
                            : parentEmail,
                      },
                    });
                  }}
                  backgroundColor={COLORS.WHITE}
                  height="27px"
                  padding="6px 24px 6px 24px"
                  margin="0px"
                >
                  <span className="button-text" style={{ color: COLORS.BLACK }}>
                    Save
                  </span>
                </SecondaryButton>
              </div>
            ) : (
              <SecondaryButton
                onClick={() => {
                  // setIsEditStudentDetails(true);
                  handleCurrentPage('edit-profile-details');
                }}
                backgroundColor={COLORS.WHITE}
                height="27px"
                padding="6px 24px 6px 24px"
                margin="0px"
              >
                <span className="button-text" style={{ color: COLORS.BLACK }}>
                  Edit
                </span>
              </SecondaryButton>
            )}
          </div>
        </div>
      </div>

      <section>
        <span
          style={{ color: COLORS.TEXT_PRIMARY, marginBottom: '16px' }}
          className="edit-enrolment-section-subtitle"
        >
          Courses Can Teach
        </span>

        <div className="flex flex-col">
          {!editCoursesCanTeach ? (
            <div>
              <ol>
                {mentorCourses?.map((course: any, index: number) => (
                  <li className=" text-sm" key={course._id}>
                    {index + 1}. {course.title}
                  </li>
                ))}
              </ol>
              <div className="flex justify-end">
                <SecondaryButton
                  onClick={() => {
                    setEditCoursesCanTeach(true);
                  }}
                  backgroundColor={COLORS.WHITE}
                  height="27px"
                  padding="6px 24px 6px 24px"
                  margin="0px"
                >
                  <span className="button-text" style={{ color: COLORS.BLACK }}>
                    Edit
                  </span>
                </SecondaryButton>
              </div>
            </div>
          ) : (
            <div className="w-full mt-2">
              <AgoraBigSelector
                onSelect={(value) => {
                  const courseToAdd = {
                    _id: value ?? '',
                    title: 'asedasdas',
                  };
                  setFormCoursesCanTeach([...formCoursesCanTeach, courseToAdd]);
                }}
                onDeselect={(value) => {
                  const updatedCourses = formCoursesCanTeach.filter(
                    (course) => course._id !== value
                  );
                  setFormCoursesCanTeach(updatedCourses);
                }}
                onClear={() => {
                  setFormCoursesCanTeach([]);
                }}
                options={allCourses?.map((course) => ({
                  label: course?.title,
                  value: course?._id,
                }))}
                labelKey="label"
                valueKey="value"
                maxTagCount={10}
                mode="multiple"
                value={formCoursesCanTeach?.map((course: any) => ({
                  lable: course?.title,
                  value: course?._id,
                }))}
              />
              <div className="flex justify-end">
                <SecondaryButton
                  onClick={() => {
                    const selectedCoursesIds = formCoursesCanTeach.map(
                      (course) => course._id as string
                    );
                    saveUserProfile({
                      coursesCanTeach: selectedCoursesIds,
                    });
                    setEditCoursesCanTeach(false);
                  }}
                  backgroundColor={COLORS.WHITE}
                  height="32px"
                  padding="4px 10px 4px 10px"
                  margin="12px 0 0 0"
                >
                  <span className="button-text" style={{ color: COLORS.BLACK }}>
                    Save
                  </span>
                </SecondaryButton>
              </div>
            </div>
          )}
        </div>
      </section>

      <div style={{ width: '100%', marginTop: '8px' }}>
        {/*TABS*/}
        <div style={{ marginBottom: '16px' }}>
          <nav
            style={{ overflowX: 'auto' }}
            className="flex space-x-4"
            aria-label="Tabs"
          >
            {/*TODO Uncomment when tabs info is ready*/}
            {/*<span*/}
            {/*  onClick={() => {*/}
            {/*    handleSelectedTab('Academic experience');*/}
            {/*  }}*/}
            {/*  style={{*/}
            {/*    cursor: 'pointer',*/}
            {/*    color:*/}
            {/*      selectedTab === 'Academic experience'*/}
            {/*        ? COLORS.BLACK*/}
            {/*        : COLORS.TEXT_PRIMARY,*/}
            {/*    background:*/}
            {/*      selectedTab === 'Academic experience'*/}
            {/*        ? COLORS.PRIMARY*/}
            {/*        : COLORS.DARK_GREY_ALT,*/}
            {/*    borderRadius: '32px',*/}
            {/*    paddingLeft: '24px',*/}
            {/*    paddingRight: '24px',*/}
            {/*    paddingTop: '4px',*/}
            {/*    paddingBottom: '4px',*/}
            {/*    height: '24px',*/}
            {/*    fontSize: '11px',*/}
            {/*    lineHeight: '15px',*/}
            {/*  }}*/}
            {/*  aria-current={*/}
            {/*    selectedTab === 'Academic experience' ? 'page' : undefined*/}
            {/*  }*/}
            {/*>*/}
            {/*  Academic experience*/}
            {/*</span>*/}

            {/*<span*/}
            {/*  onClick={() => {*/}
            {/*    handleSelectedTab('Work experience');*/}
            {/*  }}*/}
            {/*  style={{*/}
            {/*    cursor: 'pointer',*/}
            {/*    color:*/}
            {/*      selectedTab === 'Work experience'*/}
            {/*        ? COLORS.BLACK*/}
            {/*        : COLORS.TEXT_PRIMARY,*/}
            {/*    background:*/}
            {/*      selectedTab === 'Work experience'*/}
            {/*        ? COLORS.PRIMARY*/}
            {/*        : COLORS.DARK_GREY_ALT,*/}
            {/*    borderRadius: '32px',*/}
            {/*    paddingLeft: '24px',*/}
            {/*    paddingRight: '24px',*/}
            {/*    paddingTop: '4px',*/}
            {/*    paddingBottom: '4px',*/}
            {/*    height: '24px',*/}
            {/*    fontSize: '11px',*/}
            {/*    lineHeight: '15px',*/}
            {/*  }}*/}
            {/*  aria-current={*/}
            {/*    selectedTab === 'Work experience' ? 'page' : undefined*/}
            {/*  }*/}
            {/*>*/}
            {/*  Work experience*/}
            {/*</span>*/}

            {/*<span*/}
            {/*  onClick={() => {*/}
            {/*    handleSelectedTab('Capacity');*/}
            {/*  }}*/}
            {/*  style={{*/}
            {/*    cursor: 'pointer',*/}
            {/*    color:*/}
            {/*      selectedTab === 'Capacity'*/}
            {/*        ? COLORS.BLACK*/}
            {/*        : COLORS.TEXT_PRIMARY,*/}
            {/*    background:*/}
            {/*      selectedTab === 'Capacity'*/}
            {/*        ? COLORS.PRIMARY*/}
            {/*        : COLORS.DARK_GREY_ALT,*/}
            {/*    borderRadius: '32px',*/}
            {/*    paddingLeft: '24px',*/}
            {/*    paddingRight: '24px',*/}
            {/*    paddingTop: '4px',*/}
            {/*    paddingBottom: '4px',*/}
            {/*    height: '24px',*/}
            {/*    fontSize: '11px',*/}
            {/*    lineHeight: '15px',*/}
            {/*  }}*/}
            {/*  aria-current={selectedTab === 'Capacity' ? 'page' : undefined}*/}
            {/*>*/}
            {/*  Capacity*/}
            {/*</span>*/}

            <span
              onClick={() => {
                handleSelectedTab('Payment details');
              }}
              style={{
                cursor: 'pointer',
                color:
                  selectedTab === 'Payment details'
                    ? COLORS.BLACK
                    : COLORS.TEXT_PRIMARY,
                background:
                  selectedTab === 'Payment details'
                    ? COLORS.PRIMARY
                    : COLORS.DARK_GREY_ALT,
                borderRadius: '32px',
                paddingLeft: '24px',
                paddingRight: '24px',
                paddingTop: '4px',
                paddingBottom: '4px',
                height: '24px',
                fontSize: '11px',
                lineHeight: '15px',
              }}
              aria-current={
                selectedTab === 'Payment details' ? 'page' : undefined
              }
            >
              Payment details
            </span>
          </nav>
        </div>

        {selectedTab === 'Academic experience' ? (
          <AcademicExperienceDetailsSection />
        ) : selectedTab === 'Work experience' ? (
          <WorkExperienceDetailsSection />
        ) : selectedTab === 'Capacity' ? (
          <CapacityDetailsSection />
        ) : selectedTab === 'Payment details' ? (
          <PaymentDetailsSection
            mentorData={mentorData}
            handleCurrentPage={handleCurrentPage}
            refetchMentorData={refetchMentorData}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ProfilePageMentors;
