import PlusIconGrey from '@assets/icons/plus-icon-grey.svg';
import PlusIcon from '@assets/icons/plus-icon.svg';
import { NewMeetingModal } from '@components';
import PrimaryButtonV3 from '@components/V3/PrimaryButtonV3';
import { useModal } from '@hooks';
import { Moment } from 'moment';

type Props = {
  date?: Moment;
  isOnHomePage?: boolean;
  disabled?: boolean;
  title?: string;
  width?: string;
};

const NewMeetingButton = ({
  date,
  isOnHomePage,
  disabled,
  title,
  width,
}: Props) => {
  const [Modal, show] = useModal(NewMeetingModal);

  return (
    <>
      {Modal}
      <PrimaryButtonV3
        onClick={() =>
          show({
            date: date,
            isOnHomePage: isOnHomePage,
          })
        }
        buttonText={title || 'Add Meeting'}
        icon={PlusIcon}
        disabled={disabled}
        disabledIcon={PlusIconGrey}
      />
    </>
  );
};

export default NewMeetingButton;
