import React, { ReactNode, useState } from 'react';
import { COLORS } from '../../../../../v3/global';
import './meeting-card-buttons.styles.scss';

type Props = {
  children: ReactNode;
};

const MeetingListItemButton = ({ children }: Props) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        backgroundColor: isHovered
          ? COLORS.SURFACE_BACKGROUND
          : COLORS.TRANSPARENT,
      }}
      className="meeting-list-item-button-container"
    >
      {children}
    </div>
  );
};

export default MeetingListItemButton;
