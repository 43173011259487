import EditProfileDetailsPage from '@components/V3/ProfileModalMentors/EditProfileDetailsPage';
import AddLanguageExam from '@components/V3/ProfileModalStudents/AddLanguageExam';
import AgoraModal from '@components/V3/Utils/AgoraModal';
import {
  useGetStudentEnrollmentsWithExtraInfo,
  useGetUserProfile,
} from '@shared/react';
import React, { useState } from 'react';
import './profile-modal-mentors.styles.scss';
import ProfilePageMentors from '@components/V3/ProfileModalMentors/ProfilePageMentors';
import EditPaymentDetailsPage from '@components/V3/ProfileModalMentors/EditPaymentDetailsPage';

type Props = {
  showModal: boolean;
  handleShowModal: () => void;
  mentorData?: any;
  enrolmentModalPage?: string;
  currentEnrolmentId?: string;
};

const ProfileModalMentors = ({
  showModal,
  handleShowModal,
  mentorData,
  enrolmentModalPage,
  currentEnrolmentId,
}: Props) => {
  const [page, setPage] = useState(1);
  const [isEnded, setIsEnded] = useState(false);

  const {
    data: userProfile,
    refetch: refetchUserProfile,
    isLoading: isLoadingUserProfile,
  } = useGetUserProfile(mentorData?.mentor?._id);

  // const {
  //   data: enrollments,
  //   refetch: refetchEnrollments,
  //   isLoading: isLoadingEnrollments,
  // } = useGetStudentEnrollmentsWithExtraInfo({
  //   id: mentorData?._id ?? '',
  //   page: page,
  //   isEnded: isEnded,
  //   limit: 10,
  // });

  const [currentEnrolment, setCurrentEnrolment] = useState<string>(
    currentEnrolmentId ?? ''
  );
  const [currentPage, setCurrentPage] = useState(
    enrolmentModalPage ?? 'mentor-profile'
  );

  const [selectedTab, setSelectedTab] = useState('Payment details');

  const handleCurrentPage = (page: string) => {
    setCurrentPage(page);
  };

  const handleSelectedTab = (tab: string) => {
    setSelectedTab(tab);
  };

  return (
    <div>
      <AgoraModal
        backButton={
          currentPage === 'edit-profile-details' ||
          currentPage === 'add-academic-experience' ||
          currentPage === 'add-work-experience' ||
          currentPage === 'add-capacity' ||
          currentPage === 'edit-payment-details'
        }
        onBack={
          currentPage === 'edit-profile-details'
            ? handleCurrentPage
            : currentPage === 'add-academic-experience' ||
              currentPage === 'add-work-experience' ||
              currentPage === 'add-capacity' ||
              currentPage === 'edit-payment-details'
            ? () => handleCurrentPage('mentor-profile')
            : () => null
        }
        backRoute="mentor-profile"
        title={
          currentPage === 'mentor-profile'
            ? `Mentor Profile`
            : currentPage === 'edit-profile-details'
            ? 'Edit Mentor Profile'
            : currentPage === 'add-academic-experience'
            ? 'Add Academic Experience'
            : currentPage === 'add-work-experience'
            ? 'Add Work Experience'
            : currentPage === 'add-capacity'
            ? 'Add Capacity'
            : currentPage === 'edit-payment-details'
            ? 'Edit Payment Details'
            : ''
        }
        title2={
          currentPage === 'mentor-profile'
            ? `${mentorData?.mentor?.fullName}`
            : ''
        }
        setModalOpen={() => handleShowModal()}
        isModalOpen={showModal}
        closable={true}
        width="58vw"
      >
        {currentPage === 'mentor-profile' ? (
          <ProfilePageMentors
            mentorId={mentorData?.mentor?._id}
            mentorData={userProfile}
            refetchMentorData={refetchUserProfile}
            handleCurrentPage={handleCurrentPage}
            selectedTab={selectedTab}
            handleSelectedTab={handleSelectedTab}
          />
        ) : currentPage === 'edit-payment-details' ? (
          <EditPaymentDetailsPage
            handleCurrentPage={handleCurrentPage}
            mentorId={mentorData?.mentor?._id ?? ''}
            // refetchEnrollments={refetchEnrollments}
            mentorData={userProfile}
            refetchMentorData={refetchUserProfile}
          />
        ) : currentPage === 'edit-profile-details' ? (
          <EditProfileDetailsPage
            handleCurrentPage={handleCurrentPage}
            mentorId={mentorData?.mentor?._id ?? ''}
            // refetchEnrollments={refetchEnrollments}
            mentorData={userProfile}
            refetchMentorData={refetchUserProfile}
          />
        ) : null}
      </AgoraModal>
    </div>
  );
};

export default ProfileModalMentors;
