import { useAuthState, useCourseProgress } from '@shared/react';

const useCourseCompleted = (courseId: string) => {
  const { userId } = useAuthState();
  const impersonateId = localStorage.getItem('impersonate-user-id');
  const getUserId = () => {
    if (impersonateId) return impersonateId;
    return userId;
  };
  const { data } = useCourseProgress({
    courseId,
    studentId: getUserId(),
  });

  if (!data) {
    return 0;
  }

  const { course, completedLessonIds, completedQuizzIds } = data;
  const noLessons = course.modules.reduce((acc, module) => {
    return acc + module.lessons?.length ?? 0;
  }, 0);

  const completedLessons =
    completedLessonIds?.length ?? 0 + completedQuizzIds?.length ?? 0;

  return completedLessons / noLessons;
};

export default useCourseCompleted;
