import styles from '@styles/components/session-history-table.module.scss';
import { Empty, Spin } from 'antd';
import { FC } from 'react';
import { COLORS } from '../../../../v3/global';
import SessionHistoryRow from './SessionHistoryRow';

type Props = {
  meetings: any[] | undefined;
  isLoading: boolean;
  tableHeight?: number;
};

const SessionHistoryTable: FC<Props> = ({
  meetings,
  isLoading,
  tableHeight,
}) => {
  return (
    <div>
      <div
        style={{
          backgroundColor: COLORS.BLACK,
        }}
        className={`${styles.gridTable} ${
          isLoading ? styles.tableLoading : ''
        }`}
      >
        <div
          style={{
            backgroundColor: COLORS.BLACK,
            color: COLORS.TEXT_PRIMARY,
          }}
          className={styles.gridTableHeader}
        >
          Student
        </div>
        <div
          style={{
            backgroundColor: COLORS.BLACK,
            color: COLORS.TEXT_PRIMARY,
          }}
          className={styles.gridTableHeader}
        >
          Course
        </div>
        <div
          style={{
            backgroundColor: COLORS.BLACK,
            color: COLORS.TEXT_PRIMARY,
          }}
          className={styles.gridTableHeader}
        >
          Date
        </div>
        <div
          style={{
            backgroundColor: COLORS.BLACK,
            color: COLORS.TEXT_PRIMARY,
          }}
          className={styles.gridTableHeader}
        >
          Status
        </div>
        <div
          style={{
            backgroundColor: COLORS.BLACK,
            color: COLORS.TEXT_PRIMARY,
          }}
          className={styles.gridTableHeader}
        >
          Actions
        </div>

        {isLoading && (
          <Spin className="w-full" style={{ gridColumn: '1 / 7' }} />
        )}

        {meetings?.length && !isLoading ? (
          meetings?.map((meeting, index) => (
            <SessionHistoryRow
              isEven={index % 2 === 0}
              meeting={meeting}
              key={`${meeting._id}-${index}`}
            />
          ))
        ) : (
          <div style={{ gridColumn: '1 / 7' }}>{!isLoading && <Empty />}</div>
        )}
      </div>
    </div>
  );
};

export default SessionHistoryTable;
