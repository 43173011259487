import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { useHistory } from 'react-router';

type Props = {
  startingIn: string;
};

const MeetingTooEarly = ({ startingIn }: Props) => {
  const history = useHistory();

  return (
    <div>
      <ExclamationCircleOutlined style={{ fontSize: '36px' }} />
      <p>
        The meeting <b>starts {startingIn}</b>
      </p>
      <p>You can only join the meeting 15 minutes earlier than scheduled</p>
      <Space>
        <Button type="primary" onClick={history.goBack}>
          Go Back
        </Button>
      </Space>
    </div>
  );
};

export default MeetingTooEarly;
