import { InterestsSelector, StudySubjectsSelector } from '@components';
import SectionHeader from '@components/Common/SectionHeader';
import FormButton from '@components/Form/FormButton';
import FormCustomElement from '@components/Form/FormCustomElement';
import FormInput from '@components/Form/FormInput';
import { MyAccountInfo } from '@shared/common';
import {
  useUploadProfilePicture,
  useUpdateMyAccount,
  useAuthState,
  useAuthActions,
} from '@shared/react';
import styles from '@styles/pages/account.module.scss';
import { Select, Col, InputNumber, Row, message } from 'antd';
import { useEffect, useState } from 'react';
import { COLORS } from '../../../v3/global';
import PersonalSetting from './PersonalSetting';

const { Option } = Select;

const StudentSettings = () => {
  const user: MyAccountInfo = useAuthState();
  const { logout } = useAuthActions();
  const { mutate: updateMyAccount } = useUpdateMyAccount({
    onSuccess: () => {
      message.success('Successfully updated your profile.');
    },
    onError: (err: any) => {
      message.error(`Error: ${err.response?.data?.message || err.message}`);
    },
  });

  const { mutate: uploadImage } = useUploadProfilePicture();
  const [gender, setGender] = useState<string | number>('');
  const [pronouns, setPronouns] = useState('none');
  const [avatar, setAvatar] = useState('');
  const [fileData, setFileData] = useState<any>();
  const [highSchool, setHighSchool] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [graduationYear, setGraduationYear] = useState<number>();
  const [interests, setInterests] = useState<string[]>([]);
  const [studySubjects, setStudySubjects] = useState<string[]>([]);

  useEffect(() => {
    const applyCurrentInfo = () => {
      setGender(user.gender);
      setAvatar(user.avatar);
      setPronouns(user.pronouns);
      setHighSchool(user.highSchool);
      setCountry(user.country);
      setCity(user.city);
      setGraduationYear(user.graduationYear);
      setInterests(user.interests.filter(Boolean));
      setStudySubjects(user.studySubjects.filter(Boolean));
    };
    applyCurrentInfo();
  }, []);

  const changePassword = () => {
    logout();
  };

  const changeCountries = () => {
    window.location.href = '/onboarding';
  };

  const sendUpdateQuery = (data: any) => {
    updateMyAccount(data);
  };

  const onImageChange = (event: any) => {
    setFileData(event.target.files[0]);
    setAvatar(window.URL.createObjectURL(event.target.files[0]));
  };

  const saveAll = () => {
    if (fileData) {
      const data = new FormData();
      data.append('image', fileData);
      uploadImage(data);
    }
    sendUpdateQuery({
      gender,
      pronouns,
      highSchool,
      country,
      city,
      graduationYear,
      interests,
      studySubjects,
    });
  };

  const schoolInfoSettings = (
    <div className={styles.settingsCard}>
      <h3
        style={{ color: COLORS.TEXT_PRIMARY }}
        className={styles.settingsCardTitle}
      >
        School Info
      </h3>

      <div
        style={{
          margin: '2rem 0',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1.5rem',
        }}
      >
        <FormInput
          value={highSchool}
          title="High School"
          placeholder="Enter school"
          onChange={(e) => setHighSchool(e.target.value)}
        />

        <FormInput
          value={country}
          title="Country"
          placeholder="Enter country"
          onChange={(e) => setCountry(e.target.value)}
        />

        <FormInput
          value={city}
          title="City"
          placeholder="Enter city"
          onChange={(e) => setCity(e.target.value)}
        />

        <FormCustomElement title="Graduation Year">
          <InputNumber
            value={graduationYear}
            min={2021}
            max={2030}
            style={{ width: '100%' }}
            onChange={(year) => year && setGraduationYear(year)}
            placeholder="Enter graduation year"
          />
        </FormCustomElement>
      </div>
    </div>
  );

  const goalsAndInterestsSettings = (
    <div className={styles.settingsCard}>
      <h3
        style={{ color: COLORS.TEXT_PRIMARY }}
        className={styles.settingsCardTitle}
      >
        Goals and Interests
      </h3>

      <div className={styles.accountPageGoalsFormContainer}>
        <FormCustomElement title="Countries">
          <div className="custom-antd-select">
            <Select
              mode="multiple"
              disabled
              style={{ width: '100%' }}
              defaultValue={user.countries.filter(Boolean)}
            >
              {user.countries.map((country: any) => (
                <Option key={country} value={country}>
                  {country}
                </Option>
              ))}
            </Select>
          </div>
        </FormCustomElement>

        <FormCustomElement title="Interests">
          <div className="custom-antd-select">
            <InterestsSelector
              value={interests}
              onChange={(val) => setInterests(val)}
              placeholder="Please select your interests"
              style={{ width: '100%' }}
            />
          </div>
        </FormCustomElement>

        <FormCustomElement title="Study Subjects">
          <div className="custom-antd-select">
            <StudySubjectsSelector
              value={studySubjects}
              style={{ width: '100%' }}
              onChange={(value: string[]) => {
                setStudySubjects(value);
              }}
            />
          </div>
        </FormCustomElement>

        <FormButton
          onClick={() => saveAll()}
          style={{
            width: 'fit-content',
            borderRadius: '250px',
            padding: '4px 32px',
          }}
        >
          Save
        </FormButton>
      </div>
    </div>
  );

  return (
    <div
      style={{
        backgroundColor: COLORS.SECTION_BACKGROUND,
      }}
      className={`${styles.page} ${styles.accountPage}`}
    >
      <div
        style={{
          backgroundColor: COLORS.COMPONENT_BACKGROUND,
        }}
        className={styles.pageContent}
      >
        <div className={styles.accountPageHeaderContainer}>
          <SectionHeader title="My Account" />
        </div>

        <Row
          style={{
            backgroundColor: COLORS.COMPONENT_BACKGROUND,
          }}
          className={`${styles.settings} ${styles.accountPageInfoContainer}`}
        >
          <Col
            className={styles.accountPageColumn}
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={8}
          >
            <PersonalSetting
              avatar={avatar}
              name={user.fullName}
              email={user.email}
              uploadFn={onImageChange}
              username={user.username}
              changePasswordFn={changePassword}
              gender={gender}
              setGender={setGender}
              pronouns={pronouns}
              setPronouns={setPronouns}
              saveAll={saveAll}
            />
          </Col>

          <Col
            className={styles.accountPageColumn}
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={8}
          >
            {schoolInfoSettings}
          </Col>

          <Col
            className={styles.accountPageColumn}
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={8}
          >
            {goalsAndInterestsSettings}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StudentSettings;
