import {
  useAllMyMeetings,
  useAuthState,
  useFeedbackMeetings,
  useStudentChecklistArrayForMentor,
  useUpcomingMeetings,
} from '@shared/react';
import { ChecklistItem } from '@shared/types';
import { Spin } from 'antd';
import { Moment } from 'moment';
import Calendar from './Calendar';

const MentorCalendar = () => {
  const { userId } = useAuthState();

  const impersonateId = localStorage.getItem('impersonate-user-id');

  const { data: studentChecklists } = useStudentChecklistArrayForMentor(
    impersonateId ?? userId
  );

  const studentChecklistItems =
    studentChecklists &&
    Array.isArray(studentChecklists) &&
    studentChecklists.length > 0
      ? studentChecklists?.reduce(
          (acc: ChecklistItem[], curr: any) => [
            ...acc,
            ...curr.checklist.items,
          ],
          []
        )
      : [];

  const { data: allMeetings, isLoading, isError } = useAllMyMeetings();

  const {
    data: meetingsNeedingFeedback,
    isLoading: isLoadingMeetingsNeedingFeedback,
    isError: isErrorMeetingsNeedingFeedback,
  } = useFeedbackMeetings();

  const {
    data: scheduledMeetings,
    isLoading: isLoadingScheduledMeetings,
    isError: isErrorScheduledMeetings,
  } = useUpcomingMeetings();

  const milestonesForDate = (date: Moment) => {
    return studentChecklistItems?.filter((item) =>
      date.isSame(item.deadline, 'date')
    );
  };

  if (isLoading) return <Spin style={{ width: '100%' }} />;
  if (isError) return <div>Error</div>;
  return (
    <Calendar
      allMeetings={allMeetings}
      milestonesForDate={milestonesForDate}
      meetingsNeedingFeedback={meetingsNeedingFeedback}
      scheduledMeetings={scheduledMeetings}
    />
  );
};

export default MentorCalendar;
