import { CloseModalProps, User } from '@shared/common';
import { Avatar, Button, List, message, Modal } from 'antd';

type Props = CloseModalProps & {
  fieldType: 'rating' | 'platformRating';
  userRole: 'student' | 'mentor' | 'parent';
  npsLevel: 'detractors' | 'passives' | 'promoters';
  data: Partial<User>[] | undefined;
  userRates?: any;
};

const NPSUsersModal = ({
  fieldType,
  userRole,
  npsLevel,
  userRates,
  data,
  visible,
  hide,
}: Props) => {
  if (!data) {
    message.loading('Loading...', 0.2);
    return null;
  }

  const fullName = (item: Partial<User>) =>
    userRole === 'parent'
      ? `${item?.parentData?.fullName}, parent of ${item?.fullName}`
      : `${item?.fullName}`;

  const contactInfo = (item: Partial<User>) =>
    userRole === 'parent'
      ? `${item?.parentData?.email} - ${item?.parentData?.phoneNumber}`
      : `${item?.email} - ${item?.phoneNumber}`;

  const getStat = (currentMonth: number, lastMonth: number) => {
    if (lastMonth === undefined) {
      return (
        <div
          style={{
            color: 'gray',
            display: 'inline-block',
          }}
        >
          No NPS Last Month
        </div>
      );
    }
    if (currentMonth < lastMonth) {
      return (
        <div
          style={{
            color: 'red',
            display: 'inline-block',
          }}
        >
          Down from {lastMonth}
        </div>
      );
    } else if (currentMonth > lastMonth) {
      return (
        <div
          style={{
            color: 'green',
            display: 'inline-block',
          }}
        >
          Up from {lastMonth}
        </div>
      );
    } else if (currentMonth === lastMonth) {
      return (
        <div
          style={{
            color: 'gray',
            display: 'inline-block',
          }}
        >
          Equal to {lastMonth}
        </div>
      );
    }
    return (
      <div
        style={{
          color: 'gray',
          display: 'inline-block',
        }}
      >
        No data
      </div>
    );
  };

  return (
    <Modal visible={visible} onCancel={hide} footer={false} width={700}>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item
            actions={
              undefined && [
                <Button type="primary" onClick={() => {}}>
                  NPS History
                </Button>,
                <Button type="default" onClick={() => {}}>
                  Courses
                </Button>,
              ]
            }
          >
            <List.Item.Meta
              avatar={<Avatar src={item?.avatar} />}
              title={fullName(item)}
              description={contactInfo(item)}
            />
            <div style={{ float: 'left', width: '25%' }}>
              Rating:{' '}
              {npsLevel === 'promoters'
                ? userRates?.promotersRates[index]?.userRateCurrentMonth?.[
                    fieldType
                  ]
                : npsLevel === 'detractors'
                ? userRates?.detractorsRates[index]?.userRateCurrentMonth?.[
                    fieldType
                  ]
                : npsLevel === 'passives'
                ? userRates?.passivesRates[index]?.userRateCurrentMonth?.[
                    fieldType
                  ]
                : null}
              <br />
              {npsLevel === 'promoters'
                ? getStat(
                    userRates?.promotersRates[index]?.userRateCurrentMonth?.[
                      fieldType
                    ],
                    userRates?.promotersRates[index]?.userRateLastMonth?.[
                      fieldType
                    ]
                  )
                : npsLevel === 'detractors'
                ? getStat(
                    userRates?.detractorsRates[index]?.userRateCurrentMonth?.[
                      fieldType
                    ],
                    userRates?.detractorsRates[index]?.userRateLastMonth?.[
                      fieldType
                    ]
                  )
                : npsLevel === 'passives'
                ? getStat(
                    userRates?.passivesRates[index]?.userRateCurrentMonth?.[
                      fieldType
                    ],
                    userRates?.passivesRates[index]?.userRateLastMonth?.[
                      fieldType
                    ]
                  )
                : null}
            </div>
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default NPSUsersModal;
