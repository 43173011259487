import { ContainerOutlined, EditOutlined } from '@ant-design/icons';
import { JitsiApi } from '@shared/common';
import { MENTOR_ROLE } from '@shared/constants';
import { useAuthState, useMarkAttended, useMeeting } from '@shared/react';
import { message, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FeedbackBanner from '../Home/PendingFeedback/FeedbackBanner';

import './Client.css';
import MentorSidebarDrawer from './MentorSidebarDrawer';
import SalesSidebarDrawer from './SalesSidebarDrawer';
import StudentSidebarDrawer from './StudentSidebarDrawer';
import WhiteboardDrawer from './WhiteboardDrawer';

type MeetingClientParams = {
  meetingId: string;
};

const MeetingClient = () => {
  const [needsSecondFeedback, setNeedsSecondFeedback] = useState(false);
  const { meetingId } = useParams<MeetingClientParams>();
  const { isAdmin, roles } = useAuthState();
  const { data: meetingInfo } = useMeeting(meetingId);
  const [mentorDrawerOpen, setMentorDrawerOpen] = useState(false);
  const [studentDrawerOpen, setStudentDrawerOpen] = useState(false);
  const [whiteboardDrawerOpen, setWhiteboardDrawerOpen] = useState(false);
  const isMentor = roles.includes(MENTOR_ROLE);
  const isStudent = !isMentor;

  const [jitsiApi, setJitsiApi] = useState<JitsiApi>();
  const [showMissingStudent, setShowMissingStudent] = useState(false);

  const { mutate: markAttended } = useMarkAttended(meetingId);

  const deleteSiteLayout = () => {
    document.querySelector('.ant-layout-sider')?.remove();
    document.querySelector('.ant-layout-header')?.remove();
  };

  useEffect(() => {
    deleteSiteLayout();
    markAttended({ id: meetingId });
  }, []);

  useEffect(() => {
    if (!jitsiApi) return;
    if (!meetingInfo) return;

    let fiveMinutesAfterStart = moment(meetingInfo.startDate)
      .add(5, 'minutes')
      .diff(moment(), 'milliseconds');
    if (fiveMinutesAfterStart < 0) fiveMinutesAfterStart = 1000 * 60 * 5;

    jitsiApi.addEventListener('screenSharingStatusChanged', () => {
      jitsiApi.isVideoMuted().then((muted: boolean) => {
        if (muted) jitsiApi.executeCommand('toggleVideo');
      });
    });

    const timeout = setTimeout(() => {
      const participants = jitsiApi?.getNumberOfParticipants();
      if (participants === 1 && isMentor) setShowMissingStudent(true);
    }, fiveMinutesAfterStart);

    return () => {
      clearTimeout(timeout);
      if (jitsiApi) {
        jitsiApi.removeEventListener('screenSharingStatusChanged');
      }
    };
  }, [jitsiApi, meetingInfo]);

  const onMeetingEnd = () => {
    message.info('Meeting has ended');
    window.location.href = isStudent ? '/home' : '/mentor/home';
  };

  const jitsiConfig = {
    prejoinConfig: {
      enabled: false,
    },
    enableWelcomePage: false,
    readOnlyName: true,
    toolbarButtons: [
      'camera',
      'desktop',
      'microphone',
      'chat',
      'select-background',
      'settings',
      'hangup',
      '__end',
    ],
    p2p: {
      enabled: true,
      enableUnifiedOnChrome: true,
      disabledCodec: 'H264',
      preferredCodec: 'VP9',
    },
    constraints: {
      video: {
        height: {
          ideal: 720,
          max: 720,
          min: 240,
        },
      },
    },
    enableCalendarIntegration: false,
    enableClosePage: false,
  };

  const containerWidth =
    mentorDrawerOpen || studentDrawerOpen ? 'calc(100% - 500px)' : '100%';

  if (!meetingInfo)
    return (
      <p>
        Loading... If the meeting doesn't load, make sure you are using a
        Chromium-based browser or try refreshing.
      </p>
    );

  return (
    <div id="meeting-client">
      {isMentor && (
        <div>
          {needsSecondFeedback && (
            <FeedbackBanner width={mentorDrawerOpen ? '50%' : undefined} />
          )}
          <div
            id="mentor-sidebar-opener"
            onClick={() => setMentorDrawerOpen(true)}
          >
            <Tooltip placement="left" title="Mentor Tools">
              <ContainerOutlined />
            </Tooltip>
          </div>
          <MentorSidebarDrawer
            visible={mentorDrawerOpen}
            hide={() => setMentorDrawerOpen(false)}
            meetingId={meetingId}
          />
        </div>
      )}
      {isAdmin && (
        <div>
          <div
            id="sales-sidebar-opener"
            onClick={() => setStudentDrawerOpen(true)}
          >
            <Tooltip placement="left" title="Sales Tools">
              $
            </Tooltip>
          </div>
          <SalesSidebarDrawer
            visible={studentDrawerOpen}
            setVisible={setStudentDrawerOpen}
          />
        </div>
      )}
      {isStudent && (
        <div>
          <div
            id="student-sidebar-opener"
            onClick={() => setStudentDrawerOpen(true)}
          >
            <Tooltip placement="left" title="Student Tools">
              <ContainerOutlined />
            </Tooltip>
          </div>
          <StudentSidebarDrawer
            visible={studentDrawerOpen}
            setVisible={setStudentDrawerOpen}
          />
        </div>
      )}
      <div>
        <div
          id="whiteboard-sidebar-opener"
          onClick={() => setWhiteboardDrawerOpen(true)}
        >
          <Tooltip placement="left" title="Whiteboard Tools">
            <EditOutlined />
          </Tooltip>
        </div>
        <WhiteboardDrawer
          visible={whiteboardDrawerOpen}
          setVisible={setWhiteboardDrawerOpen}
        />
      </div>
    </div>
  );
};

export default MeetingClient;
