import {
  CourseSelector,
  MentorSelectorMeeting,
  StudentSelector,
} from '@components';
import { MEETING_STATUSES } from '@shared/constants';
import { DatePicker, Space, Form, Select } from 'antd';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

const { RangePicker } = DatePicker;

type Props = {
  searchParams: any;
  updateSearchParams: (params: Partial<any>) => void;
};

const useQuery = () => new URLSearchParams(useLocation().search);

const meetingStatuses = Object.entries(MEETING_STATUSES)
  .sort((a, b) => parseInt(a[0], 10) - parseInt(b[0], 10))
  .map(([key, value]) => value);

export const Filters = ({ searchParams, updateSearchParams }: Props) => {
  const query = useQuery();
  const studentName = query.get('userName');
  const mentorName = query.get('mentorName');
  const status = query.get('status');
  const course = query.get('course') ?? '';
  const rangeStart = query.get('rangeStart');
  const rangeEnd = query.get('rangeEnd');

  return (
    <div
      style={{
        padding: '22px',
        overflowX: 'auto',
      }}
    >
      <Space size="large">
        <div>
          <label>Course</label> <br />
          <CourseSelector
            optionPlaceholder="ALL"
            defaultValue={course}
            value={searchParams.course}
            onChange={(course) => updateSearchParams({ course })}
            style={{ width: 250 }}
          />
        </div>

        <div style={{ width: '250px', height: '54px' }}>
          <label>Status</label> <br />
          <Form.Item
            required
            rules={[{ required: true }]}
            name={['mentorData', 'status']}
          >
            <Select
              placeholder="Select a status"
              onChange={(status) => {
                updateSearchParams({ status });
              }}
              defaultValue={null}
            >
              <Select.Option value={null}>ALL</Select.Option>
              {meetingStatuses?.map((option: any, index) => (
                <Select.Option key={option} value={index}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div>
          <label>Student</label> <br />
          <StudentSelector
            value={
              searchParams.studentName
                ? {
                    value: searchParams.studentName,
                    label: searchParams.studentName,
                  }
                : undefined
            }
            onChange={(student) =>
              updateSearchParams({
                studentName: student.label,
              })
            }
            style={{ width: 250 }}
            showDeactivatedStudents={true}
          />
        </div>

        <div>
          <label>Mentor</label> <br />
          <MentorSelectorMeeting
            value={
              searchParams.mentorName
                ? {
                    value: searchParams.mentorName,
                    label: searchParams.mentorName,
                  }
                : undefined
            }
            onChange={(mentor) =>
              updateSearchParams({
                mentorName: mentor.label,
              })
            }
            style={{ width: 250 }}
          />
        </div>
        <div>
          <label>Date Range</label> <br />
          <RangePicker
            value={[
              moment(searchParams.rangeStart),
              moment(searchParams.rangeEnd),
            ]}
            onChange={(arr) => {
              const start =
                arr?.[0]?.startOf('day') || moment().subtract(1, 'month');
              const end = arr?.[1]?.endOf('day') || moment().add(1, 'month');

              updateSearchParams({
                rangeStart: start.toISOString(),
                rangeEnd: end.toISOString(),
              });
            }}
            style={{ width: 250 }}
          />
        </div>
      </Space>
    </div>
  );
};
