import { ToggleModalProps } from '@shared/common';
import { Card, Drawer } from 'antd';
import { DrawerProps } from 'antd/es/drawer';
import { UpcomingHomeworks } from '../reusables';

const StudentSidebarDrawer = ({ visible, setVisible }: ToggleModalProps) => {
  const DRAWER_SIZE: DrawerProps['width'] = '500px';

  return (
    <Drawer
      bodyStyle={{ paddingBottom: '200px' }}
      width={DRAWER_SIZE}
      title="Sidebar"
      placement="right"
      onClose={() => setVisible(false)}
      visible={visible}
      mask={false}
    >
      <Card title="Homework" style={{ marginBottom: '20px' }}>
        <UpcomingHomeworks />
      </Card>
    </Drawer>
  );
};

export default StudentSidebarDrawer;
