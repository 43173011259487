import { LoadingOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { HoverShinyEffect } from '@components';
import { USE_AUTH0 } from '@shared/frontendEnv';
import v3global from '@styles/v3/global-v3.module.scss';
import { message, Input, Alert } from 'antd';
import axios from 'axios';

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { COLORS } from '../../v3/global';

const PreOnboardingPage = () => {
  const $_GET: any = {};
  const parts = window.location.search.substr(1).split('&');

  for (let i = 0; i < parts.length; i++) {
    const temp = parts[i].split('=');
    $_GET[decodeURIComponent(temp[0])] = decodeURIComponent(temp[1]);
  }

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [id, setId] = useState<any>('');
  const [registrationToken, setRegistrationToken] = useState('');
  const [isRegistrationTokenValidated, setIsRegistrationTokenValidated] =
    useState(Boolean);

  const [authFlag] = useState<boolean>(USE_AUTH0 === '1');

  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);

  const validatePassword = (password: string): string[] => {
    const errors: string[] = [];

    if (!password.trim()) {
      errors.push('"Password" cannot be empty.');
    }

    if (!confirmPassword.trim()) {
      errors.push('"Confirm Password" cannot be empty.');
    }

    if (password !== confirmPassword) {
      errors.push('Passwords do not match');
    }

    if (password.length < 8) {
      errors.push('Password must be at least 8 characters long.');
    }

    // Check if the password contains at least one lowercase letter, one uppercase letter, one number, and one special character
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /\d/;
    const specialCharacterRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/;

    if (!lowercaseRegex.test(password)) {
      errors.push('Password must contain at least one lowercase letter.');
    }

    if (!uppercaseRegex.test(password)) {
      errors.push('Password must contain at least one uppercase letter.');
    }

    if (!numberRegex.test(password)) {
      errors.push('Password must contain at least one number.');
    }

    if (!specialCharacterRegex.test(password)) {
      errors.push('Password must contain at least one special character.');
    }

    return errors;
  };

  const handlePasswordChange = (newPassword: string) => {
    setPassword(newPassword);
  };

  const checkURLquery = async () => {
    const parts = window.location.search.substr(1).split('&');
    if (parts.length === 0) return;
    const $_GET: any = {};
    for (let i = 0; i < parts.length; i++) {
      const temp = parts[i].split('=');
      $_GET[decodeURIComponent(temp[0])] = decodeURIComponent(temp[1]);
    }
    setId($_GET['id']);
    setRegistrationToken($_GET['token']);

    await axios
      .post('/api/auth/validateRegistrationToken', $_GET)
      .then((response) => {
        setRegistrationToken(response.data.registrationToken);
      })
      .catch((err) => {
        setRegistrationToken('');
        message.error(err.response?.data?.message || err.message);
        console.error(err);
      })
      .finally(() => {
        setIsRegistrationTokenValidated(true);
      });
  };

  useEffect(() => {
    checkURLquery();
  }, []);

  const submitData = () => {
    const errors = validatePassword(password);
    setPasswordErrors(errors);

    if (errors.length > 0) {
      return;
    }

    setLoading(true);

    const data = {
      password,
      id,
      registrationToken,
    };

    axios
      .post('/api/auth/signup', data)
      .then(() => {
        if (!authFlag) {
          history.push('/');
        } else {
          message.success(
            'Account created successfully. Redirecting to login...'
          );
          setTimeout(() => {
            history.push('/');
          }, 500);
        }
      })
      .catch((err) => {
        message.error(err.response?.data?.message || err.message);
        console.error(err);
        setLoading(false);
      });
  };

  const action = loading ? (
    <LoadingOutlined />
  ) : (
    <button
      className={`${v3global.buttonPrimary} onboarding-btn`}
      onClick={submitData}
      style={{
        marginBottom: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <span>Get started</span>
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '8px',
        }}
      >
        <ArrowRightOutlined /> <HoverShinyEffect />
      </span>
    </button>
  );

  return registrationToken !== '' && isRegistrationTokenValidated ? (
    <div
      className={v3global.background}
      style={{ minHeight: '100vh' }}
      id="onboarding-container"
    >
      <div id="onboarding-info">
        <h2 className={v3global.text}>Welcome to Agora!</h2>
        <h3 className={v3global.text}>We're glad to have you here!</h3>
        <div
          id="onboarding-form"
          style={{ textAlign: 'left', marginTop: '30px' }}
        >
          <span>*Password:</span>
          <Input.Password
            style={{ marginBottom: '16px' }}
            placeholder="Password"
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
          />

          <span>*Confirm password:</span>
          <Input.Password
            style={{ marginBottom: '16px' }}
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />

          {passwordErrors.length > 0 && (
            <ul>
              {passwordErrors.map((error, index) => (
                <li style={{ color: COLORS.ERROR }} key={index}>
                  {error}
                </li>
              ))}
            </ul>
          )}
        </div>
        <br style={{ marginBottom: 10 }} />
        {action}
        <br style={{ marginBottom: 50 }} />
        <Alert
          type="info"
          description={
            <>
              If you're having trouble creating your account and need urgent
              access, please get in touch with us and we'll sort it.
            </>
          }
          showIcon
        />
      </div>
    </div>
  ) : (
    <div id="onboarding-container">
      <div id="onboarding-info">
        <h3>
          Link expired, please try to <a href="/">login</a>
        </h3>
        <Alert
          type="info"
          description={
            <>
              If you're having trouble logging in and need to urgently access
              your account, please get in touch with us and we'll sort it.
            </>
          }
          showIcon
        />
      </div>
    </div>
  );
};

export default PreOnboardingPage;
