import ECLTable from '@components/V3/ProfileModalStudents/ECLTable';
import HighSchoolTable from '@components/V3/ProfileModalStudents/HighSchoolTable';
import LanguageExamTable from '@components/V3/ProfileModalStudents/LanguageExamTable';
import UniversitiesTable from '@components/V3/ProfileModalStudents/UniversitiesTable';
import { useSaveUserProfile } from '@shared/react';
import SecondaryButton from '@utils/SecondaryButton';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './profile-modal-students.styles.scss';

type Props = {
  studentId: string;
  studentData: any;
  handleCurrentPage: (page: string) => void;
  selectedTab: string;
  handleSelectedTab: (tab: string) => void;
  refetchStudentData: () => void;
};

const ProfilePageStudents = ({
  studentId,
  studentData,
  handleCurrentPage,
  selectedTab,
  handleSelectedTab,
  refetchStudentData,
}: Props) => {
  const { mutate: saveUserProfile } = useSaveUserProfile(studentId, {
    onSuccess: () => {
      refetchStudentData();
      setIsEditStudentDetails(false);
    },
  });

  const [isEditStudentDetails, setIsEditStudentDetails] = useState(false);

  const [email, setEmail] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [country, setCountry] = useState<string | null>(null);
  const [parentName, setParentName] = useState<string | null>(null);
  const [parentEmail, setParentEmail] = useState<string | null>(null);
  const [parentPhone, setParentPhone] = useState<string | null>(null);

  // TODO Delete old edit profile details

  return (
    <div>
      {/*ENROLMENT INFO*/}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '8px',
          marginBottom: '8px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                lineHeight: '14px',
              }}
            >
              <span
                style={{ color: COLORS.TEXT_PRIMARY, marginBottom: '16px' }}
                className="edit-enrolment-section-subtitle"
              >
                Contact Info
              </span>
              <div
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    height: 'fit-content',
                    marginRight: '4px',
                  }}
                  className="edit-enrolment-details-label"
                >
                  Email:{' '}
                </span>
                {isEditStudentDetails ? (
                  <span>
                    <input
                      className="edit-enrolment-details-value block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
                      style={{
                        backgroundColor: 'transparent',
                        maxWidth: '160px',
                        height: '16px',
                        marginBottom: '0px',
                        fontSize: '12px',
                        lineHeight: '16px',
                      }}
                      value={email === null ? studentData?.email : email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </span>
                ) : (
                  <span
                    style={{ color: COLORS.TEXT_PRIMARY }}
                    className="edit-enrolment-details-value"
                  >
                    {studentData?.email ?? '-'}
                  </span>
                )}
              </div>
              <div
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    height: 'fit-content',
                    marginRight: '4px',
                  }}
                  className="edit-enrolment-details-label"
                >
                  Phone:{' '}
                </span>
                {isEditStudentDetails ? (
                  <span>
                    <input
                      className="edit-enrolment-details-value block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
                      style={{
                        backgroundColor: 'transparent',
                        maxWidth: '160px',
                        height: '16px',
                        marginBottom: '0px',
                        fontSize: '12px',
                        lineHeight: '16px',
                      }}
                      value={phone === null ? studentData?.phoneNumber : phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                  </span>
                ) : (
                  <span
                    style={{ color: COLORS.TEXT_PRIMARY }}
                    className="edit-enrolment-details-value"
                  >
                    {studentData?.phoneNumber ?? '-'}
                  </span>
                )}
              </div>
              <div
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    height: 'fit-content',
                    marginRight: '4px',
                  }}
                  className="edit-enrolment-details-label"
                >
                  City:{' '}
                </span>
                {isEditStudentDetails ? (
                  <span>
                    <input
                      className="edit-enrolment-details-value block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
                      style={{
                        backgroundColor: 'transparent',
                        maxWidth: '160px',
                        height: '16px',
                        marginBottom: '0px',
                        fontSize: '12px',
                        lineHeight: '16px',
                      }}
                      value={city === null ? studentData?.city : city}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                    />
                  </span>
                ) : (
                  <span
                    style={{ color: COLORS.TEXT_PRIMARY }}
                    className="edit-enrolment-details-value"
                  >
                    {studentData?.city ?? '-'}
                  </span>
                )}
              </div>
              <div
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    height: 'fit-content',
                    marginRight: '4px',
                  }}
                  className="edit-enrolment-details-label"
                >
                  Country:{' '}
                </span>
                {isEditStudentDetails ? (
                  <span>
                    <input
                      className="edit-enrolment-details-value block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
                      style={{
                        backgroundColor: 'transparent',
                        maxWidth: '160px',
                        height: '16px',
                        marginBottom: '0px',
                        fontSize: '12px',
                        lineHeight: '16px',
                      }}
                      value={country === null ? studentData?.country : country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                    />
                  </span>
                ) : (
                  <span
                    style={{ color: COLORS.TEXT_PRIMARY }}
                    className="edit-enrolment-details-value"
                  >
                    {studentData?.country ?? '-'}
                  </span>
                )}
              </div>
            </div>

            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                lineHeight: '14px',
              }}
            >
              <span
                style={{ color: COLORS.TEXT_PRIMARY, marginBottom: '16px' }}
                className="edit-enrolment-section-subtitle"
              >
                Parent Info
              </span>
              <div
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    height: 'fit-content',
                    marginRight: '4px',
                  }}
                  className="edit-enrolment-details-label"
                >
                  Parent Name:{' '}
                </span>
                {isEditStudentDetails ? (
                  <span>
                    <input
                      className="edit-enrolment-details-value block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
                      style={{
                        backgroundColor: 'transparent',
                        maxWidth: '160px',
                        height: '16px',
                        marginBottom: '0px',
                        fontSize: '12px',
                        lineHeight: '16px',
                      }}
                      value={
                        parentName === null
                          ? studentData?.parentData?.fullName
                          : parentName
                      }
                      onChange={(e) => {
                        setParentName(e.target.value);
                      }}
                    />
                  </span>
                ) : (
                  <span
                    style={{ color: COLORS.TEXT_PRIMARY }}
                    className="edit-enrolment-details-value"
                  >
                    {studentData?.parentData?.fullName ?? '-'}
                  </span>
                )}
              </div>
              <div
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    height: 'fit-content',
                    marginRight: '4px',
                  }}
                  className="edit-enrolment-details-label"
                >
                  Parent Email:{' '}
                </span>
                {isEditStudentDetails ? (
                  <span>
                    <input
                      className="edit-enrolment-details-value block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
                      style={{
                        backgroundColor: 'transparent',
                        maxWidth: '160px',
                        height: '16px',
                        marginBottom: '0px',
                        fontSize: '12px',
                        lineHeight: '16px',
                      }}
                      value={
                        parentEmail === null
                          ? studentData?.parentData?.email
                          : parentEmail
                      }
                      onChange={(e) => {
                        setParentEmail(e.target.value);
                      }}
                    />
                  </span>
                ) : (
                  <span
                    style={{ color: COLORS.TEXT_PRIMARY }}
                    className="edit-enrolment-details-value"
                  >
                    {studentData?.parentData?.email ?? '-'}
                  </span>
                )}
              </div>
              <div
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    height: 'fit-content',
                    marginRight: '4px',
                  }}
                  className="edit-enrolment-details-label"
                >
                  Parent Phone:{' '}
                </span>
                {isEditStudentDetails ? (
                  <span>
                    <input
                      className="edit-enrolment-details-value block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
                      style={{
                        backgroundColor: 'transparent',
                        maxWidth: '160px',
                        height: '16px',
                        marginBottom: '0px',
                        fontSize: '12px',
                        lineHeight: '16px',
                      }}
                      value={
                        parentPhone === null
                          ? studentData?.parentData?.phoneNumber
                          : parentPhone
                      }
                      onChange={(e) => {
                        setParentPhone(e.target.value);
                      }}
                    />
                  </span>
                ) : (
                  <span
                    style={{ color: COLORS.TEXT_PRIMARY }}
                    className="edit-enrolment-details-value"
                  >
                    {studentData?.parentData?.phoneNumber ?? '-'}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '36px',
            }}
          >
            {isEditStudentDetails ? (
              <div style={{ display: 'flex' }}>
                <SecondaryButton
                  onClick={() => {
                    setIsEditStudentDetails(false);
                    // handleCurrentPage('edit-profile-details')
                  }}
                  backgroundColor={COLORS.TRANSPARENT}
                  height="27px"
                  padding="6px 24px 6px 24px"
                  margin="0px 16px 0px 0px"
                >
                  <span
                    className="button-text"
                    style={{ color: COLORS.TEXT_PRIMARY }}
                  >
                    Cancel
                  </span>
                </SecondaryButton>
                <SecondaryButton
                  onClick={() => {
                    saveUserProfile({
                      email: email === null ? studentData?.email : email,
                      phoneNumber:
                        phone === null ? studentData?.phoneNumber : phone,
                      city: city === null ? studentData?.city : city,
                      country:
                        country === null ? studentData?.country : country,
                      parentData: {
                        fullName:
                          parentName === null
                            ? studentData?.parentData?.fullName
                            : parentName,
                        phoneNumber:
                          parentPhone === null
                            ? studentData?.parentData?.phoneNumber
                            : parentPhone,
                        email:
                          parentEmail === null
                            ? studentData?.parentData?.email
                            : parentEmail,
                      },
                    });
                  }}
                  backgroundColor={COLORS.WHITE}
                  height="27px"
                  padding="6px 24px 6px 24px"
                  margin="0px"
                >
                  <span className="button-text" style={{ color: COLORS.BLACK }}>
                    Save
                  </span>
                </SecondaryButton>
              </div>
            ) : (
              <SecondaryButton
                onClick={() => {
                  // setIsEditStudentDetails(true);
                  handleCurrentPage('edit-profile-details');
                }}
                backgroundColor={COLORS.WHITE}
                height="27px"
                padding="6px 24px 6px 24px"
                margin="0px"
              >
                <span className="button-text" style={{ color: COLORS.BLACK }}>
                  Edit
                </span>
              </SecondaryButton>
            )}
          </div>
        </div>
      </div>
      <div style={{ width: '100%', marginTop: '8px' }}>
        {/*TABS*/}
        <div style={{ marginBottom: '16px' }}>
          <nav
            style={{ overflowX: 'auto' }}
            className="flex space-x-4"
            aria-label="Tabs"
          >
            <span
              onClick={() => {
                handleSelectedTab('Universities');
              }}
              style={{
                cursor: 'pointer',
                color:
                  selectedTab === 'Universities'
                    ? COLORS.BLACK
                    : COLORS.TEXT_PRIMARY,
                background:
                  selectedTab === 'Universities'
                    ? COLORS.PRIMARY
                    : COLORS.DARK_GREY_ALT,
                borderRadius: '32px',
                paddingLeft: '24px',
                paddingRight: '24px',
                paddingTop: '4px',
                paddingBottom: '4px',
                height: '24px',
                fontSize: '11px',
                lineHeight: '15px',
              }}
              aria-current={selectedTab === 'Universities' ? 'page' : undefined}
            >
              Universities
            </span>

            <span
              onClick={() => {
                handleSelectedTab('High School');
              }}
              style={{
                cursor: 'pointer',
                color:
                  selectedTab === 'High School'
                    ? COLORS.BLACK
                    : COLORS.TEXT_PRIMARY,
                background:
                  selectedTab === 'High School'
                    ? COLORS.PRIMARY
                    : COLORS.DARK_GREY_ALT,
                borderRadius: '32px',
                paddingLeft: '24px',
                paddingRight: '24px',
                paddingTop: '4px',
                paddingBottom: '4px',
                height: '24px',
                fontSize: '11px',
                lineHeight: '15px',
              }}
              aria-current={selectedTab === 'High School' ? 'page' : undefined}
            >
              High School
            </span>

            <span
              onClick={() => {
                handleSelectedTab('ECL');
              }}
              style={{
                cursor: 'pointer',
                color:
                  selectedTab === 'ECL' ? COLORS.BLACK : COLORS.TEXT_PRIMARY,
                background:
                  selectedTab === 'ECL' ? COLORS.PRIMARY : COLORS.DARK_GREY_ALT,
                borderRadius: '32px',
                paddingLeft: '24px',
                paddingRight: '24px',
                paddingTop: '4px',
                paddingBottom: '4px',
                height: '24px',
                fontSize: '11px',
                lineHeight: '15px',
              }}
              aria-current={selectedTab === 'ECL' ? 'page' : undefined}
            >
              ECL
            </span>

            <span
              onClick={() => {
                handleSelectedTab('Language Exam');
              }}
              style={{
                cursor: 'pointer',
                color:
                  selectedTab === 'Language Exam'
                    ? COLORS.BLACK
                    : COLORS.TEXT_PRIMARY,
                background:
                  selectedTab === 'Language Exam'
                    ? COLORS.PRIMARY
                    : COLORS.DARK_GREY_ALT,
                borderRadius: '32px',
                paddingLeft: '24px',
                paddingRight: '24px',
                paddingTop: '4px',
                paddingBottom: '4px',
                height: '24px',
                fontSize: '11px',
                lineHeight: '15px',
              }}
              aria-current={
                selectedTab === 'Language Exam' ? 'page' : undefined
              }
            >
              Language Exam
            </span>
          </nav>
        </div>

        {selectedTab === 'Universities' ? (
          <UniversitiesTable
            handleCurrentPage={handleCurrentPage}
            studentData={studentData}
            refetchStudentData={refetchStudentData}
          />
        ) : selectedTab === 'High School' ? (
          <HighSchoolTable
            handleCurrentPage={handleCurrentPage}
            studentData={studentData}
            refetchStudentData={refetchStudentData}
          />
        ) : selectedTab === 'ECL' ? (
          <ECLTable
            handleCurrentPage={handleCurrentPage}
            studentData={studentData}
            refetchStudentData={refetchStudentData}
          />
        ) : selectedTab === 'Language Exam' ? (
          <LanguageExamTable
            handleCurrentPage={handleCurrentPage}
            studentData={studentData}
            refetchStudentData={refetchStudentData}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ProfilePageStudents;
