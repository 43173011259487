import { Lesson } from '../common';
import { ApiDescription, ApiData, RequestsMethod, Takeaway } from '../types';

export const getLesson: ApiDescription = {
  endpoint: '/lessons/:id',
  method: RequestsMethod.Get,
};
export type GetLesson = ApiData<unknown, Lesson>;

export const listLessonTakeaways: ApiDescription = {
  endpoint: '/lessons/:id/takeaways',
  method: RequestsMethod.Get,
};
export type ListLessonTakeaways = ApiData<unknown, Takeaway[]>;

export const markComplete: ApiDescription = {
  endpoint: '/lessons/:id/mark-complete',
  method: RequestsMethod.Post,
};
export type MarkComplete = ApiData<unknown, Lesson>;
