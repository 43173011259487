import { SalesOnboarding } from '@components';
import { ToggleModalProps } from '@shared/common';
import { Drawer } from 'antd';
import { DrawerProps } from 'antd/es/drawer';

const SalesSidebarDrawer = ({ visible, setVisible }: ToggleModalProps) => {
  const DRAWER_SIZE: DrawerProps['width'] = '500px';

  return (
    <Drawer
      bodyStyle={{ padding: 0, paddingBottom: '200px' }}
      width={DRAWER_SIZE}
      title="Sales Sidebar"
      placement="right"
      onClose={() => setVisible(false)}
      visible={visible}
      mask={false}
    >
      <SalesOnboarding />
    </Drawer>
  );
};

export default SalesSidebarDrawer;
