import { LoadingOutlined } from '@ant-design/icons';
import AgoraModal from '@components/V3/Utils/AgoraModal';
import { useResetAuth0Password } from '@shared/react';
import v3global from '@styles/v3/global-v3.module.scss';
import AgoraInput from '@utils/AgoraInput';
import { message } from 'antd';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';

type Props = {
  showModal: boolean;
  handleShowModal: () => void;
  userId: string;
};

const ResetPassowrdModal = ({ showModal, handleShowModal, userId }: Props) => {
  const { mutate, isLoading } = useResetAuth0Password({
    onSuccess: () => {
      handleShowModal();
      message.success('Password reset successfully!');
    },
    onError: (error: any) => {
      const errorMessage =
        error?.message ||
        error?.response?.data?.message ||
        error?.message ||
        'Failed to reset password. Please try again later.';
      message.error(errorMessage);
    },
  });

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);

  const handlePasswordChange = (newPassword: string) => {
    setPassword(newPassword);
  };

  const validatePassword = (password: string): string[] => {
    const errors: string[] = [];

    if (!password.trim()) {
      errors.push('"Password" cannot be empty.');
    }

    if (!confirmPassword.trim()) {
      errors.push('"Confirm Password" cannot be empty.');
    }

    if (password !== confirmPassword) {
      errors.push('Passwords do not match');
    }

    if (password.length < 8) {
      errors.push('Password must be at least 8 characters long.');
    }

    // Check if the password contains at least one lowercase letter, one uppercase letter, one number, and one special character
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /\d/;
    const specialCharacterRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/;

    if (!lowercaseRegex.test(password)) {
      errors.push('Password must contain at least one lowercase letter.');
    }

    if (!uppercaseRegex.test(password)) {
      errors.push('Password must contain at least one uppercase letter.');
    }

    if (!numberRegex.test(password)) {
      errors.push('Password must contain at least one number.');
    }

    if (!specialCharacterRegex.test(password)) {
      errors.push('Password must contain at least one special character.');
    }

    return errors;
  };

  const submitData = () => {
    const errors = validatePassword(password);
    setPasswordErrors(errors);

    if (errors.length > 0) {
      return;
    }

    setLoading(true);

    mutate({
      id: userId,
      password: password,
    });
  };

  const action = loading ? (
    <LoadingOutlined />
  ) : (
    <button
      className={`${v3global.buttonPrimary} onboarding-btn`}
      onClick={submitData}
      style={{
        marginBottom: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <span style={{ fontSize: '16px' }}>Reset Password</span>
    </button>
  );

  return (
    <div>
      <AgoraModal
        title={'Reset User Password'}
        title2={''}
        setModalOpen={() => handleShowModal()}
        isModalOpen={showModal}
        closable={true}
        width="58vw"
      >
        <div style={{ margin: '16px' }}>
          <div>
            <div
              className={v3global.background}
              style={{ backgroundColor: 'transparent' }}
              id="onboarding-container"
            >
              <div style={{ paddingTop: '0px' }} id="onboarding-info">
                <div id="onboarding-form" style={{ textAlign: 'left' }}>
                  <AgoraInput
                    htmlFor="password"
                    name="password"
                    id="password"
                    type="password"
                    placeholder="Password"
                    label="Password*"
                    margin="0px 0px 16px 0px"
                    value={password}
                    onChange={(e) => handlePasswordChange(e.target.value)}
                  />

                  <AgoraInput
                    htmlFor="confirmPassword"
                    name="confirmPassword"
                    id="confirmPassword"
                    type="password"
                    placeholder="Confirm Password"
                    label="Confirm Password*"
                    margin="0px 0px 16px 0px"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />

                  {passwordErrors.length > 0 && (
                    <ul>
                      {passwordErrors.map((error, index) => (
                        <li style={{ color: COLORS.ERROR }} key={index}>
                          {error}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <br style={{ marginBottom: 10 }} />
                {action}
              </div>
            </div>
          </div>
        </div>
      </AgoraModal>
    </div>
  );
};

export default ResetPassowrdModal;
