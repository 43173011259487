import './login-error-screen.scss';
import { useAuth0 } from '@auth0/auth0-react';
import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import { COLORS } from '../../../v3/global';

const LoginErrorView = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div
      style={{
        backgroundColor: 'black',
        width: '100vw',
        height: '100vh',
        padding: '80px 32px 80px 32px',
      }}
      className="scrollableContainer"
    >
      <div className="scrollableContent">
        <p style={{ color: COLORS.TEXT_PRIMARY }} className="loginErrorContent">
          <h1
            style={{
              color: '#fff',
              marginBottom: '40px',
              fontSize: '32px',
              textDecoration: 'underline',
            }}
          >
            Login Error
          </h1>
          <br />
          We regret to inform you that your account has been deactivated.
          <br />
          <br />
          If you believe this is an error, or have any questions, please get in
          touch with us
          <br />
          <br />
          <SecondaryButton onClick={() => loginWithRedirect()}>
            Take me to login page
          </SecondaryButton>
          <br />
          <br />
        </p>
      </div>
    </div>
  );
};

export default LoginErrorView;
