import USBigPrimary from '@assets/images/us_big_1.jpg';
import USBigPurple from '@assets/images/us_big_2.jpg';
import USBigGold from '@assets/images/us_big_3.jpg';
import React, { useEffect, useState } from 'react';
import { COLORS } from '../../../v3/global';
import './course-card.styles.scss';

type Props = {
  title?: string;
  subtitle?: string;
  color?: string;
  image?: any;
  imageWidth?: any;
  imageHeight?: any;
};

const CourseCard = ({
  title,
  subtitle,
  color,
  image,
  imageWidth,
  imageHeight,
}: Props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [hover, setHover] = useState(false);

  const maxDescriptionLength = 40;

  let isTruncated = false;

  if (subtitle != null) {
    isTruncated = subtitle.length > maxDescriptionLength;
  }

  let truncatedDescription = '';

  if (subtitle != null) {
    truncatedDescription = isTruncated
      ? `${subtitle.substring(0, maxDescriptionLength)}...`
      : subtitle;
  }

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        backgroundColor: hover ? COLORS.DARK_GREY : COLORS.COMPONENT_BACKGROUND,
        padding: '0px',
      }}
      className="course-card-container"
    >
      <div style={{ width: '100%' }} className="course-card-content">
        {image ? (
          <div
            style={{
              background: `url(${image ?? USBigGold})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '80px',
              height: '80px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-end',
              marginLeft: '16px',
              marginRight: '16px',
              borderRadius: '16px',
            }}
          ></div>
        ) : (
          <img
            className="course-image"
            style={{ width: imageWidth ?? '', height: imageHeight ?? '' }}
            src={
              image ??
              (title?.toLowerCase().includes('coach') ||
                title?.toLowerCase().includes('training'))
                ? USBigPrimary
                : title?.toLowerCase().includes('academic')
                ? USBigPurple
                : USBigGold
            }
            alt="course-image"
          />
        )}

        <div
          style={{
            height: '100%',
            width: '60%',
          }}
          className="course-card-text-container"
        >
          <div className="course-card-title">
            <div
              style={{
                color: COLORS.TEXT_PRIMARY,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              <span>{title}</span>
            </div>
          </div>

          <div className="course-card-subtitle">
            <div
              style={{
                color: COLORS.TEXT_SECONDARY,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: 'calc(100%)',
                textOverflow: 'ellipsis',
              }}
            >
              <span>{subtitle}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
