import styles from '@styles/components/form.module.scss';
import { useRef } from 'react';

const CustomUploader = ({ action, title }: { action: any; title?: string }) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  return (
    <>
      <div onClick={handleClick} className={styles.formUpload}>
        {title || 'Upload File'}
      </div>
      <input
        type="file"
        name="myImage"
        onChange={action}
        style={{ display: 'none' }}
        ref={hiddenFileInput}
      />
    </>
  );
};

export default CustomUploader;
