import {
  ApiData,
  ApiDescription,
  RequestsMethod,
  Course,
  ModuleStatus,
} from '../common';

export const getUserCourses: ApiDescription = {
  endpoint: '/v3/courses',
  method: RequestsMethod.Get,
};
export type GetUserCourses = ApiData<never, Course[]>;

export const softDeleteCourse: ApiDescription = {
  endpoint: '/v3/courses/:id/soft-delete',
  method: RequestsMethod.Delete,
};

export const resetDeletedStatusForCourses: ApiDescription = {
  endpoint: '/v3/course/reset-deleted-status-for-courses',
  method: RequestsMethod.Patch,
};
export type ResetDeletedStatusForCourses = ApiData<unknown, any>;

export type SoftDeleteCourse = ApiData<unknown, any>;

export const getCourse: ApiDescription = {
  endpoint: '/courses/:id',
  method: RequestsMethod.Get,
};
export type GetCourse = ApiData<unknown, Course>;

export const getCourseProgress: ApiDescription = {
  endpoint: '/courses/:id/progress',
  method: RequestsMethod.Get,
};
export type GetCourseProgress = ApiData<
  { courseId: string; studentId: string },
  {
    course: Course;
    completedLessonIds: string[];
    completedQuizzIds: string[];
  }
>;

export const getAllCourses: ApiDescription = {
  endpoint: '/v2/course/listAll',
  method: RequestsMethod.Get,
};
export type GetAllCourses = ApiData<unknown, Course[]>;

export const getAllCoursesLite: ApiDescription = {
  endpoint: '/v2/course/listAllTitles',
  method: RequestsMethod.Get,
};
export type GetAllCoursesLite = ApiData<unknown, Course[]>;

export const getCoursesByMentor: ApiDescription = {
  endpoint: '/v2/course/listByMentor',
  method: RequestsMethod.Get,
};
export type GetCoursesByMentor = ApiData<unknown, Course[]>;

export const getStudentEnrollmentStatus: ApiDescription = {
  endpoint: '/v2/course/student-enrollment-status',
  method: RequestsMethod.Get,
};

export type GetStudentEnrollmentStatus = ApiData<
  { courseId: string; studentId: string },
  { completedPerModule: ModuleStatus[] }
>;

export const getNextChapterFromModule: ApiDescription = {
  endpoint: '/v2/course/get-next-chapter',
  method: RequestsMethod.Get,
};

export const uploadCourseImage: ApiDescription = {
  endpoint: '/v2/course/upload-image/:id',
  method: RequestsMethod.Post,
};

export type UploadCourseImage = ApiData<unknown, any>;
