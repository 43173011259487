import TrashIcon from '@assets/icons/Icon-awesome-trash.png';
import JoinIcon from '@assets/icons/Icon-material-video-call.svg';
import { useModal } from '@hooks';
import { searchMyMeetings } from '@shared/apis/meetings.apis';
import { NewMeeting } from '@shared/common';
import { useDeleteMeeting, useUpdateNewMeeting } from '@shared/react';
import button from '@styles/components/reusables/buttons.module.scss';
import { message } from 'antd';
import React from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { COLORS } from '../../../../../v3/global';
import RescheduleModal from '../../Mentor/Calendar/RescheduleModal';
import MeetingListItemButton from './MeetingListItemButton';

interface JoinButtonProps {
  meeting: NewMeeting;
  hideJoinAndRescheduleBtn?: boolean;
  afterSave?: () => void;
}

const JoinButtonMentor: React.FC<JoinButtonProps> = ({
  meeting,
  hideJoinAndRescheduleBtn,
  afterSave,
}) => {
  const [RescheduledModal, showRescheduleModal] = useModal(RescheduleModal);
  const { mutate: attendMeeting } = useUpdateNewMeeting(meeting._id);

  const endMeetingHandler = () => {
    attendMeeting({
      _id: meeting._id,
      attended: { mentor: true, student: meeting.attended?.student },
    });
  };

  const queryClient = useQueryClient();

  const { mutate: deleteMeeting } = useDeleteMeeting(meeting._id, {
    onSuccess: () => {
      queryClient.refetchQueries(searchMyMeetings.endpoint);
      message.success('Meeting deleted successfully.');
    },
  });

  return (
    <div className={button.joinButtonMentorContainer}>
      {!hideJoinAndRescheduleBtn && (
        <>
          <span
            style={{
              color: COLORS.TEXT_PRIMARY,
            }}
            className={`${button.actionButton} gradient ${button.joinButtonMentorJoinContainer}`}
          >
            {meeting.meetingLink !== 'http://' ? (
              <MeetingListItemButton>
                <div
                  onClick={() => endMeetingHandler()}
                  className={button.joinButtonMentorTextAndIcon}
                >
                  <img src={JoinIcon} className={button.joinButtonMentorIcon} />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={meeting.meetingLink}
                    style={{ color: 'inherit' }}
                  >
                    Join meeting
                  </a>
                </div>
              </MeetingListItemButton>
            ) : (
              <MeetingListItemButton>
                <div
                  onClick={() => endMeetingHandler()}
                  className={button.joinButtonMentorTextAndIcon}
                >
                  <img src={JoinIcon} className={button.joinButtonMentorIcon} />
                  <Link
                    to={meeting.meetingLink ?? ''}
                    style={{ color: 'inherit' }}
                  >
                    Join meeting
                  </Link>
                </div>
              </MeetingListItemButton>
            )}
          </span>

          <MeetingListItemButton>
            <div
              onClick={() =>
                showRescheduleModal({
                  meetingId: meeting._id,
                  dateTime: meeting.startDate,
                  afterSave: afterSave && afterSave,
                })
              }
              className={button.joinButtonMentorRescheduleContainer}
            >
              <img
                src={JoinIcon}
                alt="Join meeting"
                className={button.joinButtonMentorIcon}
              />
              <div>Reschedule</div>
            </div>
          </MeetingListItemButton>
        </>
      )}
      <MeetingListItemButton>
        <div
          onClick={() => deleteMeeting({})}
          className={button.joinButtonMentorRescheduleContainer}
        >
          <img src={TrashIcon} className={button.joinButtonMentorIcon} />
          <span>Delete</span>
        </div>
      </MeetingListItemButton>
      {RescheduledModal}
    </div>
  );
};

export default JoinButtonMentor;
