import SectionTitle from '@components/Page/SectionTitle';
import { useQueryParamsState } from '@hooks';
import { useMyMentorEnrollments } from '@shared/react';
import { SearchMentorEnrollmentsParams } from '@shared/types';
import pageStyle from '@styles/components/reusables/page.module.scss';
import { Pagination } from 'antd';
import { useEffect } from 'react';
import './my-students-page.styles.scss';

import MyStudentsFilters from './MyStudentsFilters';
import MyStudentsTable from './StudentsTable/index';

const MyStudents = () => {
  const [searchParams, setSearchParams] =
    useQueryParamsState<SearchMentorEnrollmentsParams>({
      status: 'all',
    });

  const {
    data: myStudentsData,
    isLoading,
    refetch,
  } = useMyMentorEnrollments({
    ...searchParams,
    limit: searchParams.limit || 20,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div className={`${pageStyle.page} my-students-page-container`}>
      <div>
        <SectionTitle title="Your Students" />
        <div style={{ marginTop: '0.5rem', marginBottom: '2rem' }}>
          <MyStudentsFilters
            searchParams={searchParams}
            updateSearchParams={(data) =>
              setSearchParams({ ...searchParams, ...data })
            }
            totalStudents={myStudentsData?.total}
          />
        </div>
      </div>
      <MyStudentsTable
        isLoading={isLoading}
        studentsEnrollments={myStudentsData?.documents}
        refetch={refetch}
        // tableHeight={tableHeight}
      />

      <div style={{ textAlign: 'left', marginTop: 20 }}>
        <Pagination
          total={myStudentsData?.total}
          showTotal={(total: number) => `Total ${total} items`}
          defaultPageSize={20}
          defaultCurrent={1}
          onChange={(page, pageSize) => {
            setSearchParams({ ...searchParams, page, limit: pageSize });
          }}
        />
      </div>
    </div>
  );
};

export default MyStudents;
