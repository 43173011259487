import { ReactComponent as SnoozeIcon } from '@assets/icons/snooze-icon.svg';
import { useModal } from '@hooks';
import { Meeting, NewMeeting } from '@shared/common';
import { message, Modal } from 'antd';
import moment from 'moment';
import { FC } from 'react';
import { COLORS } from '../../../../v3/global';
import ProgressBar from './ModalsSteps/ProgressBar';
import SnoozeModal from './SnoozeModal';
import './pending-feedback.style.scss';

const MentorFeedbackModalTemplate: FC<{
  meeting: NewMeeting;
  visible: boolean;
  hasProgressBar?: boolean;
  totalSteps?: number;
  currentStep?: number;
  setVisible: (arg1: boolean) => void;
}> = ({
  meeting,
  visible: visibleFeedback,
  hasProgressBar,
  totalSteps,
  currentStep,
  children,
  setVisible,
}) => {
  const [snoozeModal, showSnoozeModal] = useModal(SnoozeModal);

  const handleSnooze = () => {
    const now = moment();
    const item = {
      value: 'true',
      expiry: now.add('24', 'h'),
    };
    localStorage.setItem(
      `snoozedFeedback-${meeting._id}`,
      JSON.stringify(item)
    );
    setVisible(false);
    message.success('Feedback snoozed for 24h');
  };

  return (
    <>
      <Modal
        visible={visibleFeedback}
        // closeIcon={<SnoozeIcon />}
        width="65rem"
        centered
        keyboard={false}
        closable={true}
        bodyStyle={{
          backgroundColor: COLORS.SECTION_BACKGROUND,
          borderRadius: '10px',
        }}
        onCancel={
          () =>
            // showSnoozeModal({
            //   okText: 'Snooze feedback',
            //   cancelText: 'No, take me back',
            //   handleOk: handleSnooze,
            // })
            setVisible(!visibleFeedback)
          // handleSnooze()
        }
        footer={null}
        className={'agora-modal-content'}
      >
        <div
          style={{
            minHeight: 450,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '2.5rem 4rem',
          }}
        >
          {children}
          {hasProgressBar && currentStep && currentStep > 0 && totalSteps ? (
            <ProgressBar totalSteps={totalSteps} currentStep={currentStep} />
          ) : null}
        </div>
      </Modal>
      {snoozeModal}
    </>
  );
};

export default MentorFeedbackModalTemplate;
