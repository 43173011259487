import { Homework, MentorFeedback, NewMeeting } from '../common';
import { ApiDescription, ApiData, RequestsMethod } from '../types';

export const needsFeedback: ApiDescription = {
  endpoint: '/after-meeting/needs-feedback',
  method: RequestsMethod.Get,
};

export type NeedsFeedback = ApiData<
  unknown,
  { meeting: NewMeeting; as: 'mentor' | 'student' }
>;

export const searchMyMeetings: ApiDescription = {
  endpoint: '/meetings/my',
  method: RequestsMethod.Get,
};
export type SearchMyMeetings = ApiData<
  {
    active?: boolean;
    limit?: number;
    page?: number;
    ended?: boolean;
    paid?: boolean;
    startDate?: boolean;
    sort?: string;
    excludeNoShow?: boolean;
    extraInfo?: boolean;
  },
  NewMeeting[]
>;

export const searchMySessionHistoryMeetings: ApiDescription = {
  endpoint: '/meetings/my-session-history',
  method: RequestsMethod.Get,
};
export type SearchMySessionHistoryMeetings = ApiData<
  {
    limit?: number;
    page?: number;
  },
  { meetings: NewMeeting[]; total: number; page: number; limit: number }
>;

export const getStudentMeetings: ApiDescription = {
  // TODO: we need to present studentId as a parameter not a id to clarify the purpose of the api.
  endpoint: '/meetings/student/:id',
  method: RequestsMethod.Get,
};
export type GetStudentMeetings = ApiData<
  {
    studentId: string;
  },
  NewMeeting[]
>;

export const getMeeting: ApiDescription = {
  endpoint: '/meeting/:id',
  method: RequestsMethod.Get,
};
export type GetMeeting = ApiData<unknown, NewMeeting>;

export const searchMeetings: ApiDescription = {
  endpoint: '/meetings',
  method: RequestsMethod.Get,
};
export type SearchMeetings = ApiData<
  {
    limit?: number;
    page?: number;
    student?: string;
    mentor?: string;
    ended?: boolean;
    paid?: boolean;
  },
  NewMeeting[]
>;

export const editMeeting: ApiDescription = {
  endpoint: '/meeting/:id',
  method: RequestsMethod.Put,
};

export type EditMeeting = ApiData<Partial<NewMeeting>, NewMeeting>;

export const registerMentorFeedback: ApiDescription = {
  endpoint: '/meeting/:id/mentor-feedback',
  method: RequestsMethod.Post,
};
export type RegisterMentorFeedback = ApiData<MentorFeedback, unknown>;

export const markAttended: ApiDescription = {
  endpoint: '/meeting/:id/attended',
  method: RequestsMethod.Post,
};

export type MarkAttended = ApiData<unknown, unknown>;

export const markMeetingAsCompleted: ApiDescription = {
  endpoint: '/meetings/update-session',
  method: RequestsMethod.Patch,
};

export type MarkMeetingAsCompleted = ApiData<
  Partial<{
    meetingId: string;
    status: number;
  }>,
  NewMeeting
>;

export const createNewMeeting: ApiDescription = {
  endpoint: '/meeting-new',
  method: RequestsMethod.Post,
};

export type CreateNewMeeting = ApiData<
  {
    name: string;
    enrollmentId: string;
    mentorId: string;
    students: any;
    startDate: string;
    meetingLink: string;
    agenda?: string;
  },
  NewMeeting
>;

export const deleteNewMeeting: ApiDescription = {
  endpoint: '/meeting-new/:id',
  method: RequestsMethod.Delete,
};

export type DeleteNewMeeting = ApiData<unknown, unknown>;

export const updateNewMeeting: ApiDescription = {
  endpoint: '/meeting-new/:id',
  method: RequestsMethod.Patch,
};

export type UpdateNewMeeting = ApiData<
  Partial<NewMeeting> & {
    mentorId?: string;
  },
  { message: string }
>;

export const setMeetingForReschedule: ApiDescription = {
  endpoint: '/meeting/:id/set-for-reschedule',
  method: RequestsMethod.Post,
};
export type SetMeetingForReschedule = ApiData<unknown, unknown>;

export const getHomeworkByMeeting: ApiDescription = {
  endpoint: '/meeting-new/:id/homework',
  method: RequestsMethod.Get,
};

export type GetHomeworkByMeeting = ApiData<never, Homework>;

export const markMeetingAsEnded: ApiDescription = {
  endpoint: '/meeting-new/:id/ended',
  method: RequestsMethod.Patch,
};

export type MarkMeetingAsEnded = ApiData<{ ended: boolean }, NewMeeting>;

export const getNoOfCompletedMeetings: ApiDescription = {
  endpoint: '/meetings/completed/:id',
  method: RequestsMethod.Get,
};

export type GetNoOfCompletedMeetings = ApiData<
  unknown,
  { completedMeetings: number }
>;

export const getMentorStatisticsByFeedback: ApiDescription = {
  endpoint: '/meeting-new/statistics/:id',
  method: RequestsMethod.Get,
};

export type GetMentorStatisticsByFeedback = ApiData<
  unknown,
  {
    noOfAllMeetings: number;
    noOfFeedbackMeetings: number;
  }
>;

export const countCompletedMeetingForMentor: ApiDescription = {
  endpoint: '/meeting-new/mentor/:id/sessions',
  method: RequestsMethod.Get,
};

export type CountCompletedMeetingForMentor = ApiData<
  unknown,
  {
    noOfSessions: number;
  }
>;

export const searchAllMeetings: ApiDescription = {
  endpoint: '/meeting-new/all',
  method: RequestsMethod.Get,
};

export type SearchAllMeetings = ApiData<
  {
    limit?: number;
    page?: number;
    studentName?: string;
    mentorName?: string;
    status?: number | null;
    rangeStart?: string;
    rangeEnd?: string;
  },
  {
    meetings: NewMeeting[];
    total: number;
    page: number;
    limit: number;
  }
>;

export const getMeetingsByDay: ApiDescription = {
  endpoint: '/meeting-new/day/:date',
  method: RequestsMethod.Get,
};

export type GetMeetingsByDay = ApiData<unknown, NewMeeting[]>;
