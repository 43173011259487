import StudentDetails from '@modules/Mentor/MyStudents/StudentDetails';
import { MINUTES_45 } from '@shared/constants';
import { useMyMentorEnrollments } from '@shared/react';
import { Alert, Collapse } from 'antd';
import axios from 'axios';

import { useEffect, useState } from 'react';
import authHeader from '../../../services/auth-header';

const { Panel } = Collapse;

type Props = {
  meetingId: string;
};

const MentorSidebar = (props: Props) => {
  const { meetingId } = props;
  const [meetingData, setMeetingData] = useState<any>({ students: true });
  const [loading, setLoading] = useState(true);
  const [showScheduleMeetingReminder, setShowScheduleMeetingReminder] =
    useState(false);

  const { data, isLoading } = useMyMentorEnrollments({ status: 'active' });
  const enrollment = data?.documents.find(
    (enrollment) =>
      enrollment.course._id === meetingData.course?._id &&
      enrollment.user._id === meetingData.students?.[0]._id
  );

  const getMeetingData = async (meetingId: string) => {
    const newMeetingData = await axios.get(`/api/meeting/${meetingId}`, {
      headers: authHeader(),
    });
    setMeetingData(newMeetingData.data);
    setTimeout(() => setLoading(false), 1000);
  };

  useEffect(() => {
    getMeetingData(meetingId);
    const reminderTimer = setTimeout(() => {
      setShowScheduleMeetingReminder(true);
    }, MINUTES_45);
    return () => {
      clearTimeout(reminderTimer);
    };
  }, []);

  if (loading || isLoading) return <p>Loading...</p>;

  return (
    <div>
      {showScheduleMeetingReminder && (
        <Alert
          type="info"
          showIcon
          message="Don't forget to schedule the next meeting before leaving."
          banner
          closable
        />
      )}
      <Collapse bordered={false} defaultActiveKey={['plan']}>
        <Panel
          header={<span style={{ color: '#1a2258' }}>Student Details</span>}
          key="details"
          style={{ backgroundColor: 'white', color: '#1a2258' }}
        >
          {enrollment ? (
            <StudentDetails
              studentId={meetingData.students[0]._id}
              enrollmentId={meetingData.course._id}
            />
          ) : (
            'No enrollment found with this student'
          )}
        </Panel>
      </Collapse>
    </div>
  );
};

export default MentorSidebar;
