import { ReactComponent as ClockIcon } from '@assets/icons/clock-icon.svg';
import styles from '@styles/components/reusables/modal.module.scss';
import { AutoComplete } from 'antd';
import moment, { Moment } from 'moment';
import { FC } from 'react';
import './custom-form.styles.scss';

type Props = {
  time: string;
  handleChange: any;
  handleFocus: any;
  date?: Moment;
  disablePastHours?: boolean;
  className?: string;
  onBlur?: (e: any) => void;
};

function CustomTimePicker({
  time,
  handleChange,
  handleFocus,
  date,
  disablePastHours,
  className,
  onBlur,
}: Props) {
  const timeArray = [];
  // create time list
  for (let i = 0; i <= 23; i++) {
    const hour = `${i}:00`;
    const half = `${i}:30`;
    timeArray.push(moment(hour, 'H:mm').format('HH:mm'));
    timeArray.push(moment(half, 'H:mm').format('HH:mm'));
  }

  const isDisabledTime = (time: string) => {
    const isToday = date && moment(date)?.isSame(moment(), 'day');
    if (isToday) {
      if (time && moment(time, 'HH:mm') < moment()) {
        return true;
      }
    }
    return false;
  };

  return (
    <AutoComplete
      value={time}
      notFoundContent={<div style={{ height: '0' }}></div>}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={onBlur}
      showSearch
      allowClear={true}
      className={`${className} agora-custom-time-picker`}
      style={{
        backgroundColor: 'transparent',
        fontFamily: 'Open Sans, sans-serif',
        position: 'relative',
        width: '100%',
      }}
    >
      {timeArray.map((timeOption, index: number) => (
        <AutoComplete.Option
          className={styles.newMeetingModalSelectTime}
          disabled={
            isDisabledTime && disablePastHours && isDisabledTime(timeOption)
          }
          key={index}
          value={timeOption}
          style={{ fontFamily: 'Open Sans, sans-serif' }}
        >
          {timeOption}
        </AutoComplete.Option>
      ))}
    </AutoComplete>
  );
}

// ! use only outside Form.Item
export const CustomTimePickerIcon: FC<{ timepickerFocus: boolean }> = ({
  timepickerFocus,
}) => (
  <div className={styles.newMeetingModalSelectTimeIcon}>
    {timepickerFocus && <ClockIcon />}
  </div>
);

export default CustomTimePicker;
