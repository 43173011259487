import axios from 'axios';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { storage } from '../UniversalStorage';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      // TODO: Delete this after refactoring is complete
      queryFn: async ({ queryKey }) => {
        let url = `/api/${queryKey[0]}`;
        queryKey
          .slice(1)
          .forEach((key) => (url = url.replace('$', key as string)));

        const { data } = await axios.get(url, {
          headers: {
            'x-access-token': storage.getItem('authToken'),
            Authorization: `Bearer ${storage.getItem('auth0token')}`,
            'impersonate-user-id': storage.getItem('impersonate-user-id') ?? '',
          },
        });
        return data;
      },
    },
  },
});

type Props = {
  showWebDevTools?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

const ReactQueryProvider = ({ children, showWebDevTools }: Props) => {
  return (
    <QueryClientProvider client={queryClient}>
      {showWebDevTools && <ReactQueryDevtools initialIsOpen={false} />}
      {children}
    </QueryClientProvider>
  );
};

export default ReactQueryProvider;
