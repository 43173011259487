import { ArrowRightOutlined } from '@ant-design/icons';
import { MentorSelector } from '@components';
import { MentorSelectorValue } from '@components/Selectors/MentorSelector';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { CreateEnrollment } from '@shared/apis/enrollments.apis';
import { subjectsOptions } from '@shared/constants';
import {
  useCreateEnrollment,
  useGetAllCourses,
  useGetEnrollments,
  useStudentDetails,
} from '@shared/react';
import { CloseModalProps } from '@shared/types';
import styles from '@styles/components/reusables/modal.module.scss';
import {
  Button,
  Col,
  DatePicker,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
} from 'antd';
import { COLORS } from 'apps/agora/src/v3/global';
import { Moment } from 'moment';
import moment from 'moment/moment';
import React, { useState } from 'react';

type Props = CloseModalProps & {
  userId: string;
};

const FormItem: React.FC<{ title: string }> = ({ title, children }) => {
  return (
    <Row align="middle">
      <Col flex="0 1 200px">{title}</Col>
      <Col flex="1">{children}</Col>
    </Row>
  );
};

const CreateEnrollmentModal: React.FC<Props> = ({ visible, hide, userId }) => {
  const [selectedCourse, setSelectedCourse] = useState<null | string>(null);
  const [selectedSubject, setSelectedSubject] = useState<null | string>(null);
  const [selectedMentor, setSelectedMentor] = useState<MentorSelectorValue>({
    choice: {
      label: 'TBD',
      value: 'TBD',
    },
  });
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [estimatedEndDate, setEstimatedEndDate] = useState<Moment | null>(
    moment().add(1, 'years')
  );
  const [nrSessions, setNrSessions] = useState(10);

  const { data: courses } = useGetAllCourses();
  const { data: userData, refetch: refetchUserData } =
    useStudentDetails(userId);
  const { refetch: refetchUserEnrollments } = useGetEnrollments(userId);

  const { mutate: createEnrollment, isLoading: creatingEnrollment } =
    useCreateEnrollment({
      onSuccess: () => {
        refetchUserData();
        refetchUserEnrollments();
        message.success('Enrollment created successfully');
        setSelectedCourse(null);
        hide();
      },
    });

  const handleEnroll = () => {
    if (!selectedCourse || !selectedSubject) {
      message.error('Please select a course and a subject');
      return;
    }

    const course = courses?.find((course) => course._id === selectedCourse);
    if (!course) {
      message.error('Course not found!');
      return;
    }

    if (!startDate) {
      message.error('Please select a start date');
      return;
    }

    const data: CreateEnrollment['payload'] = {
      userId,
      courseId: selectedCourse,
      subject: selectedSubject,
      mentor: 'none',
      startDate: startDate?.toISOString(),
      estimatedEndDate: estimatedEndDate?.toISOString(),
      numberOfSessions: nrSessions,
    };

    // Add mentor
    if (selectedMentor?.choice?.value === 'TBD') {
      data.mentor = 'TBD';
    } else {
      const mentor = selectedMentor?.user;
      if (!mentor) {
        message.error('Unexpected error: Mentor not defined!');
        return;
      }

      data.mentor = {
        _id: mentor.value,
        fullName: mentor.label,
      };
    }

    createEnrollment(data);
  };

  return (
    <Modal
      visible={visible}
      onCancel={hide}
      footer={false}
      closable={false}
      width={900}
      style={{ animationDuration: '0s' }}
      bodyStyle={{
        padding: 0,
        minHeight: '90vh',
        backgroundColor: COLORS.SECTION_BACKGROUND,
      }}
    >
      <div className={styles.modalSecondaryHeader}>
        <ChevronLeftIcon width={25} cursor="pointer" onClick={hide} />
        Create a new enrollment
      </div>

      <div
        className={styles.modalSecondaryBody}
        style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
      >
        <FormItem title="Course">
          <Select
            showSearch
            value={selectedCourse}
            onSelect={setSelectedCourse}
            disabled={creatingEnrollment}
            placeholder="Select a course"
            optionFilterProp="children"
            style={{ width: '100%' }}
            filterOption={(input: any, option: any) =>
              option &&
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {courses?.map((course: any) => (
              <Select.Option key={course._id} value={course._id}>
                {course.title}
              </Select.Option>
            ))}
          </Select>
        </FormItem>

        <FormItem title="Subject">
          <Select
            showSearch
            value={selectedSubject}
            onSelect={setSelectedSubject}
            disabled={creatingEnrollment}
            placeholder="Select a subject"
            optionFilterProp="children"
            style={{ width: '100%' }}
            filterOption={(input: any, option: any) =>
              option &&
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {subjectsOptions &&
              subjectsOptions.map((subject: any) => (
                <Select.Option key={subject} value={subject}>
                  {subject}
                </Select.Option>
              ))}
          </Select>
          {!!userData?.studySubjects?.length && (
            <h5>
              Student's subjects:{' '}
              <span>{userData?.studySubjects.join(', ')}</span>
            </h5>
          )}
        </FormItem>

        <FormItem title="Mentor">
          <MentorSelector
            disabled={creatingEnrollment}
            onChange={(data) => {
              setSelectedMentor(data);
            }}
            value={selectedMentor}
          />
        </FormItem>

        <FormItem title="Number of sessions">
          {/* 
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore */}
          <InputNumber min={0} value={nrSessions} onChange={setNrSessions} />
        </FormItem>

        <FormItem title="Start date">
          <DatePicker
            value={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </FormItem>

        <FormItem title="Estimated end date">
          <DatePicker
            value={estimatedEndDate}
            onChange={(date) => setEstimatedEndDate(date)}
          />
        </FormItem>

        <div className={styles.modalFooterActions}>
          <Button type="primary" onClick={handleEnroll}>
            <ArrowRightOutlined /> Enroll
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateEnrollmentModal;
