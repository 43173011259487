import { CloseModalProps } from '@shared/common';
import { useMentorData } from '@shared/react';
import { Collapse, Drawer } from 'antd';

import PaidMeetingsTable from './PaidMeetingsTable';
import UnpaidAmount from './UnpaidAmount';
import UnpaidMeetingsTable from './UnpaidMeetingsTable';

type Props = CloseModalProps & {
  mentorId: string;
};

const MentorPaymentDetailsDrawer = ({ visible, hide, mentorId }: Props) => {
  const { data: mentor } = useMentorData(mentorId);

  return (
    <Drawer
      title={
        mentor?.fullName
          ? `${mentor?.fullName} Payment Details`
          : 'Payment Details'
      }
      visible={visible}
      onClose={hide}
      width={736}
    >
      <UnpaidAmount mentorId={mentorId} />

      <Collapse>
        <Collapse.Panel header="Unpaid Amount Breakdown" key="unpaid-breakdown">
          <UnpaidMeetingsTable mentorId={mentorId} />
        </Collapse.Panel>
        <Collapse.Panel header="Paid Amount Breakdown" key="paid-breakdown">
          <PaidMeetingsTable mentorId={mentorId} />
        </Collapse.Panel>
      </Collapse>
    </Drawer>
  );
};

export default MentorPaymentDetailsDrawer;
