import { ExclamationCircleFilled } from '@ant-design/icons';
import UploadInvoiceModal from '@components/UploadInvoiceModal/UploadInvoiceModal';
import AgoraSpinner from '@components/V3/Utils/AgoraSpinner';
import { useModal } from '@hooks';
import EditMeetingModal from '@modules/Admin/Meetings/EditMeetingModal/EditMeetingModal';
import authHeader from '@services/auth-header';
import { NewMeeting } from '@shared/common';
import {
  ExtraServiceStatus,
  MEETING_STATUSES,
  MeetingStatus,
  ReportStatus,
  SessionStatus,
} from '@shared/constants';
import { BASE_URL } from '@shared/frontendEnv';
import { useMarkMeetingAsCompleted } from '@shared/react';
import rowStyles from '@styles/components/activity-report-row.module.scss';
import styles from '@styles/components/table.module.scss';
import { Checkbox, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

interface Props {
  selectedDate: any;
  report: any;
  completedNumber?: number;
  isEven: boolean;
  handleSelected: (report: any) => void;
  selectAll: boolean;
  selectedItems: any[];
  handleRowTouched: () => void;
  setSelectedReportId: (id: string) => void;
  setShowSideBySideModal: (show: boolean) => void;
  setShowElectronicInvoiceModal: (show: boolean) => void;
}

const ActivityReportRow: React.FC<Props> = ({
  selectedDate,
  report,
  isEven,
  handleSelected,
  selectAll,
  selectedItems,
  handleRowTouched,
  setSelectedReportId,
  setShowSideBySideModal,
  setShowElectronicInvoiceModal,
}) => {
  const [selectedRow, setSelectedRow] = useState(false);
  const [invoiceUploaded, setInvoiceUploaded] = useState(!!report.invoiceId);
  const [showSecondRow, setShowSecondRow] = useState(false);
  const [showDownloadActivityReport, setShowDownloadActivityReport] =
    useState<boolean>(!!report.activityReportId);

  const [showElectronicInvoice] = useState(
    report.status === ReportStatus.INVOICE_ACCEPTED && invoiceUploaded
  );

  const [showSideBySide] = useState(
    report.status >= ReportStatus.INVOICE_UPLOADED &&
      report.status !== ReportStatus.PAID &&
      invoiceUploaded &&
      showDownloadActivityReport &&
      !showElectronicInvoice
  );

  const [showUploadInvoice, setShowUploadInvoice] = useState(
    report.status >= ReportStatus.AWAIT_INVOICE &&
      report.status !== ReportStatus.PAID
  );
  const [showRegenerateReport, setShowRegenerateReport] = useState(
    report.status !== ReportStatus.PAID
  );

  const [markedAsCompleted, setMarkedAsCompleted] = useState<string | any>([]);
  const [isLoadingRegenerateReport, setIsLoadingRegenerateReport] =
    useState(false);

  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  const getStatus = (status: MeetingStatus) => {
    return MEETING_STATUSES[status];
  };

  const handleSetInvoiceUploaded = (value: any) => {
    setInvoiceUploaded(value);
  };

  const [Modal, show] = useModal(UploadInvoiceModal, {
    reportId: report._id,
    setInvoiceUploaded: handleSetInvoiceUploaded,
    handleRowTouched: handleRowTouched,
  });

  const classes = `${styles.gridTableRow} ${
    isEven ? styles.gridTableRowEven : styles.gridTableRowOdd
  }`;

  const invoiceStatus = ReportStatus[report.status].replaceAll('_', ' ');

  const saveByteArrayFile = (
    blob: any,
    report: any,
    isActivityReport: boolean
  ) => {
    const url = window.URL.createObjectURL(blob.data);
    const link = document.createElement('a');
    link.href = url;

    const monthName = moment(selectedDate)
      .locale('ro')
      .format('MMMM')
      .toLocaleUpperCase();

    const fileName = `${
      isActivityReport ? 'Activity Report' : 'Factura mentor'
    } ${monthName} ${report?.mentor?.fullName}.pdf`;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  const handleDownloadInvoice = async (report: any) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/report/${report._id}/download-invoice`,
        { headers: authHeader(), responseType: 'blob' }
      );
      message.success('Invoice downloaded successfully');
      saveByteArrayFile(response, report, false);
    } catch (error: any) {
      console.log('error: ', error?.response?.status);
      if (error?.response?.status === 404) {
        message.error('Report was not found');
      }
    }
  };

  const handleDownloadActivityReport = async (report: any) => {
    setIsLoadingDownload(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/report/${report._id}/download-activity-report`,
        { headers: authHeader(), responseType: 'blob' }
      );
      message.success('Activity Report downloaded successfully');
      saveByteArrayFile(response, report, true);
    } catch (error: any) {
      console.log('error: ', error?.response?.status);
      if (error?.response?.status === 404) {
        message.error('Report was not found');
      }
    } finally {
      setIsLoadingDownload(false);
    }
  };

  const handleRegenerateReport = async (report: any) => {
    setIsLoadingRegenerateReport(true);
    try {
      const newReport = await axios.post(
        `${BASE_URL}/api/report/${report._id}/regenerate`,
        {},
        { headers: authHeader() }
      );

      message.success('Report regenerated successfully');
      if (newReport.data.activityReportId) {
        setShowDownloadActivityReport(true);
      }
    } catch (error: any) {
      message.error(error.response.data.message);
    } finally {
      setIsLoadingRegenerateReport(false);
    }
    handleRowTouched();
  };

  const handleSelect = () => {
    handleSelected(report);
    setSelectedRow(!selectedRow);
  };

  const { mutate: markMeetingAsCompleted } = useMarkMeetingAsCompleted({
    onSuccess: (meeting) => {
      message.success('Session updated successfully');
      handleRowTouched();
    },
  });

  const [showEditMeetingModal, setShowEditMeetingModal] = useState(false);
  const [meeting, setMeeting] = useState<NewMeeting>();

  const editClickHandler = (record: NewMeeting) => {
    setShowEditMeetingModal(true);
    setMeeting(record);
  };

  const session = JSON.parse(
    '{\n' +
      '    "enrollment": {\n' +
      '        "_id": "63f4c090eb339e001004a480",\n' +
      '        "title": "CoachMe US",\n' +
      '        "color": "#0693e3",\n' +
      '        "courseId": "60ed987210382c001122201b"\n' +
      '    },\n' +
      '    "mentor": {\n' +
      '        "_id": "63f36f53f1f70b0012dbf9c0",\n' +
      '        "fullName": "Tinu Mentor"\n' +
      '    },\n' +
      '    "attended": {\n' +
      '        "mentor": true,\n' +
      '        "student": false\n' +
      '    },\n' +
      '    "mentorFeedback": {\n' +
      '        "message": "\\n\\n    Student engagement: Unsatisfactory \\n\\n    Student is frequently rescheduling our sessions, Student forgets about our scheduled sessions \\n\\n\\n    Student progress: On track \\n\\n    Student completed all the steps so far, Student being aware of the application process timeline \\n\\n\\n    0\\n    "\n' +
      '    },\n' +
      '    "status": 5,\n' +
      '    "ended": true,\n' +
      '    "_id": "63fdc0f2704f5014b665f47f",\n' +
      '    "name": "CoachMe US Session 4",\n' +
      '    "students": [\n' +
      '        {\n' +
      '            "_id": "619d0304dc76cd0010c9ff04",\n' +
      '            "fullName": "Horea Lazar",\n' +
      '            "phoneNumber": "+40754499657"\n' +
      '        }\n' +
      '    ],\n' +
      '    "startDate": "2023-02-28T09:00:00.000Z",\n' +
      '    "feedback": [],\n' +
      '    "googleEventId": "dkuqfsce5p4v1734m31532hh7k",\n' +
      '    "createdAt": "2023-02-28T08:53:08.500Z",\n' +
      '    "updatedAt": "2023-02-28T10:55:46.706Z",\n' +
      '    "__v": 0\n' +
      '}'
  );

  return (
    <>
      {/*FIRST ROW*/}
      <div
        className={`${rowStyles.clickableItem} ${classes} ${styles.gridTableRowFirst} `}
        onClick={() => {
          setShowSecondRow(!showSecondRow);
        }}
      >
        <Checkbox
          checked={selectedItems.includes(report)}
          onClick={(event) => {
            event.stopPropagation();
            handleSelect();
          }}
        />
      </div>
      <div
        onClick={() => setShowSecondRow(!showSecondRow)}
        className={`${rowStyles.clickableItem} ${classes}`}
      >
        {report?.mentor?.fullName}
        {report?.sessions?.some((session: any) => session?.status === 9) ? (
          <ExclamationCircleFilled className={rowStyles.exclamationCircle} />
        ) : null}
      </div>
      <div
        onClick={() => setShowSecondRow(!showSecondRow)}
        className={`${rowStyles.clickableItem} ${classes}`}
      >
        {invoiceStatus}
      </div>
      <div
        onClick={() => setShowSecondRow(!showSecondRow)}
        className={`${rowStyles.clickableItem} ${classes}`}
      >
        {report?.noOfCompletedSessions}
      </div>
      <div
        onClick={() => setShowSecondRow(!showSecondRow)}
        className={`${rowStyles.clickableItem} ${classes}`}
      >
        {report?.noOfActiveStudents}
      </div>
      <div
        onClick={() => setShowSecondRow(!showSecondRow)}
        className={`${rowStyles.clickableItem} ${classes}`}
      >
        {report?.hubspotCurrency} {report?.paymentAmountInHC?.toFixed(2)}
      </div>
      <div
        onClick={() => setShowSecondRow(!showSecondRow)}
        className={`${rowStyles.clickableItem} ${classes}`}
      >
        {report?.invoiceCurrency}{' '}
        {report?.paymentAmountInExtraService?.toFixed(2)}
      </div>

      <div
        onClick={() => setShowSecondRow(!showSecondRow)}
        className={`${rowStyles.clickableItem} ${classes}`}
      >
        {report?.invoiceCurrency} {report?.paymentAmountInIC?.toFixed(2)}
      </div>

      <div
        style={{
          justifyContent: 'center',
          paddingTop: '8px',
          paddingBottom: '8px',
          minHeight: '120px',
        }}
        className={`${rowStyles.optionsContainer} ${classes} ${styles.gridTableRowLast}`}
        onClick={() => setShowSecondRow(!showSecondRow)}
      >
        {showDownloadActivityReport && (
          <span
            onClick={(event) => {
              event.stopPropagation();
              handleDownloadActivityReport(report);
            }}
            className={rowStyles.clickableItem}
          >
            {isLoadingDownload ? (
              <AgoraSpinner useBlack={true} fontSize={22} margin="0" />
            ) : (
              'Download Report'
            )}
          </span>
        )}

        {showRegenerateReport && (
          <span
            onClick={(event) => {
              event.stopPropagation();
              handleRegenerateReport(report);
            }}
            style={{
              cursor: isLoadingRegenerateReport ? 'default' : 'pointer',
              color: isLoadingRegenerateReport ? 'gray' : 'inherit',
            }}
          >
            {isLoadingRegenerateReport ? 'Loading...' : 'Regenerate Report'}
          </span>
        )}
        {invoiceUploaded && (
          <span
            onClick={(event) => {
              event.stopPropagation();
              handleDownloadInvoice(report);
            }}
            className={rowStyles.clickableItem}
          >
            Download Invoice
          </span>
        )}
        {Modal}
        {showUploadInvoice && (
          <span
            onClick={(event) => {
              event.stopPropagation();
              show({
                reportId: report._id,
                setInvoiceUploaded: handleSetInvoiceUploaded,
                handleRowTouched,
              });
            }}
            className={rowStyles.clickableItem}
          >
            {report.invoiceId ? 'Override Invoice' : 'Upload Invoice'}
          </span>
        )}
        {showSideBySide ? (
          <span
            onClick={(event) => {
              event.stopPropagation();
              setSelectedReportId(report._id);
              setShowSideBySideModal(true);
              setShowElectronicInvoiceModal(false);
            }}
            className={rowStyles.clickableItem}
          >
            View Side-by-Side
          </span>
        ) : showElectronicInvoice ? (
          <span
            onClick={(event) => {
              event.stopPropagation();
              setSelectedReportId(report._id);
              setShowElectronicInvoiceModal(true);
              setShowSideBySideModal(false);
            }}
            className={rowStyles.clickableItem}
          >
            Verify E-Invoice
          </span>
        ) : null}
      </div>

      {/*SECOND EXTRA ROW*/}
      <div style={{ gridColumn: '2/-1', minHeight: '10px' }}>
        {showSecondRow && (
          <div style={{ margin: '20px 0' }}>
            {/*HEADER*/}
            <h1 style={{ marginTop: '32px' }}>Meetings</h1>

            <div className={rowStyles.headerContainer}>
              <span style={{ width: '25%' }}>Session</span>
              <span style={{ width: '25%' }}>Date</span>
              <span style={{ width: '20%' }}>Student</span>
              <span style={{ width: '10%' }}>Payment</span>
              <span style={{ width: '10%' }}>Status</span>
              <span style={{ width: '10%' }}>Actions</span>
            </div>

            {/*DATA ROWS*/}
            {report?.sessions?.map(
              (session: any | number | string, index: number) => {
                return (
                  session !== null && (
                    <div className={rowStyles.rowContainer}>
                      <span style={{ width: '25%' }}>
                        {session?.status !== 9 ||
                        markedAsCompleted?.includes(session?._id) ? null : (
                          <ExclamationCircleFilled
                            className={rowStyles.exclamationCircleFilled}
                          />
                        )}
                        #{index + 1}. {session?.enrollment?.title}
                      </span>
                      <span style={{ width: '25%' }}>
                        {moment(session?.startDate).format(
                          'DD MMM YYYY, HH:MM'
                        )}
                      </span>
                      <span
                        style={{
                          width: '20%',
                          cursor: 'pointer',
                          color: 'rgb(51, 169, 171)',
                        }}
                        onClick={() => editClickHandler(session)}
                      >
                        {session?.students[0]?.fullName}
                      </span>
                      <span
                        style={{
                          width: '10%',
                          color:
                            session?.status === 7 ||
                            session?.status === 8 ||
                            session?.status === 11
                              ? 'inherit'
                              : 'rgb(207, 19, 34)',
                        }}
                      >
                        {session?.status === 7 ||
                        session?.status === 8 ||
                        session?.status === 11 ? (
                          <>
                            {report?.rate?.amount ?? '0'}{' '}
                            {report?.hubspotCurrency}
                          </>
                        ) : (
                          <>0 {report?.hubspotCurrency}</>
                        )}
                      </span>
                      <span style={{ width: '10%' }}>
                        {markedAsCompleted.includes(session?._id)
                          ? 'Completed'
                          : getStatus(session?.status)}
                      </span>
                      <span style={{ width: '10%', color: 'rgb(88, 88, 88)' }}>
                        {session?.status !== 9 ||
                        markedAsCompleted.includes(session?._id) ? null : (
                          <span
                            onClick={() => {
                              markMeetingAsCompleted({
                                meetingId: session?._id,
                                status: SessionStatus.COMPLETED_ADMIN,
                              });
                            }}
                            className={rowStyles.markAsCompleted}
                          >
                            Mark as completed
                          </span>
                        )}
                      </span>
                    </div>
                  )
                );
              }
            )}

            {/*EXTRA SERVICES ROW*/}
            <h1 style={{ marginTop: '32px' }}>Extra Services</h1>

            <div className={rowStyles.headerContainer}>
              <span style={{ width: '20%' }}>Mentor</span>
              <span style={{ width: '20%' }}>Extra Activity</span>
              <span style={{ width: '20%' }}>Description</span>
              <span style={{ width: '20%' }}>Amount</span>
              <span style={{ width: '20%' }}>Status</span>
            </div>
            {report?.extraServices?.map(
              (extraService: any | number | string, index: number) => {
                return (
                  session !== null && (
                    <div className={rowStyles.rowContainer}>
                      <span style={{ width: '20%' }}>
                        {extraService?.mentor?.fullName}
                      </span>
                      <span style={{ width: '20%' }}>
                        {extraService?.serviceType.charAt(0).toUpperCase() +
                          extraService?.serviceType.slice(1)}
                      </span>
                      <span style={{ width: '20%' }}>
                        {extraService?.description}
                      </span>
                      <span
                        style={{
                          width: '20%',
                        }}
                      >
                        {extraService?.amount} {extraService?.currency}
                      </span>
                      <span style={{ width: '20%' }}>
                        {ExtraServiceStatus[extraService?.status]}
                      </span>
                    </div>
                  )
                );
              }
            )}
          </div>
        )}
      </div>
      {showEditMeetingModal && (
        <EditMeetingModal
          meeting={meeting!}
          isModalOpen={showEditMeetingModal}
          setModalOpen={setShowEditMeetingModal}
        />
      )}
    </>
  );
};

export default ActivityReportRow;
