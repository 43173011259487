import {
  RenderRoutes,
  SalesOnboarding,
  ResetUserActivationLink,
} from '@components';

const Sales = () => {
  return (
    <RenderRoutes
      routes={[
        // { path: '/new-user', component: NewUserPage },
        {
          path: '/reset-user-activation-link',
          component: ResetUserActivationLink,
        },
        // { path: '/new-meeting', component: NewSalesMeetingPage },
        { path: '/onboarding', component: SalesOnboarding },
        // { path: '/automated-plans', component: AutomatedPlans },
        // { path: '/plan-pdf', component: StudentPlanPdf },
      ]}
      redirectPath="/home"
    />
  );
};

export default Sales;
