import { userManagementApis, usersApis } from '../../apis';
import {
  genericMutation,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';

export const usePromoteUser = genericMutation<userManagementApis.PromoteUser>(
  userManagementApis.promoteUser
);

export const useUpdateMyAccount =
  genericMutation<userManagementApis.UpdateMyAccount>(
    userManagementApis.updateMyAccount,
    usersApis.me.endpoint
  );

export const useClearUserRoles = genericMutation<userManagementApis.ClearRoles>(
  userManagementApis.clearRoles,
  usersApis.searchUsers.endpoint
);

export const useSetActiveStatus =
  genericMutation<userManagementApis.SetActiveStatus>(
    userManagementApis.setActiveStatus
  );

export const useChangeUserTestState =
  genericMutation<userManagementApis.ChangeUserTestState>(
    userManagementApis.changeUserTestState
  );

export const useSendActivationLink =
  genericMutation<userManagementApis.SendActivationLink>(
    userManagementApis.sendActivationLink
  );

export const useResetAuth0Password =
  genericMutation<userManagementApis.ResetAuth0Password>(
    userManagementApis.resetAuth0Password
  );

export const useCheckDriveStatus =
  genericQueryWithParams<userManagementApis.CheckDriveStatus>(
    userManagementApis.checkDriveStatus
  );
