import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import AgoraInput from '@utils/AgoraInput';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './profile-modal-students.styles.scss';
import { useSaveUserProfile } from '@shared/react';

type Props = {
  handleCurrentPage: (page: string) => void;
  studentId: string;
  refetchEnrollments: () => any;

  studentData: any;
  refetchStudentData: () => void;
};

const EditProfileDetailsPage = ({
  handleCurrentPage,
  studentId,
  refetchEnrollments,

  studentData,
  refetchStudentData,
}: Props) => {
  const { mutate: saveUserProfile } = useSaveUserProfile(studentId, {
    onSuccess: () => {
      refetchStudentData();
      handleCurrentPage('student-profile');
    },
  });

  const [email, setEmail] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [country, setCountry] = useState<string | null>(null);
  const [parentName, setParentName] = useState<string | null>(null);
  const [parentEmail, setParentEmail] = useState<string | null>(null);
  const [parentPhone, setParentPhone] = useState<string | null>(null);

  return (
    <div style={{ marginTop: '8px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}></div>

      <h3
        style={{ color: COLORS.TEXT_SECONDARY, marginBottom: '24px' }}
        className="modal-page-section-title"
      >
        Contact Info
      </h3>

      <AgoraInput
        htmlFor="email"
        name="email"
        id="email"
        type="email"
        placeholder="email@example.com"
        label="Email"
        margin="0px 0px 16px 0px"
        value={email === null ? studentData?.email : email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />

      <AgoraInput
        htmlFor="phone"
        name="phone"
        id="phone"
        type="text"
        placeholder="004 0711 222 333"
        label="Phone"
        margin="0px 0px 16px 0px"
        value={phone === null ? studentData?.phoneNumber : phone}
        onChange={(e) => {
          setPhone(e.target.value);
        }}
      />

      <div
        style={{
          marginBottom: '24px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <AgoraInput
          htmlFor="city"
          name="city"
          id="city"
          type="text"
          placeholder="Cluj"
          label="City"
          width="calc(50% - 16px)"
          value={city === null ? studentData?.city : city}
          onChange={(e) => {
            setCity(e.target.value);
          }}
        />

        <AgoraInput
          htmlFor="country"
          name="country"
          id="country"
          type="text"
          placeholder="Romania"
          label="Country"
          width="calc(50% - 16px)"
          value={country === null ? studentData?.country : country}
          onChange={(e) => {
            setCountry(e.target.value);
          }}
        />
      </div>

      <h3
        style={{ color: COLORS.TEXT_SECONDARY, marginBottom: '24px' }}
        className="modal-page-section-title"
      >
        Parent Info
      </h3>

      <AgoraInput
        htmlFor="parentName"
        name="parentName"
        id="parentName"
        type="text"
        placeholder="Dragos Popescu"
        label="Parent Name"
        margin="0px 0px 16px 0px"
        value={
          parentName === null ? studentData?.parentData?.fullName : parentName
        }
        onChange={(e) => {
          setParentName(e.target.value);
        }}
      />

      <AgoraInput
        htmlFor="parentEmail"
        name="parentEmail"
        id="parentEmail"
        type="text"
        placeholder="email@example.com"
        label="Parent Email"
        margin="0px 0px 16px 0px"
        value={
          parentEmail === null ? studentData?.parentData?.email : parentEmail
        }
        onChange={(e) => {
          setParentEmail(e.target.value);
        }}
      />

      <AgoraInput
        htmlFor="parentPhone"
        name="parentPhone"
        id="parentPhone"
        type="text"
        placeholder="004 0711 222 333"
        label="Parent Phone"
        margin="0px 0px 32px 0px"
        value={
          parentPhone === null
            ? studentData?.parentData?.phoneNumber
            : parentPhone
        }
        onChange={(e) => {
          setParentPhone(e.target.value);
        }}
      />

      <SecondaryButton
        onClick={() => {
          saveUserProfile({
            email: email === null ? studentData?.email : email,
            phoneNumber: phone === null ? studentData?.phoneNumber : phone,
            city: city === null ? studentData?.city : city,
            country: country === null ? studentData?.country : country,
            parentData: {
              fullName:
                parentName === null
                  ? studentData?.parentData?.fullName
                  : parentName,
              phoneNumber:
                parentPhone === null
                  ? studentData?.parentData?.phoneNumber
                  : parentPhone,
              email:
                parentEmail === null
                  ? studentData?.parentData?.email
                  : parentEmail,
            },
          });
        }}
        backgroundColor={COLORS.WHITE}
        height="32px"
        padding="4px 10px 4px 10px"
        margin="12px 0 0 0"
        width="100%"
      >
        <span className="button-text" style={{ color: COLORS.BLACK }}>
          Save
        </span>
      </SecondaryButton>
    </div>
  );
};

export default EditProfileDetailsPage;
