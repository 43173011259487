import {
  LoadingOutlined,
  CheckCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Alert, Button, message } from 'antd';
import { useEffect, useState } from 'react';

const SpeedTestPage = () => {
  const [loading, setLoading] = useState(true);
  const [isOptimal, setIsOptimal] = useState(false);
  const [error, setError] = useState('');
  const [goTo, setGoTo] = useState('');

  const [joining, setJoining] = useState(false);

  const testSpeed = () => {
    const navigatorInstance: any = navigator;
    const networkInformation: any = navigatorInstance.connection;
    if (!navigator.onLine) {
      setError(
        "Your internet connection appears to be offline. This page might be stored in your browser's cache, or you might be using an unsupported browser."
      );
      return false;
    }
    if (!navigatorInstance.connection || !networkInformation.downlink) {
      setError(
        'Your browser does not let us check your connection speed. You may still join the call, but some features might not be available.'
      );
      return false;
    }
    if (networkInformation.downlink < 2) {
      setError(
        'Your internet connection appears to be slow, even for low quality video. Try switching to a different network or moving closer to the router.'
      );
      return false;
    }
    if (networkInformation.downlink < 4) {
      setError(
        'Your internet connection might be slow for high quality video. Try switching to a different network or moving closer to the router.'
      );
      return false;
    }
    return true;
  };

  const checkURLquery = () => {
    const parts = window.location.search.substr(1).split('&');

    if (parts.length === 0) return;

    const $_GET: any = {};
    for (let i = 0; i < parts.length; i++) {
      const temp = parts[i].split('=');
      $_GET[decodeURIComponent(temp[0])] = decodeURIComponent(temp[1]);
    }

    if ($_GET['id']) {
      setGoTo($_GET['id']);
    } else {
      message.error('No meeting specified. Joining might not work.');
    }
  };

  const joinCall = () => {
    setJoining(true);
    const url = `/meeting/${goTo}`;
    window.location.href = url;
  };

  useEffect(() => {
    checkURLquery();
    setTimeout(() => {
      setIsOptimal(testSpeed());
      setLoading(false);
    }, 2000);
  }, []);

  if (loading)
    return (
      <div>
        <h3>Checking your connection...</h3>
        <LoadingOutlined style={{ fontSize: '36px' }} />
        <br />
        <p>
          A strong internet connection is recommended for this call. We're
          checking your connection to ensure everything will work smoothly.
        </p>
        <small>
          This shouldn't take long. If this page seems unresponsive or you can't
          join your call, please contact us via the button in the bottom right
          corner.
        </small>
      </div>
    );

  return (
    <div>
      <h2>
        {isOptimal ? 'Your connection is good!' : 'There might be some issues'}
      </h2>
      {isOptimal ? (
        <CheckCircleOutlined style={{ fontSize: '36px' }} />
      ) : (
        <WarningOutlined style={{ fontSize: '36px' }} />
      )}
      <br />
      <p>{isOptimal ? 'Click the button below to join the meeting.' : error}</p>
      {!isOptimal && (
        <Alert
          type="info"
          style={{ marginBottom: '20px' }}
          message={
            <>
              Meetings work best in{' '}
              <a
                href="https://www.google.com/chrome/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Chrome
              </a>{' '}
              or any{' '}
              <a
                href="https://ungoogled-software.github.io/ungoogled-chromium-binaries/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Chromium-based browser
              </a>
              . We strongly encourage you to use one of these browsers.
            </>
          }
        />
      )}
      <Button
        onClick={joinCall}
        loading={joining}
        type={isOptimal ? 'primary' : 'default'}
      >
        Join {!isOptimal ? 'anyway' : ''}
      </Button>
      {!isOptimal ? (
        <Button
          onClick={() => window.location.reload()}
          type="primary"
          style={{ marginLeft: 20 }}
        >
          Try again
        </Button>
      ) : null}
    </div>
  );
};

export default SpeedTestPage;
