import { CloseCircleFilled } from '@ant-design/icons';
import arrowDown from '@assets/icons/input-arrow-expand.svg';
import { useDebounce } from '@hooks';
import { SelectOption } from '@shared/common';
import { STUDENT_ROLE } from '@shared/common';
import { useSearchUsers } from '@shared/react';
import { Select, Spin } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import AgoraInputError from '../AgoraInputError';
import AgoraInputLabel from '../AgoraInputLabel';
import './agora-student-selector.styles.scss';

type Props = {
  value?: SelectOption;
  onChange?: (value: SelectOption) => void;
  className?: string;
  isValid?: boolean;
  isTouched?: boolean;
  isRequired?: boolean;
  label?: string;
  errorText?: string;
};

const AgoraStudentSelector = ({
  value,
  onChange,
  className,
  isValid,
  isTouched,
  isRequired,
  label,
  errorText,
}: Props) => {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    if (value) setInternalValue(value);
  }, [value]);

  const setValues = (val: SelectOption) => {
    if (onChange) onChange(val);
    if (!value) setInternalValue(val);
  };

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const { data: studentsResponse, isLoading: studentsLoading } = useSearchUsers(
    {
      roles: [STUDENT_ROLE],
      search: debouncedSearch,
      isDezactivated: 'unchecked',
      limit: 20,
    }
  );

  const options = (studentsResponse?.users || []).map((s) => ({
    label: s?.fullName,
    value: s._id,
  }));

  return (
    <div
      className={classNames(
        'agora-student-selector',
        className,
        isValid && isTouched
          ? 'agora-student-selector--valid'
          : !isValid && isTouched
          ? 'agora-student-selector--error'
          : ''
      )}
    >
      {label && (
        <AgoraInputLabel
          label={label}
          isRequired={isRequired}
          isTouched={isTouched}
          isValid={isValid}
        />
      )}

      <Select
        labelInValue
        showSearch
        optionFilterProp="label"
        clearIcon={
          <div className="clear-icon">
            <CloseCircleFilled className="close-icon" />
          </div>
        }
        suffixIcon={
          <img
            className="suffix-icon"
            src={arrowDown}
            alt="Select a student."
          />
        }
        placeholder={
          <div className="placeholder">
            <span>Search to select</span>
            <span>
              <img
                className="placeholder-image"
                src={arrowDown}
                alt="Select a student."
              />
            </span>
          </div>
        }
        options={options}
        onSearch={setSearch}
        notFoundContent={studentsLoading ? <Spin size="small" /> : null}
        value={internalValue}
        onChange={setValues}
      />

      {!isValid && isTouched && errorText && (
        <AgoraInputError errorText={errorText} />
      )}
    </div>
  );
};

export default AgoraStudentSelector;
