import { useMyUpcomingHomeworks } from '@shared/react';
import { documentUrl } from '@shared/utils/string';
import { List, Space } from 'antd';

const UpcomingHomeworks = () => {
  const { data, isLoading } = useMyUpcomingHomeworks();

  return (
    <List
      loading={isLoading}
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <Space>
            *
            <a href={documentUrl(item.fileId)} target="_blank" rel="noreferrer">
              {item.title}
            </a>
          </Space>
        </List.Item>
      )}
    />
  );
};

export default UpcomingHomeworks;
