import { Environment } from './types';

const baseUrls = {
  development: 'http://localhost:8080',
  staging: 'https://staging.agora.upgrade.education',
  CI: 'https://ci.agora.upgrade.education',
  production: 'https://agora.upgrade.education',
};

const domains = {
  development: 'localhost:8080',
  staging: 'staging.agora.upgrade.education',
  CI: 'ci.agora.upgrade.education',
  production: 'agora.upgrade.education',
};

export const AUTH0_CLIENT_ID = process.env.NX_AUTH0_CLIENT_ID;

export const AUTH0_DOMAIN = process.env.NX_AUTH0_DOMAIN;

export const AUTH0_AUDIENCE = process.env.NX_AUTH0_AUDIENCE;

export const ENVIRONMENT = process.env.NX_ENVIRONMENT as Environment;

export const BASE_URL = baseUrls[ENVIRONMENT];
export const DOMAIN = domains[ENVIRONMENT];

export const isDevelopment = ENVIRONMENT === 'development';
export const isStaging = ENVIRONMENT === 'staging';
export const isCi = ENVIRONMENT === 'CI';

export const AUTH0_CONNECTION = process.env.NX_AUTH0_CONNECTION;

export const USE_AUTH0 = process.env.NX_USE_AUTH0 ?? '1';

export const MAIN_WEBSITE_DOMAIN = 'upgrade.education';

export const MIXPANEL_TOKEN = process.env.NX_MIXPANEL_TOKEN;

export const FIRST_UPLOAD_INVOICE_DAY = Number(
  process.env.NX_FIRST_UPLOAD_INVOICE_DAY
);
export const LAST_UPLOAD_INVOICE_DAY = Number(
  process.env.NX_LAST_UPLOAD_INVOICE_DAY
);
