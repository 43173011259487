import { ReactComponent as CloseIcon } from '@assets/icons/close-icon.svg';
import styles from '@styles/components/mentor-feedback.module.scss';
import modalStyles from '@styles/components/reusables/modal.module.scss';
import { FC, useState } from 'react';

const FeedbackBanner: FC<{ width?: string }> = ({ width }) => {
  const [visible, setVisible] = useState(true);

  return (
    <div
      className={styles.feedbackBanner}
      style={{
        width: width,
        display: `${visible ? 'block' : 'none'}`,
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p style={{ fontWeight: 'bold', marginBottom: '0' }}>
          Quick reminder: Extended feedback needed for this session!
        </p>
        <button
          onClick={() => setVisible(false)}
          className={modalStyles.modalCloseIcon}
        >
          <CloseIcon />
        </button>
      </div>
      <p style={{ marginBottom: '0' }}>
        We advise to keep this lesson 45 minutes long and spare 15 minutes at
        the end of the lesson to complete the mandatory feedback.
      </p>
    </div>
  );
};

export default FeedbackBanner;
