import { BASE_URL } from '@shared/frontendEnv';
import { useRef, useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import './board.css';
import { ClearIcon } from './boardIcons';
import { EraserIcon } from './boardIcons';
import { PenIcon } from './boardIcons';
import { UndoIcon } from './boardIcons';
import { RedoIcon } from './boardIcons';
import eraserCursor from './eraserCursor.svg';
// the other person's move doesnt get in the history

type Drawing = {
  color: string;
  width: number;
  socketUrl: string;
  socket?: Socket<any>;
  waiting?: boolean;
  history: Array<any>;
  historyStep: number;
  finishedStep: boolean;
};

type Tool = {
  handleClick: any;
  icon: JSX.Element;
  hoverName: string;
  classes?: string;
};

const CanvasTool = ({ handleClick, icon, hoverName, classes }: Tool) => {
  return (
    <div className="tool-container">
      <button className={`tool-icon ${classes}`} onClick={handleClick}>
        {icon}
      </button>
      <div className="tool-hover">{hoverName}</div>
    </div>
  );
};

const WhiteBoard = () => {
  const [cursor, setCursor] = useState('crosshair');
  const prevColor = useRef('');
  const canvasRef = useRef<any>();
  const contextRef = useRef<any>();

  const { current: canvasDetails } = useRef<Drawing>({
    color: '#000000',
    width: 2,
    socketUrl: '/',
    history: [],
    historyStep: 0,
    finishedStep: true,
  });

  useEffect(() => {
    canvasRef.current.height = window.innerHeight - 30;
    canvasRef.current.width = window.innerWidth;
    contextRef.current = canvasRef.current?.getContext('2d');
  }, []);

  // recurrent while drawing
  const onRecurrentSave = () => {
    canvasDetails.finishedStep = false;
    if (!canvasDetails.waiting) {
      const base64EncodedUrl = canvasRef.current.toDataURL('image/png');
      const data = {
        base64EncodedUrl,
        finishedStep: false,
        actionType: 'drawing',
      };
      canvasDetails.socket && canvasDetails.socket.emit('image-data', data);
      canvasDetails.waiting = true;
      setTimeout(() => {
        canvasDetails.waiting = false;
      }, 100);
    }
  };

  // instant saving
  const onInstantSave = (actionType: string, finishedStep: boolean) => {
    const base64EncodedUrl = canvasRef.current.toDataURL('image/png');
    const data = {
      base64EncodedUrl,
      finishedStep,
      actionType,
    };
    canvasDetails.socket && canvasDetails.socket.emit('image-data', data);
  };

  // START helpers
  // when drawing while in the history (using undo), all further steps are deleted (cannot redo)
  const deleteRedosHistory = () => {
    if (canvasDetails.history.length > canvasDetails.historyStep) {
      const newHistory = canvasDetails.history.slice(
        0,
        canvasDetails.historyStep
      );
      canvasDetails.history = [...newHistory];
    }
  };

  const contextClearRect = () => {
    contextRef.current.clearRect(
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
  };

  const contextDrawImage = (img: HTMLImageElement) => {
    contextRef.current.drawImage(
      img,
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
  };

  const drawImgOnCanvas = (
    base64EncodedUrl: string,
    actionType: string,
    userType: string
  ) => {
    contextClearRect();
    const img = new Image();
    img.onload = function () {
      contextDrawImage(img);
      // emit saving only from the emitter
      // if finished drawing (mouseUp), it will be added to the receiver's history
      if (userType === 'emitter') {
        if (actionType === 'finishedDraw') {
          onInstantSave(actionType, true);
        } else {
          onInstantSave(actionType, false);
        }
      }
    };
    img.src = base64EncodedUrl;
  };

  const addCanvasToHistory = () => {
    const base64EncodedUrl = canvasRef.current.toDataURL('image/png');
    canvasDetails.history = [...canvasDetails.history, base64EncodedUrl];
    canvasDetails.historyStep++;
  };

  const changeColor = (newColor: string) => {
    canvasDetails.color = newColor;
    setCursor('crosshair');
    canvasDetails.width = 2;
  };
  // END helpers

  // START event listeners
  const onUndo = (userType: string) => {
    if (canvasDetails.historyStep > 0) {
      canvasDetails.historyStep--;
      const prevBase64EncodedUrl =
        canvasDetails.history[canvasDetails.historyStep - 1];
      drawImgOnCanvas(prevBase64EncodedUrl, 'undo', userType); // draws and emits
    }
  };

  const onRedo = (userType: string) => {
    if (canvasDetails.historyStep < canvasDetails.history.length) {
      canvasDetails.historyStep++;
      const nextBase64EncodedUrl =
        canvasDetails.history[canvasDetails.historyStep - 1];
      drawImgOnCanvas(nextBase64EncodedUrl, 'redo', userType);
    }
  };

  const onClear = (userType: string) => {
    deleteRedosHistory();
    contextClearRect();
    addCanvasToHistory();

    if (userType === 'emitter') {
      onInstantSave('clear', true);
    }
  };

  const onErase = () => {
    if (canvasDetails.color !== '#FFFFFF') {
      prevColor.current = canvasDetails.color;
    }
    canvasDetails.color = '#FFFFFF';
    canvasDetails.width = 30;
    setCursor(`url(${eraserCursor}) 15 15, auto`);
  };

  const onPen = () => {
    canvasDetails.color = prevColor.current;
    canvasDetails.width = 2;
    setCursor('crosshair');
  };
  // END event handlers

  useEffect(() => {
    canvasDetails.socketUrl = BASE_URL;
    canvasDetails.socket = io(canvasDetails.socketUrl);
    canvasDetails.socket.on('connect', () => {
      canvasDetails.socket && canvasDetails.socket.emit('image-data');
    });
    canvasDetails.socket.on('image-data', (data: any) => {
      if (data && data.actionType === 'undo') {
        onUndo('receiver');
      } else if (data && data.actionType === 'redo') {
        onRedo('receiver');
      } else if (data && data.actionType === 'clear') {
        deleteRedosHistory();
        onClear('receiver');
      } else {
        //is drawing
        // contextClearRect();
        const image = new Image();
        if (data) {
          image.src = data.base64EncodedUrl;
        }

        image.addEventListener('load', () => {
          contextDrawImage(image);
          // if it's not still drawing (mouse up), addCanvasToHistory
          if (data && data.finishedStep === true) {
            deleteRedosHistory();
            addCanvasToHistory();
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    const mouseMoveHandler = (e: TouchEvent, type: string) => {
      const event = type === 'touch' ? e.touches[0] : e;
      findxy('move', event);
      canvasDetails.finishedStep = false;
    };
    const mouseDownHandler = (e: TouchEvent, type: string) => {
      const event = type === 'touch' ? e.touches[0] : e;
      findxy('down', event);
      canvasDetails.finishedStep = false;
    };
    const mouseUpHandler = (e: TouchEvent, type: string) => {
      const event = type === 'touch' ? e.touches[0] : e;
      findxy('up', event);

      deleteRedosHistory();
      addCanvasToHistory();
      onInstantSave('finishedDraw', true);
    };

    let prevX = 0,
      currX = 0,
      prevY = 0,
      currY = 0,
      flag = false;

    const draw = (e: any) => {
      // START- DRAW
      if (contextRef.current) {
        contextRef.current.beginPath();
        contextRef.current.moveTo(prevX, prevY);
        contextRef.current.lineTo(currX, currY);
        contextRef.current.strokeStyle = canvasDetails.color;
        contextRef.current.lineCap = 'round';
        contextRef.current.lineJoin = 'round';
        contextRef.current.lineWidth = canvasDetails.width;

        contextRef.current.stroke();
        contextRef.current.closePath();
      }
      // END- DRAW

      onRecurrentSave();
    };

    const findxy = (res: string, e: any) => {
      if (res === 'down') {
        prevX = currX;
        prevY = currY;
        currX = e.clientX - canvasRef.current.offsetLeft;
        currY = e.clientY - canvasRef.current.offsetTop;
        flag = true;
      }
      if (res === 'up' || res === 'out') {
        flag = false;
      }
      if (res === 'move') {
        if (flag) {
          prevX = currX;
          prevY = currY;
          currX = e.clientX - canvasRef.current.offsetLeft;
          currY = e.clientY - canvasRef.current.offsetTop;
          draw(e);
        }
      }
    };

    canvasRef.current?.addEventListener('mousemove', mouseMoveHandler);
    canvasRef.current?.addEventListener('mousedown', mouseDownHandler);
    canvasRef.current?.addEventListener('mouseup', mouseUpHandler);
    canvasRef.current?.addEventListener(
      'touchmove',
      (e: any) => mouseMoveHandler(e, 'touch'),
      {
        passive: true,
      }
    );
    canvasRef.current?.addEventListener(
      'touchstart',
      (e: any) => mouseDownHandler(e, 'touch'),
      {
        passive: true,
      }
    );
    canvasRef.current?.addEventListener('touchend', (e: any) =>
      mouseUpHandler(e, 'touch')
    );
    canvasRef.current?.addEventListener('dblclick', onRecurrentSave);

    return () => {
      canvasRef.current?.removeEventListener('mousemove', mouseMoveHandler);
      canvasRef.current?.removeEventListener('mousedown', mouseDownHandler);
      canvasRef.current?.removeEventListener('mouseup', mouseUpHandler);
      canvasRef.current?.removeEventListener('dblclick', onRecurrentSave);
      canvasRef.current?.removeEventListener('touchend', (e: any) =>
        mouseUpHandler(e, 'touch')
      );
      canvasRef.current?.removeEventListener(
        'touchstart',
        (e: any) => mouseDownHandler(e, 'touch'),
        {
          passive: true,
        }
      );
      canvasRef.current?.removeEventListener(
        'touchmove',
        (e: any) => mouseMoveHandler(e, 'touch'),
        {
          passive: true,
        }
      );
    };
  }, []);

  return (
    <div className="canvas-wrapper">
      <div className="canvas-tools">
        <div className="color-picker-wrapper">
          <input
            className="color-picker"
            type="color"
            defaultValue="#000000"
            onChange={(e) => changeColor(e.target.value)}
          />
        </div>

        <CanvasTool
          classes={cursor === 'crosshair' ? 'active' : undefined}
          handleClick={onPen}
          icon={<PenIcon />}
          hoverName="Pen Tool"
        />
        <CanvasTool
          classes={cursor !== 'crosshair' ? 'active' : undefined}
          handleClick={onErase}
          icon={<EraserIcon />}
          hoverName="Eraser Tool"
        />
        <CanvasTool
          handleClick={() => onUndo('emitter')}
          icon={<UndoIcon />}
          hoverName="Undo"
        />
        <CanvasTool
          handleClick={() => onRedo('emitter')}
          icon={<RedoIcon />}
          hoverName="Redo"
        />
        <CanvasTool
          handleClick={() => onClear('emitter')}
          icon={<ClearIcon />}
          hoverName="Clear All"
        />
      </div>

      <canvas
        style={{ cursor: cursor }}
        ref={canvasRef}
        className="canvas"
        id="canvas"
      ></canvas>
    </div>
  );
};

export default WhiteBoard;
