import authHeader from '@services/auth-header';
import {
  useMentorData,
  usePronouns,
  useMentorActiveEnrollments,
} from '@shared/react';
import { User } from '@shared/types';
import { Collapse } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';

interface EnrollmentsInfoProps {
  record: User;
}

const EnrollmentsInfo = ({ record }: EnrollmentsInfoProps) => {
  const { they, They, them, are } = usePronouns(record.pronouns);
  const { data: enrollments } = useMentorActiveEnrollments(record?._id);
  const { data: mentor } = useMentorData(record?._id);
  const [courseTitles, setCourseTitles] = useState<string[]>([]);

  useEffect(() => {
    const courseTitles: any = [];
    mentor?.coursesCanTeach?.forEach((c) => {
      const course = axios
        .get(`/api/course/get-details/${c}`, { headers: authHeader() })
        .then((response) => {
          courseTitles.push(response.data.course.title);
        });
    });
    setCourseTitles(courseTitles);
  }, [mentor]);

  const mentorStatus = () => {
    if (!enrollments) return 'Loading...';
    if (record.isMarkedInactive) return 'Not a mentor anymore';
    if (!record.mentorData.available) return 'Temporarily unavailable';
    if (record.mentorData.maximumActiveEnrollments === enrollments.length + 1) {
      return 'Close to capacity!';
    }
    if (record.mentorData.maximumActiveEnrollments <= enrollments.length) {
      return 'At capacity!';
    }
    return 'Available';
  };

  return (
    <>
      <b>Basic info:</b>
      <ul>
        <li>
          {They} {are} currently teaching{' '}
          <b>
            {enrollments?.length} active enrollments out of{' '}
            {record.mentorData.maximumActiveEnrollments || 0}
          </b>{' '}
          {they} said {they} can take.
        </li>
        <li>
          <b>{mentorStatus()}</b>
        </li>
        <li>We owe {them} &euro;[todo] at the moment.</li>
      </ul>
      <b>Teaches:</b>
      <ul>
        <h4>Subjects:</h4>
        {mentor?.majors?.map((major: string) => (
          <li>
            <b>{major}</b>{' '}
          </li>
        ))}
        <h4>Courses:</h4>
        {courseTitles.map((course: string) => (
          <li>
            <b>{course}</b>{' '}
          </li>
        ))}
      </ul>
    </>
  );
};

export default EnrollmentsInfo;
