import NextMeeting from '@components/V3/NextMeeting';
import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import { useModal } from '@hooks';
import MentorFeedbackModalSession from '@modules/Base/Home/PendingFeedback/MentorFeedbackModalSession';
import StudentDetailsModal from '@modules/Mentor/MyStudents/StudentDetailsModal';
import { NewMeeting } from '@shared/common';
import React from 'react';
import GiveFeedbackButtonMentor from '../../../views/UserView/Home/Common/Buttons/GiveFeedbackButtonMentor';
import HomeworkButton from '../../../views/UserView/Home/Common/Buttons/HomeworkButton';
import JoinButtonMentor from '../../../views/UserView/Home/Common/Buttons/JoinButtonMentor';

interface Props {
  meeting: NewMeeting;
  isMentor?: boolean;
}

const MeetingNeedingFeedbackMentor: React.FC<Props> = ({
  meeting,
  isMentor,
}) => {
  const { students } = meeting;

  const name = students[0]?.fullName || '';
  const isStudentDeactivated = meeting.enrollment?.user?.isDezactivated;

  const [DetailsModal, showDetailsModal] = useModal(StudentDetailsModal);

  return (
    <>
      <NextMeeting
        meetingNeedingFeedback={true}
        meeting={meeting}
        otherName={name}
        isMentor={isMentor}
        showDetailsModal={showDetailsModal}
      >
        {!isStudentDeactivated && (
          <div style={{ width: '100%' }}>
            <HomeworkButton meeting={meeting} />
          </div>
        )}
        <div style={{ width: '100%' }}>
          <JoinButtonMentor
            meeting={meeting}
            hideJoinAndRescheduleBtn={isStudentDeactivated}
          />
        </div>
      </NextMeeting>
      {DetailsModal}
    </>
  );
};

export default MeetingNeedingFeedbackMentor;
