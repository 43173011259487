import defaultAvatar from '@assets/default-user.png';
import { useStudentEnrollments, useUserDetails } from '@shared/react';
import styles from '@styles/pages/mentor/my-students.module.scss';
import { Avatar } from 'antd';
import { COLORS } from '../../../../v3/global';
import AdditionalInfoCard from './AdditionalInfoCard';
import ContactInfoCard from './ContactInfoCard';
import ParentInfoCard from './ParentInfoCard';

type Props = {
  studentId: string;
  enrollmentId: string;
};

const StudentDetails = ({ studentId, enrollmentId }: Props) => {
  const { data: enrollments, isLoading } = useStudentEnrollments(studentId);
  const { data: student } = useUserDetails(studentId);

  if (isLoading) return <p>Loading...</p>;
  if (!enrollments?.length) return <p>No enrollments</p>;

  return (
    <div className={styles.studentModal}>
      <div className={styles.studentModalHead}>
        <Avatar
          size="large"
          src={student?.avatar || defaultAvatar}
          className={styles.studentModalHeadAvatar}
        />
        <div style={{ color: COLORS.TEXT_PRIMARY }}>
          {student?.fullName}'s Details
        </div>
      </div>

      <div className={styles.studentModalInfo}>
        <ContactInfoCard studentId={studentId} />
        <ParentInfoCard studentId={studentId} />
        <AdditionalInfoCard studentId={studentId} />
      </div>
    </div>
  );
};

export default StudentDetails;
