import { useUnpaidMeetings } from '@shared/react';
import { Card, Statistic } from 'antd';

type Props = {
  mentorId: string;
};

const UnpaidAmount = ({ mentorId }: Props) => {
  const { data, isLoading } = useUnpaidMeetings(mentorId);
  const amount = data?.reduce((acc, cur) => acc + cur.sessionRate, 0);

  return (
    <Card loading={isLoading}>
      <Statistic
        title="Unpaid Amount"
        value={amount}
        precision={2}
        valueStyle={{ color: '#3f8600' }}
        suffix="€"
      />
    </Card>
  );
};

export default UnpaidAmount;
