import CoursesMentorSelector from '@components/Selectors/CoursesMentorSelector';
import { AdjustmentsIcon, SearchIcon, XIcon } from '@heroicons/react/outline';
import { SearchMentorEnrollmentsParams } from '@shared/types';
import styles from '@styles/pages/mentor/my-students.module.scss';
import { DatePicker, Select, Space } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { COLORS } from '../../../v3/global';

type Props = {
  searchParams: SearchMentorEnrollmentsParams;
  updateSearchParams: (
    searchParams: Partial<SearchMentorEnrollmentsParams>
  ) => void;
  totalStudents?: number;
};

const MyStudentsFilters = ({
  searchParams,
  updateSearchParams,
  totalStudents,
}: Props) => {
  const [extended, setExtended] = useState(false);

  return (
    <>
      <div className={styles.search} style={{ marginBottom: '4rem' }}>
        <input
          type="text"
          placeholder="Search by Student Name"
          value={searchParams.studentSearch}
          onChange={(e) =>
            updateSearchParams({
              studentSearch: e.target.value,
            })
          }
        />

        <SearchIcon className={styles.icon} />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '18px 0',
          padding: '0 1.5rem',
        }}
      >
        <Space size="middle" wrap style={{ width: '100%' }}>
          <div className={styles.filterOption}>
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
              }}
            >
              Course
            </span>
            <CoursesMentorSelector
              value={searchParams.courses ? searchParams.courses : []}
              onChange={(value) => {
                updateSearchParams({
                  courses: value,
                });
              }}
            />
          </div>
          <div className={styles.filterOption}>
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
              }}
            >
              Status
            </span>
            <Select
              placeholder="Select Status"
              style={{ width: 200 }}
              value={searchParams.status}
              onChange={(value) => {
                updateSearchParams({
                  status: value,
                });
              }}
            >
              <Select.Option value="all">All Students</Select.Option>
              <Select.Option value="upcoming">Upcoming Students</Select.Option>
              <Select.Option value="active">Active Students</Select.Option>
              <Select.Option value="alumni">Alumni</Select.Option>
            </Select>
          </div>
          {/*TODO Remove all functionality later on*/}
          {/*<div className={styles.filterOption}>*/}
          {/*  <span>Graduation Year</span>*/}
          {/*  <InputNumber*/}
          {/*    placeholder="Grad Year"*/}
          {/*    className={styles.filterInput}*/}
          {/*    min={2000}*/}
          {/*    max={2050}*/}
          {/*    value={searchParams.gradYear}*/}
          {/*    onChange={(value) =>*/}
          {/*      value && updateSearchParams({ gradYear: value })*/}
          {/*    }*/}
          {/*  />*/}
          {/*</div>*/}

          {!!totalStudents && (
            <div className={styles.filterOption}>
              <span
                style={{
                  display: 'flex',
                  color: COLORS.TEXT_SECONDARY,
                }}
              >
                Total:
              </span>
              <label
                style={{
                  display: 'flex',
                  paddingLeft: 5,
                  color: COLORS.TEXT_SECONDARY,
                }}
              >
                {totalStudents} results
              </label>
            </div>
          )}
        </Space>

        <div
          className={`${styles.extraFiltersButton} ${
            extended ? styles.extraFiltersButtonExtended : ''
          }`}
          onClick={() => setExtended(!extended)}
        >
          {extended ? (
            <XIcon className={styles.icon} />
          ) : (
            <AdjustmentsIcon className={styles.icon} />
          )}
        </div>
      </div>
      {extended && (
        <Space
          wrap
          style={{ width: '100%', marginBottom: '18px', padding: '0 1.5rem' }}
        >
          <div className={styles.filterOption}>
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
              }}
            >
              Period
            </span>
            <DatePicker.RangePicker
              className={styles.filterInput}
              style={{ width: 'unset' }}
              value={
                searchParams.periodStart && searchParams.periodEnd
                  ? [
                      moment(searchParams.periodStart),
                      moment(searchParams.periodEnd),
                    ]
                  : undefined
              }
              onChange={(data) => {
                if (!data)
                  return updateSearchParams({
                    periodStart: undefined,
                    periodEnd: undefined,
                  });

                updateSearchParams({
                  periodStart: data[0] ? data[0].toISOString() : undefined,
                  periodEnd: data[1] ? data[1].toISOString() : undefined,
                });
              }}
            />
          </div>
        </Space>
      )}
    </>
  );
};

export default MyStudentsFilters;
