import { interestsOptions } from '@shared/constants';
import { Select } from 'antd';
import { CSSProperties } from 'react';

type Props = {
  value?: string[];
  onChange?: (value: string[]) => void;
  placeholder?: string;
  style?: CSSProperties;
};

const InterestsSelector = ({ placeholder, value, onChange, style }: Props) => {
  return (
    <Select
      mode="multiple"
      allowClear
      placeholder={placeholder || 'Interests'}
      value={value}
      onChange={onChange}
      style={style}
    >
      {interestsOptions.map((interest) => {
        return (
          <Select.Option key={interest.value} value={interest.value}>
            {interest.text}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default InterestsSelector;
