import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import { useSaveUserProfile } from '@shared/react';
import AgoraInput from '@utils/AgoraInput';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './profile-modal-mentors.styles.scss';
import AgoraBigSelector from '@utils/AgoraBigSelector';
import { Checkbox } from 'antd';
import { countryList } from '@shared/constants';

type Props = {
  handleCurrentPage: (page: string) => void;
  mentorId: string;
  refetchEnrollments?: () => any;

  mentorData: any;
  refetchMentorData: () => void;
};

const currencyOptions2 = ['RON', 'EUR', 'USD'];

const EditPaymentDetailsPage = ({
  handleCurrentPage,
  mentorId,
  refetchEnrollments,

  mentorData,
  refetchMentorData,
}: Props) => {
  const { mutate: saveUserProfile } = useSaveUserProfile(mentorId, {
    onSuccess: () => {
      refetchMentorData();
      handleCurrentPage('mentor-profile');
    },
  });

  const [amount, setAmount] = useState<string | null>(null);
  const [currency, setCurrency] = useState<string | null>(null);
  const [billingCurrency, setBillingCurrency] = useState<string | null>(null);
  const [vatPayer, setVatPayer] = useState<boolean | null>(null);
  const [companyName, setCompanyName] = useState<string | null>(null);
  const [companyAddress, setCompanyAddress] = useState<string | null>(null);
  const [companyCountry, setCompanyCountry] = useState<string | null>(null);
  const [companyCUI, setCompanyCUI] = useState<string | null>(null);
  const [companyIBAN, setCompanyIBAN] = useState<string | null>(null);

  return (
    <div style={{ marginTop: '8px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}></div>

      <h3
        style={{ color: COLORS.TEXT_SECONDARY, marginBottom: '24px' }}
        className="modal-page-section-title"
      >
        Billing Info
      </h3>

      <div
        style={{
          marginBottom: '16px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <AgoraInput
          htmlFor="amount"
          name="amount"
          id="amount"
          type="text"
          width="calc(50% - 16px)"
          placeholder="eg: 20"
          label="Amount"
          value={
            amount === null ? mentorData?.mentorData?.rate?.amount : amount
          }
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 'calc(50% - 16px)',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            height: '70px',
          }}
        >
          <span className="block text-sm font-medium leading-6 text-customPrimary">
            VAT Payer?
          </span>
          <Checkbox
            onChange={(e) => setVatPayer(e.target.checked)}
            checked={
              vatPayer === null
                ? mentorData?.mentorData?.rate?.vatPayer
                : vatPayer
            }
          />
        </div>
      </div>
      <div
        style={{
          marginBottom: '16px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            width: 'calc(50% - 16px)',
          }}
        >
          <AgoraBigSelector
            showOptionsBy="value"
            label="Currency"
            labelColor={COLORS.PRIMARY}
            labelMargin="-6px 0 16px 0"
            onSelect={(value: any) => setCurrency(value)}
            options={currencyOptions2}
            labelKey="title"
            valueKey="_id"
            height="36px"
            width="100%"
            selectMargin="-6px 0 0 0"
            borderRadius="6px"
            value={
              currency === null
                ? mentorData?.mentorData?.rate?.currency
                : currency
            }
          />
        </div>

        <div
          style={{
            width: 'calc(50% - 16px)',
          }}
        >
          <AgoraBigSelector
            showOptionsBy="value"
            label="Billing Currency"
            labelColor={COLORS.PRIMARY}
            labelMargin="-6px 0 16px 0"
            onSelect={(value: any) => setBillingCurrency(value)}
            options={currencyOptions2}
            labelKey="title"
            valueKey="_id"
            height="36px"
            width="100%"
            selectMargin="-6px 0 0 0"
            borderRadius="6px"
            value={
              billingCurrency === null
                ? mentorData?.mentorData?.rate?.billingCurrency
                : billingCurrency
            }
          />
        </div>
      </div>

      <AgoraInput
        htmlFor="companyName"
        name="companyName"
        id="companyName"
        type="text"
        placeholder=""
        margin="0 0 16px 0"
        label="Company / Freelancer Name"
        value={
          companyName === null
            ? mentorData?.mentorData?.company?.name
            : companyName
        }
        onChange={(e) => {
          setCompanyName(e.target.value);
        }}
      />

      <AgoraBigSelector
        showOptionsBy="value"
        label="Country"
        labelColor={COLORS.PRIMARY}
        labelMargin="-6px 0 16px 0"
        onSelect={(value) => setCompanyCountry(value as string)}
        options={countryList}
        labelKey="title"
        valueKey="_id"
        height="36px"
        width="100%"
        selectMargin="0 0 16px 0"
        borderRadius="6px"
        value={
          companyCountry === null
            ? mentorData?.mentorData?.rate?.country
            : companyCountry
        }
      />

      <AgoraInput
        htmlFor="companyAddress"
        name="companyAddress"
        id="companyAddress"
        type="text"
        margin="0 0 16px 0"
        placeholder=""
        label="Company / Freelancer Address"
        value={
          companyAddress === null
            ? mentorData?.mentorData?.company?.address
            : companyAddress
        }
        onChange={(e) => {
          setCompanyAddress(e.target.value);
        }}
      />

      <AgoraInput
        htmlFor="companyCUI"
        name="companyCUI"
        id="companyCUI"
        type="text"
        margin="0 0 16px 0"
        placeholder=""
        label="Fiscal registration number (or equivalent)"
        value={
          companyCUI === null
            ? mentorData?.mentorData?.company?.CUI
            : companyCUI
        }
        onChange={(e) => {
          setCompanyCUI(e.target.value);
        }}
      />

      <AgoraInput
        htmlFor="companyIBAN"
        name="companyIBAN"
        id="companyIBAN"
        type="text"
        margin="0 0 32px 0"
        placeholder=""
        label="IBAN"
        value={
          companyIBAN === null
            ? mentorData?.mentorData?.company?.IBAN
            : companyIBAN
        }
        onChange={(e) => {
          setCompanyIBAN(e.target.value);
        }}
      />

      <SecondaryButton
        onClick={() => {
          saveUserProfile({
            rate: {
              amount:
                amount === null ? mentorData?.mentorData?.rate?.amount : amount,
              currency:
                currency === null
                  ? mentorData?.mentorData?.rate?.currency
                  : currency,
              billingCurrency:
                billingCurrency === null
                  ? mentorData?.mentorData?.rate?.billingCurrency
                  : billingCurrency,
              vatPayer:
                vatPayer === null
                  ? mentorData?.mentorData?.rate?.vatPayer
                  : vatPayer,
            },
            company: {
              name:
                companyName === null
                  ? mentorData?.mentorData?.company?.name
                  : companyName,
              country:
                companyCountry === null
                  ? mentorData?.mentorData?.company?.country
                  : companyCountry,
              address:
                companyAddress === null
                  ? mentorData?.mentorData?.company?.address
                  : companyAddress,
              CUI:
                companyCUI === null
                  ? mentorData?.mentorData?.company?.CUI
                  : companyCUI,
              IBAN:
                companyIBAN === null
                  ? mentorData?.mentorData?.company?.IBAN
                  : companyIBAN,
            },
          });
        }}
        backgroundColor={COLORS.WHITE}
        height="32px"
        padding="4px 10px 4px 10px"
        margin="12px 0 0 0"
        width="100%"
      >
        <span className="button-text" style={{ color: COLORS.BLACK }}>
          Save
        </span>
      </SecondaryButton>
    </div>
  );
};

export default EditPaymentDetailsPage;
