import { enrollmentsApis } from '../../apis';
import {
  genericMutation,
  genericMutationWithId,
  genericQuery,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';

export const useEnrollment = genericQueryWithId<enrollmentsApis.GetEnrollment>(
  enrollmentsApis.getEnrollment
);

export const useEnrollmentMentorHistory =
  genericQueryWithId<enrollmentsApis.GetEnrollmentMentorHistory>(
    enrollmentsApis.getEnrollmentMentorHistory
  );

export const useCreateEnrollment =
  genericMutation<enrollmentsApis.CreateEnrollment>(
    enrollmentsApis.createEnrollment,
    enrollmentsApis.createEnrollment.endpoint
  );

export const useUpdateEnrollment =
  genericMutation<enrollmentsApis.UpdateEnrollment>(
    enrollmentsApis.updateEnrollment,
    enrollmentsApis.createEnrollment.endpoint,
    enrollmentsApis.getEnrollments.endpoint
  );

export const useMyEnrollments = genericQuery<enrollmentsApis.GetMyEnrollments>(
  enrollmentsApis.getMyEnrollments
);

export const useGetEnrollments =
  genericQueryWithId<enrollmentsApis.GetEnrollments>(
    enrollmentsApis.getEnrollments
  );

export const useMyEnrollmentsWithPopulatedCourses =
  genericQuery<enrollmentsApis.GetUsersEnrollmentsWithPopulatedCourses>(
    enrollmentsApis.getUsersEnrollmentsWithPopulatedCourses
  );

export const useMyMentorEnrollments =
  genericQueryWithParams<enrollmentsApis.SearchMentorEnrollments>(
    enrollmentsApis.searchMentorEnrollments,
    undefined,
    true
  );

export const useMentorActiveEnrollments =
  genericQueryWithId<enrollmentsApis.GetMentorActiveEnrollments>(
    enrollmentsApis.getMentorActiveEnrollments
  );

export const useMentorEnrollments =
  genericQueryWithId<enrollmentsApis.GetMentorEnrollments>(
    enrollmentsApis.getMentorEnrollments
  );

export const useStudentEnrollments =
  genericQueryWithId<enrollmentsApis.GetStudentEnrollments>(
    enrollmentsApis.getStudentEnrollments
  );

export const useUpdateEnrollmentMentor =
  genericMutationWithId<enrollmentsApis.UpdateEnrollmentMentorBeforeFindBy>(
    enrollmentsApis.updateEnrollmentMentorBeforeFindBy
  );

export const useGetEnrollmentsByMentor =
  genericQueryWithParams<enrollmentsApis.GetEnrollmentsByMentor>(
    enrollmentsApis.getEnrollmentsByMentor
  );

export const useGetActiveStudentsByMentor =
  genericQuery<enrollmentsApis.GetActiveStudentsByMentor>(
    enrollmentsApis.getActiveStudentsByMentor
  );

export const useAddSession = genericMutation<enrollmentsApis.AddSession>(
  enrollmentsApis.addSession,
  enrollmentsApis.getEnrollment.endpoint
);

export const useDeleteSession = genericMutation<enrollmentsApis.DeleteSession>(
  enrollmentsApis.deleteSession,
  enrollmentsApis.getEnrollment.endpoint
);

export const useEndEnrollment = genericMutation<enrollmentsApis.EndEnrollment>(
  enrollmentsApis.endEnrollment,
  enrollmentsApis.getActiveStudentsByMentor.endpoint,
  enrollmentsApis.getEnrollmentsByMentor.endpoint
);

export const useResumeEnrollment =
  genericMutation<enrollmentsApis.ResumeEnrollment>(
    enrollmentsApis.resumeEnrollment
  );

export const useGetMentorEnrollmentsWithExtraInfo =
  genericQueryWithParams<enrollmentsApis.GetMentorEnrollmentsWithExtraInfo>(
    enrollmentsApis.getMentorEnrollmentsWithExtraInfo
  );

export const useGetStudentEnrollmentsWithExtraInfo =
  genericQueryWithParams<enrollmentsApis.GetStudentEnrollmentsWithExtraInfo>(
    enrollmentsApis.getStudentEnrollmentsWithExtraInfo
  );

export const useGetMentorTeachingAndEnrolledInEnrollments =
  genericQueryWithId<enrollmentsApis.GetMentorTeachingAndEnrolledInEnrollments>(
    enrollmentsApis.getMentorTeachingAndEnrolledInEnrollments
  );

export const useMinimumMentorCapacity =
  genericQuery<enrollmentsApis.GetMinimumMentorCapacity>(
    enrollmentsApis.getMinimumMentorCapacity
  );

export const useHasMentorUpcomingStudents =
  genericQuery<enrollmentsApis.HasMentorUpcomingStudents>(
    enrollmentsApis.hasMentorUpcomingStudents
  );

export const useUnenrollUser = genericMutation<enrollmentsApis.UnenrollUser>(
  enrollmentsApis.unenrollUser
);
