import {
  PopulatedTakeaway,
  ApiDescription,
  ApiData,
  RequestsMethod,
  Takeaway,
} from '../common';

export const searchMyTakeaways: ApiDescription = {
  endpoint: '/takeaways/my',
  method: RequestsMethod.Get,
};
export type SearchMyTakeaways = ApiData<
  {
    limit?: number;
    q?: string;
  },
  PopulatedTakeaway[]
>;

export const getModuleTakeaways: ApiDescription = {
  endpoint: '/takeaways/module',
  method: RequestsMethod.Get,
};
export type GetModuleTakeaways = ApiData<
  { courseId: string; moduleId: number },
  Takeaway[]
>;
