import {
  HomeOutlined,
  BookOutlined,
  SettingOutlined,
  HighlightOutlined,
  CustomerServiceOutlined,
  CalendarOutlined,
  ReadOutlined,
  SolutionOutlined,
  UserOutlined,
  FlagOutlined,
  CheckSquareOutlined,
  RocketOutlined,
  FileDoneOutlined,
  DollarOutlined,
  TeamOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { ACCOUNT } from '@routes';
import {
  CUSTOMER_SUCCESS,
  FINANCIAL_ROLE,
  MENTORS_MANAGER,
  NewEnrollment,
  STUDENTS_MANAGER,
  USERS_MANAGER,
} from '@shared/common';
import { ReactNode } from 'react';

import * as ROUTES from '../../constants/routes';
import DropdownItem from './DropdownItem';
import NavItem from './NavItem';

type Tab = {
  route: string;
  title: ReactNode;
  icon?: ReactNode;
};

const myAccountTab = { route: ACCOUNT, title: 'My Account' };
const homeTab = (route: string) => {
  return { route, title: 'Home', icon: <HomeOutlined /> };
};

const tabsToMenus = (tabs: Tab[]) => (
  <div>
    {tabs.map((tab) => (
      <NavItem
        key={tab.route}
        route={tab.route}
        icon={tab.icon}
        title={tab.title}
      />
    ))}
  </div>
);

export const coursesTabs = (enrollments: NewEnrollment[]) =>
  enrollments.map(({ course }) => (
    <DropdownItem
      key={course?._id}
      route={`/course/${course?._id}`}
      title={course?.title}
      backgroundColor={course?.color}
    />
  ));

export const studentTabs = (isMobile: undefined | boolean) => (
  <>
    {isMobile
      ? tabsToMenus([myAccountTab])
      : tabsToMenus([homeTab(ROUTES.HOME)])}
    {tabsToMenus([
      { route: ROUTES.COURSES, title: 'COURSES', icon: <BookOutlined /> },
      {
        route: ROUTES.NEW_ENROLLMENTS,
        title: 'ENROLLMENTS',
        icon: <BookOutlined />,
      },
    ])}
  </>
);

const mentorMobileTabs = [
  myAccountTab,
  {
    route: ROUTES.MENTOR_STUDENTS,
    title: 'My Students',
    icon: <HighlightOutlined />,
  },
];

const mentorDesktopTabs = [
  homeTab(ROUTES.MENTOR_HOME),
  {
    route: ROUTES.MENTOR_STUDENTS,
    title: 'My Students',
    icon: <HighlightOutlined />,
  },
  {
    route: ROUTES.MENTOR_SETTINGS,
    title: 'My Account',
    icon: <SettingOutlined />,
  },
];

export const mentorTabs = (isMobile: undefined | boolean) =>
  tabsToMenus(isMobile ? mentorMobileTabs : mentorDesktopTabs);

export const adminTabs = (roles: number[]) => {
  const tabs = [
    {
      route: ROUTES.MEETING_MANAGEMENT,
      title: 'All Meetings',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CalendarOutlined />
        </div>
      ),
    },
    {
      route: ROUTES.MANAGE_COURSES,
      title: 'Courses',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ReadOutlined />
        </div>
      ),
    },
    {
      route: ROUTES.FLAGS,
      title: 'Flags',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FlagOutlined />
        </div>
      ),
    },
    {
      route: ROUTES.MANAGE_KNOWLEDGE,
      title: 'Knowledge Base',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FileTextOutlined />
        </div>
      ),
    },
    // {
    //   route: ROUTES.CHECKLIST_MANAGEMENT,
    //   title: 'Checklists',
    //   icon: (
    //     <div
    //       style={{
    //         display: 'flex',
    //         alignItems: 'center',
    //       }}
    //     >
    //       <CheckSquareOutlined />
    //     </div>
    //   ),
    // },
    // {
    //   route: ROUTES.SALES_TOOLS_POPUP,
    //   title: 'Sales Onboarding',
    //   icon: (
    //     <div
    //       style={{
    //         display: 'flex',
    //         alignItems: 'center',
    //       }}
    //     >
    //       <RocketOutlined />
    //     </div>
    //   ),
    // },
  ];

  if (roles.includes(CUSTOMER_SUCCESS)) {
    tabs.splice(0, 0, {
      route: ROUTES.NPS_DASHBOARD,
      title: 'NPS Dashboard',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomerServiceOutlined />
        </div>
      ),
    });
  }

  // if (roles.includes(CUSTOMER_SUCCESS)) {
  //   tabs.splice(0, 0, {
  //     route: ROUTES.CUSTOMER_SUCCESS_DASHBOARD,
  //     title: 'Customer Success',
  //     icon: (
  //       <div
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //         }}
  //       >
  //         <CustomerServiceOutlined />
  //       </div>
  //     ),
  //   });
  // }

  if (roles.includes(STUDENTS_MANAGER)) {
    tabs.splice(3, 0, {
      route: ROUTES.MANAGE_STUDENTS,
      title: 'Students',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <UserOutlined />
        </div>
      ),
    });
  }

  if (roles.includes(MENTORS_MANAGER)) {
    // tabs.splice(3, 0, {
    //   route: ROUTES.MANAGE_MENTORS,
    //   title: 'Mentors',
    //   icon: (
    //     <div
    //       style={{
    //         display: 'flex',
    //         alignItems: 'center',
    //       }}
    //     >
    //       <SolutionOutlined />
    //     </div>
    //   ),
    // });

    tabs.splice(4, 0, {
      route: ROUTES.MANAGE_MENTORS_V3,
      title: 'Mentors',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SolutionOutlined />
        </div>
      ),
    });
  }

  if (roles.includes(USERS_MANAGER)) {
    // tabs.splice(3, 0, {
    //   route: ROUTES.MANAGE_USERS,
    //   title: 'Users',
    //   icon: (
    //     <div
    //       style={{
    //         display: 'flex',
    //         alignItems: 'center',
    //       }}
    //     >
    //       <TeamOutlined />
    //     </div>
    //   ),
    // });
    tabs.splice(4, 0, {
      route: ROUTES.MANAGE_USERS_V3,
      title: 'Users',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TeamOutlined />
        </div>
      ),
    });
  }

  if (roles.includes(FINANCIAL_ROLE)) {
    tabs.splice(3, 0, {
      route: ROUTES.ACTIVITY_REPORT,
      title: 'Activity Report',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FileDoneOutlined />
        </div>
      ),
    });
    tabs.splice(4, 0, {
      route: ROUTES.EXTRA_SERVICES,
      title: 'Extra Services',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <DollarOutlined />
        </div>
      ),
    });
  }

  return (
    <>
      {tabsToMenus(tabs)}

      {/*TODO Remove if no longer needed*/}
      {/*<DropdownMenu title="Sales">*/}
      {/*  {tabsToMenus([*/}
      {/*    {*/}
      {/*      route: ROUTES.SALES_RESET_USER_ACTIVATION_LINK,*/}
      {/*      title: 'Account Tools',*/}
      {/*    },*/}
      {/*    { route: ROUTES.SALES_TOOLS_POPUP, title: 'Sales Onboarding' },*/}
      {/*  ])}*/}
      {/*</DropdownMenu>*/}
    </>
  );
};
