import authHeader from '@services/auth-header';
import {
  CloseModalProps,
  NewEnrollment,
  ServiceType,
  Session,
} from '@shared/common';
import { BASE_URL } from '@shared/frontendEnv';
import { useGetAllMentors } from '@shared/react';
import {
  useCreateExtraService,
  useGetExtraService,
} from '@shared/react/react-query/extraServices.actions';
import styles from '@styles/components/reusables/modal.module.scss';
import {
  Button,
  DatePicker,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Tooltip,
  Typography,
} from 'antd';
import axios from 'axios';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';

type UserArg = { _id: string; fullName: string };

type Props = CloseModalProps & {
  name?: string;
  users?: UserArg[];
  enrollmentId?: string;
  date?: Moment;
  isOnHomePage?: boolean;
  defaultEnrollment?: NewEnrollment;
  defaultStudent?: {
    fullName?: string;
    id: string;
  };
  defaultSession?: Session;
  postSuccess?: () => void;
  handleRowTouched: () => void;
  serviceId: string;
  // updateSearchParams: (params: Partial<UserSearchParams>) => void;
};

const EditExtraServicesModal = ({
  visible,
  hide,
  handleRowTouched,
  serviceId,
}: Props) => {
  const { mutate: createExtraService, isLoading: isLoadingCreateExtraService } =
    useCreateExtraService();
  const { data: mentors } = useGetAllMentors({
    active: true,
  });

  const { data: extraService } = useGetExtraService(serviceId);

  const [showModal, setShowModal] = useState(true);
  const [isCreating, setIsCreating] = useState(false);

  const [serviceType, setServiceType] = useState<ServiceType>();
  const [description, setDescription] = useState<string>();
  const [amount, setAmount] = useState<number>();
  const [currency, setCurrency] = useState<string>();
  const [mentorId, setMentorId] = useState<string>();
  const [selectedDate, setSelectedDate] = useState<{
    month: number | null;
    year: number | null;
  }>({ month: null, year: null });

  useEffect(() => {
    setServiceType(extraService?.serviceType);
    setDescription(extraService?.description);
    setAmount(extraService?.amount);
    setCurrency(extraService?.currency);
    setMentorId(extraService?.mentor?._id);
    setSelectedDate({
      month: extraService?.month ?? null,
      year: extraService?.year ?? null,
    });
  }, [extraService]);

  const validateFields = () => {
    if (serviceType === undefined || serviceType === null) {
      message.error('Please select a Service Type');
      return;
    } else if (!amount || amount < 0) {
      message.error('Please enter a valid amount');
      return;
    } else if (!currency) {
      message.error('Please select a Currency');
      return;
    } else if (!mentorId) {
      message.error('Please select a Mentor');
      return;
    } else if (
      !selectedDate.month ||
      selectedDate.month < 0 ||
      selectedDate.month > 12
    ) {
      message.error('Please enter a valid month');
      return;
    } else if (!selectedDate.year || selectedDate.year < 2020) {
      message.error('Please enter a valid year');
      return;
    } else {
      message.success('Extra service edited successfully!');
      // setSelectedDate({
      //   month: null,
      //   year: null,
      // });
      // handleRowTouched();
    }
  };

  const handleEditExtraService = async () => {
    if (extraService) {
      try {
        validateFields();
        const response = await axios.put(
          `${BASE_URL}/api/v1/extra-services/admin/${serviceId}`,
          {
            serviceType: serviceType ? serviceType : extraService?.serviceType,
            description: description ? description : extraService?.description,
            amount: amount ? amount : extraService?.amount,
            currency: currency ? currency : extraService?.currency,
            mentorId: mentorId ? mentorId : extraService?.mentor?._id,
            month: selectedDate.month
              ? selectedDate.month
              : extraService?.month,
            year: selectedDate.year ? selectedDate.year : extraService?.year,
          },
          { headers: authHeader() }
        );
      } catch (error) {
        console.log(error);
      }
    }
    handleRowTouched();
  };

  const handleDateChange = (date: any) => {
    if (date) {
      setSelectedDate({
        month: date.month() + 1, // Month is 0-based, so add 1
        year: date.year(),
      });
    } else {
      setSelectedDate({ month: null, year: null });
    }
  };

  return (
    <Modal
      title="Edit extra service"
      visible={visible && showModal}
      onCancel={hide}
      footer={
        <Button
          onClick={() => {
            handleEditExtraService();
            hide();
            handleRowTouched();

            if (isLoadingCreateExtraService) handleRowTouched();
          }}
          disabled={isCreating}
          loading={isCreating}
        >
          Edit Extra Service
        </Button>
      }
      className={`${styles.modal} ${styles.newMeetingModal}`}
    >
      <div>
        <Select
          showSearch
          style={{ width: '100%', marginBottom: '10px' }}
          onChange={(val) => setServiceType(val)}
          value={serviceType}
          placeholder={
            extraService ? extraService?.serviceType : 'Service Type'
          }
        >
          <Select.Option value={ServiceType.WEBINAR_HOST}>
            Webinar Host
          </Select.Option>
          <Select.Option value={ServiceType.SPEAKING_LIVE_EVENT}>
            Speaking Live Event
          </Select.Option>
          <Select.Option value={ServiceType.REFERRAL}>Referral</Select.Option>
          <Select.Option value={ServiceType.MARKETING_ACTION}>
            Marketing Action
          </Select.Option>
          <Select.Option value={ServiceType.CREATIVE_WRITING_COURSE}>
            Creative Writing Course
          </Select.Option>
          <Select.Option value={ServiceType.SUBSCRIPTION}>
            Subscription
          </Select.Option>
          <Select.Option value={ServiceType.EXTRA_SESSIONS}>
            Extra Sessions
          </Select.Option>
          <Select.Option value={ServiceType.RESOURCE_DEVELOPMENT}>
            Resource Development
          </Select.Option>
          <Select.Option value={ServiceType.EXTRA_PAYMENT_PER_SESSION}>
            Extra Payment Per Session
          </Select.Option>
          <Select.Option value={ServiceType.MENTOR_TRAINING}>
            Mentor Training
          </Select.Option>
          <Select.Option value={ServiceType.OTHER}>Other</Select.Option>
        </Select>

        <Input
          type="text"
          placeholder={extraService ? extraService?.description : 'Description'}
          value={description}
          name="description"
          onChange={(e) => setDescription(e.target.value)}
          style={{ marginBottom: 10 }}
        />

        <Tooltip title="Use . NOT , (eg: 17.23)">
          <InputNumber
            placeholder={
              extraService ? extraService?.amount.toString() : 'Amount'
            }
            value={amount}
            name="amount"
            onChange={(e) => !!e && setAmount(e)}
            style={{ marginBottom: 10, width: '100%' }}
          />
        </Tooltip>

        <Select
          showSearch
          style={{ width: '100%', marginBottom: '10px' }}
          onChange={(val) => setCurrency(val)}
          value={currency}
          placeholder={
            extraService ? extraService?.currency.toString() : 'Currency'
          }
        >
          <Select.Option value={'EUR'}>EUR</Select.Option>
          <Select.Option value={'RON'}>RON</Select.Option>
          <Select.Option value={'USD'}>USD</Select.Option>
          <Select.Option value={'GBP'}>GBP</Select.Option>
        </Select>

        <Select
          style={{ width: '100%', marginBottom: '10px' }}
          showSearch
          placeholder={extraService ? extraService?.mentor?.fullName : 'Mentor'}
          optionFilterProp="children"
          onChange={(value: string) => {
            setMentorId(value);
          }}
        >
          <Select.Option key="select-a-mentor" value="">
            Select a mentor
          </Select.Option>
          <Select.Option key="no-mentor" value="no-mentor">
            No mentor
          </Select.Option>
          {mentors &&
            mentors?.map((mentor) => (
              <Select.Option key={mentor?._id} value={mentor?._id}>
                {mentor.fullName}
              </Select.Option>
            ))}
        </Select>

        <Typography.Text>Year & Month: </Typography.Text>
        <DatePicker.MonthPicker
          value={moment(
            `${selectedDate.year}-${selectedDate.month}`,
            'YYYY-MM'
          )}
          onChange={handleDateChange}
          placeholder="Select Month"
        />
      </div>
    </Modal>
  );
};

export default EditExtraServicesModal;
