import { ReportStatus, UPLOAD_INVOICE_STATES } from '@shared/constants';
import {
  FIRST_UPLOAD_INVOICE_DAY,
  LAST_UPLOAD_INVOICE_DAY,
} from '@shared/frontendEnv';
import moment from 'moment';

export const showMentorUploadInvoiceBanner = (
  isMentor: boolean,
  lastReportStatus: number | undefined
) =>
  isMentor &&
  ((Number(moment().format('DD')) >= FIRST_UPLOAD_INVOICE_DAY &&
    Number(moment().format('DD')) <= LAST_UPLOAD_INVOICE_DAY &&
    lastReportStatus !== ReportStatus.READY_FOR_PAYMENT &&
    lastReportStatus !== ReportStatus.PAID) ||
    lastReportStatus === ReportStatus.REJECTED ||
    lastReportStatus === ReportStatus.INVALID_E_INVOICE);

export const getUploadInvoiceBannerMessage = (status: string) => {
  switch (status) {
    case UPLOAD_INVOICE_STATES.NOT_GENERATED:
      return 'Your activity report is being generated. Please check back later to review your report and submit your invoice.';
    case UPLOAD_INVOICE_STATES.NOTHING_TO_UPLOAD:
      return `You have no meetings or extra services in ${moment()
        .subtract(1, 'month')
        .format('MMMM')}. No invoice submission is required.`;
    case UPLOAD_INVOICE_STATES.AWAITING_UPLOAD:
      return `No Invoice uploaded. Please check the activity report and upload the invoice by ${
        `${LAST_UPLOAD_INVOICE_DAY}.` + moment().format('MM')
      }`;
    case UPLOAD_INVOICE_STATES.UPLOADED:
      return `Your invoice has been uploaded successfully. You can replace it by ${
        `${LAST_UPLOAD_INVOICE_DAY}.` + moment().format('MM')
      } if needed.`;
    case UPLOAD_INVOICE_STATES.INVOICE_ACCEPTED:
      return 'Your invoice has been accepted. Waiting for E-invoice approval.';
    case UPLOAD_INVOICE_STATES.REJECTED:
      return 'Your invoice was rejected. Check your email for the reason and upload a new invoice.';
    case UPLOAD_INVOICE_STATES.INVALID_E_INVOICE:
      return 'Your E-invoice is invalid. Please fix in SPV.';
    default:
      return 'Error. Please contact support.';
  }
};

export const getInvoiceActionableState = (status?: number) => {
  switch (status) {
    case ReportStatus.INVOICE_ACCEPTED:
      return UPLOAD_INVOICE_STATES.INVOICE_ACCEPTED;
    case ReportStatus.INVALID_E_INVOICE:
      return UPLOAD_INVOICE_STATES.INVALID_E_INVOICE;
    case ReportStatus.INVOICE_UPLOADED:
      return UPLOAD_INVOICE_STATES.UPLOADED;
    default:
      return UPLOAD_INVOICE_STATES.AWAITING_UPLOAD;
  }
};

export const getUploadInvoiceBannerTitle = (
  hasMentorMeetingsOrExtraServices = false,
  isError = false,
  lastReportStatus: number | undefined
) => {
  if (isError) return 'Activity Report Pending';
  if (!hasMentorMeetingsOrExtraServices)
    return `No Activity for ${moment().subtract(1, 'month').format('MMMM')}`;
  if (lastReportStatus === ReportStatus.REJECTED) return 'Invoice Rejected';
  if (lastReportStatus === ReportStatus.INVOICE_ACCEPTED)
    return 'Invoice Accepted';
  if (lastReportStatus === ReportStatus.INVOICE_UPLOADED)
    return 'Invoice Uploaded';
  if (lastReportStatus === ReportStatus.INVALID_E_INVOICE)
    return 'Invalid E-Invoice';
  return `Upload Invoice for ${moment().subtract(1, 'month').format('MMMM')}`;
};
