import UserPlaceholderAvatar from '@assets/icons/mentor-placeholder-avatar.png';
import { ACCOUNT } from '@routes';
import React from 'react';
import { Link } from 'react-router-dom';
import { COLORS } from '../../../v3/global';
import './user-home-card.styles.scss';
import { Avatar } from 'antd';
import styles from '@styles/pages/mentor/settings.module.scss';

type Props = {
  name: string;
  description: string;
  avatar: any;
};

const UserHomeCard = ({ name, description, avatar }: Props) => {
  return (
    <div className="user-home-card-container">
      <div className="card-user-name-role-container">
        <div style={{ color: COLORS.TEXT_PRIMARY }} className="card-user-name">
          {name}
        </div>
        <div
          style={{ color: COLORS.TEXT_SECONDARY }}
          className="card-user-role"
        >
          {description}
        </div>
      </div>
      <div style={{ marginLeft: 15 }}>
        <Link to={ACCOUNT}>
          <div className={styles.baseHeaderAvatarWrapper}>
            <Avatar
              src={avatar || UserPlaceholderAvatar}
              style={{ width: '48px', height: '48px' }}
            />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default UserHomeCard;
