import ExternalLinkBlack from '@assets/icons/link_black.svg';
import CourseCard from '@components/V3/CourseCard';
import PrimaryButtonV3 from '@components/V3/PrimaryButtonV3';
import AgoraSpinner from '@components/V3/Utils/AgoraSpinner';
import SectionTitle from '@components/V3/Utils/SectionTitle';
import { COURSES } from '@routes';
import { useLoginAndRedirectToCourse } from '@shared/react';
import { Empty } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { COLORS } from '../../../v3/global';
import './your-courses.styles.scss';

type Props = {
  enrollments: any;
};

const TeachingCourses = ({ enrollments }: Props) => {
  const history = useHistory();

  const { mutate: redirectToLMS, isLoading } = useLoginAndRedirectToCourse({
    onSuccess: (data) => {
      if (data?.url) {
        setTimeout(() => {
          window.open(data.url, '_blank');
        });
      }
    },
  });

  const divParentRef = useRef<HTMLDivElement | null>(null);
  const [divParentWidth, setDivParentWidth] = useState(0);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [showAll, setShowAll] = useState(false);
  const itemsPerPage = 6;
  const slicedCourses = showAll
    ? enrollments?.teachingEnrollments
    : enrollments?.teachingEnrollments?.slice(0, itemsPerPage);

  const [externalLinkHovered, setExternalLinkHovered] = useState(false);
  const [showAllHovered, setShowAllHovered] = useState(false);

  const handleMouseEnterLink = () => {
    setExternalLinkHovered(true);
  };

  const handleMouseLeaveLink = () => {
    setExternalLinkHovered(false);
  };

  const handleMouseEnterShowAll = () => {
    setShowAllHovered(true);
  };

  const handleMouseLeaveLinkShowAll = () => {
    setShowAllHovered(false);
  };

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  const sectionTitle = 'Teaching';

  useEffect(() => {
    const handleResize = () => {
      if (divParentRef.current) {
        const width = divParentRef.current.offsetWidth;
        setDivParentWidth(width);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <div className="your-courses-header-container">
        <div
          className="teaching-courses-left-container"
          // style={{ minWidth: '120px' }}
        ></div>
        <div className="your-courses-section-title">
          <SectionTitle title={sectionTitle} />
        </div>

        {enrollments?.teachingEnrollments &&
          enrollments?.teachingEnrollments?.length > itemsPerPage &&
          windowWidth > 1017 && (
            <div
              style={{
                cursor: 'pointer',
                fontSize: '13px',
                fontWeight: 'bold',
                marginRight: '16px',
              }}
              onClick={() => setShowAll(!showAll)}
              onMouseEnter={handleMouseEnterShowAll}
              onMouseLeave={handleMouseLeaveLinkShowAll}
            >
              {showAll ? (
                <span
                  style={{
                    textDecoration: showAllHovered ? 'underline' : 'none',
                    whiteSpace: 'nowrap',
                    color: showAllHovered
                      ? COLORS.PRIMARY
                      : COLORS.TEXT_PRIMARY,
                  }}
                >
                  See Less
                </span>
              ) : (
                <span
                  style={{
                    textDecoration: showAllHovered ? 'underline' : 'none',
                    whiteSpace: 'nowrap',
                    color: showAllHovered
                      ? COLORS.PRIMARY
                      : COLORS.TEXT_PRIMARY,
                  }}
                >
                  See All
                </span>
              )}
            </div>
          )}

        <PrimaryButtonV3
          className="mt-8 self-end tablet:mt-0 tablet:self-auto"
          onClick={() =>
            window.open(
              'https://lms.upgrade.education/author/welcome',
              '_blank'
            )
          }
          buttonText="LMS Dashboard"
          icon={ExternalLinkBlack}
        />
      </div>

      <div
        ref={divParentRef}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
        className="your-courses-content"
      >
        {isLoading ? (
          <div className="loading-spinner-container">
            <AgoraSpinner fontSize={40} />
          </div>
        ) : enrollments?.teachingEnrollments?.length &&
          !isLoading &&
          windowWidth > 1017 ? (
          slicedCourses?.map((course: any, index: number) => (
            <div
              key={index}
              onClick={() => {
                if (course?.course?.lmsCourseId) {
                  redirectToLMS({ courseId: course?.course?.lmsCourseId });
                } else {
                  history.push(`${COURSES}/${course?.course?._id}`);
                }
              }}
              style={{
                cursor: 'pointer',
                marginBottom: '16px',
                width:
                  windowWidth > 1340
                    ? `calc(${divParentWidth / 2}px - 16px)`
                    : '100%',
              }}
            >
              <CourseCard
                image={
                  course?.course?.headerImage?.['160x160']
                    ? course.course.headerImage['160x160']
                    : null
                }
                title={course?.course?.title}
                subtitle={course.course?.subtitle}
                color={course.course?.color}
              />
            </div>
          ))
        ) : enrollments?.teachingEnrollments?.length &&
          !isLoading &&
          windowWidth <= 1017 ? (
          enrollments?.teachingEnrollments?.map(
            (course: any, index: number) => (
              <div
                key={course?.course?._id}
                onClick={() => {
                  if (course?.course?.lmsCourseId) {
                    redirectToLMS({ courseId: course?.course?.lmsCourseId });
                  } else {
                    history.push(`${COURSES}/${course?.course?._id}`);
                  }
                }}
                style={{
                  cursor: 'pointer',
                  marginBottom: '16px',
                  width: '100%',
                }}
              >
                <CourseCard
                  image={
                    course?.course?.headerImage?.['160x160']
                      ? course.course.headerImage['160x160']
                      : null
                  }
                  title={course?.course?.title}
                  subtitle={course?.course?.subtitle}
                  color={course?.course?.color}
                />
              </div>
            )
          )
        ) : (
          <div
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            {!isLoading && <Empty />}
          </div>
        )}
      </div>
    </div>
  );
};

export default TeachingCourses;
