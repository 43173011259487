import {
  NewEnrollment,
  NewEnrollmentCourse,
  EnrollmentWithExtraInfo,
  SearchMentorEnrollmentsParams,
  ActiveStudents,
} from '../common';
import { ApiDescription, ApiData, RequestsMethod } from '../types';

export const getEnrollment: ApiDescription = {
  endpoint: '/enrollments/:id',
  method: RequestsMethod.Get,
};
export type GetEnrollment = ApiData<never, NewEnrollment>;

export const getEnrollmentMentorHistory: ApiDescription = {
  endpoint: '/enrollments/:id/mentor-history',
  method: RequestsMethod.Get,
};

export type GetEnrollmentMentorHistory = ApiData<
  never,
  {
    fullName: string;
    addedOn: string;
    status: string;
  }[]
>;

export const createEnrollment: ApiDescription = {
  endpoint: '/enrollments',
  method: RequestsMethod.Post,
};
export type CreateEnrollment = ApiData<
  Partial<Omit<NewEnrollment, 'mentor'>> & {
    userId: string;
    courseId: string;
    numberOfSessions: number;
    mentor:
      | string
      | {
          _id: string;
          fullName: string;
        };
  },
  { message: string; enrollmentId: string }
>;

export const updateEnrollment: ApiDescription = {
  endpoint: '/enrollments/update',
  method: RequestsMethod.Patch,
};
export type UpdateEnrollment = ApiData<
  Partial<Omit<NewEnrollment, 'sessions'>> & { enrollmentId: string },
  NewEnrollment
>;

export const getMyEnrollments: ApiDescription = {
  endpoint: '/enrollments',
  method: RequestsMethod.Get,
};
export type GetMyEnrollments = ApiData<never, NewEnrollment[]>;

export const getEnrollments: ApiDescription = {
  endpoint: '/user-enrollments/get-enrollments/:id',
  method: RequestsMethod.Get,
};
export type GetEnrollments = ApiData<
  { id: string },
  {
    enrollments: NewEnrollment[];
    sessionCompleted: number[];
    graduationYear: number;
  }
>;

export const getStudentEnrollments: ApiDescription = {
  endpoint: '/enrollments/student/:id',
  method: RequestsMethod.Get,
};
export type GetStudentEnrollments = ApiData<never, NewEnrollment[]>;

export const getMentorEnrollments: ApiDescription = {
  endpoint: '/enrollments/mentor/:id',
  method: RequestsMethod.Get,
};
export type GetMentorEnrollments = ApiData<never, NewEnrollment[]>;

export const getUsersEnrollmentsWithPopulatedCourses: ApiDescription = {
  endpoint: '/enrollments/populated',
  method: RequestsMethod.Get,
};

export type GetUsersEnrollmentsWithPopulatedCourses = ApiData<
  never,
  NewEnrollmentCourse[]
>;

export const searchMentorEnrollments: ApiDescription = {
  endpoint: '/enrollments/search-mentor-enrollments',
  method: RequestsMethod.Get,
};

export type SearchMentorEnrollments = ApiData<
  SearchMentorEnrollmentsParams,
  {
    documents: any[];
    page: number;
    limit: number;
    total: number;
  }
>;

export const getActiveStudentsByMentor: ApiDescription = {
  endpoint: '/enrollments/search-active-students',
  method: RequestsMethod.Get,
};

export type GetActiveStudentsByMentor = ApiData<unknown, ActiveStudents[]>;

//TODO: delete
export const getMentorActiveEnrollments: ApiDescription = {
  endpoint: '/enrollments/mentor/:id/active',
  method: RequestsMethod.Get,
};
export type GetMentorActiveEnrollments = ApiData<never, NewEnrollment[]>;

export const updateEnrollmentMentorBeforeFindBy: ApiDescription = {
  endpoint: '/enrollments/update-mentor-before-findby/:enrollmentId',
  method: RequestsMethod.Patch,
};
export type UpdateEnrollmentMentorBeforeFindBy = ApiData<
  { mentorId: string },
  NewEnrollment
>;

export const getEnrollmentsByMentor: ApiDescription = {
  endpoint: '/v2/enrollments/mentor',
  method: RequestsMethod.Get,
};
export type GetEnrollmentsByMentor = ApiData<
  { studentId: string },
  NewEnrollment[]
>;

export const endEnrollment: ApiDescription = {
  endpoint: '/enrollments/end',
  method: RequestsMethod.Post,
};

export type EndEnrollment = ApiData<{ enrollmentId: string }, NewEnrollment>;

export const resumeEnrollment: ApiDescription = {
  endpoint: '/enrollments/resume',
  method: RequestsMethod.Post,
};

export type ResumeEnrollment = ApiData<{ enrollmentId: string }, NewEnrollment>;

export const addSession: ApiDescription = {
  endpoint: '/enrollments/add-session',
  method: RequestsMethod.Patch,
};
export type AddSession = ApiData<{ enrollmentId: string }, NewEnrollment>;

export const deleteSession: ApiDescription = {
  endpoint: '/enrollments/delete-session',
  method: RequestsMethod.Patch,
};
export type DeleteSession = ApiData<
  { enrollmentId: string; sessionIndex: number },
  NewEnrollment
>;

export const getMentorEnrollmentsWithExtraInfo: ApiDescription = {
  endpoint: '/enrollments/mentor/:id/extra-info',
  method: RequestsMethod.Get,
};
export type GetMentorEnrollmentsWithExtraInfo = ApiData<
  {
    id: string;
    teachingPage: number;
    beingTaughtPage: number;
    isEnded: boolean;
    limit?: number;
  },
  {
    teachingEnrollmentsWithExtraInfo: {
      enrollments: EnrollmentWithExtraInfo[];
      page: number;
      limit: number;
      total: number;
    };
    beingTaughtEnrollments: {
      enrollments: NewEnrollmentCourse[];
      page: number;
      limit: number;
      total: number;
    };
  }
>;

export const getStudentEnrollmentsWithExtraInfo: ApiDescription = {
  endpoint: '/enrollments/student/:id/extra-info',
  method: RequestsMethod.Get,
};

export type GetStudentEnrollmentsWithExtraInfo = ApiData<
  {
    id: string;
    page: number;
    isEnded: boolean;
    limit?: number;
  },
  {
    enrollments: EnrollmentWithExtraInfo[];
    page: number;
    limit: number;
    total: number;
  }
>;

export const getMentorTeachingAndEnrolledInEnrollments: ApiDescription = {
  endpoint: '/enrollments/mentor/:id/teaching-and-enrolled-in',
  method: RequestsMethod.Get,
};

export type GetMentorTeachingAndEnrolledInEnrollments = ApiData<
  {
    id: string;
  },
  {
    teachingEnrollments: any[];
    enrolledInEnrollments: any[];
  }
>;

export const getMinimumMentorCapacity: ApiDescription = {
  endpoint: '/v2/enrollments/minimum-mentor-capacity',
  method: RequestsMethod.Get,
};

export type GetMinimumMentorCapacity = ApiData<
  never,
  { currentCapacity: number; activeEnrollments: number }
>;

export const hasMentorUpcomingStudents: ApiDescription = {
  endpoint: '/v2/enrollments/has-upcoming-students',
  method: RequestsMethod.Get,
};

export type HasMentorUpcomingStudents = ApiData<never, boolean>;

export const unenrollUser: ApiDescription = {
  endpoint: '/v2/enrollments/unenroll',
  method: RequestsMethod.Post,
};

export type UnenrollUser = ApiData<{ enrollmentId: string }, unknown>;
