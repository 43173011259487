import authHeader from '@services/auth-header';
import { BASE_URL } from '@shared/frontendEnv';
import styles from '@styles/components/table.module.scss';
import { Button, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { COLORS } from '../../../../v3/global';

interface Props {
  report: any;
  completedNumber?: number;
  isEven: boolean;
}

const OpenReportsRow: React.FC<Props> = ({ report, isEven }) => {
  const handleDownloadActivityReport = async () => {
    const response = await axios.get(
      `${BASE_URL}/api/report/${report._id}/download-activity-report`,
      { headers: authHeader(), responseType: 'blob' }
    );
    saveByteArray(response);
  };

  const classes = `${styles.gridTableRow} ${
    isEven ? styles.gridTableRowEven : styles.gridTableRowOdd
  }`;

  let status = '';
  if (report?.status === 0) {
    status = 'AWAIT INVOICE';
  } else if (report?.status === 1) {
    status = 'NEED REGENERATION';
  } else if (report?.status === 2) {
    status = 'INVOICE UPLOADED';
  } else if (report?.status === 3) {
    status = 'READY FOR PAYMENT';
  } else if (report?.status === 4) {
    status = 'PAID';
  } else {
    status = 'Other';
  }

  const saveByteArray = (blob: any) => {
    const url = window.URL.createObjectURL(blob.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.pdf');
    document.body.appendChild(link);
    link.click();
  };

  const handleDownload = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/report/${report._id}/download-invoice`,
        { headers: authHeader(), responseType: 'blob' }
      );
      // Handle successful response
      message.success('Invoice downloaded successfully');

      saveByteArray(response);
    } catch (error) {
      // Handle error
      console.log('error: ', error);
      // message.error(error.response.data.message);
    }
  };

  return (
    <>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={`${classes} ${styles.gridTableRowFirst}`}
      >
        {report?._id}{' '}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {moment(report?.createdAt).format('MMMM YYYY')}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {moment(report?.updatedAt).format('DD MMM YYYY')}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {report?.paymentAmountInHC} {report?.hubspotCurrency}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {status}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={`${classes} ${styles.gridTableRowLast}`}
      >
        {/*{Modal}*/}
        {report?.status === 2 ? (
          <Button
            type="primary"
            // disabled
            style={{
              border: '2px solid #000000',
              margin: '0px 10px',
              // cursor: 'not-allowed',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div onClick={() => handleDownload()}>Download Invoice</div>
              {/*<Dropdown*/}
              {/*  trigger={['click']}*/}
              {/*  overlay={*/}
              {/*    <div className={dropdown.dropdown}>*/}
              {/*      <div onClick={() => {}} className={dropdown.dropdownItem}>*/}
              {/*        Download Activity Report*/}
              {/*      </div>*/}

              {/*      /!*<DeleteMeetingButton meetingId={meeting._id} />*!/*/}
              {/*    </div>*/}
              {/*  }*/}
              {/*  placement="bottomRight"*/}
              {/*>*/}
              {/*  <DotsVerticalIcon className={button.actionButtonIcon} />*/}
              {/*</Dropdown>*/}
            </div>
          </Button>
        ) : null}

        <Button
          onClick={() => handleDownloadActivityReport()}
          type="primary"
          style={{
            border: '2px solid #000000',
            margin: '0px 10px',
          }}
        >
          <span style={{ width: '170px' }}>View Monthly Report</span>
        </Button>
      </div>
    </>
  );
};

export default OpenReportsRow;
