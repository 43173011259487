import TableHeaderInfoCard from '@components/V3/TableHeaderInfoCard';
import { MENTOR_ROLE, STUDENT_STATUS } from '@shared/constants';
import { useGetAllCourses, useSearchUsersByFullName } from '@shared/react';
import AgoraSearchBar from '@utils/AgoraSearchbar';
import AgoraSelector from '@utils/AgoraSmallSelector';
import { DatePicker } from 'antd';
import { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './students-table-header.styles.scss';

type Props = {
  studentStatistics?: any;
  search?: string;
  handleSearch: (searchValue: string) => void;
  handleFilterBy: (status: string | undefined) => void;
  handleEnrolledIn: (course: string | undefined) => void;
  handleMentoredBy: (mentor: string | undefined) => void;
  handleGraduationYear: (gradYear: number | undefined) => void;
  handleExtraFilter: (extraFilter: any) => void;
  extraFilter: any;
  handleClearStatuses?: () => void;
};

const StudentsTableHeader = ({
  studentStatistics,
  search,
  handleSearch,
  handleFilterBy,
  handleEnrolledIn,
  handleMentoredBy,
  handleGraduationYear,
  handleExtraFilter,
  extraFilter,
  handleClearStatuses,
}: Props) => {
  const { data: allCourses } = useGetAllCourses();

  const [mentorFullName, setMentorFullName] = useState('');

  const { data: allMentors } = useSearchUsersByFullName({
    fullName: mentorFullName,
    role: MENTOR_ROLE,
  });

  return (
    <div
      style={{
        backgroundColor: COLORS.DARK_GREY_ALT,
        padding: '24px 32px 32px 32px',
      }}
    >
      <div
        style={{
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/*HEADER TITLE AND BUTTON*/}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '24px',
          }}
        >
          <h1 style={{ color: COLORS.TEXT_PRIMARY }} className="header-title">
            Students
          </h1>
        </div>

        <div style={{ maxWidth: '1920px' }}>
          {/*TABLE HEADER INFO*/}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              marginBottom: '16px',
            }}
          >
            <TableHeaderInfoCard
              title={studentStatistics?.noCoursesAssigned ?? 'N/A'}
              description={'No courses assigned'}
              // status={'Active'}
              handleExtraFilter={handleExtraFilter}
              extraFilter={extraFilter}
            />
            <TableHeaderInfoCard
              title={studentStatistics?.onGoingCourse ?? 'N/A'}
              description={'Ongoing course'}
              handleExtraFilter={handleExtraFilter}
              extraFilter={extraFilter}
            />
            <TableHeaderInfoCard
              title={studentStatistics?.courseCompleted ?? 'N/A'}
              description={'Courses completed'}
              handleExtraFilter={handleExtraFilter}
              extraFilter={extraFilter}
            />
            <TableHeaderInfoCard
              title={studentStatistics?.noMentorAssigned ?? 'N/A'}
              description={'No mentor assigned'}
              // status={'At Capacity'}
              handleExtraFilter={handleExtraFilter}
              extraFilter={extraFilter}
            />
            <TableHeaderInfoCard
              title={studentStatistics?.needsMentorNow ?? 'N/A'}
              description={'Needs mentor now'}
              // status={'In Training'}
              handleExtraFilter={handleExtraFilter}
              extraFilter={extraFilter}
            />
            <TableHeaderInfoCard
              title={studentStatistics?.mentorAllocated ?? 'N/A'}
              description={'Mentor allocated'}
              handleExtraFilter={handleExtraFilter}
              extraFilter={extraFilter}
            />
            <TableHeaderInfoCard
              title={studentStatistics?.firstSessionScheduled ?? 'N/A'}
              description={'First session scheduled'}
              handleExtraFilter={handleExtraFilter}
              extraFilter={extraFilter}
            />
            <TableHeaderInfoCard
              title={studentStatistics?.firstSessionCompleted ?? 'N/A'}
              description={'First session completed'}
              handleExtraFilter={handleExtraFilter}
              extraFilter={extraFilter}
            />
          </div>

          {/*SEARCHBAR*/}
          <AgoraSearchBar
            search={search}
            handleSearch={handleSearch}
            placeholder={'Search by name, email, phone number...'}
          />

          {/*FILTERS*/}
          <div
            style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
          >
            {/*FILTER BY*/}
            <AgoraSelector
              label="Filter By"
              onSelect={handleFilterBy}
              options={STUDENT_STATUS}
              showOptionsBy="value"
              onClearAll={() => handleFilterBy(undefined)}
            />

            {/*ENROLLED IN*/}
            <AgoraSelector
              label="Enrolled In"
              onSelect={handleEnrolledIn}
              options={allCourses}
              labelKey="title"
              valueKey="_id"
              onClearAll={() => handleEnrolledIn(undefined)}
            />

            {/*MENTORED BY*/}
            <AgoraSelector
              label={'Mentored By'}
              onSelect={handleMentoredBy}
              onSearch={(value) => setMentorFullName(value)}
              options={allMentors}
              labelKey="fullName"
              valueKey="_id"
              onClearAll={() => handleMentoredBy(undefined)}
            />

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: '24px',
                marginTop: '12px',
                maxWidth: '200px',
              }}
            >
              <span
                style={{ color: COLORS.TEXT_PRIMARY, marginBottom: '8px' }}
                className="filter-label"
              >
                Graduation Year
              </span>
              <div style={{ width: '200px' }} className="custom-date-picker">
                <DatePicker
                  style={{ width: '200px' }}
                  picker="year"
                  onChange={(date, dateString) => {
                    if (dateString) {
                      handleGraduationYear(parseInt(dateString));
                    } else {
                      handleGraduationYear(undefined);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentsTableHeader;
