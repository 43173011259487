import { ToggleModalProps } from '@shared/common';
import { Drawer } from 'antd';
import WhiteBoard from './WhiteBoard';

const WhiteboardDrawer = ({ visible, setVisible }: ToggleModalProps) => {
  return (
    <Drawer
      bodyStyle={{ paddingBottom: '50px' }}
      width="100%"
      title="Whiteboard"
      placement="right"
      onClose={() => setVisible(false)}
      visible={visible}
      mask={false}
    >
      <WhiteBoard />
    </Drawer>
  );
};

export default WhiteboardDrawer;
