import { ApiData, ApiDescription, RequestsMethod, User } from '@shared/types';

export const promoteUser: ApiDescription = {
  endpoint: '/user-management/promote',
  method: RequestsMethod.Post,
};
export type PromoteUser = ApiData<
  { id: string; role: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 11 },
  { message: string }
>;

export const updateMyAccount: ApiDescription = {
  endpoint: '/user-management/update-my-account',
  method: RequestsMethod.Post,
};
export type UpdateMyAccount = ApiData<Partial<User>, { message: string }>;

export const sendUserEmail: ApiDescription = {
  endpoint: '/user-management/send-user-email',
  method: RequestsMethod.Post,
};

export type SendUserEmail = ApiData<
  {
    to: string;
    subject: string;
    text: string;
  },
  { message: string }
>;

export const clearRoles: ApiDescription = {
  endpoint: '/user-management/clear-roles',
  method: RequestsMethod.Post,
};
export type ClearRoles = ApiData<{ id: string }, { message: string }>;

export const setActiveStatus: ApiDescription = {
  endpoint: '/user-management/set-active-status',
  method: RequestsMethod.Post,
};
export type SetActiveStatus = ApiData<
  { userId: string; deactivate: boolean; reason?: string },
  unknown
>;

export const changeUserTestState: ApiDescription = {
  endpoint: '/user-management/change-user-test-state',
  method: RequestsMethod.Post,
};

export type ChangeUserTestState = ApiData<
  { userId: string; isTestUser: boolean },
  unknown
>;

export const migrateUsersToAuth0: ApiDescription = {
  endpoint: '/user-management/migrate-users-to-auth0',
  method: RequestsMethod.Post,
};

export type MigrateUsersToAuth0 = ApiData<unknown, unknown>;

export const updateUsersWithAuthID: ApiDescription = {
  endpoint: '/user-management/update-users-with-authid',
  method: RequestsMethod.Post,
};

export type UpdateUsersWithAuthID = ApiData<unknown, unknown>;

export const updateAllUsers: ApiDescription = {
  endpoint: '/user-management/update-users-from-auth0',
  method: RequestsMethod.Post,
};

export type UpdateUsersFromAuth0 = ApiData<unknown, unknown>;

export const cleanUsersWithAuthID: ApiDescription = {
  endpoint: '/user-management/clean-users-with-authid',
  method: RequestsMethod.Post,
};

export type CleanUsersWithAuthID = ApiData<unknown, unknown>;

export const dezactivateUsersFromAgoraInAuth0: ApiDescription = {
  endpoint: '/user-management/deactivate-users-from-agora-in-auth0',
  method: RequestsMethod.Post,
};

export type DezactivateUsersFromAgoraInAuth0 = ApiData<unknown, unknown>;

export const migrateMentorStatuses: ApiDescription = {
  endpoint: '/user-management/migrate-mentor-statuses',
  method: RequestsMethod.Patch,
};

export type MigrateMentorStatuses = ApiData<unknown, unknown>;

export const sendActivationLink = {
  endpoint: '/user-management/send-activation-link',
  method: RequestsMethod.Post,
};

export type SendActivationLink = ApiData<{ id: string }, unknown>;

export const resetAuth0Password = {
  endpoint: '/user-management/reset-auth0-password',
  method: RequestsMethod.Post,
};

export type ResetAuth0Password = ApiData<
  { id: string; password: string },
  unknown
>;

export const syncHubspotIBANs = {
  endpoint: '/user-management/sync-hubspot-ibans',
  method: RequestsMethod.Patch,
};

export type SyncHubspotIBANs = ApiData<unknown, unknown>;

export const populateUsersWithHarvesterToken = {
  endpoint: '/user-management/populate-users-with-harvester-token',
  method: RequestsMethod.Post,
};

export type PopulateUsersWithHarvesterToken = ApiData<unknown, unknown>;

export const checkDriveStatus: ApiDescription = {
  endpoint: '/user-management/check-drive-status',
  method: RequestsMethod.Get,
};

export type CheckDriveStatus = ApiData<
  {
    id: string;
    searchBy: string;
  },
  unknown
>;

export const deleteFolderContents = {
  endpoint: '/user-management/delete-folder-contents',
  method: RequestsMethod.Delete,
};

export type DeleteFolderContents = ApiData<
  {
    folderId: string;
  },
  unknown
>;

export const saveDriveIds: ApiDescription = {
  endpoint: '/user-management/save-drive-ids',
  method: RequestsMethod.Patch,
};

export type SaveDriveIds = ApiData<unknown, unknown>;

export const copyDocuments = {
  endpoint: '/user-management/copy-documents',
  method: RequestsMethod.Patch,
};

export type CopyDocuments = ApiData<
  {
    oldStudentBaseFolder: string;
    oldMentorBaseFolder: string;
  },
  unknown
>;

export const createMentorShortcuts = {
  endpoint: '/user-management/create-mentor-shortcuts',
  method: RequestsMethod.Post,
};

export type CreateMentorShortcuts = ApiData<unknown, unknown>;

export const changeFoldersPermission = {
  endpoint: '/user-management/change-folders-permission',
  method: RequestsMethod.Patch,
};

export type ChangeFoldersPermission = ApiData<
  {
    oldStudentBaseFolder: string;
    oldMentorBaseFolder: string;
  },
  unknown
>;

export const deleteShortcuts = {
  endpoint: '/user-management/delete-shortcuts',
  method: RequestsMethod.Delete,
};

export type DeleteShortcuts = ApiData<unknown, unknown>;
