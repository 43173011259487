import { MentorSelectorValue } from '@components/Selectors/MentorSelector';
import MentorSelector from '@components/V3/MentorSelector';
import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import { CreateEnrollment } from '@shared/apis/enrollments.apis';
import { subjectsOptions } from '@shared/constants';
import {
  useCreateEnrollment,
  useGetAllCourses,
  useGetEnrollments,
} from '@shared/react';
import AgoraBigSelector from '@utils/AgoraBigSelector';
import { DatePicker, message } from 'antd';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './profile-modal-students.styles.scss';

type Props = {
  handleCurrentPage: (page: string) => void;
  studentId: string;
  refetchEnrollments: () => any;

  studentData: any;
  refetchStudentData: () => void;
};

const AddLanguageExam = ({
  handleCurrentPage,
  studentId,
  refetchEnrollments,

  studentData,
  refetchStudentData,
}: Props) => {
  const [selectedCourse, setSelectedCourse] = useState<null | string>(null);
  const [selectedUniversity, setSelectedUniversity] = useState();
  const [selectedSubject, setSelectedSubject] = useState<null | string>(null);

  const [selectedMentor, setSelectedMentor] = useState<MentorSelectorValue>({
    choice: {
      label: 'TBD',
      value: 'TBD',
    },
  });

  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [estimatedEndDate, setEstimatedEndDate] = useState<Moment | null>(
    moment().add(1, 'years')
  );
  const [nrSessions, setNrSessions] = useState(10);

  const { data: courses } = useGetAllCourses();
  const { refetch: refetchUserEnrollments } = useGetEnrollments(studentId);

  const { mutate: createEnrollment, isLoading: creatingEnrollment } =
    useCreateEnrollment({
      onSuccess: () => {
        refetchStudentData();
        refetchUserEnrollments();
        message.success('Enrollment created successfully');
        setSelectedCourse(null);
        handleCurrentPage('enrollments');
        refetchEnrollments();
      },
    });

  const handleEnrollBeingTaught = () => {
    if (!selectedCourse || !selectedSubject) {
      message.error('Please select a course and a subject');
      return;
    }

    const course = courses?.find((course) => course._id === selectedCourse);
    if (!course) {
      message.error('Course not found!');
      return;
    }

    if (!startDate) {
      message.error('Please select a start date');
      return;
    }

    const data: CreateEnrollment['payload'] = {
      userId: studentId,
      courseId: selectedCourse,
      subject: selectedSubject,
      mentor: 'none',
      startDate: startDate?.toISOString(),
      estimatedEndDate: estimatedEndDate?.toISOString(),
      numberOfSessions: nrSessions,
    };

    // Add mentor
    if (selectedMentor?.choice?.value === 'TBD') {
      data.mentor = 'TBD';
    } else {
      const mentor = selectedMentor?.user;
      if (!mentor) {
        message.error('Unexpected error: Mentor not defined!');
        return;
      }

      data.mentor = {
        _id: mentor.value,
        fullName: mentor.label,
      };
    }

    createEnrollment(data);
  };

  return (
    <div style={{ marginTop: '8px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}></div>
      {/*<div style={{ marginBottom: '24px' }}>*/}
      {/*  <label*/}
      {/*    style={{ color: COLORS.PRIMARY }}*/}
      {/*    htmlFor="course"*/}
      {/*    className="block text-sm font-medium leading-6 text-gray-900"*/}
      {/*  >*/}
      {/*    Course*/}
      {/*  </label>*/}
      {/*  <select*/}
      {/*    onChange={(e) => {*/}
      {/*      const selectedValue = e.target.value;*/}
      {/*      setSelectedCourse(selectedValue);*/}
      {/*    }}*/}
      {/*    id="course"*/}
      {/*    name="course"*/}
      {/*    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-white-900 focus:text-customPrimary ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-customPrimary sm:text-sm sm:leading-6"*/}
      {/*    style={{*/}
      {/*      backgroundColor: 'transparent',*/}
      {/*    }}*/}
      {/*    placeholder="Select a course"*/}
      {/*  >*/}
      {/*    <option value={undefined}></option>*/}
      {/*    {courses?.map((course: any) => (*/}
      {/*      <option key={course._id} value={course._id}>*/}
      {/*        {course.title}*/}
      {/*      </option>*/}
      {/*    ))}*/}
      {/*  </select>*/}
      {/*</div>*/}
      <AgoraBigSelector
        label="University"
        onSelect={(value: any) => setSelectedUniversity(value)}
        options={courses}
        labelKey="title"
        valueKey="_id"
      />

      <div style={{ marginBottom: '24px' }}>
        <label
          style={{ color: COLORS.PRIMARY }}
          htmlFor="subject"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Subject
        </label>
        <select
          onChange={(e) => {
            const selectedValue = e.target.value;
            setSelectedSubject(selectedValue);
          }}
          placeholder="Select a subject"
          id="subject"
          name="subject"
          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-white-900 focus:text-customPrimary ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-customPrimary sm:text-sm sm:leading-6"
          style={{
            backgroundColor: 'transparent',
          }}
        >
          <option value={undefined}></option>
          {subjectsOptions &&
            subjectsOptions.map((subject: any) => (
              <option key={subject} value={subject}>
                {subject}
              </option>
            ))}
        </select>
        <p
          style={{ color: COLORS.TEXT_SECONDARY }}
          className="mt-2 text-sm text-gray-500"
          id="email-description"
        >
          Students Subjects: Mathematics, Biology
        </p>
      </div>

      <div style={{ marginBottom: '24px' }}>
        <label
          style={{ color: COLORS.PRIMARY, marginBottom: '8px' }}
          htmlFor="student"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Mentor
        </label>

        <MentorSelector
          disabled={creatingEnrollment}
          onChange={(data) => {
            setSelectedMentor(data);
          }}
          value={selectedMentor}
        />
      </div>

      <div style={{ display: 'flex', width: '100%', marginBottom: '24px' }}>
        <div style={{ width: '50%', marginRight: '24px' }}>
          <label
            style={{ color: COLORS.PRIMARY, backgroundColor: 'transparent' }}
            htmlFor="location"
            className="block text-sm font-medium leading-6 text-customPrimary"
          >
            Start Date
          </label>
          <DatePicker
            value={startDate}
            onChange={(date) => setStartDate(date)}
            style={{ backgroundColor: 'transparent', width: '100%' }}
            className="custom-date-picker"
          />
        </div>

        <div style={{ width: '50%' }}>
          <label
            style={{ color: COLORS.PRIMARY, backgroundColor: 'transparent' }}
            htmlFor="location"
            className="block text-sm font-medium leading-6 text-customPrimary"
          >
            End Date
          </label>
          <DatePicker
            value={estimatedEndDate}
            onChange={(date) => setEstimatedEndDate(date)}
            style={{
              backgroundColor: 'transparent',
              width: '100%',
            }}
            className="custom-date-picker"
          />
        </div>
      </div>

      <div style={{ marginBottom: '64px', width: 'calc(50% - 12px)' }}>
        <label
          style={{ color: COLORS.PRIMARY }}
          htmlFor="noOfMeetings"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          No. of meetings
        </label>
        <div className="mt-2">
          <input
            onChange={(e) => setNrSessions(parseInt(e.target.value))}
            value={nrSessions}
            type="number"
            name="noOfMeetings"
            min="1"
            id="noOfMeetings"
            className="block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-customPrimary sm:text-sm sm:leading-6"
            style={{
              backgroundColor: 'transparent',
            }}
          />
        </div>
      </div>

      <SecondaryButton
        onClick={handleEnrollBeingTaught}
        backgroundColor={COLORS.WHITE}
        height="32px"
        padding="4px 10px 4px 10px"
        margin="12px 0 0 0"
        width="100%"
      >
        <span className="button-text" style={{ color: COLORS.BLACK }}>
          Create Enrolment
        </span>
      </SecondaryButton>
    </div>
  );
};

export default AddLanguageExam;
