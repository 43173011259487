import { useUpcomingMeetings } from '@shared/react';
import style from '@styles/views/home.module.scss';
import { Spin, Typography } from 'antd';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import NextMeetingMentor from './NextMeetingMentor';

type Props = {
  isMentor?: boolean;
};

const NextMeetingsMentor = ({ isMentor }: Props) => {
  const { data: meetings, isLoading, isError } = useUpcomingMeetings();

  const [viewAll, setViewAll] = useState(false);

  if (isLoading) {
    return <Spin />;
  }
  if (isError) {
    return (
      <Typography.Paragraph
        style={{ color: COLORS.TEXT_PRIMARY, fontSize: '16px' }}
      >
        An error has occurred...
      </Typography.Paragraph>
    );
  }

  return (
    <div className={style.meetings}>
      {!meetings?.length ? (
        <Typography.Paragraph
          style={{ color: COLORS.TEXT_SECONDARY, fontSize: '16px' }}
        >
          No upcoming meetings
        </Typography.Paragraph>
      ) : null}

      {!viewAll && meetings?.length
        ? meetings.map((meeting, index) =>
            index < 5 ? (
              <div>
                <NextMeetingMentor
                  key={meeting._id}
                  meeting={meeting}
                  isMentor={isMentor}
                />
              </div>
            ) : null
          )
        : null}

      {viewAll && meetings?.length
        ? meetings.map((meeting, index) => (
            <div>
              <NextMeetingMentor
                key={meeting._id}
                meeting={meeting}
                isMentor={isMentor}
              />
            </div>
          ))
        : null}

      {meetings && meetings?.length > 5 ? (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <span
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              font: 'normal normal medium 13px/16px Urbane Rounded',
              fontSize: '13px',
              color: COLORS.PRIMARY,
              alignSelf: 'flex-end',
            }}
            onClick={() => setViewAll(!viewAll)}
          >
            {viewAll ? 'View less' : 'View all'}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default NextMeetingsMentor;
