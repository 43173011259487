import NextMeeting from '@components/V3/NextMeeting';
import { NewMeeting } from '@shared/common';
import React from 'react';
import JoinButtonStudent from '../../Common/Buttons/JoinButtonStudent';
import './meeting-needing-feedback-student.styles.scss';

interface Props {
  meeting: NewMeeting;
}

const MeetingNeedingFeedbackStudent: React.FC<Props> = ({ meeting }) => {
  const { mentor } = meeting;

  return (
    <NextMeeting
      meetingNeedingFeedbackStudent={true}
      meeting={meeting}
      otherName={mentor.fullName}
    >
      <div className="needing-feedback-student-join-button-container">
        <JoinButtonStudent meeting={meeting} />
      </div>
    </NextMeeting>
  );
};

export default MeetingNeedingFeedbackStudent;
