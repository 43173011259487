/* eslint-disable @typescript-eslint/ban-ts-comment */
import ViewMoreLess from '@components/ViewMoreLess';
import { TrashIcon } from '@heroicons/react/outline';
import { MEETING_STATUSES, MeetingStatus } from '@shared/constants';
import { useDeleteMeeting, useUpdateNewMeeting } from '@shared/react';
import styles from '@styles/components/sessions-menu.module.scss';
import {
  Button,
  Checkbox,
  Input,
  message,
  Popconfirm,
  Rate,
  Select,
} from 'antd';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';

type Props = {
  meetingId: string;
  onDelete?: (meetingId: string) => void;
  meeting: any;
  refetch?: () => void;
};

const MeetingEditor: React.FC<Props> = ({
  meetingId,
  onDelete,
  meeting,
  refetch,
}) => {
  const { mutate: updateMeeting } = useUpdateNewMeeting(meetingId, {
    onSuccess: () => {
      message.success('Meeting updated successfully!');
      refetch && refetch();
    },
  });

  const [name, setName] = useState(meeting?.name);
  const [status, setStatus] = useState<MeetingStatus>(meeting?.status);
  const [isVisible, setIsVisible] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!meeting) {
      return;
    }
    setStatus(meeting.status);
  }, [meeting]);

  const statuses = Object.values(MeetingStatus).filter(
    (s) => typeof s == 'number'
  ) as MeetingStatus[];

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const { mutate: deleteMeeting, isLoading } = useDeleteMeeting(meetingId, {
    onSuccess: () => {
      message.success('Meeting deleted successfully.');
    },
  });

  // Reset state
  useEffect(() => {
    if (!meeting) {
      return;
    }
    // @ts-ignore
    setName(meeting?.name);
  }, [meeting]);

  useEffect(() => {
    if (status === meeting?.status) {
      return;
    }
    updateMeeting({ _id: meeting._id, status });
  }, [status]);

  // Debouncer
  useEffect(() => {
    const id = setTimeout(() => {
      // @ts-ignore
      if (!meeting || meeting?.name === name) {
        return;
      }
      // @ts-ignore
      updateMeeting({
        _id: meeting._id,
        name,
      });
    }, 2000);
    return () => clearTimeout(id);
  }, [name]);

  if (!meeting) {
    return null;
  }

  return (
    <div>
      <div>
        <div className={styles.editorRow}>
          <div>Name:</div>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ width: 300 }}
          />
        </div>
        <div className={styles.editorRow}>
          <div>Status:</div>
          <Select
            onChange={(val: MeetingStatus) => setStatus(val)}
            value={status}
            style={{ width: 300 }}
          >
            {statuses.map((s) => {
              return (
                <Select.Option key={s} value={s}>
                  {MEETING_STATUSES[s]}
                </Select.Option>
              );
            })}
          </Select>
        </div>
        <div className={styles.editorRow}>
          <div>Date:</div>
          <div>{moment(meeting.startDate).toLocaleString()}</div>
        </div>
        <div className={styles.editorRow}>Mentor</div>
        <div className={styles.editorRow}>
          <div style={{ whiteSpace: 'nowrap' }}>Name: </div>
          <div>&nbsp;{meeting?.mentor?.fullName}</div>
        </div>
        <div className={styles.editorRow}>
          <div>Attended:</div>
          <Checkbox
            checked={meeting?.attended?.mentor}
            onChange={(e) => {
              updateMeeting({
                _id: meeting._id,
                attended: { ...meeting.attended, mentor: e.target.checked },
              });
              meeting.attended.mentor = e.target.checked;
            }}
          />
        </div>
        <div className={styles.editorRow}>
          <div style={{ whiteSpace: 'nowrap' }}>Homework Rate: </div>
          <div>
            &nbsp;{meeting?.mentorFeedback?.studentHomework?.rate ?? '?'}/5
          </div>
        </div>
        <div className={styles.editorRow}>
          <div style={{ whiteSpace: 'nowrap' }}>Homework Explanation: </div>
          <div>
            &nbsp;
            <ViewMoreLess
              text={meeting?.mentorFeedback?.studentHomework?.explanation}
            />
          </div>
        </div>
        <div className={styles.editorRow}>
          <div style={{ whiteSpace: 'nowrap' }}>Engagement Rate: </div>
          <div>
            &nbsp;{meeting?.mentorFeedback?.studentEngagement?.rate ?? '?'}/5
          </div>
        </div>
        <div className={styles.editorRow}>
          <div style={{ whiteSpace: 'nowrap' }}>Engagement Explanation: </div>
          <div>
            &nbsp;
            <ViewMoreLess
              text={meeting?.mentorFeedback?.studentEngagement?.explanation}
            />
          </div>
        </div>
        <div className={styles.editorRow}>
          <div style={{ whiteSpace: 'nowrap' }}>Progress Rate:</div>
          <div>
            &nbsp;{meeting?.mentorFeedback?.studentProgress?.rate ?? '?'}/5
          </div>
        </div>
        <div className={styles.editorRow}>
          <div style={{ whiteSpace: 'nowrap' }}>Progress Explanation:</div>
          <div>
            &nbsp;
            <ViewMoreLess
              text={meeting?.mentorFeedback?.studentProgress?.explanation}
            />
          </div>
        </div>
        <div className={styles.editorRow}>
          <div style={{ whiteSpace: 'nowrap' }}>Current Status:</div>
          <div>
            &nbsp;
            <ViewMoreLess text={meeting?.mentorFeedback?.currentStatus} />
          </div>
        </div>
        <div className={styles.editorRow}>
          <div style={{ whiteSpace: 'nowrap' }}>Action Plan:</div>
          <div>
            &nbsp;
            <ViewMoreLess text={meeting?.mentorFeedback?.actionPlan} />
          </div>
        </div>
        <div className={styles.editorRow}>
          <div style={{ whiteSpace: 'nowrap' }}>Parent Help:</div>
          <div>
            &nbsp;
            <ViewMoreLess text={meeting?.mentorFeedback?.parentHelp} />
          </div>
        </div>
        <div className={styles.editorRow}>
          <div style={{ whiteSpace: 'nowrap' }}>Overall Feedback:</div>
          {/*<div>&nbsp;{meeting.mentorFeedback?.message || '-'}</div>*/}
          <div
            dangerouslySetInnerHTML={{
              __html: meeting?.mentorFeedback?.message || '-',
            }}
          />
        </div>
        <div className={styles.editorRow}>Student</div>
        <div className={styles.editorRow}>
          <div>Attended:</div>
          <Checkbox
            checked={meeting?.attended?.student}
            onChange={(e) => {
              updateMeeting({
                _id: meeting._id,
                attended: { ...meeting.attended, student: e.target.checked },
              });
              meeting.attended.student = e.target.checked;
            }}
          />
        </div>
        <div className={styles.editorRow}>
          <div>Feedback:</div>
          <div>
            {meeting.feedback?.length
              ? meeting.feedback.map(({ message }: any) => {
                  return <div key={message}>{message}</div>;
                })
              : '-'}
          </div>
        </div>
        <div className={styles.editorRow}>
          <div>Rating:</div>
          <div>
            {meeting.feedback?.length
              ? meeting.feedback.map(({ rating }: any) => {
                  return (
                    <div key={rating}>
                      {rating}
                      <Rate
                        disabled
                        defaultValue={rating}
                        style={{ fontSize: '10px', marginLeft: 10 }}
                      />
                    </div>
                  );
                })
              : '-'}
          </div>
        </div>

        <div className={styles.editorRow}>
          <div>Updated on:</div>
          <div>{moment(meeting.updatedAt).toLocaleString()}</div>
        </div>
        <div className={styles.editorRow}>
          <Popconfirm
            title="Are you sure you want to delete this meeting?"
            onConfirm={() => {
              deleteMeeting({});
              setIsVisible(false);
              onDelete && onDelete(meetingId);
            }}
            okText="Yes"
            visible={isVisible}
            cancelText="No"
            okButtonProps={{
              loading: isLoading,
            }}
            onCancel={() => setIsVisible(false)}
          >
            <Button
              style={{ marginTop: '16px' }}
              onClick={() => setIsVisible(true)}
            >
              <TrashIcon
                className={styles.icon}
                color="red"
                style={{
                  width: '20%',
                }}
              />
            </Button>
          </Popconfirm>
        </div>
      </div>
    </div>
  );
};

export default MeetingEditor;
